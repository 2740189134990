/* Workativ | Cognitive Automation Solutions */

/************ TABLE OF CONTENTS ***************

Mobile Responsive

**********************************************/

/*******  landing modal popup start *******/

/* background color hrader changes */

.landing-virtual-agent .landing_bg.bg_header_trial.trial_mb_80 {
  padding-top: 80px;
  margin-bottom: 0px !important;
}

.landing_it_virtual .center_image_home {
  background: #f8f8f8;
  width: 100%;
  float: left;
  padding-top: 60px;
  margin-bottom: 60px;
  padding-bottom: 60px;
  position: relative;
  top: -1px;
}

.landing_it_virtual .center_image_home.cards_landing_page {
  padding: 40px 0px;
  margin-bottom: 40px;
}

.landing_it_virtual .apps_landing_page {
  margin-bottom: 0px;
}

.landing_it_virtual .footer_form {
  margin-bottom: 40px;
}

.landing_it_virtual .video_section .cogni_fav_apps_header h3 {
  font-family: "Roboto-Medium";
}

.landing_it_virtual .video_section {
  margin-bottom: 40px;
}

.landing_it_virtual .servicenow_virtual {
  background: #fff;
}

.trial_page_img.bg_trial_page.bg_transparent.landing_virtual {
  background: #f5f5f5;
}

.trial_page_img.bg_landing_yellow.bg_landing_virtual {
  padding-top: 60px;
}

.landing_bg .trial_page_img.bg_trial_page.bg_landing_white_mb {
  margin-bottom: 0px;
  background: #fff;
}

.home_bg_header {
  background: #edf1ff 0% 0% no-repeat padding-box;
}

.bg_slider_home {
  background: #edf1ff;
}

.accordion-wrapper li.active svg {
  transform: rotate(0deg);
}

.bg_slider_home.pad_0 {
  padding: 0px;
}

.top_header {
  width: 100%;
  float: left;
  min-height: 40px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.top_header_open {
  height: auto;
  padding-top: 25px;
  padding-bottom: 15px;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
}

.top_header_open .navbarlogo_img {
  display: none;
}

.top_header.header.res_header.sticky .navbarlogo_img {
  display: none;
}

.top_header.header.res_header.sticky .navbarlogo_main {
  display: none;
}

.footerlink_left {
  width: 100%;
  float: left;
  height: 100%;
  position: relative;
}

.footerlink_left:before {
  content: "";
  border-right: 1px solid #999;
  position: absolute;
  right: 20px;
  height: 100%;
}

.footerlink_left .footerlogo_left {
  position: absolute;
  bottom: 0;
}

.footerlink_left .footerlogo_left a {
  padding-bottom: 0px;
}

.footer_right {
  width: 100%;
  float: left;
  height: 100%;
  position: relative;
}

.footer_right .wrapperlink {
  padding-top: 10px;
}

.footer_right .wrapperlink:nth-child(1) {
  padding-top: 50px;
}

.footer_right .wrapperlink:nth-child(2) {
  padding-top: 50px;
}

.footer_right .wrapperlink:nth-child(3) {
  padding-top: 20px;
}

.footer_right .wrapperlink:nth-child(4) {
  padding-top: 50px;
}

.footer_right .wrapperlink:nth-child(5) {
  padding-top: 50px;
}

.footerlink_left .wrapperlink {
  padding-top: 50px;
}

.footer_home {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}

.footer_home .footer_aws {
  position: absolute;
  bottom: 0;
}

.footer_home .footer_aws img {
  width: 85%;
  height: auto;
}

.top_header_open._mob_header .linkwrapper {
  border-bottom: none;
}

.top_header_open .linkwrapper {
  border-bottom: 1px solid #c1c1c1;
}

.header_menu {
  width: 100%;
  float: left;
  position: relative;
}

.header_menu img {
  width: 36px;
  height: 36px;
  position: relative;
  top: 8px;
  margin-right: 5px;
}

.header_menu span svg {
  width: 36px !important;
  height: 36px !important;
  position: relative;
  top: 8px;
  margin-right: 5px;
  transform: rotate(0deg) !important;
  margin-left: 0px;
}

.header_menu p {
  color: #193a5d;
  font-size: 14px;
  font-weight: 300;
  font-family: "Gordita-Regular";
  margin-bottom: 0px;
  max-width: 240px;
  line-height: inherit;
  padding-left: 44px;
  padding-bottom: 12px;
}

.header_menu span {
  font-family: "Gordita-Medium";
}

.sticky_pad_0:first-child .home_menu_top .header_menu:before {
  position: absolute;
  content: "";
  width: 100%;
  border-bottom: 1px solid #c1c1c1;
  height: 1px;
  bottom: -9px;
}

.footerlogo_left .home_img_footer {
  height: auto;
  position: absolute;
  bottom: 0;
}

/* Header menu changes */

/* Integrations new Ui start */

.freq_ask_questions p:last-child {
  margin-bottom: 0px;
}

.apps_integrations.bg_marketpalce .cogni_fav_apps {
  margin-bottom: 40px;
}

.last_bg_pad_0:nth-child(3) .pl-10-tage.trial_page_img.bg_trial_page {
  margin-bottom: 0px;
}

.last_bg_pad_0:nth-child(5) .pl-10-tage.trial_page_img.bg_trial_page {
  margin-bottom: 0px;
}

.last_bg_pad_0 {
  width: 100%;
  float: left;
}

.auth_0_pad_0 {
  width: 100%;
  float: left;
}

.auth_0_pad_0 .pl-10-tage.trial_page_img.bg_trial_page:last-child {
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.row.beta_change_form ul {
  width: 100%;
  float: left;
  padding-left: 20px;
}

.row.beta_change_form ul li {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.overview_wrapper .row.beta_change_form ul li {
  margin-bottom: 15px;
}

.support_chatbot {
  width: 100%;
  float: left;
  padding-bottom: 0px;
}

.content_left_img_right.chat_channels_page {
  width: 100%;
  float: left;
  padding-top: 0px;
}

.features_container_left {
  width: 100%;
  float: left;
  padding-top: 0px;
}

.features_change_img_header.chat_img_header .assistant_change_img {
  background: none;
  box-shadow: none;
}

.integrations_cards_wrapper {
  width: 100%;
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dad9d9;
  padding: 30px;
  text-decoration: none !important;
  border-radius: 6px;
}

.integrations_cards_wrapper:hover {
  box-shadow: 10px 10px 30px #00000029;
  border: 1px solid #fff;
}

.integrations_cards_wrapper img {
  width: 64px;
  height: 64px;
}

.integrations_cards_wrapper h5 {
  width: 100%;
  float: left;
  font-size: 16px;
  font-family: "Roboto-Medium";
  color: #333333;
  text-align: left;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 12px;
}

.integrations_cards_wrapper .card-text {
  width: 100%;
  float: left;
  font-size: 13px;
  font-family: "Roboto-Regular";
  color: #416183;
  text-align: left;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 38px;
}

.integrations_cards_wrapper .card_link {
  width: 100%;
  float: left;
  font-size: 14px;
  font-family: "Roboto-Regular";
  color: #2468f6;
  text-align: left;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.integrations_cards.col-lg-3 {
  padding: 0px 8px;
  padding-bottom: 16px;
}

.integrations_cards_wrapper span {
  width: 12px;
  height: 12px;
}

.integrations_cards_wrapper span svg {
  width: 12px;
  height: 12px;
}

.Integrations_card .search_marketpalce {
  margin-left: 8px;
  margin-right: 8px;
}

.bg_marketpalce .marketplace_integrations_icons {
  padding-bottom: 0px;
}

.helpdesk_solutions.card_two {
  padding-top: 0px;
  padding-bottom: 40px;
}

.awards_img_coantainer {
  display: flex;
  width: 100%;
  float: left;
  margin-bottom: 60px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0px 40px;
}

.awards_img_coantainer a {
  width: 50%;
  float: left;
  padding: 0px 30px;
  height: 100%;
}

.awards_img_coantainer a img {
  width: 100%;
  float: left;
  filter: drop-shadow(7px 8px 12px rgb(104, 103, 103, 0.1));
  padding: 0px;
  height: 100%;
}

.awards_img_coantainer a:last-child {
  cursor: default;
  padding-left: 20px;
}

.awards_img_coantainer a:first-child {
  padding-right: 20px;
}

/* Integrations new Ui end */

.container_trial .accordian_landing .faq_landing .accordion .link {
  cursor: pointer;
  display: flex;
  padding: 15px 15px 15px 42px;
  color: #4095e9;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
}

.container_trial .more-faqs-content p {
  display: flex;
  justify-content: center;
}

.faq_landing ul li svg {
  width: 18px;
  height: 18px;
  fill: #4095e9;
  transform: rotate(180deg);
}

.terms_condtions .statistics-boggle-content h3 {
  font-family: "Gordita-Bold";
  font-size: 28px;
  letter-spacing: -0.2px;
  color: #333333;
  margin-bottom: 20px;
  padding: 0 15px;
  line-height: 1.67;
}

.terms_condtions .statistics-boggle-content ol {
  font-family: "Gordita-Regular";
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #485a63;
  margin-bottom: 40px;
}

.terms_condtions .statistics-boggle-content p {
  font-family: "Gordita-Regular";
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  color: #485a63;
  margin-bottom: 20px;
  padding: 0px 15px;
}

.menu_desktop {
  position: absolute;
  min-width: 150%;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  left: 0;
  top: 30px;
  z-index: 1;
}

.search_icon svg {
  width: 18px;
  height: 18px;
}

.integration_boxes .card-text {
  line-height: inherit;
}

.back_features_hide {
  width: 100%;
  float: left;
}

.back_features_hide .back_features {
  display: none;
}

.content_right_img_left {
  width: 100%;
  float: left;
  margin-bottom: 0px;
}

.content_right_img_left .col-md-6.feature_page_content_left {
  order: 2;
}

.content_left_img_right .col-md-6.feature_page_img_right {
  order: 2;
}

.leftcontent-wrapper {
  width: 100%;
  float: left;
}

.leftimage-wrapper {
  width: 100%;
  float: left;
}

.leftcontent-wrapper .col-md-6.feature_page_img_right {
  order: 2;
}

.leftimage-wrapper .col-md-6.feature_page_content_left {
  order: 2;
}

.conversational_facelift .conversational_facelift_card p {
  line-height: inherit;
}

.card_head.solutions_card .card-text {
  line-height: inherit;
}

.integration_boxes .card_office {
  margin: 25px auto;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  float: none;
}

.submenu_wrapper {
  position: absolute;
  top: 0;
  left: 100%;
  width: auto;
  max-width: 100vw;
  min-width: 150%;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.menu_desktop .submenu_wrapper ul::before {
  display: none;
}

.menu_desktop ul {
  padding-left: 0px;
}

.menu_desktop ul li {
  width: 100%;
  float: left;
  padding: 8px 15px;
}

.menu_desktop ul li a {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menu_desktop ul li a span {
  width: 15px;
  height: 15px;
  float: left;
}

.menu_desktop ul li a span svg {
  width: 15px;
  height: 15px;
  float: left;
  transform: rotate(-90deg);
}

.menu_desktop ul li:hover {
  color: #2468f6;
  background: #f5f5f5;
  z-index: 1;
  position: relative;
}

.menu_desktop ul li:hover a {
  color: #2468f6 !important;
}

.menu_desktop ul li:hover a .submenu_wrapper {
  color: inherit !important;
}

.menu_desktop ul li:hover span svg {
  fill: #2468f6;
}

.submenu_wrapper ul li:first-child:hover {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.submenu_wrapper ul li:last-child:hover {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.menu_desktop ul li:first-child:hover {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.menu_desktop ul li:last-child:hover {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.menu_desktop ul::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(45deg) translateX(-50%);
  -ms-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
  background: white;
  height: 20px;
  width: 20px;
  border-radius: 4px 0 0 0;
  left: 50%;
}

/* landing page css */

.icon-top-img {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.icon-top-img h4 {
  font-family: "Roboto-Bold";
  font-size: 16px;
  color: #333333;
  margin-left: 10px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.icon-top-img span {
  font-family: "Roboto-Bold";
  font-size: 16px;
  color: #333333;
  margin-left: 10px;
  text-transform: uppercase;
}

.icon-top-img img {
  width: 84px;
  height: 84px;
  filter: none;
}

.arrow_svg_link {
  width: 12px;
  height: 12px;
}

.arrow_svg_link svg {
  width: 12px;
  height: 12px;
  margin-left: 3px;
}

.landing_pages_img {
  width: 100%;
  float: left;
}

.container_trial .landing_page_charging .landing_pages_img img {
  width: 84px !important;
  height: 84px;
}

.integrations_pages ul .landing_pages_img h4 {
  display: flex;
  justify-content: center;
  font-size: 22px;
}

.integrations_pages ul .landing_pages_img p {
  font-size: 17px;
  min-height: 85px;
  text-align: center;
}

.integrations_pages .beta-charging ul .landing_pages_img {
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
}

.landing_pages_img .arrow_svg_link svg {
  margin-left: 3px;
}

.card_link_landing {
  width: 100%;
  float: left;
  justify-content: center;
  display: flex;
}

.card_link_landing a {
  width: auto;
}

.feature_page_content_left .card_link_landing {
  width: 100%;
  float: left;
  justify-content: space-between;
}

/* footer-form start */

svg.svg_mobile_open {
  width: 25px;
  height: 25px;
  position: relative;
  left: 10px;
}

svg.svg_mobile_close {
  width: 25px;
  height: 25px;
  position: relative;
  left: 5px;
}

.footer_form {
  width: 100%;
  float: left;
  margin-bottom: 50px;
}

.footer_form_container {
  width: 100%;
  float: left;
  background: #edf1ff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 40px !important;
  height: 100%;
}

.footer_form .row {
  padding: 0px 15px;
}

.footer_form_right {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
  padding: 0px;
}

.footer_form_left {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px;
  width: auto;
}

.footer_form_left .main-slider-left p {
  margin-bottom: 0px;
}

.footer_form_left .main-slider-left h3 {
  margin-bottom: 10px;
}

.footer_form_left .main-slider-left {
  padding: 0px;
}

.footer_form_right .main-slider-button.beta-button input,
select {
  width: 33%;
}

.footer_form_right .main-slider-button.beta-button input.sign_up_form_input {
  width: 100%;
}

.footer_form_right .form_below_content.signup_cta {
  justify-content: center;
}

.container_trial .more-faqs-content p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

/* footer-form end */

/* whitepaer */

.href_web {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}

.href_web a {
  color: #494949;
}

/* whitepaper */

.pl-20-tage.feature_page_top {
  width: 100%;
  float: left;
  padding-bottom: 80px;
}

.solutions_form {
  width: 100%;
  float: left;
  margin-bottom: 60px;
}

.width_100 {
  width: 100%;
  float: left;
}

.knowledge_header_form {
  width: 100%;
  float: left;
  margin-top: 0px;
}

.form_below_content {
  width: 100%;
  float: left;
  padding-top: 0;
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.automate-center-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.automate-center-image-content-first,
.automate-center-image-content-second {
  display: flex;
  justify-content: center;
}
.automate-center-content {
  align-self: center;
}

.automate-home-slider-section {
  background: #f8f8f8;
  padding: 50px 0;
  margin-top: 0;
  margin-bottom: 0;
}
.automate-slider-bottom-main-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.automate-slider-left-first-div,
.automate-slider-left-scnd-div,
.automate-slider-right-first-div,
.automate-slider-right-scnd-div {
  display: flex;
  gap: 20px;
}

.app-integrations-links-automate-home {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}
.app-integrations-url-navigate-automate-home {
  color: #007bff !important;
}

.form_below_content span {
  font-family: "Gordita-Regular";
  font-size: 18px;
  color: #333;
  margin-right: 0px;
}

.container_trial .main-slider-left .basic_plans {
  width: 100%;
  float: left;
  margin-top: 40px;
  color: #333333;
  font-size: 18px;
}

.footer_form .basic_plans {
  display: none;
}

.form_below_content span:last-child {
  margin-right: 0px;
}

.knowledge_header_form .main-slider-button.beta-button {
  width: 80%;
  margin: 0 auto;
  display: inline-block;
}

.knowledge_header_form .main-slider-button.beta-button form {
  justify-content: center;
}

.knowledge_header_form .main-slider-button.beta-button form {
  width: 80%;
  position: relative;
  left: 10%;
}

.beta-button button {
  padding: 10px 0px;
  vertical-align: baseline;
  width: 31%;
  border-radius: 0px;
  background: #2e6dfa;
  border: 1px solid #2e6dfa;
  color: #fff;
  font-size: 16px;
}

.main-slider-button.beta-button form {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
}

.beta-button input:focus {
  outline: 0;
}

.modal_submit_form .modal {
  background: rgba(0, 0, 0, 0.55) none repeat scroll 0% 0%;
  z-index: 10000;
}

.btn.btn-primary.disabled_button {
  pointer-events: none;
  opacity: 0.9;
}

.beta-button input,
select {
  width: 33%;
  padding: 10px;
  margin-right: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;

  font-size: 15px;
  background: #fff !important;
  box-shadow: none;
  color: #333;
  outline: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.beta-button select:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #ccc;
}

.beta-button input:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #ccc;
}

#beta-thankyou-page {
  width: 500px;
  margin: 0 auto;
  padding: 40px 30px;
}

#beta-thankyou-page img {
  width: 240px;
  margin: 0 auto;
  display: block;
  padding-bottom: 25px;
}

#beta-thankyou-page button {
  background: #2e6dfa;
  color: #fff;
  border-radius: 50px;
  width: 112px;
  text-align: center;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
  border: none;
  outline: 0;
  cursor: pointer;
  margin: 0 auto;
}

.thankyou-page-img {
  width: 100%;
  float: left;
}

.thankyou-page-img p {
  color: #485a63;
  font-size: 18px;
  font-family: "Roboto-Regular";
  text-align: center;
  margin-bottom: 20px;
}

.loader_demo {
  width: 100%;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10000;
  background: #000;
  opacity: 0.8;
  top: 0;
  left: 0px;
}

.loader {
  border: 10px solid #ff7900;
  border-radius: 50%;
  border-top: 10px solid #2e6dfa;
  width: 74px;
  height: 74px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-content-popup input[type="number"]::-webkit-inner-spin-button,
.modal-content-popup input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.modal-content-popup ._field-wrapper input {
  width: 100%;
  padding: 6px 7px;
  margin-right: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 15px;
}

.modal-content-popup ._form_element {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.modal-content-popup ._field-wrapper input {
  outline: 0;
}

.modal-content-popup ._field-wrapper select {
  width: 100%;
  height: 35px;
  background: #fff;
  padding: 0px 10px;
  font-size: 15px;
}

#exampleModalCenter .modal-dialog {
  max-width: 50%;
}

.modal-body-header {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
}

#exampleModalCenter .modal-body {
  width: 50%;
  float: left;
}

#exampleModalCenter .modal-body {
  width: 50%;
  float: left;
}

#exampleModalCenter .modal_body_right {
  width: 50%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

#exampleModalCenter .modal_body_right img {
  width: 70%;
  float: left;
}

.modal_submit_form .modal-header {
  border-bottom: 1px solid #fff !important;
  padding-bottom: 0px;
}

.modal_submit_form .modal-header h5 {
  font-size: 24px;
  font-family: "Roboto-Medium";
  padding-top: 15px;
}

.modal_submit_form ._button-wrapper._full_width button {
  border-radius: 50px;
  margin-bottom: 20px;
  background: #2e6dfa;
  border: 1px solid #2e6dfa;
  color: #fff;
  font-family: "Roboto-Regular";
  font-size: 16px;
}

.modal-content.modal-content-popup {
  padding: 0px 15px;
  border-radius: 12px;
}

/****** Landing modal popup end ******/

.iframe_zoho iframe {
  position: relative;
  margin-top: 15px;
  height: 100vh;
}

.--footer-sublink ul li:last-child {
  display: none;
}

.marketplace_footer_ul {
  display: none;
}

li.price_hide {
  display: none;
}

.--quick-link:nth-child(5) {
  display: block;
}

.footer_content_between {
  border-bottom: 1px solid #dadce0;
}

.footer_content_between {
  width: 100%;
  float: left;
}

.wv-social-link {
  display: flex;
  align-items: center;
}

.header-logo .free_trial a {
  color: #fff;
  font-size: 14px;
  font-family: "Roboto-Medium";
  line-height: 16px;
  padding: 0px;
}

.footer_social_icons {
  display: inline-flex;
  align-items: center;
}

.footer_logo {
  width: 100%;
  position: relative;
  left: -3px;
}

.footer_logo img {
  width: 140px;
}

.footer_content_between_row {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  padding-bottom: 65px;
}

.mobile_logo_img {
  display: none;
}

#navbarSupportedContent {
  justify-content: space-between;
}

#navbarSupportedContent .navbar-nav {
  align-items: center;
  display: flex;
}

#navbarSupportedContent .navbar-nav .nav-item {
  position: relative;
  top: 3px;
  padding-left: 25px;
}

#navbarSupportedContent .nav-item.free_trial {
  padding-left: 0px;
  padding-right: 0px;
}

#navbarSupportedContent .header_log_img {
  padding-right: 30px;
}

.lightbox {
  display: none;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 9);
}

.lightbox .lightbox-video {
  width: 100%;
  padding-bottom: 56%;
}

.lightbox iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
}

.lightbox img {
  display: block;
  margin: 0 auto;
}

.lightbox .lightbox-close {
  position: absolute;
  display: block;
  top: 16%;
  right: 18%;
  color: #333333;
  font-size: 28px;
  height: 30px;
  width: 30px;
  background: rgb(255, 255, 255);
  border: 3px solid #e0e0e0;
  border-radius: 50%;
  line-height: 50px;
  text-align: center;
  z-index: 1;
}

.lightbox .lightbox-close:hover {
  text-decoration: none;
}

.lightbox .lightbox-container {
  max-width: 1024px;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

.lightbox-toggle span {
  font-size: 12px;
  padding-top: -16px;
  padding-top: -6px;
  position: relative;
  top: -16px;
}

.col-sm-12.lightbox-column {
  padding: 2px;
}

@media (max-width: 1800px) {
  .container_trial .apps_header_left_container p {
    font-size: 22px;
  }

  .am_cards_wrapper.actions_wrapper p {
    font-size: 22px;
  }

  .container_trial .autoamte_apps_header p {
    font-size: 22px;
  }

  .container_trial .why_autoamte_wrapper p {
    font-size: 22px;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 22px;
  }

  .apps_header_left_container {
    max-width: 90%;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 22px;
  }

  .container_trial .explore_apps_box p {
    font-size: 22px;
  }

  .autoamte_apps_selection {
    width: 175px;
    height: 165px;
  }

  .search_apps_box {
    min-height: 320px;
    max-height: 335px;
  }

  .container_trial p {
    font-size: 20px;
  }
}
/* Workativ Home 4K Screen */

@media (max-width: 3440px) and (min-width: 2561px) {
  .resolution_automate_first .container {
    max-width: 46% !important;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .resolution_automate_first .container {
    max-width: 50% !important;
  }
}

/* Automate Home 4K Screen */

@media (max-width: 3440px) and (min-width: 2561px) {
  .resolution_automate_first_automate_home .container {
    max-width: 50% !important;
  }
  .automate-center-image-content-first .automate-center-content p {
    padding-right: 41%;
  }
  .automate-center-image-content-second .automate-center-content p {
    padding-right: 25%;
  }
  .workflow_automate_feature_paragraph {
    padding-right: 39% !important;
  }
  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: 10.4%;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .resolution_automate_first_automate_home .container {
    max-width: 60% !important;
  }
  .automate-center-image-content-first .automate-center-content p {
    padding-right: 41%;
  }
  .automate-center-image-content-second .automate-center-content p {
    padding-right: 23%;
  }
  .workflow_automate_feature_paragraph {
    padding-right: 31% !important;
  }
  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: 10.4%;
  }
}

@media (max-width: 5000px) and (min-width: 1921px) {
  .container {
    max-width: 80% !important;
  }
}

/* Automate Feature 4K Screen */
@media (max-width: 3440px) and (min-width: 2561px) {
  .resolution_automate_first_automate_feature .container {
    max-width: 50% !important;
  }
  .header_automate_feature_new {
    padding-right: 17%;
  }
  .automate_workflow_head_paragraph {
    padding-right: 15%;
  }
  .features-top-left-section img {
    width: 80px;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .resolution_automate_first_automate_feature .container {
    max-width: 60% !important;
  }
  .header_automate_feature_new {
    padding-right: 7%;
  }
  .automate_workflow_head_paragraph {
    padding-right: 4%;
  }
  .features-top-left-section img {
    width: 80px;
  }
}

/* Automate Demo 4K Screen */
@media (max-width: 3440px) and (min-width: 2561px) {
  .resolution_automate_first_automate_demo .container {
    max-width: 44% !important;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .resolution_automate_first_automate_demo .container {
    max-width: 60% !important;
  }
}

/* Automate ROI 4K Screen */
@media (max-width: 3440px) and (min-width: 2561px) {
  .resolution_automate_first_automate_roi .container {
    max-width: 44% !important;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .resolution_automate_first_automate_roi .container {
    max-width: 60% !important;
  }
}

/* Automate Pricing 4K Screen */
@media (max-width: 3440px) and (min-width: 2561px) {
  .resolution_automate_first_automate_pricing .container {
    max-width: 44% !important;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .resolution_automate_first_automate_pricing .container {
    max-width: 60% !important;
  }
}

/* Automate Single and double 4K Screen */

@media (max-width: 3440px) and (min-width: 2561px) {
  .double_app_automate_new .container {
    max-width: 44% !important;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .double_app_automate_new .container {
    max-width: 60% !important;
  }
}

@media (max-width: 5000px) and (min-width: 1800px) {
  .automate_homepage .feature_inner_page.bg_feature_slider .main-slider-left {
    padding: 0px 10%;
  }

  .automate_homepage
    .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button {
    padding: 0px 15%;
  }

  .container_trial .automate_homepage .cta_autoamte {
    font-size: 18px;
  }

  .left-whitespace {
    display: block;
  }

  /* .cc_bg_feature_slider.bg_feature_slider {
    padding-bottom: 450px;
  } */
  .resolution_automate_first_automate_pricing .top_section.pricing_page .main-slider-button.beta-button.popup_form_pricing{
    padding: 0px !important;
  }
  .top_section.pricing_page .main-slider-button.beta-button {
    padding: 0px 35%;
  }
  

  .rwt__tablist[aria-orientation="vertical"]
    .rwt__tab[aria-selected="true"]:after {
    height: 54px !important;
  }

  .rwt__tab {
    border-radius: 10px 0px 0px 10px;
    min-width: 300px;
    max-width: 300px;
    background: #ededed !important;
    padding: 0px 30px !important;
    height: 54px;
    text-align: left;
    font-size: 18px !important;
  }
}

@media (max-width: 1920px) and (min-width: 1536px) {
  .integrations_card_slider {
    min-height: 60px;
  }

  .feature_page_content_left .card_link_landing {
    width: 100%;
    float: left;
    justify-content: left;
  }

  .card_link_landing.jira_landing_link a {
    margin-right: 40px;
  }

  .container_trial .container {
    max-width: 80%;
  }

  /* .cc_bg_feature_slider.bg_feature_slider {
    padding-bottom: 450px;
  } */
}

@media (min-width: 1200px) and (max-width: 1920px) {
  .container_trial .beta-charging p {
    color: #485a63;
  }

  .trail_apps_landing .cogni_fav_apps_header p {
    color: #416183;
    font-size: 22px;
  }

  .trial_5_img img {
    width: 95%;
  }

  .container_trial .cognii_work_content .cogni_works p {
    font-family: "Roboto-Regular";
    text-align: center;
    margin-bottom: 80px;
    padding: 0px 8%;
  }

  .container_trial .main-slider-left p {
    padding-right: 38px;
    font-size: 20px;
  }

  .row.justify-content-center.beta_change_form {
    margin-top: 30px;
  }

  .lightbox .lightbox-close {
    right: 10% !important;
    top: 8% !important;
  }

  .carousel-control-next-icon {
    position: relative;
    left: 100px;
  }

  .carousel-control-prev-icon {
    position: relative;
    left: -100px;
  }

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1 !important;
  }

  .carousel-indicators {
    bottom: -40px !important;
  }

  .lightbox .lightbox-container {
    max-width: 1360px !important;
  }
}

@media only screen and (min-width: 1650px) and (max-width: 1760px) {
  .enterprise_wrapper_center h4 {
    font-size: 26px;
  }

  .form_below_content span {
    font-size: 15px;
  }

  .how_it_works_header .how_it_content p {
    font-size: 18px;
  }

  .automate_homepage .automate_form_p {
    padding-right: 60px !important;
  }

  .enterprise_wrapper_right button {
    font-size: 18px;
  }
}

@media (max-width: 1700px) {
  .mob_accordian.mob_accordian_faq .accordion__button:before {
    top: 23px;
  }

  .table_full_comapare .table tr th:nth-child(3) {
    width: 140px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .table_full_comapare .table tr th:nth-child(4) {
    width: 140px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .table_full_comapare .table tr th:nth-child(5) {
    width: 140px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .table_full_comapare .table tr th:nth-child(6) {
    width: 140px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .servicenow_virtual .apps_landing_header_wrapper h3 {
    padding: 0px 6%;
    text-align: center;
  }

  .videos_container .submenu_active ul .submenu_active_li {
    height: 48px;
    font-size: 16px;
  }

  .resource_wrapper {
    padding: 40px 30px;
  }

  .get_started_wrapper {
    padding: 40px 30px;
  }

  .videos_wrapper
    .feature_inner_page.bg_feature_slider
    .features_change_img-center
    iframe {
    width: 75%;
    height: 520px;
  }

  .videos_wrapper .back_features_page a {
    font-size: 20px;
  }

  .videos_wrapper .feature_inner_page.bg_feature_slider .main-slider-left h1 {
    padding: 0px 6%;
    margin-bottom: 30px;
  }

  .overview_wrapper .sign_up-form_us.demo_page .get_started_wrapper h5 {
    font-size: 18px;
  }

  .get_started_wrapper button {
    height: 42px;
    font-size: 18px;
  }

  .overview_wrapper .sign_up-form_us.demo_page h6 {
    font-size: 20px;
  }

  .overview_wrapper .row.beta_change_form ul li {
    font-size: 18px;
  }

  .videos_container .search_marketpalce .search_icon {
    height: 90%;
    top: 2px;
  }

  .video_list_ul_header {
    font-size: 18px;
  }

  .videos_container .video_list_li {
    border-radius: 0px;
  }

  .videos_container .videos_submenu ul li {
    padding-bottom: 0px;
    font-size: 16px;
  }

  .videos_container .video_svg_iocn svg {
    width: 24px;
    height: 24px;
  }

  .videos_container .video_content_li {
    font-size: 18px;
  }

  .videos_container .utube_rows > .utube_col_4 p {
    font-size: 15px;
  }

  .category_lsiting_card h4 {
    font-size: 24px;
  }

  .category_lsiting_card img {
    width: 72px;
    height: 72px;
  }

  .category_lsiting_card h6 {
    font-size: 20px;
  }

  .form_automate input {
    margin: 15px 0px;
    font-size: 16px;
  }

  .selected_apps_left_border {
    width: 190px;
    min-height: 160px;
  }

  .explore_apps_box h4 img {
    width: 14%;
    height: 14%;
  }

  .selected_apps_left_border img {
    width: 64px;
    height: 64px;
  }

  .selected_center_connection {
    width: 78px;
    height: 62px;
  }

  .selected_center_connection span svg {
    width: 16px;
    height: 16px;
  }

  .selected_apps_left_border h6 {
    font-size: 16px;
  }

  .am_cards_img span svg {
    width: 9px;
    height: 9px;
  }

  .am_cards_img span.space_apps {
    margin: 0px 3px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_icon {
    height: 54px;
  }

  .three_steps_right h5 {
    font-size: 24px;
  }

  .actions_wrapper_container h4 {
    font-size: 24px;
  }

  .actions_wrapper_container .am_cards_container h5 {
    font-size: 18px;
  }

  .actions_wrapper_container h6 {
    font-size: 16px;
  }

  .show_morw_option span {
    font-size: 24px;
  }

  .actions_wrapper_container h4 span {
    width: 48px;
    height: 48px;
  }

  .actions_wrapper_container .am_cards_container .am_cards_img {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .actions_wrapper_container .am_cards_container .am_cards_content {
    margin-left: 15px;
  }

  .three_steps_wrapper img {
    width: 92px;
    height: 92px;
  }

  .am_cards_img {
    height: 48px;
  }

  .am_cards_img span img {
    width: 36px;
    height: 36px;
  }

  .am_cards_button button {
    height: 42px;
  }

  .am_cards_container h5 {
    font-size: 18px;
  }

  .mob_accordian.mob_accordian_faq .accordion__button {
    font-size: 22px;
  }

  .mob_accordian.mob_accordian_faq p {
    font-size: 18px;
  }

  .automate_homepage .mob_accordian.mob_accordian_faq .accordion__button {
    font-size: 22px;
  }

  .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"] {
    font-size: 22px;
  }

  .container_trial .content_center_automate p {
    font-size: 16px;
  }

  .container_trial .content_center_automate_selected p {
    font-size: 18px;
  }

  .apps_list {
    min-height: 118px;
  }

  .apps_list h6 {
    font-size: 16px;
  }

  .apps_list img {
    width: 54px;
    height: 54px;
  }

  .autoamte_apps_selection {
    width: 160px;
    height: 150px;
  }

  .autoamte_apps_selection img {
    width: 54px;
    height: 54px;
  }

  .search_apps_box {
    overflow-y: auto;
    min-height: 285px;
    max-height: 285px;
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    gap: 15px;
    margin-bottom: 15px;
  }

  .empty_space_clear {
    height: 25px;
  }

  .enterprise_wrapper_right button {
    font-size: 18px;
  }

  .form_below_content span {
    font-size: 15px;
  }

  .container_trial
    .automate_marketplace
    .footer_form_right
    .form_below_content
    span {
    font-size: 16px;
  }

  .rwt__tab {
    min-width: 260px;
    max-width: 280px;
    font-size: 18px !important;
  }

  .container_trial .automate_homepage .footer_form_left .main-slider-left p {
    padding-right: 60px;
  }

  .automate_homepage .feature_inner_page.bg_feature_slider .main-slider-left {
    padding: 0px 12%;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    font-size: 42px;
  }

  .zoho_desk_bg {
    padding: 12px 0px;
  }

  .container_trial .full_comaparision p {
    font-size: 18px;
  }

  .container_trial .header_menu p {
    color: #193a5d;
    font-size: 12px;
  }

  .cards_features .whitepaper_cards .card__text {
    font-size: 18px;
  }

  .roi_ul_wrapper ul li {
    font-size: 18px;
    color: #000000;
  }

  .container_trial p {
    color: #193a5d;
    font-size: 18px;
    line-height: 28px;
  }

  .build_needs .center_features_header {
    padding: 0px 0% !important;
  }

  .resources_card.knowledge_cards h2 {
    font-size: 38px;
  }

  .tabgroup_wrapper h2 {
    font-size: 38px;
  }

  .videos_wrapper .overview_wrapper h3 {
    font-size: 38px;
  }

  .videos_wrapper .similar_videos_wrapper h3 {
    font-size: 38px;
  }

  .am_cards_wrapper h2 {
    font-size: 38px;
  }

  .am_cards_wrapper h3 {
    font-size: 34px;
  }

  .explore_apps_container .explore_apps_box h4 {
    font-size: 32px;
  }

  .explore_apps_container h3 {
    font-size: 38px;
  }

  .why_autoamte_wrapper h3 {
    font-size: 38px;
  }

  .actions_wrapper.actions_wrapper h3 {
    font-size: 38px;
  }

  .roi-form h2 {
    font-size: 38px;
  }

  .how_it_section.how_it_page_content .main-slider-left h2 {
    font-size: 38px;
  }

  .integration_header_top h3.header_integrations {
    font-size: 28px;
  }

  .trial_header_left h1 {
    font-size: 38px;
  }

  .header_content {
    font-size: 38px;
  }

  .cogni_fav_apps_header h3 {
    font-size: 38px;
  }

  .center_feature_wrapper h2 {
    font-size: 38px;
  }

  .autoamte_apps_header h1 {
    font-size: 38px;
  }

  .center_image_home .main-slider-left h3 {
    font-size: 38px;
  }

  .cogni_fav_apps_header h1 {
    font-size: 34px;
  }

  .whitepaper_cards.three_cards_wrapper .header_3_cards h2 {
    font-size: 38px;
  }

  .footer_form_left .main-slider-left h3 {
    font-size: 30px;
  }

  .awards_img .main-slider-left h3 {
    font-size: 38px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
    font-size: 38px;
  }

  .center_image_home .main-slider-left h2 {
    font-size: 38px;
  }

  .icons_wrapper .main-slider-left h2 {
    font-size: 38px;
  }

  .top_menu_sticky
    .apps_integrations.apps_integrations_home
    .cc_feature_apps_header
    h2 {
    font-size: 38px;
  }

  .automation_steps h2 {
    font-size: 38px;
  }

  .apps_landing_header_wrapper h3 {
    font-size: 36px;
  }

  .top_section.pricing_page .main-slider-button.beta-button {
    padding: 0px 32%;
  }

  .wv-lg-heading {
    font-size: 38px !important;
  }

  .featured_content h3 {
    font-size: 38px;
  }

  .category_wrapper h3 {
    font-size: 38px;
  }

  .featured_content h4 {
    font-size: 38px;
  }

  .trial_cards_page h4 {
    font-size: 38px;
  }

  .container_trial .landing_page_charging img {
    width: 60px !important;
    height: 60px;
  }

  .img_position {
    position: relative;
    left: 55px;
  }

  .utube_rows {
    display: grid;
    align-content: center;
    grid-gap: 20px;
    grid-template-columns: 31% 31% 31%;
  }

  .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 38px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h2 {
    font-size: 38px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h1 {
    font-size: 38px;
    font-family: "Gordita-Medium";
  }

  .container_trial .main-slider-left p {
    font-size: 18px;
  }

  .container_trial .cognii_work_content .cogni_works p {
    color: #416183;
    font-size: 18px;
  }

  .trail_apps_landing .cogni_fav_apps_header p {
    color: #416183;
    font-size: 18px;
    padding: 0px 10rem;
  }

  .container_trial .more-faqs-content p {
    font-size: 18px;
  }

  .videos_wrapper .sign_up-form_us.demo_page p {
    font-size: 18px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    font-size: 18px;
  }

  .container_trial .main-slider-left h1 {
    font-size: 38px;
  }

  .helpdesk_solutions .suprcharging_benefits .main-slider-left h2 {
    font-size: 38px;
  }

  .container_trial .trail_apps_landing h1 {
    font-size: 38px;
  }

  .container_trial .cognii_work_content .cogni_works h1 {
    font-size: 38px;
  }

  .page_header_center h1 {
    font-size: 38px;
  }

  .trial_accordian_landing h2 {
    font-size: 38px;
  }

  .card_landing_trial h4 {
    font-family: "Gordita-Medium";
    font-size: 26px;
    margin: 20px 0px 10px;
  }

  .trial_cards_page p {
    font-size: 20px;
    line-height: 29px;
  }

  .container_trial .beta-charging p {
    color: #485a63;
    font-size: 18px;
  }

  .container_trial .beta-charging {
    padding-top: 15px;
    margin: 0 5%;
  }

  .container_trial .accordian_landing .accordion .link {
    cursor: pointer;
    display: block;
    padding: 15px 15px 15px 42px;
    color: #4095e9;
    font-size: 22px;
  }

  .accordian_landing .submenu li {
    font-size: 18px;
  }
}

@media (max-width: 1700px) {
  .container_trial .apps_header_left_container p {
    font-size: 20px;
  }

  .container_trial .content_center_automate .form_automate p {
    font-size: 18px;
  }

  .am_cards_wrapper.actions_wrapper p {
    font-size: 20px;
  }

  .container_trial .autoamte_apps_header p {
    font-size: 20px;
  }

  .container_trial .why_autoamte_wrapper p {
    font-size: 20px;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 20px;
  }

  .apps_header_left_container {
    max-width: 95%;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 20px;
  }

  .container_trial .explore_apps_box p {
    font-size: 20px;
  }

  .autoamte_apps_selection h6 {
    font-size: 16px;
  }

  .center_connection {
    width: 60px;
    height: 60px;
  }

  .center_connection svg {
    width: 18px;
    height: 18px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_icon {
    top: 0px;
    align-items: center;
    justify-content: center;
    place-items: center;
    height: 54px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_click {
    font-size: 18px;
    height: 55px;
  }

  .how_it_works_header .how_it_content p {
    font-size: 18px;
  }

  .center_image_home.cards_landing_page .card_landing_section p {
    font-size: 18px;
    text-align: center;
  }

  .features_img_left .button_list_li .rwt__tab {
    font-size: 18px !important;
  }

  .cards_features .cards__item .card_link_landing a {
    font-size: 18px;
  }

  .build_needs .center_features_header {
    padding: 0px 0% !important;
  }

  /* market blog pages start*/
  .grid_container_chatbot_wrapper .main-slider-left-market h1 {
    font-size: 56px;
  }

  .grid_container_chatbot_wrapper h3 {
    font-size: 32px;
  }

  .table_contents_wrapper a {
    font-size: 20px;
  }

  .market_wrapper_page h2 {
    font-size: 40px;
  }

  .container_trial .market_wrapper_page p {
    font-size: 20px;
    margin-bottom: 34px;
    line-height: 42px;
  }

  .section__ul li {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 16px;
  }

  .ul_list_number li {
    font-size: 20px;
    line-height: 34px;
    margin-bottom: 16px;
  }

  .market_wrapper_page h4 {
    font-size: 24px;
  }

  .market_wrapper_page h5 {
    font-size: 22px;
  }

  .market_main_wrapper .nocode_wrapper h4 {
    font-size: 26px;
  }

  .market_main_wrapper .nocode_wrapper button {
    padding: 0px 40px;
    height: 54px;
    min-width: 200px;
    font-size: 20px;
  }

  .market_main_wrapper .nocode_wrapper {
    padding: 30px;
  }

  .market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 span img {
    width: 24px;
    height: 24px;
  }

  .market_main_wrapper .nocode_wrapper h4 span {
    width: 40px;
    height: 36px;
    float: left;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button {
    font-size: 26px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"] {
    font-size: 26px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 26px;
    height: 26px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    width: 26px;
    height: 26px;
  }

  /* market blog pages start*/
}

@media only screen and (max-width: 1536px) {
  /* market blog pages start*/
  .grid_container_chatbot_wrapper .main-slider-left-market h1 {
    font-size: 48px;
  }

  .grid_container_chatbot_wrapper h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .table_contents_wrapper a {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .market_wrapper_page h2 {
    font-size: 36px;
  }

  .container_trial .market_wrapper_page p {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .container_trial #section4 .p_with_ul {
    margin-bottom: 20px;
  }

  .market_wrapper_page h4 {
    font-size: 22px;
  }

  .market_wrapper_page h5 {
    font-size: 18px;
  }

  .section__ul li {
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .button_last button {
    font-size: 18px;
  }

  .ul_list_number li {
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .table_contents_wrapper {
    padding: 35px;
  }

  .ul_list_number {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }

  #section8 .section__ul {
    margin-bottom: 20px;
  }

  #section9 .section__ul {
    margin-bottom: 20px;
  }

  .market_wrapper_page {
    margin-bottom: 20px;
  }

  #section3 .img_source_erapper img {
    margin-bottom: 20px;
  }

  .grid_container_chatbot {
    margin-top: 60px;
  }

  .table_contents_wrapper {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .market_main_wrapper .nocode_wrapper h4 {
    font-size: 26px;
  }

  .market_main_wrapper .nocode_wrapper button {
    padding: 0px 40px;
    height: 54px;
    min-width: 200px;
    font-size: 20px;
  }

  .market_main_wrapper .nocode_wrapper {
    padding: 30px;
  }

  .market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 span img {
    width: 24px;
    height: 24px;
  }

  .market_main_wrapper .nocode_wrapper h4 span {
    width: 40px;
    height: 36px;
    float: left;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button {
    font-size: 26px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"] {
    font-size: 26px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 26px;
    height: 26px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    width: 26px;
    height: 26px;
  }

  .market_main_wrapper .nocode_wrapper h4 {
    font-size: 22px;
  }

  .market_main_wrapper .nocode_wrapper button {
    padding: 0px 40px;
    height: 48px;
    min-width: 200px;
    font-size: 20px;
  }

  .market_main_wrapper .nocode_wrapper {
    padding: 30px;
  }

  .market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 span img {
    width: 24px;
    height: 24px;
  }

  .market_main_wrapper .nocode_wrapper h4 span {
    width: 40px;
    height: 36px;
    float: left;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button {
    font-size: 22px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"] {
    font-size: 22px;
    padding-top: 20px;
  }

  .container_trial .market_wrapper_page .accordion-wrapper.pricing_faq p {
    margin-bottom: 20px;
    padding: 0px 2px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 22px;
    height: 22px;
    top: 30px;
    right: 26px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    width: 22px;
    height: 22px;
  }

  /* market blog pages start*/
}

@media (max-width: 1500px) {
  .main-slider-button.beta-button form.sign_up_form {
    grid-template-columns: 1fr 35%;
  }

  .beta-button .sign_up_form input {
    height: 48px;
    font-size: 14px !important;
    padding: 0px 20px;
  }

  .beta-button .sign_up_form button {
    height: 48px;
    font-size: 15px !important;
  }

  .form_below_content.signup_cta {
    justify-content: flex-start;
  }

  .form_below_content.signup_cta span {
    font-size: 14px;
  }

  .table_full_comapare .table tr th:nth-child(3) {
    width: 120px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .table_full_comapare .table tr th:nth-child(4) {
    width: 120px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .table_full_comapare .table tr th:nth-child(5) {
    width: 120px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .table_full_comapare .table tr th:nth-child(6) {
    width: 120px;
    padding: 10px;
    vertical-align: middle;
    text-align: center;
  }

  .int_landing_page .horizontal_slider .rwt__tab {
    font-size: 18px !important;
    padding-bottom: 18px !important;
  }

  .servicenow_virtual .footer_form_container {
    padding: 40px 25px !important;
  }

  .videos_wrapper
    .feature_inner_page.bg_feature_slider
    .features_change_img-center
    iframe {
    width: 73.7%;
    height: 453px;
  }

  .overview_wrapper .sign_up-form_us.demo_page .get_started_wrapper h5 {
    font-size: 16px;
  }

  .similar_videos_wrapper .utube_rows > .utube_col_4 p {
    font-size: 18px;
  }

  .resource_wrapper {
    padding: 30px;
  }

  .get_started_wrapper {
    padding: 30px;
  }

  .videos_wrapper .back_features_page a {
    font-size: 18px;
  }

  .get_started_wrapper button {
    height: 42px;
    font-size: 16px;
  }

  .overview_wrapper .row.beta_change_form ul li {
    font-size: 16px;
  }

  .overview_wrapper .sign_up-form_us.demo_page h6 {
    font-size: 18px;
  }

  .videos_container .search_marketpalce .search_click {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 16px;
    height: 48px;
  }

  .video_list_ul_header {
    font-size: 16px;
    height: 48px;
  }

  .videos_container .video_list_li {
    border-radius: 0px;
  }

  .videos_container .videos_submenu ul li {
    padding-bottom: 0px;
  }

  .videos_container .video_svg_iocn svg {
    width: 18px;
    height: 18px;
  }

  .videos_container .video_content_li {
    font-size: 16px;
  }

  .videos_container .utube_rows > .utube_col_4 p {
    font-size: 13px;
  }

  .container_trial .content_center_automate_selected p {
    font-size: 16px;
  }

  .container_trial .content_center_automate .form_automate p {
    font-size: 16px;
  }

  .form_automate input {
    height: 48px;
    margin: 15px 0px;
    padding: 0px 15px;
    font-size: 14px;
  }

  .form_automate button {
    height: 48px;
  }

  .selected_apps_left_border {
    width: 180px;
    min-height: 150px;
  }

  .selected_apps_left_border img {
    width: 56px;
    height: 56px;
  }

  .selected_center_connection {
    width: 64px;
    height: 52px;
  }

  .selected_apps_left_border h6 {
    font-size: 15px;
  }

  .container_trial .pricing-columns .enterprise_message {
    padding-top: 60px;
    text-decoration: none;
    color: #494949;
    font-size: 13px;
    line-height: inherit;
  }

  .mob_accordian.mob_accordian_faq .accordion__button {
    font-size: 18px;
  }

  .mob_accordian.mob_accordian_faq .accordion__button[aria-expanded="true"] {
    font-size: 18px;
  }

  .container_trial .content_center_automate p {
    font-size: 16px;
  }

  .container_trial .content_center_automate_selected p {
    font-size: 16px;
  }

  .center_connection {
    height: 54px;
    width: 54px;
  }

  .apps_header_left_container {
    width: 100%;
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .apps_list h6 {
    font-size: 14px;
    margin-top: 0px;
    padding-top: 6px;
    padding-bottom: 10px;
  }

  .apps_list {
    min-height: 116px;
    margin-bottom: 10px;
  }

  .autoamte_apps_selection {
    width: 146px;
    height: 136px;
  }

  .autoamte_apps_selection img {
    width: 42px;
    height: 42px;
  }

  .autoamte_apps_selection {
    margin-bottom: 20px;
  }

  .autoamte_apps_selection h6 {
    font-size: 14px;
    margin-top: 10px;
  }

  .search_apps_box {
    overflow-y: auto;
    min-height: 260px;
    max-height: 260px;
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
    margin-bottom: 10px;
    row-gap: 0px;
    column-gap: 10px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_click {
    height: 55px;
    font-size: 16px;
  }

  .autoamte_apps_header_left {
    min-height: 350px;
    padding: 7%;
  }

  .autoamte_apps_header_right {
    min-height: 350px;
    padding-top: 20px;
  }

  .empty_space_clear {
    height: 20px;
  }

  .center_connection svg {
    width: 16px;
    height: 16px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_icon {
    top: 4px;
    align-items: center;
    justify-content: center;
    place-items: center;
    height: 48px;
  }

  .automate_homepage .mob_accordian.mob_accordian_faq .accordion__button:before,
  .mob_accordian.mob_accordian_faq .accordion__button:before {
    width: 18px;
    height: 18px;
  }

  .show_button_full_comaparison button {
    font-size: 16px;
  }

  .enterprise_wrapper_right button {
    font-size: 16px;
  }

  .container_trial .main-slider-left .basic_plans {
    font-size: 16px;
  }

  .form_below_content span {
    font-size: 14px;
  }

  .rwt__tab {
    min-width: 235px;
    max-width: 235px;
    background: #ededed !important;
    padding: 0 30px !important;
    height: 48px;
    text-align: left;
    font-size: 16px !important;
  }

  .automate_homepage .card_landing_trial img {
    width: 72px;
    height: 72px;
  }

  .automate_homepage
    .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button {
    padding: 0px 15%;
  }

  .inegrations_header_right img {
    width: 120px;
    height: 120px;
  }

  .container_trial .full_comaparision p {
    font-size: 16px;
  }

  .roi_ul_wrapper ul li {
    font-size: 16px;
    color: #000000;
  }

  .features_img_left .button_list_li .rwt__tab {
    font-size: 16px !important;
  }

  .center_image_home.cards_landing_page .card_landing_section p {
    font-size: 16px;
    text-align: center;
  }

  .container_trial .center_features_header p {
    padding: 0px 5%;
    padding-bottom: 15px;
    font-size: 18px;
  }

  .container_trial .header_menu p {
    font-size: 12px;
  }

  .card_landing_section.integrations_popular_bg h4 {
    font-size: 24px;
  }

  .container_trial p {
    font-size: 16px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(1) {
    padding-left: 15px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(3) {
    padding-right: 15px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(4) {
    padding-left: 15px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(6) {
    padding-right: 15px;
  }
}
@media (min-width: 1441px) and (max-width: 1680px) {
  .am_cards_wrapper
    .autoamte_apps_header_right
    .search_marketpalce
    .search_icon {
    top: 2px;
  }
}
@media (min-width: 1681px) and (max-width: 1920px) {
  .am_cards_wrapper
    .autoamte_apps_header_right
    .search_marketpalce
    .search_icon {
    top: 1px;
  }
}
@media (max-width: 1512px){
  .beta-button .sign_up_form button {
    font-size: 16px !important;
  }
}
@media (max-width: 1024px){
  .beta-button .sign_up_form button {
    font-size: 13px !important;
  }
}
@media (max-width: 1680px) {
  .footer_form_left .main-slider-left h5 {
    font-size: 22px;
  }

  .main-slider-button.beta-button form.sign_up_form {
    grid-template-columns: 1fr 35%;
  }

  .beta-button .sign_up_form input {
    font-size: 16px;
    padding: 0px 20px;
  }

  .beta-button .sign_up_form button {
    font-size: 18px;
  }

  /* .form_below_content.signup_cta {
        justify-content: flex-start;
    } */
  .form_below_content.signup_cta span {
    font-size: 14px;
  }

  .container_trial .main-slider-left .basic_plans.signup_cta_p {
    font-size: 24px;
    margin-top: 50px;
  }

  .videos_container .submenu_active ul .submenu_active_li {
    height: 42px;
    font-size: 14px;
  }

  .category_lsiting_card h4 {
    font-size: 22px;
  }

  .category_lsiting_card img {
    width: 64px;
    height: 64px;
  }

  .category_lsiting_card h6 {
    font-size: 18px;
  }

  .container_trial .apps_header_left_container p {
    font-size: 18px;
  }

  .am_cards_wrapper.actions_wrapper p {
    font-size: 18px;
  }

  .container_trial .autoamte_apps_header p {
    font-size: 18px;
  }

  .container_trial .why_autoamte_wrapper p {
    font-size: 18px;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 18px;
  }

  .apps_header_left_container {
    max-width: 85%;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 18px;
  }

  .container_trial .explore_apps_box p {
    font-size: 18px;
  }

  .show_morw_option span {
    font-size: 22px;
  }

  .am_cards_img {
    height: 42px;
  }

  .am_cards_img span img {
    width: 30px;
    height: 30px;
  }

  .am_cards_button button {
    height: 40px;
    font-size: 11px;
    padding-top: 4px;
  }

  .am_cards_button button.soon_btn_bg {
    height: 40px;
    font-size: 14px;
  }

  .am_cards_container {
    padding: 20px;
  }

  .am_cards_container h5 {
    font-size: 16px;
  }

  .enterprise_wrapper_center h4 {
    font-size: 22px;
  }

  .automate_homepage .grid_card_trial .row {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(30.33%, 1fr));
    grid-gap: 30px;
    float: left;
  }

  .automate_homepage .card_landing_trial {
    width: 100%;
    float: left;
    padding: 35px;
  }
}

/* @media (max-width: 1600px) {
  .cards_features .cards_features_header_div p {
    font-size: 16px;
}
  .roi-form p{
    font-size: 16px;
  }
  .roi-form ul li {  font-size: 16px;}
  .apps_landing_cards_wrapper ul li img {
    width: 50px;
    height: 50px;
  }
  .apps_landing_cards_wrapper ul li span {
    font-size: 15px;
  }
  .bg_header_workplace_support .main-slider-left h1 {
    padding-right: 0px;
  }

  .sticky_menu ul li {
    font-size: 13px;
  }
  .top_section.pricing_page .main-slider-button.beta-button {
    padding: 0px 28%;
  }
  .bg_section_knowledge.self_service_an .main-slider-left {
    padding-right: 40%;
    padding-left: 15px;
  }
  .pb-200.content_para {
    padding: 0px 20%;
  }
  .pb-200.content_para .wv-limited-content {
    padding-right: 0px;
  }
  .left-whitespace {
    top: 11%;
  }


  .feature_pageinner_content_img.p-t-50 {
    padding-top: 120px;
  }

  .feature_inner_page.bg_feature_slider .main-slider-left {
    width: 100%;
    float: left;
    padding: 0px 16%;
  }

  .how_it_section .container {
    padding: 0px 3%;
  }

  .how_it_works_header {
    flex-basis: 23%;
  }

  .how_it_content_before:before {
    left: 111%;
  }

  .card_landing_trial {
    padding: 40px 32px;
  }

  .card_landing_section.integrations_popular_bg h4 {
    font-size: 16px;
  }

  .card_landing_section.integrations_popular_bg h4 {
    margin: 10px 0px;
  }

  .card_landing_section.integrations_popular_bg p {
    font-size: 16px;
  }

  .card_landing_section.integrations_popular_bg .card_link_landing {
    font-size: 16px;
  }

  .container_trial .landing_page_charging img {
    width: 48px !important;
    height: 48px;
  }

  .container_trial .beta-charging p {
    color: #485a63;
    font-size: 16px;
  }

  .img_position {
    position: relative;
    left: 50px;
  }

  .utube_rows {
    display: grid;
    align-content: center;
    grid-gap: 20px;
    grid-template-columns: 33% 33% 33%;
  }

  .cogni_fav_apps_header p {
    font-size: 16px;
  }

  #exampleModalCenter .modal-dialog {
    max-width: 60%;
  }

  #exampleModalCenter1 .modal-dialog {
    max-width: 60%;
  }

  .card_office.solutions_card img {
    width: 48px !important;
  }

  .integrations_card_desk .card_head h5 {
    font-size: 16px;
  }

  .card_office.solutions_card {
    padding: 0px 15px;
    height: 70px;
  }

  .integrations_card_desk .card_head p {
    font-size: 14px;
  }

  .card_head.solutions_card {
    padding: 15px;
  }

  .know-how-assistant-content h3 {
    font-size: 2.5rem;
  }

  .solutions_center h1 {
    font-size: 39px;
  }

  .solutions_center p {
    font-size: 1.1rem;
  }

  .solutions_center .start-free-trail {
    margin: 0px;
  }

  .solutions_header {
    padding-bottom: 12rem;
  }

  .solution_service_card h5 {
    font-size: 22px;
  }

  .solution_service_card p {
    font-size: 17px;
  }

  .conversational_facelift_card p {
    font-size: 14px;
  }

  .pad_25 {
    padding: 0px 30px !important;
  }

  .container_trial .beta-charging {
    padding-top: 15px;
    margin: 0 6%;
  }
} */

@media (max-width: 1500px) {
  .videos_container .videos_submenu ul li {
    font-size: 15px;
  }

  .category_lsiting_card h4 {
    font-size: 18px;
  }

  .category_lsiting_card img {
    width: 54px;
    height: 54px;
  }

  .category_lsiting_card h6 {
    font-size: 16px;
  }

  .explore_apps_box_img {
    padding: 6% 0px;
  }

  .explore_apps_box h4 img {
    width: 12%;
    height: 12%;
  }

  .apps_header_left_container {
    max-width: 90%;
  }

  .autoamte_apps_header.autoamte_apps_header_selcted {
    padding: 0px 15%;
  }

  .three_steps_right h5 {
    font-size: 20px;
  }

  .actions_wrapper_container h4 {
    font-size: 20px;
  }

  .actions_wrapper_container .am_cards_container h5 {
    font-size: 16px;
  }

  .actions_wrapper_container h6 {
    font-size: 14px;
  }

  .show_morw_option span {
    font-size: 20px;
  }

  .actions_wrapper_container h4 span {
    width: 48px;
    height: 48px;
  }

  .actions_wrapper_container .am_cards_container .am_cards_img {
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .actions_wrapper_container .am_cards_container .am_cards_content {
    margin-left: 15px;
  }

  .three_steps_wrapper img {
    width: 84px;
    height: 84px;
  }

  .am_cards_img {
    height: 42px;
  }

  .am_cards_img span img {
    width: 30px;
    height: 30px;
  }

  .actions_wrapper_container .am_cards_button {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .three_steps_left {
    margin-right: 15px;
  }

  .am_cards_container h5 {
    font-size: 16px;
  }

  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 18px;
    height: 18px;
  }

  .automate_pricing
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 18px;
    height: 18px;
  }

  .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 18px;
    height: 18px;
  }

  .mob_accordian.mob_accordian_faq .accordion__button:before {
    width: 18px;
    height: 18px;
  }

  .mob_accordian.mob_accordian_faq p {
    font-size: 16px;
  }

  .automate_homepage .mob_accordian.mob_accordian_faq .accordion__button {
    font-size: 18px;
  }

  .feature_inner_page.bg_feature_slider .main-slider-left {
    padding: 0px 14%;
  }

  .automate_homepage .feature_inner_page.bg_feature_slider .main-slider-left {
    padding: 0px 14%;
  }

  .build_needs .center_features_header {
    padding: 0px 0% !important;
  }

  .cards_features .whitepaper_cards .card__text {
    font-size: 16px;
  }

  .container_trial p {
    font-size: 16px;
  }

  .cards_features .cards_features_header_div p {
    font-size: 16px;
  }

  .center_feature_wrapper p {
    font-size: 16px;
  }

  .helpdesk_solutions .suprcharging_benefits .cogni_workspace_content h6 {
    font-size: 16px;
  }

  .rwt__tabs .rwt__tab {
    width: 210px;
    padding: 0 25px !important;
  }

  .security_wrapper .resources_card .whitepaper_cards .card__text {
    font-size: 16px;
  }

  .security_wrapper .whitepaper_cards .card__title {
    font-size: 18px;
  }

  .integrations_cards_wrapper {
    padding: 20px;
  }

  /* .bg_feature_slider {
    padding-bottom: 300px;
  } */
  .features_content_left {
    width: 100%;
    float: left;
  }

  .feature_inner_page.bg_feature_slider .features_change_img-center img {
    width: 100%;
    float: left;
    margin: 0 auto;
  }

  .helpdesk_solutions .bg_card_1 .cogni_workspace_content {
    padding: 40px;
  }

  .helpdesk_solutions .bg_card_2 .cogni_workspace_content {
    padding: 40px 60px 40px 40px;
  }

  .helpdesk_solutions .bg_card_3 .cogni_workspace_content {
    padding: 40px;
  }

  .helpdesk_solutions .bg_card_4 .cogni_workspace_content {
    padding: 40px;
  }

  .helpdesk_solutions .suprcharging_benefits .cogni_workspace_content p {
    font-size: 16px;
  }

  .helpdesk_solutions .cogni_workspace_content h3 {
    font-size: 20px;
  }

  .img_position {
    position: relative;
    left: 45px;
  }

  .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 30px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h2 {
    font-size: 36px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h1 {
    font-size: 36px;
    font-family: "Gordita-Medium";
  }

  .wv-lg-heading {
    font-size: 36px !important;
  }

  .container_trial .main-slider-left p {
    font-size: 15px;
  }

  .container_trial .cognii_work_content .cogni_works p {
    color: #416183;
    font-size: 18px;
  }

  .trail_apps_landing .cogni_fav_apps_header p {
    color: #416183;
    font-size: 18px;
    padding: 0px 10rem;
  }

  .container_trial .more-faqs-content p {
    font-size: 18px;
  }

  .videos_wrapper .sign_up-form_us.demo_page p {
    font-size: 16px;
  }

  .how_it_works_header .how_it_content p {
    font-size: 16px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    font-size: 16px;
  }

  .container_trial .main-slider-left h1 {
    font-size: 30px;
  }

  .helpdesk_solutions .suprcharging_benefits .main-slider-left h2 {
    font-size: 32px;
  }

  .container_trial .main-slider-left h1.header_integrations {
    color: #416183;
    font-family: "Roboto-Medium";
    font-size: 28px;
  }

  .container_trial .main-slider-left h1.header_integrations_name {
    font-size: 36px;
  }

  .container_trial .cognii_work_content .cogni_works h1 {
    font-size: 36px;
  }

  .page_header_center h1 {
    font-size: 36px;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    font-size: 38px;
  }

  .resources_card.knowledge_cards h2 {
    font-size: 36px;
  }

  .roi-form h2 {
    font-size: 36px;
  }

  .am_cards_wrapper h2 {
    font-size: 36px;
  }

  .am_cards_wrapper h3 {
    font-size: 30px;
  }

  .explore_apps_container .explore_apps_box h4 {
    font-size: 30px;
  }

  .explore_apps_container h3 {
    font-size: 36px;
  }

  .why_autoamte_wrapper h3 {
    font-size: 36px;
  }

  .actions_wrapper.actions_wrapper h3 {
    font-size: 36px;
  }

  .how_it_section.how_it_page_content .main-slider-left h2 {
    font-size: 36px;
  }

  .tabgroup_wrapper h2 {
    font-size: 36px;
  }

  .videos_wrapper .overview_wrapper h3 {
    font-size: 36px;
  }

  .videos_wrapper .similar_videos_wrapper h3 {
    font-size: 36px;
  }

  .trial_header_left h1 {
    font-size: 38px;
  }

  .header_content {
    font-size: 36px;
  }

  .cogni_fav_apps_header h3 {
    font-size: 36px;
  }

  .center_feature_wrapper h2 {
    font-size: 36px;
  }

  .autoamte_apps_header h1 {
    font-size: 36px;
  }

  .center_image_home .main-slider-left h3 {
    font-size: 36px;
  }

  .cogni_fav_apps_header h1 {
    font-size: 34px;
  }

  .whitepaper_cards.three_cards_wrapper .header_3_cards h2 {
    font-size: 36px;
  }

  .footer_form_left .main-slider-left h3 {
    font-size: 30px;
  }

  .container_trial .automate_homepage .automate_form_p {
    font-size: 16px;
  }

  .awards_img .main-slider-left h3 {
    font-size: 36px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
    font-size: 36px;
  }

  .center_image_home .main-slider-left h2 {
    font-size: 34px;
  }

  .icons_wrapper .main-slider-left h2 {
    font-size: 36px;
  }

  .top_menu_sticky
    .apps_integrations.apps_integrations_home
    .cc_feature_apps_header
    h2 {
    font-size: 36px;
  }

  .automation_steps h2 {
    font-size: 36px;
  }

  .apps_landing_header_wrapper h3 {
    font-size: 32px;
  }

  .container_trial .trail_apps_landing h1 {
    font-size: 36px;
  }

  .featured_content h3 {
    font-size: 36px;
  }

  .category_wrapper h3 {
    font-size: 36px;
  }

  .featured_content h4 {
    font-size: 36px;
  }

  .trial_cards_page h4 {
    font-size: 36px;
  }

  .trial_accordian_landing h2 {
    font-size: 36px;
  }

  .card_landing_trial h4 {
    font-family: "Gordita-Medium";
    font-size: 25px;
    margin: 20px 0px 10px;
  }

  .trial_cards_page p {
    font-size: 18px;
    line-height: 27px;
  }

  .container_trial .accordian_landing .accordion .link {
    cursor: pointer;
    display: block;
    padding: 15px 15px 15px 42px;
    color: #4095e9;
    font-size: 20px;
  }

  .accordian_landing .submenu li {
    font-size: 18px;
  }
}

@media (max-width: 1400px) {
  .container_trial .apps_header_left_container p {
    font-size: 16px;
  }

  .am_cards_wrapper.actions_wrapper p {
    font-size: 16px;
  }

  .container_trial .autoamte_apps_header p {
    font-size: 16px;
  }

  .container_trial .why_autoamte_wrapper p {
    font-size: 16px;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 16px;
  }

  .apps_header_left_container {
    max-width: 80%;
  }

  .container_trial .automate_marketplace .footer_form_left .main-slider-left p {
    font-size: 16px;
  }

  .container_trial .explore_apps_box p {
    font-size: 16px;
  }

  .left-whitespace {
    left: -13%;
  }

  .featured_content h3 {
    font-size: 34px;
  }

  .category_wrapper h3 {
    font-size: 34px;
  }

  .featured_content h4 {
    font-size: 34px;
  }

  .trial_cards_page h4 {
    font-size: 34px;
  }

  .top_menu_sticky
    .apps_integrations.apps_integrations_home
    .cc_feature_apps_header
    h2 {
    font-size: 34px;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    font-size: 36px;
  }

  .resources_card.knowledge_cards h2 {
    font-size: 34px;
  }

  .integration_header_top h3.header_integrations {
    font-size: 26px;
  }

  .roi-form h2 {
    font-size: 34px;
  }

  .am_cards_wrapper h2 {
    font-size: 34px;
  }

  .am_cards_wrapper h3 {
    font-size: 26px;
  }

  .bg_p_wihout_padding h5 {
    font-size: 28px;
  }

  .explore_apps_container .explore_apps_box h4 {
    font-size: 28px;
  }

  .explore_apps_container h3 {
    font-size: 34px;
  }

  .why_autoamte_wrapper h3 {
    font-size: 34px;
  }

  .actions_wrapper.actions_wrapper h3 {
    font-size: 34px;
  }

  .how_it_section.how_it_page_content .main-slider-left h2 {
    font-size: 34px;
  }

  .tabgroup_wrapper h2 {
    font-size: 34px;
  }

  .videos_wrapper .overview_wrapper h3 {
    font-size: 34px;
  }

  .videos_wrapper .similar_videos_wrapper h3 {
    font-size: 34px;
  }

  .trial_header_left h1 {
    font-size: 34px;
  }

  .header_content {
    font-size: 34px;
  }

  .cogni_fav_apps_header h3 {
    font-size: 34px;
  }

  .center_feature_wrapper h2 {
    font-size: 34px;
  }

  .autoamte_apps_header h1 {
    font-size: 34px;
  }

  .center_image_home .main-slider-left h3 {
    font-size: 34px;
  }

  .cogni_fav_apps_header h1 {
    font-size: 34px;
  }

  .whitepaper_cards.three_cards_wrapper .header_3_cards h2 {
    font-size: 34px;
  }

  .footer_form_left .main-slider-left h3 {
    font-size: 30px;
  }

  .awards_img .main-slider-left h3 {
    font-size: 34px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
    font-size: 34px;
  }

  .center_image_home .main-slider-left h2 {
    font-size: 34px;
  }

  .icons_wrapper .main-slider-left h2 {
    font-size: 34px;
  }

  .automation_steps h2 {
    font-size: 34px;
  }

  .apps_landing_header_wrapper h3 {
    font-size: 32px;
  }

  .wv-lg-heading {
    font-size: 34px !important;
  }

  .chat_channels_trial_btn {
    width: auto;
    float: left;
    font-size: 18px;
    font-family: "Roboto-Bold";
  }

  .img_position {
    position: relative;
    left: 42px;
  }

  .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 28px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h2 {
    font-size: 32px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h1 {
    font-size: 34px;
    font-family: "Gordita-Medium";
  }

  .container_trial .main-slider-left p {
    font-size: 15px;
  }

  .container_trial .feature_inner_page.bg_feature_slider .main-slider-left p {
    font-size: 16px;
    width: 100%;
    float: left;
    text-align: center;
    padding-right: 0px;
  }

  .container_trial .cognii_work_content .cogni_works p {
    color: #416183;
    font-size: 16px;
  }

  .trail_apps_landing .cogni_fav_apps_header p {
    color: #416183;
    font-size: 16px;
  }

  .container_trial .more-faqs-content p {
    font-size: 16px;
  }

  .videos_wrapper .sign_up-form_us.demo_page p {
    font-size: 16px;
  }

  .how_it_works_header .how_it_content p {
    font-size: 16px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    font-size: 16px;
  }

  .container_trial .main-slider-left h1 {
    font-size: 30px;
    line-height: 1.5;
  }

  .helpdesk_solutions .suprcharging_benefits .main-slider-left h2 {
    font-size: 30px;
  }

  .container_trial .cognii_work_content .cogni_works h1 {
    font-size: 34px;
  }

  .page_header_center h1 {
    font-size: 34px;
    padding: 0px 10%;
  }

  .container_trial .trail_apps_landing h1 {
    font-size: 34px;
  }

  .trial_accordian_landing h2 {
    font-size: 34px;
  }

  .card_landing_trial h4 {
    font-family: "Gordita-Medium";
    font-size: 22px;
    margin: 20px 0px 10px;
  }

  .trial_cards_page p {
    font-size: 16px;
  }

  .container_trial .accordian_landing .accordion .link {
    cursor: pointer;
    display: block;
    padding: 15px 15px 15px 42px;
    color: #4095e9;
    font-size: 19px;
  }

  .accordian_landing .submenu li {
    font-size: 16px;
  }

  /* market blog pages start*/
  .grid_container_chatbot_wrapper .main-slider-left-market h1 {
    font-size: 42px;
  }

  .grid_container_chatbot_wrapper h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .table_contents_wrapper a {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .market_wrapper_page h2 {
    font-size: 32px;
  }

  .container_trial .market_wrapper_page p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .container_trial #section4 .p_with_ul {
    margin-bottom: 20px;
  }

  .market_wrapper_page h4 {
    font-size: 20px;
  }

  .market_wrapper_page h5 {
    font-size: 16px;
  }

  .section__ul li {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .ul_list_number li {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .button_last button {
    font-size: 16px;
  }

  .table_contents_wrapper {
    padding: 30px;
  }

  .ul_list_number {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }

  #section8 .section__ul {
    margin-bottom: 20px;
  }

  #section9 .section__ul {
    margin-bottom: 20px;
  }

  .market_wrapper_page {
    margin-bottom: 20px;
  }

  #section3 .img_source_erapper img {
    margin-bottom: 20px;
  }

  .grid_container_chatbot {
    margin-top: 60px;
  }

  .table_contents_wrapper {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  /* market blog pages start*/
}

@media (max-width: 1300px) {
  .enterprise_wrapper_center h4 {
    font-size: 22px;
  }

  .img_position {
    position: relative;
    left: 40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .suprcharging_benefits .cogni_workspace_content p {
    min-height: 120px;
    font-size: 14px;
    margin-bottom: 0px;
  }

  .utube_content {
    padding: 0px 10px;
  }

  .solutions_left_img {
    width: 140%;
  }

  .signup_form h2 {
    font-size: 30px;
    margin-bottom: 20px !important;
  }

  .sign_up-form_us {
    margin-top: 0px !important;
  }

  .sign_up-form {
    padding: 30px 30px !important;
  }

  #_form_1_ ._submit {
    margin-bottom: 20px;
  }

  #_form_1_ input[type="date"],
  #_form_1_ input[type="text"],
  #_form_1_ textarea {
    padding: 0px;
  }
}

@media (min-width: 1050px) and (max-width: 1680px) {
  .lightbox .lightbox-close {
    right: 9.5% !important;
    top: 8% !important;
  }

  .lightbox .lightbox-container {
    max-width: 1200px !important;
  }
}

@media (min-width: 900px) and (max-width: 1440px) {
  .lightbox .lightbox-container {
    max-width: 1024px !important;
  }
}

@media (min-width: 800px) and (max-width: 1280px) {
  .solutions_center h1 {
    font-size: 1.9rem;
  }

  .lightbox .lightbox-close {
    right: 9.5% !important;
    top: 8% !important;
  }

  .lightbox .lightbox-container {
    max-width: 900px !important;
  }
}

@media (min-width: 640px) and (max-width: 1024px) {
  .lightbox .lightbox-container {
    max-width: 700px !important;
  }

  .lightbox .lightbox-close {
    top: 7% !important;
    right: 13% !important;
  }
}

@media (max-width: 1000px) {
  .int_landing_page .rwt__tablist.horizontal_slider {
    overflow-x: scroll;
  }

  .int_landing_page .rwt__tablist.horizontal_slider {
    justify-content: flex-start;
  }

  .servicenow_virtual .footer_form_container {
    padding: 40px 20px 28px 20px !important;
  }

  .category_lsiting_card img {
    width: 42px;
    height: 42px;
  }

  .selected_apps_left_border {
    width: 180px;
    min-height: 120px;
  }

  .selected_center_connection {
    width: 42px;
    height: 36px;
  }

  .selected_apps_left_border h6 {
    font-size: 14px;
    margin-top: 12px;
  }

  .selected_apps_left_border img {
    width: 42px;
    height: 42px;
  }

  .three_steps_wrapper img {
    width: 72px;
    height: 72px;
  }

  .three_steps_wrapper {
    align-items: flex-start;
  }

  .container_trial .content_center_automate p {
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .container_trial .content_center_automate_selected p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .center_connection {
    width: 46px;
    height: 46px;
  }

  .center_connection svg {
    width: 14px;
    height: 14px;
  }

  .container_trial .automate_homepage .footer_form_left .main-slider-left p {
    padding-right: 0px;
  }

  .automate_homepage .mob_accordian.mob_accordian_faq .accordion__button {
    font-size: 22px;
  }

  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 22px;
    height: 22px;
  }

  .mob_accordian.mob_accordian_faq .accordion__button:before {
    width: 22px;
    height: 22px;
  }

  .tabgroup_wrapper {
    display: none;
  }

  .mobile_view_tab {
    display: block;
  }

  .mobile_view_tab .mob_accordian.mob_accordian_faq .container h2 {
    justify-content: center;
    display: flex;
  }

  .image_tabgroup {
    justify-content: center;
  }

  .image_tabgroup img {
    width: 65%;
    height: 100%;
  }

  .automate_homepage .how_it_works_header {
    flex-basis: inherit;
    margin-right: 0px;
  }

  .skit_contactus.roi_page_contact .roi-form-right {
    padding-left: 15px;
  }

  .skit_contactus.roi_page_contact .roi-form {
    padding-right: 15px;
  }

  .container-fluid.bg_apps_usecaes {
    display: none;
  }

  .mob_sticky_wrapper .dropdown.wv-dropdown {
    display: block;
  }

  .dropdown.wv-dropdown
    .container_trial
    .bg_section_knowledge
    .main-slider-left
    h1 {
    padding-right: 0px;
    margin-bottom: 0px;
    line-height: inherit;
    font-size: 30px;
    padding: 40px 0px;
  }

  .container_trial .bg_section_knowledge.left_to_right .main-slider-left h1 {
    padding-right: 0px;
    margin-bottom: 0px;
    line-height: inherit;
    font-size: 30px;
    padding: 40px 0px;
  }

  .apps_integrations.bg_marketpalce {
    margin-top: 0px;
  }

  .webinar_img_container .para_center_videos {
    font-size: 30px;
    padding: 0px 15px;
  }

  .container_trial .careers_head .main-slider-left h1 {
    font-size: 30px;
    margin-bottom: 0px;
  }

  .main-slider.careers_head {
    margin-bottom: 0px;
  }

  .careers_card {
    padding-bottom: 40px;
  }

  .awards_img_coantainer img {
    width: 48%;
    float: left;
    margin-bottom: 20px;
    height: 100%;
  }

  .cogni_fav_apps.career_skit .cogni_fav_apps_header p {
    text-align: center;
    margin-bottom: 0px;
  }

  .x_scroll_page {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding-left: 20px;
    padding-right: 20px;
  }

  .automate_homepage .x_scroll_page {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    padding-left: 20px;
    padding-right: 0px;
  }

  .how_it_works_header {
    flex-basis: inherit;
    margin-right: 18%;
  }

  .how_it_content img {
    width: auto;
    height: 200px;
  }

  .automate_homepage .how_it_content img {
    width: 55%;
    height: 100%;
  }

  .automate_homepage .how_it_content_before:before {
    content: "";
    position: absolute;
    top: 20%;
    left: 89%;
    right: 0;
    width: 60px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .how_it_content p {
    padding-right: 0px;
    text-align: center;
  }

  .how_it_content h3 {
    text-align: center;
  }

  .how_it_works_header:last-child {
    padding-right: 20px;
  }

  .how_it_content_before:before {
    left: 111%;
  }

  .zoho_desk_bg {
    background: #ddedff;
    background-size: cover;
    background-position: 50% 40%;
    padding: 2rem 0px;
  }

  .integration_header_top {
    padding: 0px;
  }

  .integration_header_top h3.header_integrations {
    text-align: center;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    text-align: center;
    margin-bottom: 12px;
  }

  .inegrations_header_right img {
    width: 100px;
    height: 100px;
    position: relative;
    top: 10px;
  }

  .container_trial
    .servicenow_virtual
    .integration_header_top
    h3.header_integrations_name {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .form_below_content span {
    margin-right: 0px;
    font-size: 15px;
  }

  .left-whitespace {
    display: none;
  }

  .featured_content .slick-prev {
    right: 70px;
  }

  .featured_content .drag {
    position: absolute;
    right: 55px;
  }

  .featured_content .slick-next {
    right: 20px;
  }

  .img_position {
    position: relative;
    left: 0;
  }

  .integrations_pages .beta-charging ul li {
    width: 47%;
  }

  .feature_pageinner_content_img.mob_chat_flex .feature_page_img_center {
    order: 2;
    justify-content: center;
    display: flex;
  }

  .feature_pageinner_content_img.mob_chat_flex .feature_page_img_center img {
    width: 80%;
  }

  .x_scroll_page {
    padding-top: 20px;
    padding-left: 30px;
  }

  .how_it_landing_footer.know-how-assistant {
    margin-top: 0px;
  }

  .col-sm-12.cards_features_header {
    padding: 0px !important;
  }

  .new_design_landing_footer {
    margin-top: 0px;
    padding-top: 0px;
  }

  .feature_page_img_right {
    margin-top: 30px;
  }

  .feature_page_img_left {
    margin-top: 30px;
  }

  .features_img_left .feature_page_img_right {
    justify-content: center;
  }

  .feature_page_img_right img {
    width: 80%;
    height: 100%;
  }

  /* .feature_pageinner_content_left ul {
    width: 100%;
    float: left;
  } */
  /* .bg_feature_slider .features_change_img_header {
    position: relative;
    width: auto;
  } */
  .new_design_landing_footer .know-how-assistant-content p {
    margin-right: 0px;
  }

  .main-slider.assistant_slider.bg_feature_slider {
    margin-top: 0px;
  }

  .bg_feature_slider .main-slider-left {
    padding-left: 0px;
  }

  .features_change_img {
    padding-left: 0px;
  }

  /* 
  .bg_feature_slider {
    padding-bottom: 80px;
  } */
  .bg_feature_slider.cc_bg_feature_slider {
    padding-bottom: 0px;
  }

  .pl-20-tage .feature_page_content_left {
    padding-left: 15px;
  }

  .pl-10-tage .feature_page_content_left {
    padding-left: 15px;
  }

  .nav-offcanvas-menu ul {
    padding: 0px 47px;
  }

  .feature_page_img_right {
    justify-content: center;
  }

  .header-logo .free_trial {
    height: 50px !important;
  }

  .nav-offcanvas.open .powerd_by_product img {
    padding-top: 0px;
  }

  .powerd_by_product p {
    margin-bottom: 0px;
  }

  .footer_content_between_row {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    padding-bottom: 65px;
    padding: 0px 15px;
  }

  .footer_logo {
    margin-bottom: 30px;
  }

  .wv-company-name {
    padding-left: 15px;
  }

  .nav-offcanvas-menu ul li:nth-child(5) {
    border-bottom: 1px solid #0000000f;
  }

  /* .nav-offcanvas-menu ul li:nth-child(5) {
    padding-top: 30px;
  } */
  .header-logo .free_trial {
    color: #fff !important;
    width: 100%;
    margin-top: 0px;
  }

  .hamburger svg {
    display: block !important;
  }

  .nav-offcanvas {
    width: 100%;
    background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
    height: 100vh;
    position: fixed;
    overflow-x: auto;
    z-index: 2000;
    /* padding: 50px 0; */
    top: 0;
    right: -1200px;
    transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.15);
  }

  .sticky {
    position: fixed;
    z-index: 9999;
    width: 100%;
    background-color: #fff;
    top: 0;
    box-shadow: 0 3px 5px -5px #ccc;
    height: 80px;
    padding-top: 0px;
    transition: transform 0.5s ease;
    display: flex;
    align-items: center;
    animation: smoothScroll 0.5s forwards;
  }

  @keyframes smoothScroll {
    0% {
      transform: translateY(-40px);
    }

    100% {
      transform: translateY(0px);
    }
  }

  .sticky .header-logo {
    margin: 0px;
  }

  .header.sticky {
    background: #fff !important;
    padding-bottom: 0px;
  }

  .features_content_innerpage {
    margin-top: 60px;
  }

  /* .feature_pageinner_content_left h3 {
    padding-top: 0px;
  } */
  .cards_features_header_div p {
    padding-right: 0px;
    min-height: 90px;
  }

  .feature_pageinner_content_img .feature_page_img_right {
    padding-right: 15px;
  }

  .flex_container_center {
    width: 100%;
    float: left;
  }

  .flex_container_center h3 {
    width: 100%;
    float: left;
    text-align: left !important;
  }

  .flex_container_center p {
    width: 100%;
    float: left;
    text-align: left;
  }

  .new_design_landing_footer .start-free-trail a {
    width: 100%;
    justify-content: center;
    margin-top: 60px;
  }

  .col-lg-.col-md-5.col-sm-12.col-xs-12.know-how-assistant-image.image_feature_hide {
    display: none;
  }

  .new_design_landing_footer
    .know-how-assistant-image.mobile_change_footer_img {
    display: block;
  }

  .features_inner_page_footer img {
    width: 80%;
    float: right;
  }

  .p_t_bg_remove .start-free-trail {
    width: 100%;
    margin-top: 35px;
  }

  .p_t_bg_remove {
    padding-bottom: 80px;
    margin-top: 0px;
  }

  .p_t_bg_remove .row.footer_content_between_row {
    width: 100%;
  }

  .cards_features_header_div a {
    margin-bottom: 60px;
  }

  .p_t_bg_remove .start-free-trail a {
    font-size: 16px;
    padding: 0px 30px;
    width: 100%;
    float: left;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feature_pageinner_content_img {
    padding-bottom: 0px;
  }

  /* .feature_pageinner_content_left h3 {
    font-size: 28px;
    width: 100%;
    float: left;
  }
  .feature_pageinner_content_left p {
    font-size: 16px;
    width: 100%;
    float: left;
    padding: 15px;
  } */
  .back_features {
    padding-top: 10px;
  }

  .col-sm-12.cards_features_header {
    padding: 0px 40px;
  }
}

@media (max-width: 1100px) {
  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button {
    font-size: 22px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"] {
    font-size: 22px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 22px;
    height: 22px;
    top: 30px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    width: 22px;
    height: 22px;
  }

  .market_main_wrapper .nocode_wrapper h4 {
    width: 100%;
    float: left;
    margin: 0px;
    text-align: center;
  }

  .market_main_wrapper .nocode_wrapper {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 {
    text-align: left;
  }

  .market_main_wrapper .nocode_wrapper button {
    margin-top: 16px;
  }

  .container_trial .videos_wrapper .main-slider-left h1 {
    padding-top: 0rem;
  }

  .footer_right.automate_footer .footerlogo_left {
    position: relative;
    bottom: 0;
  }

  .footer_right.automate_footer .footerlogo_left > div {
    padding-top: 10px;
  }

  .top_section.pricing_page._assistant_pricing:before {
    height: calc(37% - 0px);
  }

  ._assistant_pricing_wrapper {
    width: 100%;
    float: left;
  }

  ._assistant_pricing_wrapper .top_header.header.res_header .container {
    max-width: 100%;
  }

  ._assistant_pricing_wrapper .top_header {
    padding-bottom: 0px;
  }
}

@media (max-width: 1200px) {
  /* market blog pages start*/
  .market_main_wrapper .container {
    max-width: 100%;
  }

  .grid_container_chatbot_wrapper {
    grid-template-columns: 1fr 20%;
    padding-right: 30px;
  }

  .grid_container_chatbot_wrapper .main-slider-left-market h1 {
    font-size: 34px;
  }

  .market_wrapper_page h2 {
    font-size: 28px;
  }

  .grid_container_chatbot_wrapper h3 {
    font-size: 24px;
  }

  /* market blog pages start*/
  .form_below_content span:last-child {
    margin-right: 0px !important;
  }

  .bg_market_webinar.bg_video .col-lg-7 {
    padding-right: 15px;
    padding-top: 20px;
  }

  .inter_slider_assist
    .col-md-6.feature_page_content_left
    .coming_soon_wrapper {
    width: 100%;
    float: left;
    position: relative;
    top: 0px;
    left: 0;
    margin-bottom: 0px;
  }

  .am_cards_wrapper .container {
    width: 100%;
  }

  .apps_header_left_container {
    max-width: 95%;
  }

  .autoamte_apps_header.autoamte_apps_header_selcted {
    padding: 0px 10%;
  }

  .form_automate {
    width: 100%;
    float: left;
    padding: 0px 20%;
  }

  .show_morw_option span {
    font-size: 20px;
  }

  .container_trial .apps_header_left_container p {
    font-size: 16px;
  }

  .apps_header_left_container {
    padding: 0px;
  }

  .center_connection {
    width: 52px;
    height: 52px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_icon {
    top: 0px;
  }

  .search_apps_box {
    overflow-y: auto;
    min-height: 240px;
    max-height: 256px;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    margin-bottom: 15px;
    row-gap: 15px;
    column-gap: 15px;
  }

  .apps_list {
    min-height: 120px;
    margin-bottom: 0px;
  }

  .rwt__tab {
    min-width: 200px;
    max-width: 200px;
    background: #ededed !important;
    padding: 0 20px !important;
    height: 48px;
    text-align: left;
    font-size: 16px !important;
  }

  .landing_page_chatbot .form_below_content span {
    margin-right: 15px;
  }

  .main-slider-left .form_below_content {
    width: 100%;
  }

  .automate_homepage .card_landing_trial img {
    width: 64px;
    height: 64px;
  }

  .automate_homepage .grid_card_trial .row {
    grid-gap: 20px;
  }

  .image_tabgroup {
    width: 100%;
    height: 100%;
    display: flex;
    align-self: center;
    align-items: center;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(1) {
    margin-right: 20px;
    margin-bottom: 30px;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(2) {
    margin-right: 20px;
    margin-bottom: 30px;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(3) {
    margin-bottom: 30px;
  }

  .featured_content .integrations_card_slider h3 {
    font-size: 18px !important;
  }

  .container_trial.integrations_container
    .bg_feature_slider.bg_feature_slider_wrapper {
    padding-top: 20px;
  }

  .feature_bg_img:before {
    height: 65%;
  }

  .how_it_section .container {
    padding: 0px;
  }

  .how_it_content_before:before {
    left: 105%;
  }

  .mob_img_home .header.res_header .navbarlogo_main img {
    display: none;
  }

  .mob_img_home .header.res_header .navbarlogo_main .navbarlogo_img {
    padding: 0px;
  }

  .mob_img_home {
    width: 100%;
    float: left;
  }

  .mob_img_home .header.res_header .navbarlogo_main {
    padding-top: 0px;
  }

  .card_landing_section {
    max-width: 48.2%;
    flex: 0 0 48.2%;
  }

  .grid_card_trial .row {
    display: flex;
    justify-content: space-between;
  }

  .grid_card_trial .row .card_landing_section:nth-child(2) {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(1) {
    margin-right: 20px;
    margin-bottom: 30px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(1) {
    margin-right: 17px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(1) {
    margin-right: 17px;
  }

  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    width: 43%;
    float: left;
    padding: 10px;
    margin: 20px 10px;
    border: 1px solid #e1e1e1;
  }

  .pricing-columns .pricing-chart,
  .pricing-columns-both .pricing-chart {
    margin-top: 0px;
  }

  .smaller-plans {
    display: flex;
    flex-wrap: wrap;
  }

  .pricing-columns.pricing-section .plan {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 47%;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .it_support_chatbot
    .container_trial
    .slider_container
    .feature_page_img_right {
    padding: 0px !important;
  }

  .apps_landing_header_wrapper h5 {
    font-size: 16px;
  }

  .apps_landing_cards.col-lg-3 {
    flex: 0 0 48%;
    max-width: 48%;
    margin-bottom: 30px;
  }

  .apps_landing_cards_wrapper ul li span {
    font-size: 16px;
  }

  .header_landing ul li a {
    width: auto;
    list-style: none;
    font-size: 14px;
  }

  .header_landing_wrapper .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px;
    padding-left: 20px;
  }

  .top_section.pricing_page .main-slider-button.beta-button {
    width: 100%;
    float: left;
    padding: 0px 12%;
  }

  .featured_content h3 {
    font-size: 32px;
  }

  .category_wrapper h3 {
    font-size: 32px;
  }

  .featured_content h4 {
    font-size: 32px;
  }

  .top_menu_sticky
    .apps_integrations.apps_integrations_home
    .cc_feature_apps_header
    h2 {
    font-size: 32px;
  }

  .wv-lg-heading {
    font-size: 32px !important;
  }

  .trial_cards_page h4 {
    font-size: 32px;
  }

  .feature_inner_page.bg_feature_slider .main-slider-left {
    padding: 0px 5%;
  }

  .cards_features {
    margin-top: 0px;
  }

  .featured_content .slick-prev,
  .featured_content .slick-next {
    top: -7px;
  }

  .featured_content h3 {
    font-size: 32px;
  }

  .category_wrapper h3 {
    font-size: 32px;
  }

  .featured_content h4 {
    font-size: 32px;
  }

  .footer_form_right.col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer_form_left.col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .footer_form_left p {
    margin-bottom: 10px !important;
  }

  .whitepaer_wrapper_form h3 {
    font-size: 18px;
    justify-content: center;
    display: flex;
    font-family: "Roboto-Medium";
    margin: 20px 0px;
  }

  .trail_apps_landing .cogni_fav_apps_header p {
    padding: 0px 5rem;
  }

  .whitepaer_wrapper_form .para_whitepaer p {
    font-size: 13px !important;
  }

  .bg_header_trial.trial_mb_80 .assistant_right_img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
  }

  .container_trial .beta-charging {
    padding-top: 15px;
    margin: 0 auto;
  }

  .container_trial .beta-charging p {
    color: #485a63;
    font-size: 14px;
  }

  .container_trial .pl-20-tage.trial_page_img .feature_page_img_right {
    height: 100%;
  }

  .container_trial .feature_page_img_right img {
    width: 100%;
    height: 100%;
  }

  .container_trial .features_img_left .feature_page_img_right {
    height: 100%;
  }

  .modal_popup_video .video_iframe iframe {
    width: 100%;
    height: calc(100vh - 60vh);
  }

  .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 28px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h2 {
    font-size: 28px;
    font-family: "Gordita-Medium";
  }

  .landing_page.trial_page_img .feature_page_content_left h1 {
    font-size: 28px;
    font-family: "Gordita-Medium";
  }

  .bg_market_Webinar_page .main-slider-left h1 {
    padding-top: 40px;
  }

  .bg_market_Webinar .main-slider-left h1 {
    padding-top: 40px;
  }

  /*  */
  .trial_mb_80.bg_header_trial .main-slider-left p {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
  }

  .container_trial .main-slider-left h1 {
    font-size: 32px;
    margin-bottom: 10px;
  }

  .helpdesk_solutions .suprcharging_benefits .main-slider-left h2 {
    font-size: 32px;
  }

  .container_trial .trail_apps_landing h1 {
    font-size: 32px;
  }

  .container_trial .trial_accordian_landing h2 {
    font-size: 32px;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    font-size: 34px;
  }

  .resources_card.knowledge_cards h2 {
    font-size: 32px;
  }

  .integration_header_top h3.header_integrations {
    font-size: 24px;
  }

  .roi-form h2 {
    font-size: 32px;
  }

  .am_cards_wrapper h2 {
    font-size: 32px;
  }

  .am_cards_wrapper h3 {
    font-size: 24px;
  }

  .explore_apps_container .explore_apps_box h4 {
    font-size: 22px;
  }

  .explore_apps_box h4 img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .explore_apps_container .col-md-6:first-child {
    padding-left: 15px;
  }

  .explore_apps_container .col-md-6:last-child {
    padding-right: 15px;
  }

  .explore_apps_container h3 {
    font-size: 32px;
  }

  .why_autoamte_wrapper h3 {
    font-size: 32px;
  }

  .actions_wrapper.actions_wrapper h3 {
    font-size: 32px;
  }

  .how_it_section.how_it_page_content .main-slider-left h2 {
    font-size: 32px;
  }

  .tabgroup_wrapper h2 {
    font-size: 32px;
  }

  .videos_wrapper .overview_wrapper h3 {
    font-size: 32px;
  }

  .videos_wrapper .similar_videos_wrapper h3 {
    font-size: 32px;
  }

  .trial_header_left h1 {
    font-size: 32px;
  }

  .header_content {
    font-size: 32px;
  }

  .cogni_fav_apps_header h3 {
    font-size: 32px;
  }

  .center_feature_wrapper h2 {
    font-size: 34px;
  }

  .autoamte_apps_header h1 {
    font-size: 34px;
  }

  .center_image_home .main-slider-left h3 {
    font-size: 32px;
  }

  .cogni_fav_apps_header h1 {
    font-size: 32px;
  }

  .whitepaper_cards.three_cards_wrapper .header_3_cards h2 {
    font-size: 32px;
  }

  .footer_form_left .main-slider-left h3 {
    font-size: 32px;
  }

  .awards_img .main-slider-left h3 {
    font-size: 32px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
    font-size: 32px;
  }

  .center_image_home .main-slider-left h2 {
    font-size: 32px;
    padding: 0px 4%;
  }

  .icons_wrapper .main-slider-left h2 {
    font-size: 32px;
  }

  .automation_steps h2 {
    font-size: 32px;
  }

  .apps_landing_header_wrapper h3 {
    font-size: 32px;
  }

  .cognii_work_content .cogni_works p {
    font-size: 18px;
    padding: 0px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    font-size: 18px;
  }

  .container_trial .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 32px;
  }

  .card_landing_trial h4 {
    font-size: 28px;
  }

  .card_landing_trial p {
    font-size: 18px;
    line-height: 27px;
  }

  /*  */
  .video_list_ul {
    display: grid;
    align-content: center;
    grid-gap: 10px;
    grid-template-columns: 15.66% 15.66% 15.66% 15.66% 15.66% 15.66%;
  }

  .container.video_lists_container {
    padding: 0px;
  }

  .trial_mb_80.bg_landing_zendesk h6 {
    color: #fff;
    padding-top: 50px;
  }

  .webinar_forms_head.demo_page h1 {
    font-size: 20px !important;
  }

  .webinar_forms_head.demo_page h1 {
    font-size: 24px !important;
  }

  .webinar_forms_head.demo_page p {
    font-size: 16px;
  }

  .webinar_forms_head.demo_page h6 {
    font-size: 18px;
  }

  .webinar_forms_head.demo_page ul li {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .webinar_forms_head.demo_page {
    padding-bottom: 0px;
  }

  .cogni_fav_apps_header p {
    padding: 0px 15px;
  }

  .beta-main-slider {
    margin-top: 30px;
  }

  .trial_mb_80.bg_header_trial {
    margin-top: 0px;
    padding-bottom: 40px;
    padding-top: 21px;
    width: 100%;
    float: left;
    position: relative;
    top: -1px;
  }

  .beta-button input[type="text"],
  select {
    width: 33.33%;
  }

  .beta-button .sign_up_form input {
    width: 100%;
  }

  .beta-button button {
    width: 33.33%;
  }

  .video_prompt .main-slider-left h3 {
    font-size: 45px;
  }

  .footer_chat .mobile_change_footer_img {
    margin-top: 0px;
  }

  .footer_chat .know-how-assistant {
    padding: 60px 0px;
  }

  .footer_chat .start-free-trail {
    margin-top: 30px;
  }

  .how_it_landing_footer.know-how-assistant {
    margin-top: 0px;
  }

  .demo_page_form {
    width: 90%;
    padding: 15px;
  }

  .icon_arrow {
    position: absolute;
    top: 2%;
    left: -12%;
    right: 0;
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 1024px) {
  .am_cards_wrapper.actions_wrapper .actions_wrapper_container h4 {
    position: sticky;
    top: 60px;
  }

  .similar_videos_wrapper .utube_iframe img {
    height: 100%;
  }
  .home_page_new_workativ .main-slider-button.beta-button form.sign_up_form {
    width: 56%;
  }

  .automate_homepage
    .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button {
    padding: 0px 15%;
  }

  .inter_slider_assist .card_landing_section.integrations_popular_bg {
    max-width: 48%;
    flex: 0 0 48%;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(1) {
    margin-right: 20px;
    margin-bottom: 30px;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(2) {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(3) {
    margin-bottom: 0px;
  }

  .inter_slider_assist .header.res_header {
    background: #fff;
  }

  .apps_integrations_home .cc_feature_apps .cogni_fav_apps_color {
    margin-top: 10px;
  }

  .sticky_pad_0:first-child .home_menu_top .header_menu:before {
    display: none;
  }

  .sticky_pad_0:first-child .home_menu_top:first-child .header_menu:before {
    position: absolute;
    content: "";
    width: 100%;
    border-bottom: 1px solid #c1c1c1;
    height: 1px;
    bottom: -5px;
    display: block;
  }

  .cc_bg_feature_slider.trial_page_img.mb-0 {
    padding-top: 0px;
  }

  .cards_soon_cc {
    margin-bottom: 20px;
  }

  .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button
    .form_below_content {
    margin-bottom: 20px;
  }

  .bg_feature_slider.cc_bg_feature_slider.cc_bg_feature_slider {
    padding-top: 20px;
  }

  .footer_home .footer_aws {
    position: relative;
    bottom: 0;
    padding-bottom: 0px;
  }

  .footer_right.automate_footer:nth-child(3) .footer_menu.coming_soon_center {
    position: relative;
    left: 0;
  }

  /* autoamte footer  */
  .footer_right.automate_footer .wrapperlink:nth-child(2) {
    padding-top: 0px !important;
  }

  .footer_right.automate_footer .wrapperlink:nth-child(1) {
    padding-top: 0px;
  }

  .footer_right.automate_footer .footer_aws img {
    width: 200px !important;
  }

  .footer_menu.coming_soon_center {
    display: flex;
    justify-content: flex-start;
  }

  .desktop_h2 {
    display: none;
  }

  .mobile_h2 {
    display: block;
  }

  .helpdesk_solutions .suprcharging_benefits {
    background: #fff;
    padding: 0px 15px;
  }

  .center_image_home .main-slider-left {
    padding: 90px 5%;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .footerlink_left .wrapperlink {
    padding-top: 0px;
    padding-bottom: 15px;
  }

  .footer_right .wrapperlink:nth-child(1) {
    padding-top: 10px;
  }

  .footerlink_left .footerlogo_left {
    position: relative;
    bottom: 0;
  }

  .footerlink_left .footerlogo_left a {
    padding-bottom: 0px;
  }

  .footerlink_left .footerlogo_left img {
    margin-bottom: 0px;
  }

  .header.sticky {
    background: #fff !important;
  }

  .top_header {
    z-index: 11111 !important;
  }

  .sticky {
    position: fixed;
    z-index: 9999;
    width: 100%;
    background-color: #fff;
    top: 0;
    box-shadow: 0 3px 5px -5px #ccc;
    height: 80px;
    padding-top: 0px;
    transition: transform 0.5s ease;
    display: flex;
    align-items: center;
    animation: smoothScroll 0.5s forwards;
  }

  .col-md-6.feature_page_content_left .coming_soon_wrapper {
    width: 100%;
    float: left;
    position: relative;
    top: 0;
    left: -20px;
    margin-bottom: 40px;
  }

  .form_below_content {
    display: flex;
    justify-content: space-between;
  }

  .beta-button button {
    font-size: 15px;
  }

  /* .features_content_left.features_content_left_cc {
    margin-top: 120px;
  } */
  .assistant_change_img.feature_cc_slider img {
    width: 100%;
    float: left;
    height: 430px;
    padding: 15px;
    margin: 0px;
  }

  .buton_all_features_page {
    display: block;
  }

  .build_needs h2 {
    font-size: 32px;
  }

  .build_needs p {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .flex_container_center img {
    display: none;
  }

  .cards_features_header_div p {
    padding-right: 0%;
  }

  .know-how-assistant-image.image_feature_hide {
    margin-top: 0px;
  }

  .p_t_bg_remove {
    margin-bottom: 30px;
  }

  .know-how-assistant-image.image_feature_hide img {
    display: block;
  }

  .flex_container_center p {
    text-align: left;
  }

  .col-sm-12.cards_features_header {
    padding-top: 0px;
  }

  .new_design_landing_footer {
    padding-top: 0px;
    margin-top: 0px;
  }

  /* .feature_pageinner_content_left h3 {
    font-size: 28px;
    width: 100%;
    float: left;
  }
  .feature_pageinner_content_left p {
    font-size: 16px;
    width: 100%;
    float: left;
  } */
  .new_design_landing_footer .know-how-assistant-content p {
    margin-right: 0%;
    text-align: center;
  }

  .know-how-assistant-content h3 {
    margin-right: 0px;
    text-align: center;
  }

  .know-how-assistant-content .start-free-trail {
    text-align: center;
  }

  .features_inner_page_footer {
    display: none;
  }

  .know-how-assistant.solutions_assistant p {
    margin-right: 0rem;
  }

  .top-header {
    border-bottom: 1px solid #f5f5f5;
  }

  .hamburger {
    display: none;
  }

  .pb-200 {
    padding-bottom: 0px;
  }

  .sign_up-form {
    padding: 20px !important;
  }

  #_form_1_ {
    padding: 0px;
  }

  .powerd_by_product img {
    margin-bottom: 50px !important;
  }

  .cogni_fav_apps {
    margin-top: 20px;
  }

  .m_t_110 {
    margin-top: 122px !important;
  }

  .wv-nav-header.wv-nav-up {
    top: 0px;
    transition: ease 0.2s;
    background: #fff;
    box-shadow: 0 -9px 12px 0px #7b7b7b;
  }

  .header-logo.mobile-logo {
    z-index: 99;
    height: 70px;
  }

  .main-slider {
    padding-top: 30px;
  }

  .wv-nav-header .top-header-logo {
    background: #fff;
  }

  .mobile-logo {
    height: 60px;
    padding: 13px 0;
    /* position: absolute; */
    /* top: 12px; */
    border-bottom: 1px solid #eceff1;
    margin: 0 0 40px;
    margin-bottom: 0px;
  }

  .hamburger svg {
    display: none;
  }

  /* .top-header-logo img {
    position: relative;
    top: 6px;
  } */
  .top-header-logo {
    background: inherit;
  }

  .nav-offcanvas-menu {
    border-top: 1px solid #ccc;
    margin-top: 20px;
    padding-top: 30px;
  }

  .nav-offcanvas.open img:nth-child(1) {
    width: 140px;
    padding-left: 20px;
    padding-top: 13px;
    position: relative;
    top: 4px;
  }
}

@media (min-width: 767px) and (max-width: 768px) {
  .enterprise_wrapper {
    width: 100%;
    display: grid;
    padding: 0px 10px;
    padding-top: 40px;
  }

  .autoamte_apps_selection {
    width: 140px;
    height: 120px;
  }

  .container_trial .main-slider-left .basic_plans {
    margin-bottom: 0px !important;
  }

  .landing_page_chatbot .assistant_slider .assistant_right_img img {
    width: 75%;
  }

  .center_image_home_cc h6 {
    font-size: 18px;
  }

  .Integrations_card .search_marketpalce {
    margin-top: 0;
  }

  .assistant_slider.home_bg_header img {
    margin-top: 50px;
  }

  .video_iframe span {
    top: 15%;
    margin-left: 20px;
    cursor: pointer;
    right: 4%;
  }

  .bg_header_trial .beta-slider img {
    padding: 0 !important;
    width: 75%;
    margin-top: 20px;
  }

  .video_show .nav-item.main-download-broucher {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .boarding_wrapper_container {
    display: flex;
  }

  .modal_popup_video .video_iframe iframe {
    width: 100%;
    height: calc(100vh - 60vh);
  }

  .solutions_header .pl-0.w-30 {
    display: none;
  }

  .solutions_header .w-40 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .solutions_header .w-40 {
    position: relative;
    top: 10px;
  }

  .solutions_header .w-30 {
    flex: 0 0 25%;
    max-width: 25%;
    display: none;
  }

  .bg_landing_zendesk_img {
    display: none;
  }

  .trial_mb_80.bg_landing_zendesk h6 {
    color: #fff;
    padding-top: 50px;
  }

  .bg_slider_home .main-slider-right img {
    width: 100%;
    float: left;
    margin-top: 40px;
  }

  .mob_banner_slide {
    display: flex;
    align-items: center;
    width: 100%;
    float: left;
  }

  .know-how-assistant-image.image_feature_hide img {
    display: none;
  }

  .flex_container_center img {
    display: block;
  }

  .footer_content_between_row {
    width: 100%;
  }

  .footer_content_between_row .--quick-link {
    margin: 10px 0;
    width: 45%;
  }

  .hamburger {
    position: absolute;
    right: 40px;
    top: 0px;
    cursor: pointer;
  }

  .nav-offcanvas .close {
    right: 40px;
    top: 40px;
  }

  .wv-footer .row.--footer-border {
    flex-direction: column;
  }

  .icon_arrow {
    left: -6%;
  }
}

/* trial page */

.trial_scale {
  width: 100%;
  float: left;
}

.trial_scale p {
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: -10px;
}

.trial_scale_h2 h2 {
  font-size: 36px;
  font-family: "Roboto-Regular";
  color: #485a63;
  margin-bottom: 40px;
  text-align: center;
}

.sign_up-form_us .dropdown_selct_opt p {
  margin: 0px;
}

.trialup_form_us .dropdown_selct_opt p {
  margin-top: 30px;
}

/* Responsive header */

@media only screen and (max-width: 992px) {
  .videos_container .wrapper_left_mobile {
    display: block;
    padding-right: 15px;
  }

  .videos_container .wrapper_left_mobile svg {
    width: 18px;
    height: 18px;
  }

  .videos_container .video_list_li {
    display: flex;
  }

  .videos_container .video_list_ul {
    border-radius: 0px 0px 6px 6px;
  }

  .video_list_ul_header {
    border: none;
  }

  .videos_wrapper .video_lists {
    display: block;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .videos_wrapper .wrapper_left {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .videos_wrapper .search_marketpalce .search_click {
    width: 100%;
  }

  .videos_container .select_work_form h4 {
    font-family: "Seravek-Medium";
    font-size: 20px;
    color: #494949;
    margin-bottom: 15px;
  }

  .videos_wrapper .beta-slider img {
    padding: 3em 0 !important;
    padding-top: 0px !important;
    width: 80%;
  }

  .overview_right_wrpper {
    display: grid;
    grid-template-columns: 47% 47%;
    place-items: baseline;
    align-items: flex-start;
    grid-gap: 40px;
    margin-top: 20px;
  }

  .get_started_wrapper {
    margin-top: 0px;
  }

  .similar_videos_wrapper .utube_rows {
    padding: 0px;
  }

  .videos_container .select_work_form .arrow_up_form {
    top: 45%;
    right: 18px;
  }

  .videos_container .select_work_form {
    margin-bottom: 20px;
  }

  .videos_container .automation_scroly {
    width: 100%;
    position: absolute;
    margin-top: 0px;
    top: 90%;
  }

  .search_apps_box::-webkit-scrollbar {
    width: 4px;
    display: block !important;
  }

  .automate_marketplace .apps_integrations.bg_marketpalce .cogni_fav_apps {
    margin-bottom: 20px;
  }

  .automate_marketplace .apps_integrations.bg_marketpalce {
    margin-top: 40px;
  }

  .mob_search h2 {
    font-family: "Roboto-Medium";
    font-size: 34px;
    color: #333;
    letter-spacing: -1.5;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
    margin-bottom: 40px;
  }

  .mob_search h2:before {
    border-bottom: 1px solid #afafaf;
    content: "";
    width: 104%;
    left: -2%;
    position: absolute;
    bottom: -20px;
  }

  .container_trial
    .marketplace_header_container
    .autoamte_apps_header.autoamte_apps_header_selcted
    p {
    width: 100%;
    float: left;
    padding-bottom: 30px;
  }

  .inter_slider_assist .grid_card_trial {
    padding-right: 15px;
  }

  .container_trial .content_center_automate .form_automate p {
    margin-bottom: 0px;
  }

  .container_trial .content_center_automate_selected .form_automate p {
    margin-bottom: 0px;
  }

  .autoamte_apps_header.autoamte_apps_header_selcted {
    padding: 0px 5%;
  }

  .form_automate {
    padding: 0px 15%;
  }

  .container_trial .content_center_automate p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .container_trial .content_center_automate_selected p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .autoamate_demo {
    width: 100%;
    float: left;
    margin-top: 5px;
  }

  .autoamte_apps_header_right .search_marketpalce {
    display: flex;
    flex-direction: column;
  }

  .container_trial .apps_header_left_container p {
    font-size: 16px;
  }

  .search_apps_box {
    overflow-y: auto;
    min-height: 248px;
    max-height: 256px;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    margin-bottom: 15px;
    row-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
  }

  .marketplace_autoamte_apps {
    padding: 0px 15px;
    padding-top: 40px;
  }

  .am_cards_column_left.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .explore_apps_container .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .why_autoamte_wrapper .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .container_trial .explore_apps_box p {
    padding: 30px;
  }

  .autoamte_apps_selection {
    width: 120px;
    height: 120px;
  }

  .autoamte_apps_header_left {
    min-height: 350px;
    padding: 25px;
  }

  .enterprise_wrapper_content {
    grid-template-columns: 100%;
    padding: 30px 25px;
  }

  .enterprise_wrapper_left {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .enterprise_wrapper_left svg {
    width: 84px;
  }

  .enterprise_wrapper_right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .automate_homepage .page_header_center {
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }

  .container_trial .automate_homepage .page_header_center h1 {
    padding: 0px 15px;
  }

  .container_trial .automate_homepage .page_header_center p {
    padding: 0px 15px;
  }

  .featured_content
    .benefits-workplace-content.cogni_workspace_content.home_slider
    h3 {
    font-size: 18px !important;
  }

  .boarding_wrapper_container .col-md-12.col-lg-9.col-12.pad_left_30 {
    order: 2;
  }

  .boarding_wrapper_container .col-md-12.col-lg-9.col-12.pad_right_30 {
    order: 2;
  }

  .boarding_wrapper_container .col-md-12.col-lg-3.col-12 {
    order: 1;
    margin-bottom: 16px;
  }

  .apps_integrations .cogni_fav_apps_color .card_apps_cc .card_office {
    background: none;
    box-shadow: none;
    margin-right: 10px;
  }

  .col-lg-6.trial_header_left {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .col-lg-6.cont_right {
    max-width: 100%;
    flex: 0 0 100%;
    align-items: center;
    display: flex;
  }

  .automate_homepage .grid_card_trial .row {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  .know-how-assistant-content h3 {
    margin-bottom: 10px;
    font-family: "Roboto-Medium";
  }

  .know-how-assistant.solutions_assistant p {
    margin-right: 0rem;
    text-align: center;
    padding: 0px 12%;
  }

  .container_trial.integrations_container
    .inter_slider_assist
    .pl-20-tage.integrations_page_form:first-child
    .feature_page_content_left {
    max-width: 100%;
  }

  .container_trial.integrations_container
    .inter_slider_assist
    .pl-20-tage.integrations_page_form:first-child
    .feature_page_img_right {
    flex-basis: 100%;
    max-width: 100%;
  }

  .container_trial
    .inter_slider_assist
    .pl-20-tage.integrations_page_form:first-child
    .feature_page_img_right
    img {
    width: 80%;
    height: 100%;
  }

  .top_header.header.res_header.home_header {
    padding-bottom: 12px;
  }

  .top_header.header.res_header.home_header.sticky {
    padding-bottom: 0px;
  }

  .helpdesk_solutions .bg_card_1 .cogni_workspace_content {
    padding: 25px;
  }

  .helpdesk_solutions .bg_card_2 .cogni_workspace_content {
    padding: 25px;
  }

  .apps_integrations.apps_integrations_home .cc_feature_apps_header h3 {
    margin-bottom: 30px;
  }

  .card_landing_section {
    max-width: 48.5%;
    flex: 0 0 48.5%;
  }

  .grid_card_trial .row {
    display: flex;
    justify-content: space-between;
  }

  .grid_card_trial .row .card_landing_section:nth-child(2) {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(1) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .container_trial .center_features_header p {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 16px;
  }

  .back_features_hide.cc_teams_wrapper
    .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button {
    padding: 0px 15% !important;
  }

  .footer_home:nth-child(1) .wrapperlink {
    display: none;
  }

  .footer_home:nth-child(2) .wrapperlink {
    display: none;
  }

  .footer_home:nth-child(5) .wrapperlink {
    display: none;
  }

  .footer_home:nth-child(6) .wrapperlink {
    display: none;
  }

  .header_menu p {
    max-width: max-content;
  }

  .footer_home {
    width: 100%;
    height: auto;
    float: left;
    position: relative;
  }

  .footer_home .footer_aws {
    position: relative;
    bottom: 0;
    padding-bottom: 0px;
  }

  .footer_home .footer_aws img {
    width: 60%;
    height: auto;
    position: relative;
    bottom: 0;
  }

  .footerlink_left {
    width: 100%;
    float: left;
    height: auto;
    position: relative;
    border: none;
    margin-bottom: 20px;
  }

  .footerlink_left:before {
    display: none;
  }

  .footerlink_left .footerlogo_left {
    position: relative;
    bottom: 0;
  }

  .footerlink_left .footerlogo_left a {
    padding-bottom: 0px;
  }

  .footer_right {
    width: 100%;
    float: left;
    height: auto;
    position: relative;
  }

  .footer_right .wrapperlink {
    padding-top: 0px;
  }

  .footer_right .wrapperlink:nth-child(1) {
    padding-top: 0px;
  }

  .footer_right .wrapperlink:nth-child(2) {
    padding-top: 0px;
  }

  .footer_right .wrapperlink:nth-child(3) {
    padding-top: 0px;
  }

  .footer_right .wrapperlink:nth-child(4) {
    padding-top: 0px;
  }

  .footer_right .wrapperlink:nth-child(5) {
    padding-top: 0px;
  }

  .footerlink_left .wrapperlink {
    padding-top: 0px;
  }

  .top_header {
    min-height: 40px;
    z-index: 11111;
  }

  .slider_wrapper {
    display: block;
  }

  .container_trial .feature_page_img_right.mob_slider_pad_0 img {
    width: 80%;
    height: 80%;
    position: relative;
    left: 10%;
  }

  .container_trial.integrations_container
    .pl-10-tage
    .feature_page_img_right.mob_slider_pad_0 {
    padding: 0px;
  }

  .container_trial.integrations_container
    .col-md-6.feature_page_img_right.mob_slider_pad_0 {
    padding: 0px 15px;
  }

  .desktop_tabs {
    display: none !important;
  }

  .servicenow_virtual .desktop_tabs {
    display: none !important;
  }

  .servicenow_virtual .rwt__tablist[aria-orientation="vertical"] {
    display: none !important;
  }

  .servicenow_virtual
    .rwt__tablist.horizontal_slider[aria-orientation="vertical"] {
    display: flex !important;
  }

  .servicenow_virtual .footer_form .row {
    padding: 0px;
  }

  .navbar-brand.js-scroll-trigger.icon_open {
    margin-top: 18px;
  }

  .navbar-toggler-icon.icon_open {
    margin-top: 18px;
  }

  .mobile_menu_page {
    display: inline-block;
  }

  .desktop_menu_page {
    display: none;
  }

  .servicenow_virtual .desktop_menu_page {
    display: flex;
  }

  .landing_it_virtual .desktop_menu_page {
    display: flex;
  }

  .navbar-brand.js-scroll-trigger {
    padding-left: 15px;
  }

  .header_landing_wrapper .navbar-collapse {
    padding: 30px 0px;
  }

  .header_landing_wrapper .nav-item {
    padding: 10px 0px;
  }

  .landing_bg .navbar-toggler.collapsed .navbar-toggler-icon {
    margin-right: 20px;
    width: 24px;
    height: 24px;
    position: relative;
  }

  .navbar-toggler {
    padding-right: 20px;
  }

  .header_landing header.sticky .navbar-brand.js-scroll-trigger {
    padding-left: 15px;
    margin-top: 15px;
  }

  .search-results {
    width: 100%;
    background: #fff;
    margin-top: -60px;
    padding-top: 40px;
    height: calc(100vh - -20px);
  }

  .header_landing.sticky .fixed-top {
    height: 80px;
    top: 0px;
    padding: 0px;
  }

  .header_landing .fixed-top {
    height: 80px;
    top: 0px;
    padding: 0px;
  }

  .roi-form-right {
    width: 100%;
    float: left;
    margin-top: 30px;
  }

  .whitepaper_cards.three_cards_wrapper .cards__item {
    width: 46%;
  }

  .whitepaper_cards.three_cards_wrapper .cards {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
  }

  .whitepaper_cards.three_cards_wrapper .cards .cards__item.bg_card_1 {
    margin-bottom: 30px;
  }

  .whitepaper_cards.three_cards_wrapper .cards .cards__item.bg_card_2 {
    margin-bottom: 30px;
  }

  .card_landing_section.integrations_popular_bg {
    max-width: 48%;
    flex: 0 0 48%;
  }

  .onprem_connect .automation_steps h2 {
    text-align: center;
  }

  .onprem_connect .automation_steps p {
    padding: 0px 5%;
  }

  .card_landing_section.integrations_popular_bg.key_integrations_bg {
    margin-bottom: 20px;
  }

  .whitepaper_cards.apps_center_wrapper .cards__item {
    width: 48%;
  }

  .cards_apps_wrapper {
    width: 60%;
  }

  .mob_pricing_page {
    display: block;
  }

  .dek_pricing_page {
    display: none;
  }

  .dynamic_cards .cards_wrapper_home .col-md-6:first-child {
    margin-bottom: 0px;
  }

  .modal_ui .cards_apps_wrapper {
    width: 100%;
    padding: 0px 20px;
  }

  .modal_ui .apps_name {
    width: 88%;
  }

  .icons_wrapper {
    width: 100%;
    float: left;
    padding: 0px 15px;
  }

  .whitepaper_cards.newpages_cards .cards__item {
    width: 45%;
    padding: 0px;
  }

  .bg_section_knowledge.self_service_an .main-slider-left {
    padding-right: 15px;
    padding-left: 15px;
  }

  .top_section.pricing_page .main-slider-button.beta-button {
    padding: 0px 15%;
  }

  .pb-200.content_para {
    padding: 0px;
  }

  .mar_bot_1 .wv-heading-h4 {
    font-size: 18px;
  }

  .wv-limited-content {
    padding-right: 0px !important;
  }

  .mt-40.-innovative .wv-banner-text-large {
    margin-bottom: 20px;
  }

  .mt-40.-innovative .wv-banner-text-small {
    margin-bottom: 20px;
  }

  .pad_le_riht .wv-banner-text-small.mb-5 {
    margin-bottom: 20px !important;
  }

  .inno_tech .pad_le_riht {
    padding: 0px;
  }

  .mar_bot_40 .wv-heading-h4.mb-2 {
    font-size: 18px;
  }

  .wv-limited-content {
    max-width: max-content;
    padding-right: 0px;
  }

  .pricing-chart ul {
    width: 100%;
    float: left;
    height: -webkit-max-content;
    height: max-content;
    overflow-y: inherit;
  }

  [tooltip] {
    position: relative;
  }

  /* .pricing-columns.pricing-section .container{
    padding-right:0px;
  } */
  [tooltip]::after {
    content: attr(tooltip);
    font-family: Helvetica, sans-serif;
    width: 200px;
  }

  .pricing-columns .pricing-chart .plan ul,
  .pricing-columns-both .pricing-chart .plan ul {
    text-align: center;
    padding-left: 12px !important;
    margin-left: 0px !important;
  }

  .tab_desktop {
    display: none;
  }

  .top_section {
    padding: 40px 0px;
  }

  .top_section p {
    margin-bottom: 0px;
  }

  .top_section h1 {
    text-align: center;
  }

  .mob_res_menu .tab_resposnive {
    display: block;
    width: 100%;
    float: left;
    position: sticky;
    top: 80px;
    z-index: 1;
    background: #fff;
    padding-top: 20px;
  }

  .mob_res_menu .wv-dropdown .link {
    border: 2px solid #2468f6;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    color: #2468f6;
  }

  .mob_res_menu .wv-dropdown .link::after {
    content: "";
    background-image: url("../img/arrow-down-1.svg");
  }

  .security_wrapper .resources_card {
    padding-top: 20px;
  }

  .mob_res_menu {
    width: 100%;
    float: left;
    position: relative;
    background: #fff;
  }

  .cards_center .knowledge_cards .whitepaper_cards .cards {
    justify-content: space-between;
  }

  .cards_center .knowledge_cards .whitepaper_cards .cards .cards__item {
    place-self: flex-start;
  }

  .container_trial
    .gen_vs_true.autoamted_resolution
    .wv-section-mv-p.pb-200.content_para {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .cards_center
    .knowledge_cards
    .whitepaper_cards
    .cards
    .cards__item.card_one {
    margin-right: 0px;
  }

  .knowledge_cards .whitepaper_cards .cards__item {
    width: 47%;
  }

  .knowledge_header_form .main-slider-button.beta-button {
    width: 85%;
    margin: 0;
    display: inline-block;
  }

  .knowledge_cards .whitepaper_cards .cards__item:first-child {
    margin-bottom: 20px;
  }

  .feature_page_content_left .two_links_a .card_link_landing a {
    width: 100%;
    float: left;
  }

  .feature_page_content_left .two_links_a .card_link_landing a:first-child {
    margin-bottom: 12px;
    margin-right: 0px;
  }

  .feature_page_content_left .two_links_a .card_link_landing a:last-child {
    padding-top: 0px;
  }

  .feature_page_content_left .two_links_a .card_link_landing {
    width: 100%;
    float: left;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .apps_integrations.bg_marketpalce .cogni_fav_apps {
    margin-bottom: 0px;
  }

  /* .res_header.header .row {
    position: relative;
    top: -5px;
  } */
  .header.res_header {
    width: 100%;
    float: left;
    padding-top: 15px;
    padding-bottom: 0px;
    background: #fff;
  }

  .header.res_header.home_bg_header {
    padding-bottom: 0px;
    padding-top: 12px;
  }

  .header.res_header .navbarlogo_img {
    padding-bottom: 15px;
  }

  .header.res_header .navbarlogo_img img {
    width: 220px;
  }

  .top_header.header.res_header.sticky {
    padding-top: 0px;
  }

  .container_trial.integrations_container
    .pl-20-tage
    .feature_page_content_left {
    flex-basis: 100%;
  }

  .container_trial.integrations_container .pl-20-tage .feature_page_img_right {
    flex-basis: 100%;
  }

  .container_trial.integrations_container
    .features_header_home
    .align_center_content {
    flex-basis: 100%;
  }

  .container_trial.integrations_container .features_header_home .flex_home {
    flex-basis: 100%;
  }

  .pad_right_20 {
    padding-right: 30px;
  }

  .utube_rows {
    display: grid;
    align-content: center;
    grid-gap: 20px;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
    padding: 0px 10px;
  }

  .iframe_zoho iframe {
    position: relative;
    left: 0%;
    margin-top: 15px;
    width: 100%;
    height: 100vh;
    margin-bottom: 40px;
  }

  .img_mob_res_right {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .img_mob_res_right img {
    width: 35%;
    height: 100%;
  }

  .flex_container_center img {
    display: block !important;
  }

  .know-how-assistant-image.image_feature_hide img {
    display: none !important;
  }

  .cc_feature_apps .cogni_fav_apps_color {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .card_office_cc {
    flex-basis: 25%;
  }

  .cc_feature_apps h5 a {
    margin-top: 10px;
  }

  .cc_feature_apps .card_office_cc h6 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .assistant_change_img.feature_cc_slider {
    display: block;
  }

  .features_content_left .img_mob_res {
    display: block !important;
    height: 300px;
    width: 100%;
    margin-bottom: 55px;
  }

  /* .features_content_left.features_content_left_cc {
    margin-top: -190px;
  } */
  /* .pl-20-tage .feature_pageinner_content_left {
    padding-left: 15px;
  } */
  .bg_p_wihout_padding {
    padding: 20px;
    margin-top: 20px;
  }

  .mobile_logo_img {
    display: block;
  }

  .--quick-link {
    margin: 10px 0;
    width: 48%;
  }

  .m_t_110 {
    margin-top: 107px !important;
  }

  .hamburger {
    display: block;
  }

  .assistant_change_img .dialog-editor-content {
    height: auto;
  }

  .assistant_change_img img {
    width: 100%;
    float: left;
    height: 100%;
    padding: 15px;
    margin: 0px;
  }

  .landing_contact h2 {
    margin-bottom: 42px;
  }

  .accordian_landing h2 {
    margin-bottom: 20px;
  }

  .mobile-logo {
    height: 60px;
    padding: 13px 0;
    /* position: absolute; */
    /* top: 12px; */
    border-bottom: 1px solid #eceff1;
    margin: 0 0 40px;
    margin-bottom: 0px;
  }

  .mobile-logo .col-lg-3 a {
    position: relative;
    top: 7px;
  }

  .mobile-logo .hamburger {
    top: 21px;
  }

  .menu_border_bottom {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eceff1;
    position: relative;
    top: -50px;
    height: 60px;
  }

  .menu_border_bottom a img {
    position: relative;
    top: 10px;
    left: 10px;
  }

  /* .mobile-logo .nav-offcanvas .close {
    top: 28px;
  } */
  .top-header {
    border-bottom: 1px solid #f5f5f5;
  }

  .mobile-logo .ti-menu:before {
    color: #101629;
    content: "\e68e";
  }

  .mobile-logo .ti-close:before {
    color: #101629;
    content: "\e646";
    font-size: 18px;
  }

  .header-logo ul li {
    padding-bottom: 30px;
  }

  .mobile-logo .main-download-broucher a {
    text-align: center;
    color: #2468f6;
    font-size: 14px;
    font-family: Roboto-Bold;
    margin: 0px;
    padding: 0px;
  }

  .powerd_by_product {
    width: 100%;
    float: left;
  }

  .powerd_by_product p {
    text-align: center;
    color: #b0bec5;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto-Regular;
  }

  .powerd_by_product img {
    width: 120px;
    margin: 0 auto;
    display: block;
  }

  .beta_landing_head p {
    padding: 0px;
  }

  .beta_landing_head_link {
    margin-bottom: 20px;
  }

  .cogni_fav_apps_head_img_left img {
    display: none;
  }

  .cogni_fav_apps_head_img_right img {
    display: none;
  }

  .carousel-inner img {
    width: 100%;
    height: 100%;
  }

  .assistant_change_img {
    width: 100%;
    height: 100%;
  }

  .assistant_change_img img {
    width: 100%;
    float: left;
    height: 100%;
  }

  .assistant_change_img .dialog-editor-content {
    width: 100%;
    float: left;
  }

  .beta_landing_change_img {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 0px;
    top: 0px;
    padding-top: 80px;
    margin-bottom: 0px;
  }

  .beta_landing_card {
    margin-top: 55px;
  }

  .beta_landing_card h4 {
    text-align: center;
    font-size: 34px;
  }

  .landing_contact_img {
    display: none;
  }

  .landing_contact_left {
    padding-right: 15px !important;
    margin-bottom: 60px;
  }

  .accordian_landing .accordion .link {
    padding: 15px 15px 15px 0px;
  }

  .beta_landing_head h1 {
    font-size: 40px;
  }

  .beta_landing_head {
    padding-top: 0px;
  }

  .carousel-control-prev-icon {
    display: none !important;
  }

  .carousel-control-next-icon {
    display: none !important;
  }

  .carousel-indicators {
    bottom: -45px !important;
  }

  .hide_data_landing h2 {
    margin-bottom: 30px;
    text-align: center;
  }

  .hide_data_landing {
    margin-top: 40px;
  }

  .data_landing_success h2 {
    text-align: center;
  }

  .landing_expand_para {
    margin-top: 40px;
  }

  .it_support_chatbot .top_menu_sticky_fixed {
    width: 100%;
    float: left;
    z-index: 9;
  }

  .footerlink_left .footerlogo_left img {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .videos_wrapper .beta-slider img {
    padding: 3em 0 !important;
  }

  .autoamte_apps_selection {
    width: 125px;
    height: 120px;
  }

  .icon_arrow {
    position: absolute;
    top: 4%;
    left: -10px;
  }

  .rwt__tab {
    height: 40px;
  }

  .rwt__tablist[aria-orientation="vertical"]
    .rwt__tab[aria-selected="true"]:after {
    height: 40px !important;
  }

  .top_header.header.res_header.sticky {
    padding-bottom: 0px;
  }

  .calendly-inline-widget {
    height: calc(60vh - 0vh) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .it_support_chatbot.ai-self-service-chatbot .controls_wrapper {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .pricing-columns.pricing-section .plan:nth-child(3) {
    margin-bottom: 0px;
  }

  .pricing-columns.pricing-section .plan:nth-child(4) {
    margin-bottom: 0px;
  }

  .container_trial .how_it_section_page .main-slider-left h1 {
    padding: 0px 15px;
  }

  .featured_content .slick-prev,
  .featured_content .slick-next {
    top: -20px;
  }

  .homepage_slider.slider_homepage .featured_content {
    padding: 0px;
  }

  .container_trial.integrations_container
    .pl-10-tage
    .feature_page_content_left {
    flex-basis: 50%;
  }

  .container_trial.integrations_container .pl-10-tage .feature_page_img_right {
    flex-basis: 50%;
  }

  .footer_right.automate_footer {
    width: 50%;
  }

  /* .footer_right.automate_footer:nth-child(4){
    display: none;
  }
  .footer_right.automate_footer:nth-child(5){
    display: none;
  } */
  .bg_header_trial.trial_mb_80 .assistant_right_img {
    padding: 0px;
  }

  .assistant_change_img.feature_cc_slider {
    margin-bottom: 0px;
  }

  .order_2-res.mob_order_none {
    margin-top: 0px;
  }

  .statistics-boggle-content .newsletter_content_left p {
    font-size: 18px;
    padding: 0px;
    text-align: left;
  }

  .statistics-boggle-content .newsletter_content_right p {
    font-size: 18px;
    padding: 0px;
    text-align: left;
  }

  .statistics-boggle-content.whitepaper-boggle-content h3 {
    width: 45%;
    float: left;
    margin-bottom: 30px;
  }

  .whitepaer_border .row {
    padding: 60px 40px;
  }

  .container_trial .trial_mb_80.bg_header_trial .main-slider-left p {
    margin-bottom: 30px;
    line-height: 24px;
    margin-top: 30px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    font-size: 18px;
  }

  .cognii_work_content .cogni_works h1 {
    font-size: 32px;
  }

  .container_trial .cognii_work_content .cogni_works p {
    font-size: 18px;
  }

  .card_landing_trial h4 {
    font-size: 22px;
  }

  .card_landing_trial p {
    font-size: 16px;
    line-height: 24px;
  }

  .trail_apps_landing .cogni_fav_apps_header p {
    font-size: 18px;
  }

  .trial_mb_80.bg_landing_zendesk h6 {
    color: #fff;
    padding-top: 50px;
  }

  .container_trial p {
    font-size: 18px;
  }

  .bg_slider_home .main-slider-right img {
    width: 100%;
    float: left;
  }

  .mob_banner_slide {
    display: flex;
    align-items: center;
    width: 100%;
    float: left;
  }

  .buton_all_features_page {
    display: none;
  }

  /* .feature_pageinner_content_left {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  } */
  .features_inner_page_footer img {
    width: 65%;
    float: right;
  }

  .features_inner_page_footer {
    display: block;
    width: 100%;
    float: left;
  }

  .solutions_left_img {
    background-position-x: -121px;
    width: 140%;
  }

  .solutions_right_img {
    background-position-x: 35px;
    width: 170%;
  }

  .solutions_header .w-40 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .solutions_header .w-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .conversational_facelift_header h4 {
    font-size: 24px;
  }

  .solutions_integrations_card h4 {
    font-size: 24px;
  }

  .mobile-logo {
    border: none;
  }

  .scroll-prompt .scroll-prompt-arrow-container {
    margin-left: 10px;
  }

  .landing_contact_img {
    margin-top: -132px;
    right: 33px;
  }

  .landing_contact_img img {
    position: relative;
    width: 190px;
  }

  .scroll-prompt_dropdown {
    position: relative;
    top: 65px;
    transform: rotate(-180deg);
    left: -10px;
  }

  .assistant_change_img img {
    width: 56%;
    float: left;
    height: 235px;
    padding: 15px;
    margin: 0px;
  }

  .assistant_change_img {
    height: auto;
  }

  .assistant_change_img .dialog-editor-content {
    width: 44%;
    float: left;
    height: auto;
  }

  .trial_page_bottom {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .link_card_wrapper {
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .link_card_img {
    width: 84px;
    height: 84px;
    float: left;
    margin-right: 0;
    text-align: left;
  }

  .link_card_img img {
    width: 84px;
    height: 84px;
  }

  .link_card_content {
    width: 86%;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-left: 15px;
  }

  .link_card_content h4 {
    font-size: 18px;
    line-height: inherit;
    margin-bottom: 12px;
  }

  .market_wrapper_page iframe {
    height: 220px;
  }

  .similar_videos_wrapper .utube_rows {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-gap: 30px;
  }

  .videos_wrapper .features_change_img-center {
    margin-bottom: 0px;
  }

  .overview_wrapper .col-lg-8 {
    padding-right: 15px;
  }

  .overview_wrapper .sign_up-form_us.demo_page {
    padding-left: 0px;
    padding-right: 0px;
  }

  .overview_wrapper .row.beta_change_form ul li {
    line-height: inherit;
  }

  .videos_wrapper
    .feature_inner_page.bg_feature_slider
    .features_change_img-center
    iframe {
    width: 98%;
    height: 400px;
  }

  .autoamte_apps_selection {
    width: 140px;
    height: 120px;
  }

  .am_cards_wrapper.actions_wrapper .am_cards_column_left {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .workflow_autoamte_wrapper .ahref-wrapper {
    font-size: 16px;
    margin-top: 10px;
  }

  .container_trial .automate_triggers_center .page_header_center h1 {
    padding: 0px 7%;
  }

  .container_trial .automate_triggers_center .page_header_center p {
    padding: 0px 7%;
  }

  .boarding_wrapper_container .col-md-12.col-lg-9.col-12.pad_left_30 {
    order: 2;
  }

  .boarding_wrapper_container .col-md-12.col-lg-3.col-12 {
    order: 1;
  }

  .mobile_view_tab .mob_accordian.mob_accordian_faq .container h2 {
    text-align: left;
    padding-left: 15px;
  }

  .bg_feature_slider.bg_feature_slider_wrapper .feature_bg_img:before {
    height: 75%;
  }

  .bg_p_wihout_padding_slack a {
    color: #fff;
  }

  .cards_wrapper_home {
    flex-wrap: wrap;
  }

  .col-xs-12.bg_card_1,
  .col-xs-12.bg_card_2,
  .col-xs-12.bg_card_3,
  .col-xs-12.bg_card_4 {
    margin: 0px;
  }

  .col-xs-12.bg_card_1,
  .col-xs-12.bg_card_3 {
    margin-bottom: 0px;
  }

  /* .cards_wrapper_home .col-md-6 {
    flex-basis: 100%;
    display: flex;
    max-width: 100%;
} */
  .col-xs-12.bg_card_1 {
    margin-bottom: 0px;
  }

  .footerlink_left .wrapperlink {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .landing_bg.bg_header_trial.trial_mb_80 {
    padding-top: 60px;
  }

  .onprem_connect .boarding_wrapper_container img {
    width: 120px;
  }

  .mob_sticky_wrapper .boarding_wrapper_container img {
    width: 120px;
  }

  .container_trial .gen_vs_true .bg_section_knowledge .main-slider-left h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .mob_res_slide {
    width: 100%;
    display: flex;
    margin: 0 auto;
  }

  .mob_res_slide li {
    float: left;
    margin: 0px 5px;
  }

  .desktop_slider_cc {
    display: block;
  }

  .carousel-indicators.mob_res_slide {
    display: flex;
    margin: 0 auto;
  }

  .carousel-indicators {
    bottom: -35px !important;
  }

  .carousel-indicators .active {
    position: relative;
  }

  .carousel-indicators .active:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid #2468f6;
    top: -3px;
    border-radius: 50%;
    left: -3px;
  }

  .footer_form {
    padding: 0px 15px;
  }

  .assistant_change_img.feature_cc_slider #carouselExampleIndicators {
    margin-bottom: 40px;
  }

  .card_link_landing.jira_landing_link a {
    margin-right: 0px;
    font-size: 14px;
  }

  .mob_res_slide .carousel-indicators li {
    margin: 0px 12px;
    float: left;
  }

  .bg_p_wihout_padding_slack {
    background: #8b4d8b;
  }

  .bg_p_wihout_padding {
    min-height: auto;
  }

  .bg_p_wihout_padding_slack h3 {
    color: #fff;
  }

  .bg_p_wihout_padding_slack p {
    color: #fff !important;
  }

  .bg_p_wihout_padding.bg_p h3 {
    color: #fff;
    font-size: 25px;
  }

  .bg_p_wihout_padding_slack {
    margin-bottom: 20px;
  }

  .card_link_landing.jira_landing_link {
    display: flex;
    justify-content: space-between;
  }

  .icon-top-img img {
    width: 64px;
    height: 64px;
  }

  .card_landing_section {
    max-width: 48%;
    flex: 0 0 48%;
    float: left;
    margin-bottom: 40px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(1) {
    margin-right: 20px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(2) {
    margin-right: 0px;
  }

  .chat_channels_trial_btn img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .card_landing_section:nth-child(3) {
    margin-bottom: 0px;
  }

  .mobile_dropdown {
    display: block;
  }

  .video_lists {
    display: none;
  }

  #exampleModalCenter .modal_body_right {
    display: none;
  }

  #exampleModalCenter .modal-body {
    width: 100%;
    float: left;
  }

  #exampleModalCenter1 .modal_body_right {
    display: none;
  }

  #exampleModalCenter1 .modal-body {
    width: 100%;
    float: left;
  }

  .btn_close_popup {
    font-size: 32px;
    position: relative;
    left: 15px;
  }

  .modal_submit_form .modal-header h5 {
    font-size: 22px;
    font-family: "Roboto-Medium";
    padding-top: 15px;
    text-align: center;
  }

  /* .features_content_left.features_content_left_cc {
    margin-top: 0px !important;
  } */
  .assistant_change_img.feature_cc_slider img {
    width: 100%;
    float: left;
    height: auto;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  /* .bg_feature_slider .features_change_img_header {
    padding-right: 0px;
  } */
  .know-how-assistant-image img {
    width: 50%;
  }

  .text-right.sm-align-left {
    width: 100%;
    text-align: left !important;
    padding-bottom: 10px;
  }

  .footer_social_icons .wv-social-link img {
    margin-left: 0px;
    width: 26px;
    height: 26px;
    margin-right: 20px;
  }

  .--footer-sublink {
    width: 100%;
    float: left;
    padding-bottom: 24px;
  }

  .--footer-sublink ul {
    display: inline-flex;
  }

  .--footer-sublink ul li {
    padding-right: 25px;
  }

  .footer_social_icons {
    width: 100%;
    float: left;
    flex-wrap: wrap;
    padding-bottom: 24px;
  }

  .footer_social_icons span {
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }

  .row.--footer-border {
    width: 100%;
    float: left;
    padding: 30px;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .landing_bg .row.--footer-border {
    flex-direction: column;
  }

  .trial_page_bottom {
    margin-top: 0px !important;
  }

  .know-how-assistant-content h3 {
    font-size: 32px;
  }

  .solutions_header {
    padding-bottom: 0px;
    overflow: hidden;
    padding-top: 0px;
  }

  .solutions_form {
    margin-bottom: 0px;
  }

  .solutions_left_img {
    background-position-x: -121px;
    width: 140%;
  }

  .solutions_right_img {
    background-position-x: 35px;
    width: 170%;
  }

  .solutions_header .w-40 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .solutions_header .w-30 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .solutions_center h1 {
    font-size: 34px;
    margin-top: 20px;
  }

  .solution_service_card {
    padding: 30px 30px 0px 30px;
  }

  .--quick-link ul {
    display: none;
  }

  .--quick-link ul.show {
    display: block;
  }

  .--quick-link h4:after {
    content: "\f107";
    float: right;
    font-family: "FontAweSome";
    font-size: 20px;
  }

  .--quick-link h4.arrow-up:after {
    transform: rotate(180deg);
  }

  .landing_bg .footer_landing .text-right.sm-align-left {
    width: auto;
  }
}

@media screen and (max-width: 767px) {
  /* market blog pages start*/
  .button_last {
    margin-bottom: 40px;
  }

  .grid__wrapper {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  .most_popular_links .most_popular_header {
    font-size: 26px;
  }

  .grid_container_chatbot_wrapper {
    grid-template-columns: 1fr;
    padding-right: 0px;
  }

  .sticky_right_img {
    display: none;
  }

  .table_contents_wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .grid_container_chatbot {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .market_main_wrapper .footer_form {
    margin-bottom: 40px;
  }

  .grid_container_chatbot_wrapper .main-slider-left-market h1 {
    font-size: 30px;
  }

  .market_wrapper_page h2 {
    font-size: 26px;
  }

  .grid_container_chatbot_wrapper h3 {
    font-size: 22px;
  }

  /* market blog pages end*/
  .inegrations_header_right {
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .servicenow_virtual .inegrations_header_right {
    padding-top: 0px;
    padding-bottom: 10px;
  }

  .servicenow_virtual .pl-20-tage.mt-0 {
    padding-top: 40px;
  }

  .servicenow_virtual .features_content_left {
    padding-bottom: 40px;
  }

  .servicenow_virtual .center_image_home {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .servicenow_virtual .int_landing_page .trial_page_img.bg_trial_page {
    padding-top: 40px;
  }

  .servicenow_virtual .features_img_left {
    padding-bottom: 40px;
  }

  .servicenow_virtual .integrations_slider_bg .featured_content {
    padding: 40px 0px;
  }

  .servicenow_virtual .apps_landing_page {
    padding-top: 40px;
  }

  .servicenow_virtual
    .last_bg_pad_0
    .integrations_page_form
    .feature_page_img_right {
    display: none;
  }

  .servicenow_virtual .landing_page_chatbot.ai_cards_service {
    padding-top: 20px;
  }

  .servicenow_virtual .footer_form {
    margin-bottom: 40px;
    margin-top: 0px;
  }

  .servicenow_virtual .footer_form .row {
    padding: 0px;
  }

  .servicenow_virtual .center_feature_wrapper {
    padding: 0px;
  }

  .iocn_virtual {
    display: none;
  }

  .servicenow_virtual .col-md-12.mob_slider_pad_0 {
    padding: 0px 20px;
  }

  .servicenow_virtual .center_feature_wrapper h2 {
    text-align: center;
  }

  .int_landing_page .horizontal_slider .rwt__tab {
    color: #999;
  }

  .servicenow_virtual .apps_landing_header_wrapper h3 {
    padding: 0px;
  }

  .servicenow_virtual .video_section {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .servicenow_virtual .apps_landing_page {
    margin-bottom: 40px;
  }

  .servicenow_virtual .integration_header_top {
    padding: 0px;
  }

  .landing_it_virtual .center_image_home {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .landing_it_virtual .video_section {
    margin-bottom: 40px;
  }

  .landing_it_virtual .footer_form {
    margin-bottom: 40px;
  }

  .landing_it_virtual .servicenow_virtual .apps_landing_page {
    margin-bottom: 0px;
  }

  .container_trial .servicenow_virtual .bg_landing_white .second_p {
    font-family: "Roboto-Medium";
  }

  .landing_it_virtual .video_section .container {
    padding: 0px 20px;
  }

  .container_trial.integrations_container
    .servicenow_virtual
    .pl-10-tage
    .feature_page_content_left {
    padding: 0px;
  }

  .trial_page_img.bg_landing_yellow.bg_landing_virtual {
    padding-top: 40px;
  }

  .videos_wrapper .back_features_page a {
    margin-bottom: 20px;
    width: 100%;
    display: block;
  }

  .similar_videos_wrapper .utube_rows > .utube_col_4 p {
    font-size: 18px;
  }

  .videos_wrapper .feature_inner_page.bg_feature_slider .main-slider-left h1 {
    padding: 0px;
  }

  .videos_wrapper
    .back_features_hide.cc_teams_wrapper
    .feature_inner_page.bg_feature_slider
    .main-slider-left {
    margin-top: 0px;
  }

  .videos_wrapper .main-slider-left .footer_form {
    margin-bottom: 40px;
    padding: 0px;
  }

  .explore_apps_container .col-md-6:first-child {
    margin-bottom: 40px;
    padding-left: 0;
    max-width: 100%;
  }

  .workflow_automation_wrapper.workflow_autoamte_wrapper.trigger_page.automate_product
    .tabgroup_bg_white {
    width: 100%;
    float: left;
    border-top: 1px solid #d2d1d1;
    padding-top: 30px;
  }

  .card_landing_section.integrations_popular_bg h4 {
    font-size: 18px;
  }

  .inter_slider_assist .pl-20-tage.mt-0 {
    padding-top: 40px;
  }

  .inter_slider_assist
    .container_trial
    .landing_page.trial_page_img
    .feature_page_content_left
    h2 {
    margin-top: 0px;
  }

  .autoamte_apps_header h1 {
    line-height: inherit;
  }

  .mob_search h2 {
    font-size: 28px;
  }

  .bg_marketpalce .cc_feature_apps_header h3 {
    text-align: center;
    font-family: "Roboto-Medium";
    font-size: 28px;
    padding: 0;
    margin-bottom: 20px;
  }

  .am_cards_wrapper.actions_wrapper h3 {
    line-height: inherit;
  }

  .automate_marketplace .footer_form_right .form_below_content span {
    font-size: 14px !important;
  }

  .am_cards_wrapper {
    padding: 40px 0px;
    margin-bottom: 0px;
  }

  .am_cards_wrapper.actions_wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .why_autoamte_wrapper {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .explore_apps_container {
    margin-bottom: 40px;
  }

  .marketplace_autoamte_apps {
    margin-bottom: 0px;
  }

  .automate_marketplace .footer_form {
    margin-bottom: 40px;
  }

  .three_steps_wrapper {
    margin-bottom: 40px;
  }

  .am_cards_wrapper h2 {
    margin-top: 30px;
  }

  .am_cards_wrapper h3 {
    margin-top: 30px;
  }

  .am_cards_wrapper
    .autoamte_apps_header_right
    .search_marketpalce
    .search_icon {
    left: 5px;
  }

  .market_apps_conainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center_connection {
    overflow: hidden;
    z-index: 1;
    position: relative;
    top: 0%;
    left: 0;
    transform: none;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    width: 45px;
    height: 45px;
  }

  .content_center_automate {
    margin-top: 0px;
  }

  .market_apps_conainer .col-md-6:last-child {
    padding-bottom: 20px;
  }

  .form_automate {
    padding: 0px 10%;
  }

  .container_trial .autoamte_apps_header p {
    margin-bottom: 20px;
  }

  .market_apps_conainer {
    flex-wrap: wrap;
  }

  .container_trial .content_center_automate p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .container_trial .content_center_automate_selected p {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .am_cards_column_left.col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .am_cards_wrapper.actions_wrapper .am_cards_container {
    display: none;
  }

  .am_cards_wrapper.actions_wrapper .am_cards_container._responsive_mode {
    display: block;
  }

  .marketplace_autoamte_apps {
    padding-top: 20px;
  }

  .market_apps_conainer .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 0px;
  }

  .x_scroll_page {
    flex-direction: column;
  }

  .apps_list {
    min-height: 110px;
    margin-bottom: 0px;
  }

  .search_apps_box {
    overflow-y: auto;
    min-height: 235px;
    max-height: 235px;
    grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  }

  .autoamte_apps_header_right {
    min-height: 300px;
    padding-top: 20px;
  }

  .autoamte_apps_header_left {
    min-height: 300px;
    padding: 20px;
  }

  .footer_right.automate_footer:nth-child(2):before {
    display: none;
  }

  .automate_img_logo {
    margin-bottom: 0px !important;
  }

  .autoamte_apps_header_left {
    min-height: 350px;
    padding: 15px;
  }

  .autoamte_apps_selection {
    margin-bottom: 15px;
  }

  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    width: 18px;
    height: 18px;
  }

  .automate_homepage .page_header_center {
    margin-bottom: 20px;
  }

  .container_trial .automate_homepage .footer_form_left .main-slider-left p {
    padding-right: 100px;
  }

  .mobile_view_tab .mob_accordian.mob_accordian_faq .container h2 {
    text-align: left;
    justify-content: left;
    padding-left: 15px;
  }

  .automate_homepage .feature_inner_page.bg_feature_slider .main-slider-left {
    padding: 0px;
  }

  .automate_homepage .mob_accordian.mob_accordian_faq .accordion__button {
    font-size: 16px;
  }

  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 18px;
    height: 18px;
  }

  .mob_accordian.mob_accordian_faq .accordion__button:before {
    width: 18px;
    height: 18px;
  }

  .automate_homepage .how_it_works_header {
    flex-basis: inherit;
    margin-right: 0;
  }

  .automate_homepage .how_it_works_header {
    border-bottom: 1px solid#CBCBCB;
    margin-bottom: 20px;
  }

  .automate_homepage .how_it_works_header:last-child {
    border: none;
    margin-bottom: 0px;
  }

  .automate_homepage .mob_accordian.mob_accordian_faq {
    margin-top: 40px;
  }

  .automate_pricing .top_section_bottom.pricing_page {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    margin-bottom: 0px;
  }

  .automate_homepage .mobile_view_tab .mob_accordian.mob_accordian_faq {
    margin-top: 0px;
  }

  .automate_homepage
    .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button {
    padding: 0px 0%;
  }

  .container_trial .automate_homepage .cta_autoamte {
    text-align: center;
    font-size: 15px;
  }

  .automate_homepage .grid_card_trial .row {
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  .desktop_tab_list {
    display: none;
  }

  .mobile_tab_list {
    display: block;
  }

  .solutions_form {
    float: none;
  }

  .solutions_header .w-40 {
    position: relative;
    top: 10px;
  }

  .solutions_center p {
    margin-bottom: 16px;
  }

  .mob_img_home .modal-content {
    width: 96%;
    left: 2%;
  }

  .mob_img_home .first_popup_form_modal .modal-body-header {
    padding: 0px 0px 10px 0px;
  }

  .mob_img_home .modal_submit_form .modal-header h5 {
    font-size: 18px;
  }

  .mob_img_home .beta-button input,
  select {
    padding: 7px;
  }

  .mob_img_home .main-slider-button.beta-button.popup_form_pricing input {
    width: 100%;
    margin-bottom: 15px;
  }

  .mob_img_home .first_popup_form .modal-header h5 {
    margin-bottom: 0px;
  }

  .mob_img_home .modal_submit_form .modal-header {
    right: 5px;
  }

  .assistant_change_img.feature_cc_slider img {
    width: 85%;
  }

  .header.res_header .navbarlogo_img img {
    width: 180px;
  }

  .pricing-columns.pricing-section .plan {
    flex-basis: 46%;
  }

  .table.table-bordered.table-responsive-sm tbody tr {
    border-top: 1px solid #dee2e6 !important;
  }

  .feature_inner_page.bg_feature_slider .main-slider-button.beta-button {
    padding: 0px;
  }

  .landing_page_chatbot.ai_cards_service {
    padding-bottom: 0px;
  }

  .apps_landing_page.apps_self_page {
    margin-top: 40px;
  }

  .feature_pageinner_content_img.m_b_80 .feature_bg_img:before {
    height: calc(100vh - 85vh);
  }

  .desktop_tabs img {
    display: none;
  }

  .container_trial .second_p {
    margin-bottom: 0px;
  }

  .center_image_home_cc .cc_card_1.cc_card_2 {
    margin-bottom: 0px;
  }

  .container_trial.integrations_container
    .bg_feature_slider.bg_feature_slider_wrapper {
    padding-top: 20px;
  }

  header.res_header.--green-bg.mb-0 {
    padding-top: 0px;
    margin-top: 12px;
  }

  .container-fluid.autoamte_apps .marketplace_integrations_icons {
    padding-bottom: 10px;
  }

  .container-fluid.autoamte_apps {
    margin-bottom: 0px;
  }

  .support_chatbot.autoamte_chatbot .trial_page_img.bg_trial_page {
    margin-bottom: 0px;
  }

  .center_image_home.cards_landing_page {
    margin-bottom: 0px;
  }

  .cards_features .whitepaper_cards .card__title {
    font-size: 22px;
  }

  .cc_feature_apps .card_office_cc h6 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0;
  }

  .how_it_content h3 {
    font-size: 22px;
  }

  .container_trial.integrations_container
    .how_works_wrapper
    .leftimage-wrapper
    .pl-10-tage
    .feature_page_img_right {
    order: 2;
  }

  .container_trial.integrations_container
    .how_works_wrapper
    .leftimage-wrapper
    .pl-10-tage
    .feature_page_content_left {
    order: 1;
  }

  .table_full_comapare .table-bordered td,
  .table_full_comapare .table-bordered th {
    border: none;
  }

  .mob_accordian.mob_accordian_faq .container {
    padding: 0px 15px;
  }

  .top_section.pricing_page .main-slider-left {
    padding: 0px;
  }

  .top_section.pricing_page .form_below_content span {
    margin-right: 0;
  }

  .top_section.top_section_bottom.pricing_page .main-slider-left h6 {
    font-size: 16px;
  }

  .security_wrapper._wrapper_support {
    display: none;
  }

  .mob_accordian {
    display: block;
  }

  .container_trial.integrations_container
    .logs_wrapper
    .center_feature_wrapper.center_feature_wrapper_logs {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .features_content_left.p-t-40 {
    margin-top: 0px;
  }

  .onprem_connect .onprem_cards .helpdesk_solutions.how-it-works-solutions {
    margin-bottom: 0px;
  }

  .landing_page_chatbot .form_below_content.form_span_none {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .landing_page_chatbot .form_below_content span {
    margin-right: 0px;
  }

  .form_below_content.form_span_none {
    display: none;
  }

  .it_support_chatbot .top_menu_sticky_fixed {
    width: 100%;
    float: left;
    z-index: 9;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .landing_page.trial_page_img
    .feature_page_content_left.align_top
    h3 {
    font-size: 26px;
  }

  .bg_landing_ai.workflow_white {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .landing_bg .footer_landing_row {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .landing_bg .footer_landing .text-right.sm-align-left {
    width: 100%;
    text-align: center !important;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
  }

  .footer_landing img {
    width: 140px;
    height: 100%;
    margin: 0 auto;
  }

  .apps_landing_page {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .center_image_home .main-slider-left h2 {
    margin-top: 0px;
  }

  .landing_bg .footer_form {
    margin-bottom: 40px;
  }

  .landing_bg .center_image_home {
    padding-bottom: 40px;
  }

  .landing_bg .features_img_left {
    padding-bottom: 40px;
  }

  .landing_bg .trial_page_img.bg_trial_page {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .skit_contactus.roi_page_contact .roi-form p {
    color: #000 !important;
  }

  .skit_contactus.roi_page_contact .roi_form_wrapper p {
    font-size: 16px;
    line-height: inherit;
  }

  .roi-form ul li {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #333333;
  }

  .chatbots_wrapper {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
  }

  .col-md-6.feature_page_content_left .coming_soon_wrapper {
    margin-bottom: 30px;
  }

  .skit_contactus.roi_page_contact .roi-form-right {
    margin-top: 20px;
  }

  .cards_features .cards_features_header_div p {
    min-height: auto;
  }

  .key_integrations_bg.card_landing_section.integrations_popular_bg img {
    display: flex;
    margin-top: 30px;
    margin-left: 35px;
    margin-bottom: 12px;
  }

  .top_menu_sticky .cc_feature_apps .card_office {
    width: 64px;
    height: 64px;
  }

  .bg_apps_usecaes .video_show .nav-item.main-download-broucher {
    padding: 0px;
  }

  .bg_apps_usecaes .video_show .nav-item.main-download-broucher a {
    padding: 0px;
  }

  .bg_apps_usecaes .assistant_right_img.beta-slider img {
    display: block !important;
    width: 100% !important;
    padding: 0px !important;
    margin-top: 30px;
  }

  .container_trial .bg_header_trial.trial_mb_80.bg_apps_usecaes {
    padding-bottom: 20px;
  }

  .boarding_wrapper_container .pad_left_30 {
    padding-top: 30px;
    padding-left: 15px;
  }

  .boarding_wrapper_container .pad_right_30 {
    padding-right: 15px;
    order: 2;
    padding-top: 30px;
  }

  .top_menu_sticky
    .apps_integrations.apps_integrations_home
    .cc_feature_apps_header
    h2 {
    margin: 0px;
    margin-bottom: 20px;
  }

  .top_menu_sticky
    .apps_integrations
    .cogni_fav_apps_color
    .card_apps_cc
    .card_office {
    margin-right: 2px;
    margin-left: 2px;
  }

  .top_menu_sticky .cc_feature_apps .card_apps_cc .card_office img {
    width: 54px;
    height: 54px;
  }

  .second_form .modal-content.second-modal-content {
    width: 100%;
    overflow: hidden;
    height: max-content;
  }

  #exampleModalCenter .modal-body.second-modal-content {
    height: max-content;
    overflow-y: scroll;
  }

  .first_popup_form .modal-body-header {
    overflow-y: scroll;
    height: max-content;
  }

  .modal_submit_form.first_popup_form #exampleModalCenter .modal-body {
    height: max-content;
    overflow-y: scroll;
  }

  .select_work_form .arrow_up_form {
    right: 20px;
  }

  .connection_formwrapper .add_icons {
    margin: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .connection_formwrapper {
    padding: 20px;
  }

  .select_work_form input {
    padding: 0px 20px;
  }

  .select_work_form ul li {
    padding: 0px 20px;
  }

  .connection_formwrapper button {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }

  .dropdown_apps {
    width: 100%;
    float: left;
  }

  .form_wrapper_apps {
    width: 100%;
    float: left;
    padding: 0px 15px;
    display: flex;
    flex-wrap: wrap;
  }

  .apps_center_wrapper {
    flex-wrap: wrap;
    width: 100%;
  }

  .cards_apps_wrapper {
    padding: 0px;
    width: 100%;
  }

  .accordion-wrapper.pricing_faq .link span {
    width: 90%;
  }

  .submenu_div {
    padding: 20px 8px;
  }

  .container_trial .accordian_landing .faq_landing .accordion .link {
    font-size: 18px;
  }

  .accordian_landing h2 {
    margin-bottom: 20px;
  }

  .wv-dropdown .dropdown-content a:last-child {
    margin-bottom: 0px;
  }

  .container_trial .gen_vs_true .main-slider-left {
    padding: 0px;
  }

  .container_trial .gen_vs_true.autoamted_resolution .main-slider-left p {
    margin-bottom: 20px;
  }

  .top_section.pricing_page {
    padding: 40px 0px;
  }

  .show_button_full_comaparison.open {
    margin-bottom: 40px;
  }

  .opened_button_comparison_automation{
    margin-bottom: 0px !important;
  }

  .top_section.pricing_page .main-slider-button.beta-button {
    width: 100%;
    float: left;
    padding: 0%;
  }

  .gen_vs_true.cost_reduction .wv-limited-content {
    padding-right: 0px;
  }

  .table-responsive-sm [tooltip]:not([flow])::before,
  .table-responsive-sm [tooltip]:not([flow])::after,
  [tooltip][flow^="up"]::before,
  [tooltip][flow^="up"]::after {
    left: 40px;
  }

  .table-responsive-sm [tooltip]:not([flow])::before,
  [tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #fff;
    left: 10px !important;
  }

  .plan.plan_nterprise ul {
    margin-left: 0px !important;
  }

  /* .table-responsive-sm [tooltip]:not([flow])::after, [tooltip][flow^="up"]::after {
    bottom: calc(100% + 5px);
    left: calc(100% + 70px);
} */
  .container_trial .gen_vs_true .bg_section_knowledge .main-slider-left ul {
    padding-left: 15px;
  }

  .container_trial .bg_section_knowledge.left_to_right .main-slider-left h1 {
    padding-right: 0px;
    margin-bottom: 0px;
    line-height: inherit;
    font-size: 26px;
  }

  .wv-section-mv-p.pb-200.content_para #section-3 .col {
    padding: 0px;
  }

  .container_trial.integrations_container
    .pl-10-tage
    .feature_page_img_right.mob_slider_pad_0 {
    padding: 0px;
  }

  .knowledge_header_form .main-slider-button.beta-button form {
    width: 100%;
    position: relative;
    left: 0%;
  }

  .wv-content-section .wv-heading-h4 {
    margin-bottom: 10px !important;
  }

  .wv-content-section.mar_bot_40 {
    margin-top: 25px;
  }

  .wv-banner .back_features_page {
    font-family: "Roboto-Medium";
    font-size: 16px;
    color: #333333;
    padding: 0px 15px;
    margin-bottom: 20px;
  }

  .wv-lg-heading {
    font-size: 26px !important;
    line-height: inherit;
  }

  .wv-inner-banner.wv-landing-page-1.mt-20 .wv-banner-text-medium {
    font-size: 26px;
    line-height: inherit;
  }

  .container_trial p.wv-md-heading {
    text-align: left;
    color: #333333;
    font-family: "Roboto-Regular";
    font-size: 16px;
    margin-bottom: 10px;
  }

  .container_trial .bg_section_knowledge .main-slider-left h1 {
    font-size: 26px;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    font-size: 30px;
  }

  .resources_card.knowledge_cards h2 {
    font-size: 26px;
  }

  .roi-form h2 {
    font-size: 26px;
  }

  .am_cards_wrapper h2 {
    font-size: 26px;
  }

  .am_cards_wrapper h3 {
    font-size: 26px;
  }

  .bg_p_wihout_padding h5 {
    font-size: 24px;
  }

  .explore_apps_container .explore_apps_box h4 {
    font-size: 24px;
  }

  .explore_apps_container h3 {
    font-size: 26px;
  }

  .why_autoamte_wrapper h3 {
    font-size: 26px;
  }

  .actions_wrapper.actions_wrapper h3 {
    font-size: 26px;
  }

  .how_it_section.how_it_page_content .main-slider-left h2 {
    font-size: 26px;
  }

  .tabgroup_wrapper h2 {
    font-size: 26px;
  }

  .videos_wrapper .overview_wrapper h3 {
    font-size: 26px;
  }

  .videos_wrapper .similar_videos_wrapper h3 {
    font-size: 26px;
  }

  .trial_header_left h1 {
    font-size: 26px;
  }

  .header_content {
    font-size: 26px;
    padding: 0px 30px;
  }

  .cogni_fav_apps_header h3 {
    font-size: 26px;
  }

  .solutions_integrations_card h4 {
    font-size: 26px;
  }

  .center_feature_wrapper h2 {
    font-size: 28px;
  }

  .autoamte_apps_header h1 {
    font-size: 28px;
  }

  .mob_search h2 {
    font-size: 26px;
  }

  .bg_marketpalce .cc_feature_apps_header h3 {
    text-align: center;
    font-family: "Roboto-Medium";
    font-size: 26px;
    padding: 0;
    margin-bottom: 20px;
  }

  .center_image_home .main-slider-left h3 {
    font-size: 26px;
  }

  .cogni_fav_apps_header h1 {
    font-size: 26px;
  }

  .whitepaper_cards.three_cards_wrapper .header_3_cards h2 {
    font-size: 26px;
  }

  .footer_form_left .main-slider-left h3 {
    font-size: 26px;
  }

  .awards_img .main-slider-left h3 {
    font-size: 26px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
    font-size: 26px;
  }

  .center_image_home .main-slider-left h2 {
    font-size: 26px;
  }

  .icons_wrapper .main-slider-left h2 {
    font-size: 26px;
  }

  .boarding_wrapper_container h4 {
    font-size: 24px;
  }

  .automation_steps h2 {
    font-size: 26px;
  }

  .solutions_center h1 {
    font-size: 26px;
  }

  .apps_landing_header_wrapper h3 {
    font-size: 26px;
  }

  .wv-main-content {
    padding-bottom: 0px !important;
  }

  .bg_slider_home.features_header_home {
    padding-top: 20px;
    padding-bottom: 45px;
  }

  .webinar_forms_head h1.webinar_head {
    margin-top: 0px;
  }

  .order_2-res.mob_order_none {
    margin-top: 0px;
  }

  .container_trial .how_it_section_page .main-slider-left h1 {
    margin-bottom: 20px;
  }

  .leftimage-wrapper {
    margin-bottom: 0px;
  }

  .pl-20-tage.mt-0 {
    margin-top: 0px !important;
    padding-top: 0px;
  }

  .container_trial .content_para p {
    text-align: left;
    color: #333333;
    font-family: "Roboto-Regular";
    font-size: 16px;
  }

  .assistant_change_img.feature_cc_slider {
    background: inherit;
    box-shadow: none;
    margin-bottom: 0px !important;
  }

  .assistant_change_img.feature_cc_slider #carouselExampleIndicators {
    margin-bottom: 0px;
  }

  .search_marketpalce {
    padding-left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h1 {
    font-size: 26px;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .how_it_works_header .how_it_content p {
    margin-bottom: 30px;
  }

  .cc_feature_apps_header h3 {
    margin-top: 30px;
  }

  .x_scroll_page .how_it_works_header:last-child .how_it_content p {
    margin-bottom: 0px;
  }

  .how_it_content.how_it_content h3 {
    margin-top: 0px;
  }

  .how_it_works_header {
    border-bottom: 1px solid #afafaf;
    margin-bottom: 30px;
  }

  .how_it_works_header:last-child {
    border: none;
    margin-bottom: 0px;
  }

  .main-slider.partner_slider .main-slider-left {
    padding-top: 0px;
  }

  .cogni_fav_apps {
    margin-top: 0px;
  }

  .integrations_pages ul .landing_pages_img p {
    min-height: auto;
  }

  .integrations_pages ul {
    margin-bottom: 0px;
  }

  .card_landing_trial {
    padding: 30px;
  }

  .center_image_home {
    margin-bottom: 0px;
  }

  .awards_img_coantainer {
    margin-bottom: 40px;
  }

  .bg_header_trial.trial_mb_80 {
    margin-bottom: 40px !important;
  }

  .content_right_img_left .col-md-6.feature_page_content_left {
    order: 2;
  }

  .content_left_img_right .col-md-6.feature_page_img_right {
    order: 2;
  }

  .leftcontent-wrapper .col-md-6.feature_page_img_right {
    order: 2;
  }

  .leftimage-wrapper .col-md-6.feature_page_content_left {
    order: 2;
  }

  .pl-0.mob_res_slide {
    padding-left: 15px !important;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    font-size: 34px;
    margin: 15px 0px;
  }

  .container_trial .pl-20-tage.trial_page_img .feature_page_img_right {
    padding: 0px 30px;
  }

  .container_trial.integrations_container
    .pl-20-tage
    .feature_page_content_left {
    padding: 0px 30px;
  }

  .container_trial.integrations_container
    .pl-10-tage
    .feature_page_content_left {
    padding: 0px 30px;
  }

  .container_trial.integrations_container .pl-10-tage .feature_page_img_right {
    padding: 0px 30px;
  }

  .integration_header_top h3.header_integrations {
    color: #416183;
    font-family: "Roboto-Medium";
    font-size: 22px;
  }

  .inegrations_header_right img {
    width: 84px;
    height: 84px;
  }

  .bg_feature_slider.cc_bg_feature_slider {
    padding-bottom: 100px;
  }

  .bg_feature_slider.cc_bg_feature_slider.cc_bg_feature_slider {
    padding-bottom: 0px;
  }

  .assistant_change_img.feature_cc_slider {
    width: 100%;
  }

  /* .bg_feature_slider .features_change_img_header {
    position: relative;
    width: auto;
  } */
  .feature_pageinner_content_img.p-t-50 {
    padding-top: 80px;
  }

  .feature_pageinner_content_img.m_b_80 {
    margin-bottom: 10px;
  }

  .apps_integrations_home .cc_feature_apps_header h3 {
    padding: 0px 15px;
    margin-bottom: 20px;
  }

  .cards_features .cards_features_header_div a {
    padding-bottom: 0px;
  }

  .how_it_content_before:before {
    display: none;
  }

  .trial_page_img.bg_trial_page {
    background: #f8f8f8;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  .apps_integrations_home .cogni_fav_apps.cc_feature_apps {
    padding-bottom: 0px !important;
    padding-top: 0px;
    margin-bottom: 40px;
  }

  .build_needs {
    padding: 0px;
  }

  .video_prompt_section {
    margin-bottom: 40px;
  }

  .pl-10-tage h3 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -15px;
    top: -10px;
  }

  .pl-10-tage h2 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -15px;
    top: -10px;
  }

  .pl-10-tage h1 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -15px;
    top: -10px;
  }

  .zoho_desk_bg {
    margin-bottom: 40px;
  }

  .servicenow_virtual .zoho_desk_bg {
    margin-bottom: 0px;
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .inter_slider_assist .zoho_desk_bg {
    margin-bottom: 0px;
  }

  .trial_page_img.how_it_works_pad_top {
    padding-top: 40px;
  }

  .features_content_left {
    padding-bottom: 40px;
  }

  .features_img_left {
    padding-bottom: 40px;
  }

  .featured_content {
    margin-bottom: 40px;
  }

  .trial_cards_page {
    margin-bottom: 40px;
  }

  .footer_form {
    margin-bottom: 30px;
  }

  .build_needs p {
    padding-bottom: 40px;
  }

  .helpdesk_solutions.how-it-works-solutions {
    margin-bottom: 40px;
  }

  .integrations_page_form .feature_page_img_right img {
    padding-top: 20px;
  }

  .whitepaer_border .row {
    padding: 25px;
  }

  .statistics-boggle-content
    .newsletter_content_wrapper
    .newsletter_content_left
    p:last-child {
    margin-bottom: 30px;
  }

  .statistics-boggle-content.whitepaper-boggle-content h3 {
    width: 100%;
    float: left;
  }

  .content_wrapper_bg {
    padding: 40px 25px;
  }

  .cards_div_nesletter {
    flex: 0 0 100%;
  }

  .newsletter-explorations h1.header_top_left {
    font-size: 32px;
  }

  .statistics-boggle-content .header_top_left {
    font-size: 32px;
    margin-bottom: 40px;
  }

  .header_top_red {
    font-size: 26px !important;
  }

  .paper_blog_wrapper .statistics-boggle-content h3 span {
    font-size: 24px;
  }

  .newsletter_content_left {
    flex: 0 0 100%;
  }

  .statistics-boggle-content .newsletter_content_left p {
    font-size: 18px;
    text-align: left;
  }

  .statistics-boggle-content .newsletter_content_right p {
    font-size: 18px;
    text-align: left;
  }

  .newsletter_content_right {
    flex: 0 0 100%;
  }

  .page_header_center h1 {
    font-size: 32px;
    padding: 0px 3%;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .center_image_home .main-slider-left h2 {
    padding: 0px;
    margin-top: 10px;
  }

  .home_bg_header .p-r-35 {
    padding-right: 15px;
  }

  .helpdesk_solutions .bg_card_3 .cogni_workspace_content {
    padding: 30px 20px;
  }

  .helpdesk_solutions .bg_card_1 .cogni_workspace_content {
    padding: 30px 20px;
  }

  .helpdesk_solutions .bg_card_2 .cogni_workspace_content {
    padding: 30px 20px;
  }

  .helpdesk_solutions .bg_card_4 .cogni_workspace_content {
    padding: 30px 20px;
  }

  .cards_wrapper_home {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .cogni_fav_apps.cc_feature_apps .row {
    width: auto;
    float: left;
    padding: 20px;
  }

  .apps_integrations_home .marketplace_integrations_icons {
    padding: 30px 10px 30px 10px;
  }

  .apps_links_home a:first-child {
    margin-right: 0px;
  }

  .apps_links_home a {
    font-size: 15px;
  }

  .apps_links_home {
    justify-content: space-evenly;
  }

  .apps_integrations_home .card_office_cc {
    width: 33.33%;
    margin-bottom: 0px;
  }

  .apps_integrations_home .cc_feature_apps .card_office_cc h6 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 1.7;
  }
  .app-integrations-url-navigate-automate-home {
    margin-top: 10px;
  }

  .onprem_connect .cards_wrapper_home .col-md-6:first-child {
    margin-bottom: 0px;
  }

  .featured_content .slick-next {
    right: 17px;
  }

  .servicenow_virtual .featured_content .slick-next {
    right: -30px;
  }

  .featured_content .slick-prev {
    right: 60px;
  }

  .servicenow_virtual .featured_content .slick-prev {
    right: 20px;
  }

  .featured_content .slick-prev,
  .featured_content .slick-next {
    top: -16px;
  }

  .featured_content .drag {
    display: none !important;
  }

  .footer_form {
    padding: 0px 15px;
  }

  .footer_form_container {
    border-radius: 18px;
    padding: 40px 15px !important;
  }

  .footer_form_container .form_below_content {
    padding-bottom: 12px;
    flex-wrap: wrap;
  }

  .form_below_content {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form_below_content span {
    margin: 0px;
    font-size: 14px;
    width: auto;
    padding-bottom: 0px;
    text-align: left;
  }

  .trail_apps_landing .cogni_fav_apps_header p {
    padding: 0px;
  }

  .webinar_footer_img {
    display: none;
  }

  .container_trial .main-slider-left {
    padding: 0 0 40px;
    padding-bottom: 0px;
  }

  .bg_header_trial.trial_mb_80 .assistant_right_img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    padding-bottom: 20px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    font-size: 16px;
  }

  .cognii_work_content .cogni_works h1 {
    font-size: 32px;
  }

  .container_trial .cognii_work_content .cogni_works p {
    font-size: 16px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .card_landing_trial h4 {
    font-size: 22px;
  }

  .card_landing_trial p {
    font-size: 16px;
    line-height: 24px;
  }

  .modal_popup_video .video_iframe iframe {
    width: 100%;
    height: calc(100vh - 35vh);
  }

  .video_iframe span {
    width: 26px;
    height: 26px;
    border: 1px solid #fff;
    border-radius: 50%;
    top: -20px;
    margin-left: 16px;
    cursor: pointer;
    position: absolute;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video_iframe span svg {
    width: 11px;
    height: 11px;
  }

  .trial_mb_80.bg_header_trial .main-slider-left p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .landing_page .feature_page_content_left h3 {
    font-size: 27px;
  }

  .modal_submit_form .modal-body-header {
    overflow-y: scroll;
    height: calc(100vh - 48vh);
  }

  .solutions_header .main-slider-button {
    margin-bottom: 40px;
  }

  .knowledge_header_form .main-slider-button.beta-button {
    width: 100%;
    margin: 0;
    display: inline-block;
  }

  .bg_slider_home.m_b_80 {
    padding: 30px 0px;
  }

  .integrations_img {
    margin-top: 30px;
  }

  .iframe_zoho iframe {
    position: relative;
    left: 0%;
    margin-top: 10px;
    margin-left: -34px;
    height: calc(100vh - 20vh);
    width: 100vw;
    margin-bottom: 0px;
  }

  .sign_up-form_us.demo_page {
    margin-bottom: 0px !important;
  }

  /* webinar and demo page toggle */
  .beta-charging.landing_page_charging ul li {
    width: 33.33%;
  }

  .integrations_pages .beta-charging.landing_page_charging ul li {
    width: 47%;
    margin-bottom: 20px;
  }

  .img_mob_res_right {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .img_mob_res_right img {
    width: 45%;
    height: 100%;
  }

  .sign_up-form_us.demo_page p {
    margin-bottom: 0px;
  }

  .demo_page_right.webinar_page_right {
    display: none;
  }

  .webinar_form.webinar_form_mobile {
    display: block;
    width: 100%;
    float: left;
  }

  .demo_page_right.demo_page_right_responsive {
    display: none;
  }

  .demo_page_right.demo_page_responsive {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 30px;
  }

  /* webinar and demo page toggle */
  .feature_pageinner_content_img.mob_chat_flex .feature_page_img_center img {
    width: 95%;
  }

  .add-to-slack_wrapper {
    justify-content: center;
  }

  .apps_integrations.apps_integrations_chat .cc_feature_apps_header h3 {
    text-align: center;
    padding: 0px 15px;
  }

  .apps_integrations_chat .cc_feature_apps_header p {
    padding: 0px;
    text-align: center;
    margin-bottom: 40px;
    font-size: 15px;
  }

  .mob_chat_flex {
    margin-bottom: 0px;
  }

  .bg_landing_zendesk_img {
    display: none;
  }

  .trial_mb_80.bg_landing_zendesk h6 {
    color: #fff;
    padding-top: 20px;
  }

  .apps_integrations_slack .cc_feature_apps_header h3 {
    margin-bottom: 0px;
  }

  .apps_integrations_slack .cc_feature_apps .card_office_cc {
    margin-top: 30px;
  }

  .apps_integrations_slack .cc_feature_apps .card_office_cc h6 {
    margin-bottom: 0px;
  }

  .trial_mb_80.bg_landing_zendesk {
    margin-top: 0px;
  }
  .header.sticky .mobile-logo {
    border-bottom: 1px solid #fff;
  }

  .beta-content {
    margin-top: 60px !important;
  }

  #_form_14_ ._form_element {
    margin-bottom: 10px;
  }

  .webinar_form {
    padding-left: 0px;
  }

  #_form_14_ button[type="submit"] {
    padding: 0px;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 20px;
  }

  #_form_14_ input[type="text"] {
    font-size: 14px;
  }

  .feature_page_content_left a {
    padding-top: 0px;
  }

  .mob_res_cards .mt_30 {
    margin-top: 0px;
  }

  .mob_res_cards .cognii_work_content .benefits-workplace-content img {
    margin-top: 20px;
  }

  .beta-button input[type="text"],
  select {
    width: 34%;
  }

  .beta-button button {
    width: 30%;
  }

  .video_prompt .main-slider-left h3 {
    font-size: 35px;
    padding: 0px 15px;
  }

  .home_works_content.cognii_work_content .benefits-workplace-content img {
    width: 100%;
    float: left;
  }

  .marketplace_integrations_icons {
    padding: 60px 10px 0px 10px;
  }

  .bg_slider_home .main-slider-right img {
    width: 100% !important;
    float: left;
    display: block;
    margin-top: 50px;
    height: 100%;
  }

  .mob_banner_slide {
    display: flex;
    align-items: center;
    width: 100%;
    float: left;
  }

  .bg_slider_home .main-slider-right {
    width: 100%;
    float: left;
    display: block;
  }

  .footer_chat .start-free-trail a {
    width: 100%;
    float: left;
    font-family: "Roboto-Medium";
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    background-color: #2468f6;
    padding: 0px 48px;
    border-radius: 0px;
    height: 56px;
    display: inline-flex;
    align-items: center;
  }

  .footer_chat .start-free-trail {
    margin-top: 0px;
  }

  .how_it_section .container {
    padding: 0px 15px;
  }

  .automate_homepage .x_scroll_page {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
  }

  .how_it_works_header {
    flex-basis: inherit;
    margin-right: 0px;
  }

  .how_it_content img {
    width: 100%;
    height: 100%;
  }

  .how_it_content p {
    padding-right: 0px;
    text-align: center;
  }

  .how_it_content h3 {
    text-align: center;
  }

  .how_it_works_header:last-child {
    padding-right: 20px;
  }

  .how_it_section .feature_page_content_left p {
    padding-right: 20px;
  }

  .features_change_img-center img {
    width: 100%;
  }

  .features_change_img-center video {
    width: 100%;
    float: left;
    margin-top: 40px;
  }

  .servicenow_virtual .video_section .features_change_img-center video {
    margin-top: 0px;
  }

  .landing_it_virtual .video_section .features_change_img-center video {
    margin-top: 0px;
  }

  .container_trial .main-slider-left h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .helpdesk_solutions .suprcharging_benefits .main-slider-left h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .container_trial .feature_inner_page.bg_feature_slider .main-slider-left p {
    font-size: 16px;
    width: 100%;
    float: left;
    text-align: left;
    padding-right: 0px;
    margin-bottom: 20px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px !important;
  }

  .sticky .header-logo img {
    position: relative;
    top: 4px;
  }

  .features_content_left .img_mob_res {
    display: block !important;
    height: 240px;
    width: 100%;
    margin-bottom: 0px;
  }

  /* .features_content_left.features_content_left_cc {
    margin-top: -185px !important;
  } */
  .cc_feature_apps_header h3 {
    padding: 0px;
    font-size: 27px;
    text-align: left;
  }

  .card_office_cc {
    flex-basis: 33.33%;
  }

  .buton_all_features_page_toggle {
    display: none;
  }

  .training_feature_h4 {
    font-size: 27px;
    text-align: left;
    padding-left: 15px;
    margin-bottom: 0px;
  }

  .build_needs h2 {
    font-size: 30px;
  }

  .cards_features_header_div img {
    width: 56px;
    height: 56px;
    margin-bottom: 20px;
  }

  .cards_features_header_div p {
    min-height: 0px;
  }

  .buton_all_features_page {
    display: block;
    margin-bottom: 40px;
  }

  .features_inner_page_footer {
    display: block !important;
  }

  .flex_container_center img {
    display: block !important;
  }

  .know-how-assistant-image.image_feature_hide img {
    display: none !important;
  }

  .cards_features_header_div p {
    padding-right: 0%;
  }

  .know-how-assistant-image.image_feature_hide {
    margin-top: 0px;
  }

  .p_t_bg_remove {
    margin-bottom: 30px;
  }

  .col-sm-12.cards_features_header {
    padding: 0px;
  }

  .pl-10-tage {
    display: flex;
    flex: 0 0 0;
  }

  .pl-10-tage .feature_page_img_right {
    order: 2;
  }

  .pl-10-tage .feature_page_content_left {
    order: 1;
  }

  .feature_page_content_left h6 {
    color: #354250;
  }

  .feature_page_content_left h3 {
    font-size: 27px;
    color: #1f3750;
  }

  .feature_page_content_left p {
    font-size: 16px;
    color: #4e5257;
  }

  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
  }

  .icon_arrow {
    display: none;
  }

  .demo_page_right {
    margin-top: 0px;
  }

  .calendly-inline-widget {
    height: 450px !important;
  }
  .image-box-wide-landing  .calendly-inline-widget,  .book-demo-calendly-paid-lan .calendly-inline-widget{
    width: 100%;
    height: 600px !important;
  }

  .demo_page_form h4 {
    font-size: 23px;
  }

  .demo_page_form {
    padding: 30px 0px;
    width: 100%;
    margin-top: 10px;
  }

  .conversational_facelift_header h4 {
    text-align: left;
  }

  .x_scroll_mobile {
    display: block;
  }

  .ai_without_scroll {
    display: none;
  }

  .solutions_header .w-40 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .solutions_header .w-30 {
    display: none;
  }

  .solutions_header {
    overflow: hidden;
    padding: 0px 15px;
  }

  .solution_service_card {
    padding: 30px 30px 0px 30px;
    margin-top: 60px;
  }

  .solution_service {
    padding-bottom: 20px;
  }

  .conversational_facelift_card_scroll p {
    font-weight: 800;
    min-height: 60px;
    width: 100%;
  }

  .solution_service {
    background: url("../images/solutions/hero-graphic.png");
    background-repeat: no-repeat;
    background-position: center left;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position-y: 50px;
  }

  .conversational_facelift {
    margin-top: 0px;
    padding: 40px 0px;
  }

  .Integrations_card {
    margin-bottom: 24px;
  }

  .conversational_facelift_header h4 {
    font-size: 24px;
  }

  .know-how-assistant.solutions_assistant h3 {
    font-size: 24px;
  }

  .integrations_card_desk .card_head p {
    min-height: 0px;
  }

  .features_content_left {
    margin-top: 0px;
  }

  /* .bg_feature_slider {
    padding-bottom: 40px;
  } */
  .know-how-assistant-content h3 {
    font-size: 30px;
  }

  .lightbox iframe {
    position: fixed;
    height: 70%;
    width: 70%;
    right: 0;
    top: 15%;
    left: 15%;
  }

  .trial_mb_80.bg_landing_zendesk p {
    padding-right: 0px;
  }

  .container_trial .pl-20-tage .feature_page_img_right {
    padding-right: 15px;
    padding-left: 15px;
  }

  .container_trial .pl-20-tage .feature_page_content_left {
    padding-left: 15px;
    padding-right: 15px;
  }

  .container_trial .pl-10-tage .feature_page_img_right {
    padding-right: 15px;
  }

  .container_trial .pl-10-tage .feature_page_content_left {
    padding-left: 15px;
  }

  .alpha_letters ol {
    margin: 0px;
  }

  .container_trial.integrations_container
    .pl-10-tage
    .feature_page_content_left {
    flex-basis: 100%;
  }

  .container_trial.integrations_container .pl-10-tage .feature_page_img_right {
    flex-basis: 100%;
  }

  /* .mob_order_none h3 {
        color: #fff !important;
    } */

  .it_support_chatbot.ai-self-service-chatbot
    .landing_bg
    .slider_container
    .chat_channels_trial_btn
    img {
    width: 50px;
    height: 50px;
    padding: 0px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .landing_bg
    .slider_container
    .trial_page_img.bg_trial_page {
    padding: 20px 0px;
    margin-bottom: 0px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .landing_bg
    .slider_container
    img {
    width: 100%;
    height: 100%;
    padding: 0px 15px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .container_trial.integrations_container
    .slider_container
    .pl-10-tage
    .feature_page_content_left {
    padding: 0px 15px !important;
  }

  .landing_page_chatbot .form_below_content.form_span_none {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .landing_page_chatbot .form_below_content span {
    margin-right: 0px;
  }

  .form_below_content.form_span_none {
    display: none;
  }
}

@media (max-width: 520px) {
  .footer_form_left .main-slider-left h5 {
    font-size: 16px;
    line-height: 1.5;
  }
  .automate-slider-bottom-main-container {
    flex-direction: column;
  }
  .header.res_header .navbarlogo_img img {
    width: 220px;
  }
  .home_page_new_workativ .main-slider-button.beta-button form.sign_up_form {
    width: 56%;
  }

  .features-top-flex-section button {
    padding: 10px !important;
  }

  .automate-center-image-content-first {
    flex-direction: column;
    gap: 6px;
  }
  .automate-center-image-content-second {
    flex-direction: column-reverse;
    gap: 6px;
  }
  .first-section-mobile-automate-home {
    background: transparent linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%) 0%
      0% no-repeat padding-box;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  .second-section-mobile-automate-home {
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-bottom: 20px;
  }
  .automate-slider-left-first-div,
  .automate-slider-left-scnd-div,
  .automate-slider-right-first-div,
  .automate-slider-right-scnd-div {
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .automate-slider-left-first-div img,
  .automate-slider-left-scnd-div img,
  .automate-slider-right-first-div img,
  .automate-slider-right-scnd-div img {
    width: 20%;
  }
  .automate-center-main-container {
    gap: 16px;
  }
  .automate-center-content-main-container {
    margin-bottom: 20px;
  }
  .new_automate_background .form_below_content {
    gap: 0;
  }
  .new_automate_background .onboarding_mobile_progress {
    margin: 24px auto 0px;
  }
  .new_automate_background .onboarding_mobile_section_container {
    margin-top: 0px;
  }

  .main-slider-button.beta-button form.sign_up_form {
    grid-template-columns: 100%;
  }

  .container_trial .main-slider-left .basic_plans.signup_cta_p {
    font-size: 18px !important;
    margin-top: 10px;
  }

  .form_below_content.signup_cta span {
    font-size: 15px;
    width: 50%;
    float: left;
    display: flex;
    margin: 0;
    justify-content: center;
  }

  .beta-button .sign_up_form input {
    border-radius: 32px;
  }

  .beta-button .sign_up_form button {
    border-radius: 32px;
  }

  .link_card_wrapper {
    padding: 30px 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .link_card_img {
    width: 100%;
    height: 100%;
    float: left;
    margin-right: 0;
    text-align: left;
  }

  .link_card_img img {
    width: 64px;
    height: 64px;
  }

  .link_card_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-left: 0px;
  }

  .link_card_content h4 {
    font-size: 18px;
  }

  /* market blog pages start*/
  .market_main_wrapper .nocode_wrapper h4 span {
    width: 30px;
    min-height: 68px;
    float: left;
  }

  .market_main_wrapper .nocode_wrapper h4 {
    font-size: 20px;
  }

  .market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 span img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  .market_main_wrapper .nocode_wrapper {
    padding: 20px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 20px;
    height: 20px;
    right: 20px;
    left: inherit;
    margin-right: 0px;
    top: 24px;
  }

  .market_main_wrapper .nocode_wrapper button {
    margin-top: 16px;
    height: 36px;
    font-size: 16px;
    min-width: 180px;
    padding: 0px 30px;
  }

  .container_trial .market_wrapper_page .accordian_landing {
    margin-bottom: 20px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"] {
    font-size: 18px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 50px;
  }

  .container_trial
    .market_wrapper_page
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    width: 20px;
    height: 20px;
    right: 20px;
    left: inherit;
    margin-right: 0px;
    top: 18px;
  }

  .market_main_wrapper .nocode_wrapper.helpdesk_wrapper h4 {
    line-height: 32px;
  }

  .market_main_wrapper .nocode_wrapper h4 {
    font-size: 18px;
  }

  .button_last button {
    width: 100%;
  }

  .most_popular_links .most_popular_header {
    font-size: 24px;
  }

  .grid_container_chatbot {
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .col-xs-12.market_wrapper_page {
    padding: 0px;
  }

  .table_contents_wrapper {
    margin-top: 10px;
  }

  .grid__wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .grid_container_chatbot_wrapper {
    grid-template-columns: 1fr;
    padding-right: 0px;
  }

  .sticky_right_img {
    display: none;
  }

  .table_contents_wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .grid_container_chatbot_wrapper .main-slider-left-market h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .market_wrapper_page h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .grid_container_chatbot_wrapper h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .market_wrapper_page h4 {
    margin-bottom: 10px;
  }

  .table_contents_wrapper a {
    margin-bottom: 10px;
  }

  .table_contents_wrapper {
    padding: 30px 25px;
  }

  /* market blog pages end*/
  .top_section.pricing_page._assistant_pricing {
    justify-content: flex-start;
  }

  .top_section.pricing_page._assistant_pricing .btn_close_popup {
    font-size: 32px;
    position: relative;
    left: 32px;
  }

  .top_section.pricing_page._assistant_pricing:before {
    height: calc(38% - 152px);
  }

  .top_section.pricing_page._assistant_pricing span:last-child {
    white-space: pre;
  }

  .servicenow_virtual .flex_home video {
    width: 100%;
  }

  .servicenow_virtual .video_section .features_change_img-center img {
    width: 100%;
  }

  .landing-virtual-agent .trial_header_left .basic_plans {
    padding-bottom: 40px;
  }

  .landing-virtual-agent .landing_bg.bg_header_trial.trial_mb_80 {
    padding-top: 80px;
  }

  .bg_p_wihout_padding h5 {
    font-size: 22px;
  }

  .videos_wrapper
    .feature_inner_page.bg_feature_slider
    .features_change_img-center
    iframe {
    width: 100%;
    height: 185px;
  }

  .feature_bg_img.bg_videos:before {
    height: 75% !important;
  }

  .similar_videos_wrapper .utube_rows {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    padding: 0px;
    grid-gap: 20px;
  }

  .overview_wrapper {
    margin-bottom: 40px;
  }

  .overview_right_wrpper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    place-items: baseline;
    align-items: flex-start;
    grid-gap: 40px;
    margin-top: 20px;
  }

  .videos_container .utube_rows {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    padding: 0px;
    grid-gap: 20px;
  }

  .videos_container .search_marketpalce .search_icon {
    background: inherit;
    left: 10px;
  }

  .videos_container .utube_videos {
    flex-direction: column;
    display: grid;
    padding-right: 0px;
    padding-left: 0px;
  }

  .category_lsiting_card {
    padding: 30px 0px;
  }

  .category_lsiting {
    grid-gap: 20px;
  }

  .marketplace_header_container h1 span {
    display: inline-block;
  }

  .marketplace_header_container p {
    padding: 0px 15px;
  }

  .inter_slider_assist .pl-20-tage.mt-0 {
    padding-top: 40px;
  }

  .inter_slider_assist .feature_page_content_left p.second_p {
    margin-bottom: 1rem;
  }

  .container_trial
    .inter_slider_assist
    .landing_page.trial_page_img
    .feature_page_content_left
    h2 {
    margin-top: 0px;
  }

  .inter_slider_assist .m-b-20 {
    margin-bottom: 10px;
  }

  .inter_slider_assist
    .col-md-6.feature_page_content_left
    .coming_soon_wrapper {
    left: -15px;
  }

  .container_trial .inter_slider_assist .basic_plans {
    margin-bottom: 30px;
  }

  .inter_slider_assist .chat_channels_trial_btn img {
    width: 42px;
    height: 42px;
    margin-right: 10px;
  }

  .footer_form_container .form_below_content {
    padding-bottom: 0px;
  }

  .autoamte_apps_header.autoamte_apps_header_selcted {
    padding: 0px 15px;
  }

  .form_automate {
    padding: 0px 15px;
  }

  .form_automate input {
    height: 42px;
    width: 100%;
  }

  .form_automate button {
    height: 42px;
    width: 100%;
    margin: 5px 0px;
  }

  .explore_apps_container .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }

  .explore_apps_container .col-md-6:first-child {
    margin-bottom: 40px;
    padding-left: 0px;
  }

  .explore_apps_container .col-md-6:last-child {
    padding-right: 0px;
  }

  .am_cards_wrapper.actions_wrapper .actions_wrapper_container {
    padding: 15px;
  }

  .am_cards_wrapper.actions_wrapper .am_cards_column_left:last-child {
    margin-bottom: 0px;
  }

  .am_cards_container h5 {
    margin-top: 10px;
  }

  .am_cards_container._responsive_mode h5 {
    margin-top: 0px;
  }

  .am_cards_column_left.col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .why_autoamte_wrapper .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .three_steps_wrapper {
    margin-top: 20px;
  }

  .search_apps_box {
    overflow-y: auto;
    min-height: 235px;
    max-height: 257px;
  }

  .apps_list {
    min-height: 120px;
    margin-bottom: 0px;
  }

  .automate_product
    .container_trial
    .landing_page.trial_page_img
    .feature_page_content_left
    h2,
  .automate_product
    .container_trial
    .landing_page.trial_page_img
    .feature_page_content_left
    h3 {
    margin-top: 0px;
  }

  .search_apps_box {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }

  .autoamte_apps_header_left {
    padding: 30px;
  }

  .autoamte_apps_selection {
    width: 140px;
    min-height: 140px;
  }

  .autoamte_apps_selection img {
    width: 54px;
    height: 54px;
  }

  .marketplace_autoamte_apps.automate_marketplace {
    padding: 0px;
    padding-top: 40px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_click {
    height: 42px;
    font-size: 16px;
    padding-left: 40px !important;
    margin: 0px !important;
  }

  .autoamte_apps_header_right .search_icon svg {
    width: 14px;
    height: 14px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_icon {
    top: 0px;
    height: 42px;
  }

  .autoamte_img .automate_img_logo {
    margin-bottom: 0px;
    margin-top: 5px;
  }

  .automate_homepage .page_header_center {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }

  .automate_tabslider_wrapper .landing_bg.bg_header_trial.trial_mb_80 {
    padding-bottom: 40px;
  }

  .container_trial .automate_homepage .page_header_center h1 {
    padding: 0px;
    margin-bottom: 0px;
  }

  .container_trial .automate_triggers_center .page_header_center h1 {
    padding: 0px;
  }

  .container_trial .automate_triggers_center .page_header_center p {
    padding: 0px;
  }

  .feature_page_img_right {
    margin-top: 10px;
  }

  .container_trial .automate_homepage .footer_form_left .main-slider-left p {
    padding-right: 0px;
  }

  .automate_homepage .how_it_content img {
    width: 55%;
    height: 100%;
  }

  .image_tabgroup img {
    width: 100%;
    height: 100%;
  }

  .automate_homepage .how_it_works_header .how_it_content p {
    margin-bottom: 20px;
  }

  .automate_homepage .grid_card_trial .row {
    grid-template-columns: 100%;
  }

  .container_trial.integrations_container
    .pl-10-tage
    .feature_page_content_left {
    padding: 0px 15px;
  }

  .container_trial.integrations_container
    .pl-20-tage
    .feature_page_content_left {
    padding: 0px 15px;
  }

  .slider_container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inter_slider_assist .card_landing_section.integrations_popular_bg {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .know-how-assistant.solutions_assistant p {
    margin-right: 0rem;
    text-align: center;
    padding: 0px 15px;
  }

  .solutions_integrations_card h4 {
    font-size: 22px;
  }

  .container_trial
    .inter_slider_assist
    .pl-20-tage.integrations_page_form:first-child
    .feature_page_img_right
    img {
    width: 100%;
    height: 100%;
    padding-top: 0px;
  }

  .container_trial
    .inter_slider_assist
    .pl-20-tage.integrations_page_form:first-child
    .feature_page_img_right {
    margin-top: 0px;
  }

  .container_trial .feature_page_img_right.mob_slider_pad_0 img {
    height: 70%;
    width: 70%;
    display: flex;
    left: 0;
  }

  .mob_img_home .first_popup_form_modal .modal-body-header {
    padding: 0px 0px 30px 0px;
  }

  .mob_img_home #exampleModalCenter .modal-body {
    margin-right: 0px;
  }

  .footer_menu.coming_soon_center h4 {
    margin-top: 0px;
  }

  .feature_pageinner_content_img .feature_bg_img {
    display: block;
  }

  .cards_wrapper_home .col-md-6:first-child {
    margin-bottom: 20px;
  }

  .cards_wrapper_home .col-md-6 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .pricing-columns.pricing-section .plan {
    flex-basis: 100%;
  }

  .form_below_content span {
    width: 50%;
    padding-bottom: 12px;
  }

  .bg_feature_slider.bg_feature_slider_wrapper .feature_bg_img:before {
    height: 88%;
  }

  .feature_page_content_left p.basic_plans {
    margin-top: 20px;
  }

  .container_trial .cc_para_3 {
    margin-bottom: 0px;
  }

  .container_trial .cc_para_2 {
    margin-bottom: 0px;
  }

  .center_feature_wrapper {
    margin-bottom: 20px;
  }

  .homepage_slider.automate_slider .featured_content {
    padding-top: 0px;
  }

  .cogni_fav_apps.cc_feature_apps .row {
    padding: 20px 0px 0px 0px;
  }

  .helpdesk_solutions .suprcharging_benefits {
    background: #fff;
    padding: 0px;
  }

  .cards_features .whitepaper_cards .cards__item {
    display: flex;
    padding: 15px 0px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(1) {
    padding-left: 0px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(3) {
    padding-right: 0px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(4) {
    padding-left: 0px;
  }

  .cards_features .whitepaper_cards .cards__item:nth-child(6) {
    padding-right: 0px;
  }

  .cards_features .whitepaper_cards {
    margin-bottom: 25px;
  }

  .cc_teams_wrapper.cc_slack_wrapper p {
    padding: 0px !important;
  }

  .apps_links_home {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .apps_links_home a:first-child {
    margin-bottom: 10px;
  }

  .feature_inner_page.bg_feature_slider .main-slider-left h1 {
    text-align: left;
  }

  .back_features_hide.cc_teams_wrapper
    .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button {
    padding: 0px !important;
  }

  .build_needs .center_features_header {
    padding: 0px 12px !important;
  }

  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    margin: 15px 0px;
  }

  .center_image_home_cc .cc_card_1 {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .padding-margin-bottom {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  .section-padding-10 {
    padding-bottom: 10px !important;
  }

  .center_image_home_cc .center_image_home {
    margin-bottom: 0px;
  }

  .cards_cc_card {
    flex-wrap: wrap;
  }

  .center_feature_wrapper p {
    text-align: left;
  }

  .landing_page_chatbot
    .footer_form_right
    .form_below_content.form_span_none
    span {
    margin-right: 0px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .landing_page.trial_page_img
    .feature_page_content_left.align_top
    h3 {
    font-size: 22px;
  }

  .center_image_home.cards_landing_page .main-slider-left h3 {
    padding: 0px;
  }

  .landing_bg .slider_container .chat_channels_trial_btn img {
    width: 36px;
    height: 36px;
    padding: 0px;
  }

  .landing_bg.bg_header_trial.trial_mb_80 {
    padding-top: 60px;
    padding-bottom: 0px;
  }

  .landing_bg .main-slider-right img {
    width: 100% !important;
  }
  .chatbot_integration_parent_container .landing_bg .assistant_right_img.beta-slider img {
    padding-bottom: 0px !important;
  }
  .landing_bg .assistant_right_img.beta-slider img {
    display: block !important;
    padding-bottom: 30px !important;
  }
  

  .landing_bg .form_update_plans {
    width: 100%;
    float: left;
    padding-top: 30px;
    padding-bottom: 14px;
  }

  .landing_bg .form_below_content span {
    font-family: "Roboto-Regular";
    font-size: 15px;
    color: rgb(39, 50, 55);
    margin-right: 0px;
    width: 50%;
    float: left;
    display: inline-flex;
    padding-bottom: 10px;
  }

  .landing_bg .form_below_content {
    display: inline-block;
  }

  .apps_landing_cards.col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }

  .center_image_home.cards_landing_page .main-slider-left {
    padding: 0px;
  }

  .apps_landing_header_wrapper h3 {
    padding: 0px 15px;
    font-family: "Roboto-Medium";
    color: #333333;
    margin-bottom: 30px;
    text-align: left;
  }

  .landing_content_p {
    padding: 0px 15px;
  }

  .apps_integrations.apps_integrations_home .cc_feature_apps_header h3 {
    font-size: 22px !important;
    margin-bottom: 10px;
  }

  .chatbots_wrapper_row {
    margin-right: 0px;
  }

  .trial_page_img .chatbots_wrapper_row img {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .workflow_automation_wrapper.workflow_autoamte_wrapper
    .chatbots_wrapper_row
    .chatbots_name {
    font-size: 11px !important;
    margin-top: 5px;
  }

  .workflow_automation_wrapper.trigger_page
    .chatbots_wrapper_row
    .chatbots_name {
    font-size: 11px !important;
    margin-top: 5px;
  }

  .roi-form-right .btn_close_popup {
    font-size: 35px;
    position: relative;
    left: 30px;
  }

  .skit_contactus.roi_page_contact .popup_form_pricing h5 {
    font-size: 17px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 30px;
    padding: 0;
    margin-top: 20px;
  }

  .roi-form-right .roi_form_wrapper {
    padding: 20px;
  }

  .roi-form-right p {
    width: 100%;
    float: left;
    padding: 0px;
  }

  .roi_form_results {
    justify-content: space-between;
  }

  .skit_contactus.roi_page_contact {
    padding-bottom: 0px;
  }

  .roi-form-right ._full_width {
    width: 100%;
    float: left;
    padding: 0px 0px;
    margin-bottom: 0px;
  }

  .roi_form_results h4 {
    font-size: 20px;
  }

  .roi_form_results p {
    font-size: 11px;
  }

  .roi_form_results_left h4 span {
    font-size: 11px;
  }

  .roi_form_results_right h4 span {
    font-size: 11px;
  }

  .roi-form-right .roi_form_wrapper.roi_form_wrapper_results h2 {
    margin-bottom: 0px;
  }

  .roi-form-right .roi_form_wrapper.roi_form_wrapper_results {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .homepage_slider .featured_content .slick-slide {
    padding-left: 32px;
    padding-right: 0px !important;
  }

  .whitepaper_cards.three_cards_wrapper .cards__item {
    width: 100%;
  }

  .whitepaper_cards.three_cards_wrapper .cards {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
  }

  .onprem_connect .automation_steps p {
    padding: 0px;
  }

  .onprem_connect .cards_wrapper_home .pad_0 {
    padding-left: 15px;
  }

  .onprem_connect .automation_steps h2 {
    text-align: center;
  }

  .card_landing_section.integrations_popular_bg {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .two_apps_wrapper_center .haeder_img h4 {
    font-size: 18px;
  }

  .whitepaper_cards.apps_center_wrapper .cards__item {
    width: 100%;
  }

  .whitepaper_cards.apps_center_wrapper .cards__item:first-child {
    margin-bottom: 30px;
  }

  .pad-left-15 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .boarding_wrapper_container h4 {
    font-size: 22px;
  }

  .whitepaper_cards.three_cards_wrapper .header_3_cards h2 {
    font-size: 22px;
  }

  .container_trial .integration_header_top h3.header_integrations_name {
    font-size: 26px;
  }

  .resources_card.knowledge_cards h2 {
    font-size: 22px;
    padding: 0px 15px;
    text-align: left;
  }

  .roi-form h2 {
    font-size: 22px;
  }

  .am_cards_wrapper h2 {
    font-size: 22px;
  }

  .am_cards_wrapper h3 {
    font-size: 22px;
  }

  .explore_apps_container .explore_apps_box h4 {
    font-size: 20px !important;
  }

  .explore_apps_container h3 {
    font-size: 22px;
  }

  .why_autoamte_wrapper h3 {
    font-size: 22px;
  }

  .actions_wrapper.actions_wrapper h3 {
    font-size: 22px;
  }

  .how_it_section.how_it_page_content .main-slider-left h2 {
    font-size: 22px;
  }

  .tabgroup_wrapper h2 {
    font-size: 22px;
  }

  .videos_wrapper .overview_wrapper h3 {
    font-size: 22px;
  }

  .videos_wrapper .similar_videos_wrapper h3 {
    font-size: 22px;
  }

  .trial_header_left h1 {
    font-size: 22px;
  }

  .header_content {
    font-size: 22px;
    padding: 0px 30px;
  }

  .cogni_fav_apps_header h3 {
    font-size: 22px;
  }

  .servicenow_virtual .video_section .cogni_fav_apps_header h3 {
    font-size: 24px;
  }

  .center_feature_wrapper h2 {
    font-size: 24px;
  }

  .autoamte_apps_header h1 {
    font-size: 24px;
  }

  .mob_search h2 {
    font-size: 22px;
  }

  .bg_marketpalce .cc_feature_apps_header h3 {
    text-align: center;
    font-family: "Roboto-Medium";
    font-size: 24px;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 40px;
  }

  .mob_search h2:before {
    width: 108%;
    left: -4%;
    position: absolute;
  }

  .center_image_home .main-slider-left h3 {
    font-size: 22px;
  }

  .cogni_fav_apps_header h1 {
    font-size: 22px;
  }

  .footer_form_left .main-slider-left h3 {
    font-size: 22px;
  }

  .awards_img .main-slider-left h3 {
    font-size: 22px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h2 {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .center_image_home .main-slider-left h2 {
    font-size: 22px;
    width: 100%;
    float: left;
    line-height: inherit;
  }

  .icons_wrapper .main-slider-left h2 {
    font-size: 22px;
  }

  .solutions_center h1 {
    font-size: 22px;
  }

  .automation_steps h2 {
    font-size: 22px;
    padding: 0px;
    text-align: center;
  }

  .apps_landing_header_wrapper h3 {
    font-size: 22px;
  }

  .container_trial.integrations_container
    .slack_teams_wrapper
    .pl-20-tage
    .feature_page_content_left {
    padding: 0px 15px;
  }

  .container_trial
    .slack_teams_wrapper
    .pl-20-tage.trial_page_img
    .feature_page_img_right {
    padding: 0px 15px;
  }

  .modal_submit_form.first_popup_form #exampleModalCenter .modal-body {
    height: max-content;
    overflow-y: scroll;
  }

  .awards_img_coantainer {
    display: flex;
    width: 100%;
    float: left;
    margin-bottom: 20px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    padding: 0px;
    padding-left: 10px;
  }

  .awards_img_coantainer a:first-child {
    margin-bottom: 0px !important;
    padding-right: 20px;
    padding-left: 0px;
  }

  .awards_img_coantainer a:last-child {
    margin-bottom: 0px !important;
    padding-right: 20px;
    padding-left: 0px;
  }

  .awards_img_coantainer a {
    width: 100%;
    max-width: 90%;
    flex: 0 0 90%;
    margin-right: 0px;
    padding: 0px 10px;
  }

  .awards_img_coantainer img {
    margin-bottom: 20px !important;
  }

  .modal_ui .apps_name {
    width: 78%;
  }

  .cards_apps_wrapper .container {
    padding: 0px;
  }

  .icons_wrapper {
    padding: 0px;
  }

  .select_work_form .arrow_up_form {
    right: 15px;
  }

  .dynamic_cards .helpdesk_solutions.how-it-works-solutions p {
    margin-bottom: 0px;
  }

  .select_work_form:first-child .arrow_up_form {
    top: 0px;
  }

  .select_work_form .automation_scroly .arrow_up_form {
    top: 0px;
  }

  .whitepaper_cards.newpages_cards .cards__item {
    width: 100%;
    padding: 0px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .connection_formwrapper h2 {
    font-size: 22px;
  }

  .form_wrapper_apps {
    padding: 0px;
  }

  .connection_formwrapper {
    padding: 40px 20px;
  }

  .cards_apps_wrapper:first-child {
    margin-bottom: 20px;
  }

  .wv-limited-content {
    padding-right: 0px;
  }

  .container_trial .pricing-columns .enterprise_message {
    padding-top: 0;
  }

  .full_comaparision h1 {
    font-size: 22px;
    margin-top: 0px;
  }

  .top_section .main-slider-left h1 {
    text-align: center !important;
  }

  .top_section p {
    padding: 0px 15px;
  }

  .container_trial .gen_vs_true .bg_section_knowledge .main-slider-left h1 {
    font-size: 22px;
    margin-bottom: 0px;
    padding: 20px 0px;
  }

  .wv-main-content .col-md-9.col-sm-12.col-xs-12 {
    padding: 0px;
  }

  .content_para.content_ul .col {
    padding: 0px;
  }

  .cards_without_bg .wv-content-section .wv-heading-h4 {
    font-size: 18px;
  }

  .container_trial .bg_section_knowledge.left_to_right .main-slider-left h1 {
    padding-right: 0px;
    margin-bottom: 0px;
    line-height: inherit;
    font-size: 22px;
  }

  .wv-section-mv-p {
    padding: 0px;
  }

  #section-1 .m-b_30 {
    margin-bottom: 40px;
  }

  .--sh-sm.mt-3.mb-4.wv-banner-text-medium {
    margin: 0px !important;
    width: 100%;
    font-size: 22px;
    line-height: inherit;
    margin-bottom: 20px !important;
  }

  .wv-inner-banner.wv-landing-page-1.mt-20 .wv-banner-text-medium {
    font-size: 22px;
  }

  .wv-lg-heading {
    font-size: 22px !important;
  }

  .container_trial .wv-banner .container {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .wv-landing-page-1 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .container_trial .bg_section_knowledge .main-slider-left h1 {
    font-size: 22px;
    padding: 40px 0px;
  }

  .wv-landing-page-1 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .knowledge_cards .whitepaper_cards .cards__item {
    width: 100%;
    margin-bottom: 20px;
  }

  .knowledge_cards .whitepaper_cards .cards__item:last-child {
    width: 100%;
    margin-bottom: 0px;
  }

  .webinar_img_container .para_center_videos {
    font-size: 22px;
    padding: 0px 15px;
  }

  .cc_feature_apps_header h3 {
    margin-top: 22px;
  }

  .resources_card .card_landing_section.integrations_popular_bg {
    margin-bottom: 30px;
    margin-top: 0px;
  }

  .resources_card .card_landing_section.integrations_popular_bg:last-child {
    margin-bottom: 0px;
  }

  .helpdesk_solutions.how-it-works-solutions .main-slider-left h1 {
    font-size: 22px;
    margin-bottom: 30px;
    margin-top: 0px;
  }

  .feature_page_content_left .jira_landing_link.how_ts_link {
    justify-content: space-between;
    width: 100%;
    float: left;
  }

  .card_link_landing.jira_landing_link.how_ts_link a {
    width: auto;
    float: left;
  }

  .assistant_change_img.feature_cc_slider img {
    width: 100%;
    float: left;
    height: auto;
    padding: 0px;
    margin: 0px;
    margin-bottom: 40px;
    margin-top: 0px;
  }

  .cc_bg_feature_slider.trial_page_img.mb-0 {
    display: none;
  }

  .cards_features_header_div h5 {
    padding-bottom: 15px;
    font-size: 20px;
  }

  .cards_features_header_div p {
    padding-bottom: 15px;
    font-size: 16px !important;
  }

  .cards_features_header_div img {
    margin-bottom: 20px;
  }

  .icon-top-img {
    margin-bottom: 0px;
  }

  .Integrations_card .search_marketpalce .search_icon {
    position: absolute;
    left: 12px;
    top: 10px;
  }

  .Integrations_card .search_marketpalce .search_click {
    width: 95%;
    padding-left: 50px !important;
  }

  .awards_img_coantainer img {
    width: 48%;
    float: left;
    margin-bottom: 20px;
    height: 100%;
  }

  .card_link_landing.how_ts_link a {
    margin-right: 0px !important;
  }

  .build_needs h2 {
    font-size: 22px;
  }

  .feature_inner_page.bg_feature_slider .back_features {
    font-size: 16px;
  }

  .mob_res_slider {
    display: flex;
  }

  .desktop_slider_cc {
    display: block;
  }

  .feature_page_content_left.mob_order_none {
    /* display: none !important; */
  }

  .mob_res_slide .carousel-indicators {
    top: calc(100vh - 66.5vh);
    position: absolute;
  }

  .pl-10-tage h3 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -20px;
    top: -18px;
  }

  .pl-10-tage h2 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -20px;
    top: -18px;
  }

  .pl-10-tage h1 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -20px;
    top: -18px;
  }

  .integrations_page_form h3 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -20px;
    top: -18px;
  }

  .integrations_page_form h2 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -20px;
    top: -18px;
  }

  .integrations_page_form h1 .icon-header-left {
    width: 60px;
    height: 60px;
    position: absolute;
    filter: none;
    left: -20px;
    top: -18px;
  }

  .integrations_container .featured_content .slick-prev,
  .integrations_container .featured_content .slick-next {
    display: none !important;
  }

  .integrations_container .featured_content .slick-slide {
    padding-right: 20px;
  }

  .trial_cards_page h4 {
    font-size: 22px;
    font-family: "Gordita-Medium";
  }

  .webinar_forms_head h1.webinar_head {
    font-size: 22px !important;
  }

  .container_trial .cognii_work_content .cogni_works h1 {
    font-size: 22px !important;
  }

  .page_header_center h1 {
    font-size: 22px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .container_trial
    .bg_header_trial.trial_mb_80.marketplace-assist-header-bottom {
    margin-bottom: 0px !important;
  }
  .new_home_page_main_container.bg_header_trial.trial_mb_80 {
    margin-bottom: 30px !important;
  }
  .container_trial .bg_header_trial.trial_mb_80 {
    margin-bottom: 40px;
  }
  

  .container_trial .cognii_work_content .cogni_works p {
    font-size: 15px;
    margin-left: 0%;
    margin-right: 0%;
  }

  .container_trial .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: inherit;
  }

  .container_trial .landing_page.trial_page_img .feature_page_content_left h2 {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: inherit;
  }

  .container_trial .landing_page.trial_page_img .feature_page_content_left h1 {
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .container_trial
    .servicenow_virtual
    .landing_page.trial_page_img
    .feature_page_content_left
    h1 {
    font-size: 24px;
  }

  .chat_channels_trial_btn {
    width: auto;
    float: left;
    font-size: 16px;
    font-family: "Roboto-Medium";
  }

  .container_trial .trail_apps_landing h1 {
    font-size: 22px !important;
  }

  .container_trial .trial_accordian_landing h2 {
    font-size: 22px !important;
  }

  .container_trial p {
    color: #416183;
    font-size: 16px;
  }

  .text-right.sm-align-left p {
    text-align: center !important;
    margin-top: 10px;
    line-height: 25px;
  }

  .chat_channels_trial_btn img {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }

  .card_landing_section {
    max-width: 100%;
    flex: 0 0 100%;
    float: left;
    border-radius: 12px;
    margin-bottom: 40px;
  }

  .card_landing_section:nth-child(3) {
    margin-bottom: 0px;
  }

  .arrow_up_form {
    position: relative;
    right: 0;
    margin-top: -35px;
  }

  .automation_scroly {
    width: 100%;
    position: relative;
    margin-top: 0px;
  }

  .utube_rows {
    display: grid;
    align-content: center;
    grid-gap: 10px;
    grid-template-columns: 100%;
    justify-content: space-between;
  }

  .our_locations .location_places {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .center_contactus {
    flex-direction: column;
  }

  .center_contactus .our_location_places {
    margin-bottom: 40px;
  }

  .contact-right-img img {
    margin-top: 40px;
  }

  .lightbox iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0%;
    left: 0%;
  }

  .homepage_slider.slider_homepage .featured_content {
    padding: 0px;
  }

  /* .features_content_left.features_content_left_cc {
    margin-top: -140px !important;
  } */
  .features_content_left .img_mob_res {
    display: block !important;
    height: 210px;
    width: 100%;
    float: left;
    margin-bottom: 55px;
  }

  .cc_feature_apps h5 {
    width: 100%;
    float: left;
    border: 2px solid #4095e9;
    height: 70px;
    display: flex;
    align-items: center;
    margin: 0px 15px;
    cursor: pointer;
  }

  .cc_feature_apps h5 a {
    margin-top: 0px;
  }

  /* .feature_pageinner_content_left ul li {
    width: 50%;
    float: left;
    padding: 10px 0px;
  } */
  .feature_page_img_right img {
    width: 95%;
    height: 100%;
  }

  .new_design_landing_footer .start-free-trail a {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  /* .bg_feature_slider {
    padding-bottom: 50px;
    margin-bottom: 0px;
  } */
  .features_content_left {
    margin-top: 0px !important;
  }

  .landing_expand_hide {
    margin: 0px 15px;
  }

  .scroll-prompt .scroll-prompt-arrow-container {
    margin-left: -10px;
  }

  .lightbox .lightbox-close {
    top: 7%;
    right: 5%;
    background: #2468f6;
    color: #fff;
  }

  .accordian_landing .accordion .link,
  .accordian_landing .accordion li i {
    font-size: 15px;
  }

  .accordian_landing .accordion li i.fa-chevron-down {
    right: 8px;
  }

  .data_landing_success .col-lg-4 {
    margin-bottom: 0px;
  }

  .accordian_landing .accordion .link {
    padding: 15px 20px 15px 0 !important;
  }

  .beta_landing_head {
    padding: 50px 0;
  }

  .conversational_facelift_card_scroll {
    max-width: 60%;
    flex: 0 0 60%;
    margin-right: 10px;
    padding-left: 15px;
  }

  .new_design_landing_footer .start-free-trail a {
    width: 100%;
    justify-content: center;
  }

  .new_design_landing_footer h3 {
    text-align: center;
  }

  .new_design_landing_footer p {
    text-align: left;
    padding: 0px !important;
    font-size: 14px;
  }

  .know-how-assistant-image.mobile_change_footer_img {
    display: block;
    margin-bottom: 30px;
  }

  .know-how-assistant-image.mobile_change_footer_img img {
    width: 65%;
    margin-top: 0px;
  }

  .know-how-assistant-image {
    display: none;
  }

  .beta-button input[type="text"],
  select {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .beta-button button {
    width: 100%;
    margin-right: 0px;
    margin-top: 10px;
  }

  .main-slider-button.beta-button form {
    flex-direction: column;
  }

  #exampleModalCenter .modal-dialog {
    max-width: 100%;
    float: left;
  }

  #exampleModalCenter1 .modal-dialog {
    max-width: 100%;
    float: left;
  }

  .beta-charging ul li {
    padding: 0px;
    width: 50%;
    float: left;
  }

  .video_prompt .main-slider-left h3 {
    font-size: 22px;
    text-align: center !important;
    margin-top: 0px;
    padding-top: 0px;
  }

  .watch_video {
    font-size: 24px;
  }

  .video_prompt_section {
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .video_prompt {
    margin-bottom: 0px;
    height: 350px;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding: 20px;
  }

  .video_prompt .comapny_name {
    position: relative;
    bottom: 0px;
    right: 0px;
    font-size: 22px;
    font-family: "Roboto-Medium";
    color: #416183;
  }

  .video_prompt .main-slider-left h3 {
    position: relative;
    top: 0px;
  }

  .modal-dialog {
    margin: 0px !important;
  }

  #exampleModalCenter .modal-body {
    width: 100%;
    float: left;
    padding: 0px;
    padding-right: 15px;
  }

  .top_section.pricing_page._assistant_pricing #exampleModalCenter .modal-body {
    padding: 0px;
  }

  #exampleModalCenter1 .modal-body {
    width: 100%;
    float: left;
    padding: 0px;
    padding-right: 15px;
  }

  .modal_submit_form .modal-dialog-centered {
    min-height: calc(100% - (2px * 2));
  }

  .pad_right_20 {
    padding-right: 0px;
  }

  .cognii_work_content .cogni_works p {
    font-size: 16px;
    padding: 0px;
  }

  .cogni_fav_apps_header p {
    font-size: 16px;
  }

  .modal_submit_form .modal-header h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  #exampleModalCenter .modal-body {
    height: calc(100vh - 30vh);
    overflow-y: scroll;
  }

  #exampleModalCenter1 .modal-body {
    height: calc(100vh - 30vh);
  }

  .trial_mb_80 .main-slider-left h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .img_mob_res_right img {
    width: 32%;
    height: 100%;
  }

  .trial_mb_80.bg_landing_zendesk h6 {
    color: #fff;
    padding-top: 0px;
    line-height: 27px;
  }

  .trial_mb_80.bg_landing_zendesk p {
    padding-right: 0px;
    margin-bottom: 20px;
    line-height: 27px;
    font-size: 15px;
  }

  .bg_landing_zendesk .main-slider-button.beta-button {
    width: 100%;
    float: left;
    margin-bottom: 50px;
  }

  .main-slider-button.beta-button input[type="text"],
  select {
    width: 100% !important;
    margin: 4px 0px 8px 0px !important;
    padding: 7px 15px !important;
  }

  .beta-button button {
    width: 100%;
    margin-right: 0px;
    margin-top: 4px;
    padding: 6px 0px;
  }

  .cognii_work_content .cogni_works h1 {
    font-size: 30px;
  }

  .accordian_landing .submenu li {
    font-size: 14px;
  }

  .demo_page.webinar_forms_head {
    padding-top: 10px !important;
  }

  .sign_up-form_us.demo_page h1 {
    font-size: 22px;
  }

  .beta-charging.landing_page_charging ul li {
    width: 50%;
  }

  .integrations_pages .beta-charging.landing_page_charging ul li {
    width: 100%;
  }

  .feature_page_content_left .inter_marlet_link a {
    width: 100%;
    float: left;
  }

  .inter_marlet_link {
    width: 90%;
    float: left;
  }

  .modal_submit_form .modal-body-header {
    overflow-y: scroll;
    height: calc(100vh - 30vh);
  }

  .trial_mb_80.bg_header_trial .main-slider-left p {
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  .trial_mb_80.bg_header_trial .main-slider-left .basic_plans {
    margin: 20px 0px;
  }

  .cognii_work_content .cogni_works p {
    font-size: 16px;
    padding: 0px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    font-size: 16px;
  }

  .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 28px;
  }

  .landing_page.trial_page_img .feature_page_content_left h2 {
    font-size: 28px;
  }

  .landing_page.trial_page_img .feature_page_content_left h1 {
    font-size: 28px;
  }

  .card_landing_trial h4 {
    font-size: 24px;
  }

  .card_landing_trial p {
    font-size: 16px;
    line-height: 27px;
  }

  .container_trial .trial_accordian_landing h2 {
    font-size: 28px;
  }

  .video_iframe span {
    right: 4%;
    margin-top: -10px;
  }

  .modal_popup_video .video_iframe iframe {
    width: 100%;
    height: calc(100vh - 70vh);
  }

  .modal_popup_video .video_iframe {
    width: 100%;
  }

  .container_trial .beta-charging p {
    font-size: 15px;
  }

  .container_trial .main-slider-left {
    padding: 0 0 40px;
    padding-bottom: 0px;
  }

  .bg_header_trial.trial_mb_80 .assistant_right_img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }

  .bg_header_whitepaper.sign_up-form_us.demo_page {
    padding-top: 40px !important;
    padding-bottom: 50px;
  }

  .bg_header_whitepaper h1 {
    font-size: 34px;
  }

  #_form_24_ {
    padding: 15px !important;
  }

  .wrapper_be_paper {
    padding: 15px !important;
  }

  .whitepaer_wrapper_form {
    padding: 0px !important;
    margin-top: 30px;
  }

  .footer_form_right.col-md-12 {
    padding: 0px;
  }

  .footer_form_left.col-md-12 {
    padding: 0px;
  }

  .footer_form_left.col-md-12 h1 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .slick-prev {
    right: 25px !important;
  }

  .slick-next {
    right: 0px !important;
  }

  .slick-prev,
  .slick-next {
    top: -25px !important;
  }

  .featured_content .drag {
    right: 20px !important;
    width: 45px !important;
    height: 45px !important;
    font-size: 12px !important;
    border: none !important;
    display: none !important;
  }

  .featured_content h3 {
    font-size: 22px !important;
    font-family: "Roboto-Bold";
    color: #333333;
    margin-bottom: 10px;
    text-align: left;
  }

  .category_wrapper h3 {
    font-size: 22px;
  }

  .featured_content h4 {
    font-size: 22px !important;
    font-family: "Roboto-Bold";
    color: #333333;
    margin-bottom: 10px;
    text-align: left;
  }

  .top_menu_sticky
    .apps_integrations.apps_integrations_home
    .cc_feature_apps_header
    h2 {
    font-size: 22px;
  }

  .card_link_landing.jira_landing_link {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
  }

  .card_link_landing.jira_landing_link a {
    margin-bottom: 15px;
    width: 100%;
    float: left;
    justify-content: left;
    display: flex;
  }

  .feature_inner_page.bg_feature_slider .main-slider-left {
    padding: 0px;
  }

  .feature_inner_page.bg_feature_slider .main-slider-button.beta-button {
    padding: 0px;
  }

  .feature_inner_page.bg_feature_slider .features_change_img-center img {
    width: 100%;
  }

  .container_trial.integrations_container .trial_page_img.m_b_80 {
    margin-bottom: 0px;
  }

  .footer_form .row {
    padding: 0px;
  }

  .integrations_container .top_menu_sticky .featured_content .slick-slide {
    padding-left: 25px;
    padding-right: 0px;
  }

  .landing_page_chatbot .form_below_content.form_span_none {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .landing_page_chatbot .form_below_content span {
    margin-right: 0px;
  }

  .form_below_content.form_span_none {
    display: none;
  }
}

@media (max-width: 480px) and (min-width: 320px) {
  .space-explorations-content h1 {
    line-height: 1.5;
    font-size: 32px;
    padding: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 375px) {
  .autoamte_apps_selection {
    width: 130px;
    min-height: 130px;
  }

  .accordian_landing .accordion .link,
  .accordian_landing .accordion li i {
    font-size: 15px;
  }

  .accordian_landing .accordion li i.fa-chevron-down {
    right: 0px;
  }
}

@media only screen and (min-width: 372px) and (max-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .mob_res_slide .carousel-indicators {
    top: calc(100vh - 66.5vh);
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

/*header new changes */

@media only screen and (max-width: 1024px) {
  .servicenow_virtual .landing_content_p {
    padding: 0px 12%;
    font-weight: 200;
  }

  .iocn_virtual img {
    width: 64px;
    height: 64px;
  }

  .top_header {
    min-height: 60px;
  }

  .sticky {
    height: 60px;
  }

  .servicenow_virtual .sticky {
    height: 80px;
  }

  .landing_it_virtual .sticky {
    height: 80px;
  }

  .top_header_open {
    padding-top: 0px;
  }
  .mob_search h2:before {
    display: none;
  }

  .top_header.header.res_header.home_header {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .header.res_header {
    padding-top: 0px;
  }

  .top_header_open._mob_header .linkwrapper {
    border-bottom: 1px solid #d9d9d9;
  }

  .top_header_open._mob_header .linkwrapper a {
    font-size: 16px;
    color: #333;
    font-family: "Roboto-Medium";
  }

  .svg_roate_header {
    width: 100%;
    height: 60px;
    background: #f8f8f8;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    font-size: 16px;
    color: #333;
    font-family: "Roboto-Medium";
  }

  .header_menu {
    width: 100%;
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #d9d9d9;
    height: 76px;
  }

  .header_menu.first_child {
    width: 100%;
    height: 60px;
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 40px;
    font-size: 14px;
    font-family: "Roboto-Bold";
  }

  .header_menu.first_child span {
    font-size: 14px;
    color: #333;
    font-family: "Roboto-Bold";
  }

  .sticky_pad_0:first-child .home_menu_top:first-child .header_menu:before {
    display: none;
  }

  .mob_link_menu {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .container_trial .header_menu .mob_link_menu p {
    padding-left: 0px;
    font-size: 12px;
    padding-bottom: 0px;
    color: #333;
  }

  .container_trial .header_menu .mob_link_menu span {
    padding-bottom: 3px;
    font-family: "Gordita-Bold" !important;
  }

  .header_menu img {
    width: 36px;
    height: 36px;
    position: relative;
    top: 0px;
    margin-right: 10px;
  }

  .top_header_open .mob_header_wrapper .navbarlogo_img {
    display: block;
  }

  .top_header_open .mobile_navbartop {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: column-reverse;
  }

  .mobile_navbartop_wrapper {
    width: 100%;
    float: left;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    padding-bottom: 30px;
  }

  .mob_header_wrapper {
    width: 100%;
    float: left;
  }

  .mob_header_wrapper .sticky_pad.nav-bar-left {
    width: 100%;
    float: left;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #e5eaff;
    padding: 0px 20px;
  }

  .mob_header_wrapper .navbar_right {
    margin-top: 0px;
    position: relative;
    top: -1px;
  }

  .mob_header_wrapper .navbar_right ul li.sticky_pad_0 .svg_roate_header {
    background: #e5eaff;
    border-bottom: 1px solid #fff;
  }

  .mob_header_wrapper
    .navbar_right
    ul
    li.sticky_pad_0
    .submenu_ul
    .svg_roate_header {
    background: #f5f5f5;
    border-bottom: 1px solid #d9d9d9;
    padding-left: 40px;
    font-size: 15px;
    font-family: "Roboto-Bold";
  }

  .mob_header_wrapper .navbar_right a.url_manipulation {
    padding: 20px 20px;
    width: 100%;
    float: left;
  }

  .mob_header_wrapper .navbar_right a.url_manipulation.navbar_button {
    padding: 0px 20px;
    width: 100%;
    float: left;
  }
  .mob_header_wrapper .navbar_right a.url_manipulation.navbar_button.free-bot-mobile-header {
    padding: 0px 20px 20px;
  }

  .mob_header_wrapper .sticky_pad_0 .no_children_li {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 40px;
    font-size: 15px;
    font-family: "Roboto-Bold";
  }

  .mob_header_wrapper .navbar_right a.url_manipulation button {
    background: #fff;
    height: 48px;
    font-size: 16px;
    color: #466af3;
    font-family: "Roboto-Medium";
  }

  .mob_header_wrapper .navbar_button {
    padding-top: 0px;
  }

  .mob_header_wrapper .navbar_button button {
    margin-left: 0px !important;
  }

  .mob_header_wrapper .navbar_right ul li.sticky_pad_0 span {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mob_header_wrapper .navbar_right ul li.ul_li_wrapper {
    width: 100%;
    height: 60px;
    background: #e5eaff;
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    font-size: 16px;
    color: #333;
    font-family: "Roboto-Medium";
  }

  .mobile_navbartop .navbarlogo_main {
    display: none;
  }

  .mob_header_wrapper .dropdown_li a {
    width: 92%;
    height: 48px;
    line-height: 48px;
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    font-size: 14px;
    color: #333;
    font-family: "Roboto-Medium";
    margin: 0 auto;
  }

  .mob_header_wrapper .dropdown_li button {
    width: 92%;
    height: 48px;
    background: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    font-size: 14px;
    color: #333;
    font-family: "Roboto-Medium";
    margin: 0 auto;
  }

  .top_header.header.res_header.sticky a {
    z-index: 11111111;
  }

  .top_header.header.res_header.sticky button {
    z-index: 11111111;
  }

  .mob_header_wrapper
    .navbar_right
    ul
    li.sticky_pad_0
    .submenu_ul
    .svg_roate_header
    svg {
    width: 14px;
    height: 14px;
    fill: #000 !important;
  }

  .mob_header_wrapper .dropdown_li a:hover {
    color: #466af3;
    background: transparent;
  }

  .mob_header_wrapper .dropdown_li button:hover {
    color: #466af3;
    background: #fafafa;
  }

  .mob_header_wrapper .dropdown_li button:focus {
    outline: 0;
  }

  .automate_product .mob_header_wrapper .sticky_pad.nav-bar-left {
    background: #ceffec;
    border-bottom: 1px solid #99e2c6;
  }

  .automate_product
    .mob_header_wrapper
    .navbar_right
    ul
    li.sticky_pad_0
    .svg_roate_header {
    background: #ceffec;
    border-bottom: 1px solid #99e2c6;
  }

  .automate_product
    .mob_header_wrapper
    .navbar_right
    ul
    li.sticky_pad_0
    .submenu_ul
    .svg_roate_header {
    background: #fff;
    border-bottom: 1px solid #d9d9d9;
  }

  .automate_product .mob_header_wrapper .navbar_right ul li.ul_li_wrapper {
    background: #ceffec;
    border-bottom: 1px solid #99e2c6;
  }

  .automate_product
    .mob_header_wrapper
    .navbar_right
    a.url_manipulation
    button {
    background: #ceffec;
    color: #222222;
  }

  .automate_product .navbar_right .sticky_pad_0:hover svg {
    color: #000 !important;
    fill: #000 !important;
  }

  .automate_product .navbar_right ul {
    top: 1px !important;
  }

  .mob_header_wrapper .dropdown_li a:last-child {
    border: none;
  }

  .svg_roate_icon_inside.svg_roate_header ~ .dropdown_li {
    border-top: 1px solid #d9d9d9;
  }

  .mobile_navbartop .sticky_pad.nav-bar-left .navbarlogo_img {
    height: 60px;
    line-height: 60px;
    padding-left: 20px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
  }

  .svg_roate_icon_inside.svg_roate_header ~ .SubmenuWrapper {
    border-bottom: 1px solid #d9d9d9;
  }

  .header_menu span svg {
    width: 40px !important;
    height: 40px !important;
    display: flex;
    transform: rotate(0deg) !important;
    margin-left: 0px;
    margin-right: 12px;
  }

  .header.res_header.home_bg_header {
    padding-top: 0px;
  }

  .mobile_navbartop_wrapper
    .mobile_navbartop
    .sticky_pad.nav-bar-left
    .navbarlogo_img {
    padding-left: 0px;
  }

  .mob_header_wrapper .navbarlogo_img img {
    width: 220px;
  }

  .mob_header_wrapper .dropdown_li a {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .mob_header_wrapper .dropdown_li button {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .display-none-mobile{
    display: none !important ;
  }
}

@media only screen and (max-width: 767px) {
  .mob_header_wrapper .dropdown_li a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .SubmenuWrapper.Chatbot_Integrations {
    max-height: 300px;
    overflow-y: scroll;
  }

  .mob_header_wrapper .dropdown_li button {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  

  /* Footer new changes */
  .aws_logo_mob img {
    top: 0px !important;
  }

  .footer_home .footer_aws {
    position: relative;
    bottom: 0;
    padding-bottom: 0px;
    align-items: center;
  }

  .footer_home .footer_aws img {
    width: 185px;
    height: auto;
    position: relative;
    bottom: 0;
    padding: 40px 0px;
    top: 0px;
  }

  .footer_right_mobile ul li a:hover {
    color: #466af3;
    background: #fafafa;
    text-decoration: none;
  }

  .footer_right_mobile.automate_footer ul li a:hover {
    color: #09a86c;
    background: #fafafa;
    text-decoration: none;
  }

  .footerlink_left.footerlink_left_mobile:first-child {
    order: 2;
  }

  .aws_logo_mob .WrapperBottom {
    align-items: center !important;
  }
}

@media only screen and (max-width: 520px) {
  .landing_it_virtual .video_section .cogni_fav_apps_header h3 {
    font-size: 24px;
    color: #000000;
  }
  .mobile_navbartop .sticky_pad.nav-bar-left .navbarlogo_img {
    padding-left: 15px;
  }
  .video_section .flex_home {
    padding: 0px;
  }

  .header_menu span svg {
    position: relative;
    top: -5px;
  }

  .servicenow_virtual .landing_content_p {
    padding: 0px 15px;
    font-weight: 200;
  }

  .trial_mb_80 .main-slider-left h1 {
    font-size: 26px;
    margin-bottom: 10px;
    font-family: "Roboto-Medium";
    color: #000;
    line-height: 32px;
  }

  .trial_mb_80.bg_header_trial .main-slider-left p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #193a5d;
  }

  .main-slider-button.beta-button input[type="text"],
  select {
    width: 100% !important;
    margin: 0px 0px 18px 0px !important;
    padding: 0px 15px !important;
    height: 52px;
    font-size: 16px;
    border: 1px solid #b7b7b7;
  }

  .main-slider-button.beta-button input[type="text"]::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #333;
    opacity: 1;
    /* Firefox */
  }

  .main-slider-button.beta-button input[type="text"]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #333;
  }

  .beta-button button {
    height: 52px;
    font-family: "Roboto-Medium";
    font-size: 16px;
    background: #2468f6;
    border: 1px solid #2468f6;
  }

  .form_below_content span {
    font-family: "Roboto-Medium";
    color: #333;
  }

  .trial_mb_80.bg_header_trial .main-slider-left .basic_plans {
    font-family: "Roboto-Medium";
    font-size: 14px;
    color: #000;
    margin-bottom: 0px;
  }

  .center_image_home .main-slider-left h2 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
    margin-top: 0px;
  }

  .center_image_home .main-slider-left a {
    color: #2468f6;
    font-size: 18 px;
    font-family: "Roboto-Medium";
  }

  .center_image_home .main-slider-left .arrow_svg_link svg {
    width: 16px;
    height: 18px;
    margin-left: 3px;
  }

  .mob_image_scroll {
    width: 100%;
    float: left;
    overflow-x: scroll;
  }

  .container_trial .main-slider-left {
    width: 100%;
  }

  .center_image_home .main-slider-left .mob_image_scroll img {
    width: 680px;
    height: auto;
    margin-bottom: 15px;
  }

  .center_image_home .main-slider-left .mob_image_scroll::-webkit-scrollbar {
    display: block !important;
    width: 4px;
    height: 10px;
  }

  .center_image_home
    .main-slider-left
    .mob_image_scroll::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 6px;
  }

  .automation_steps h2 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .helpdesk_solutions .suprcharging_benefits .main-slider-left h2 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .benefits-workplace-content.cogni_workspace_content h4 {
    font-size: 20px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .helpdesk_solutions .suprcharging_benefits .cogni_workspace_content p {
    color: #193a5d;
  }

  .apps_integrations.apps_integrations_home .cc_feature_apps_header h3 {
    font-size: 22px !important;
    margin-bottom: 10px;
    color: #000;
    text-align: center;
  }
  .integrations-apps-paragaraph-automate {
    padding: 0px;
  }
  .integrations-apps-header-automate {
    margin-top: 0px !important;
  }
  .mobile-padding-reduce-automate {
    padding-top: 0px !important;
  }
  .app-list-automate-home {
    margin-top: 0px !important;
  }
  .cc_feature_apps .card_office {
    border-radius: 20px;
  }

  .apps_integrations_home .cc_feature_apps .card_office_cc h6 {
    font-size: 14px;
    font-family: "Gordita-Medium";
    color: #000;
    margin-top: 10px;
  }

  .apps_links_home a {
    font-size: 16px;
    font-family: "Roboto-Medium";
    color: #2468f6;
  }

  .helpdesk_solutions .cogni_workspace_content h3 {
    font-size: 20px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .helpdesk_solutions .suprcharging_benefits .cogni_workspace_content a {
    font-size: 16px;
    font-family: "Roboto-Medium";
    color: #2468f6;
  }

  .video_prompt .main-slider-left h3 {
    font-size: 20px;
    text-align: center !important;
    margin-top: 0px;
    padding-top: 0px;
    padding: 0px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .video_prompt {
    height: 280px;
    font-size: 26px;
    font-family: "Roboto-Bold";
    color: #416183;
  }

  .video_prompt .comapny_name {
    font-size: 20px;
    color: #416183;
    font-family: "Roboto-Bold";
    position: relative;
    top: 6px;
  }

  .video_prompt .main-download-broucher {
    margin-top: 0px;
  }

  .center_image_home.cards_landing_page .main-slider-left h3 {
    font-size: 26px;
    font-family: "Roboto-Medium";
    color: #000;
    line-height: 32px;
  }

  .center_image_home.cards_landing_page .card_landing_section h4 {
    font-size: 20px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .container_trial .center_image_home.cards_landing_page .main-slider-left p {
    color: #193a5d;
  }

  .featured_content h3 {
    font-size: 26px !important;
    font-family: "Roboto-Medium";
    color: #000;
    line-height: 32px;
  }

  .assistant_slider .container {
    padding: 0px 20px;
    padding-top: 20px;
  }
  .assistant_slider .container .customer-banner-auto-mobile {
    padding-top: 16px !important;
  }

  .center_image_home .container {
    padding: 0px 20px;
  }

  .automation_steps .container {
    padding: 0px 20px;
  }

  .suprcharging_benefits .container {
    padding: 0px 20px;
  }

  .apps_integrations .cogni_fav_apps.cc_feature_apps {
    background: #f8f8f8;
  }

  .apps_integrations .marketplace_integrations_icons {
    padding: 30px 5px 30px 5px;
  }

  .marketplace_integrations_icons {
    padding: 30px 20px 30px 20px;
  }

  .video_prompt_section .container {
    padding: 0px;
  }

  .featured_content .container {
    position: relative;
    padding: 0px 20px;
  }

  .homepage_slider .featured_content .slick-slide {
    padding-left: 20px;
    padding-right: 0px !important;
  }

  .homepage_slider .slick-slider {
    left: 10px;
  }

  .featured_content .cogni_workspace_content.home_slider {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .benefits-workplace-content.cogni_workspace_content p {
    color: #416183;
    font-size: 16px;
  }

  .home_link {
    font-size: 16px;
    font-family: "Roboto-Medium";
    color: #2468f6;
  }

  .footer_form_left .main-slider-left h3 {
    font-size: 23px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .container_trial .main-slider-left p {
    color: #193a5d;
  }

  .helpdesk_solutions .bg_card_3 .cogni_workspace_content {
    padding: 30px 5px;
  }

  .helpdesk_solutions .bg_card_4 .cogni_workspace_content {
    padding: 30px 5px;
  }

  /* Feature page css start */
  .back_features_page a {
    font-size: 16px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .feature_inner_page.bg_feature_slider .back_features {
    font-size: 16px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .feature_inner_page.bg_feature_slider .main-slider-left h1 {
    font-size: 26px;
    font-family: "Gordita-Medium";
    color: #000;
    margin-bottom: 10px;
    line-height: 1.5;
  }

  .container_trial .feature_inner_page.bg_feature_slider .main-slider-left p {
    color: #193a5d;
    line-height: 1.5;
  }

  .bg_feature_slider.bg_feature_slider_wrapper .feature_bg_img:before {
    height: 100%;
  }

  .features_change_img_header.pl-0 {
    display: block;
  }
  .feature-display-none-chat-channel.container_trial.integrations_container
  .bg_feature_slider.bg_feature_slider_wrapper {
   padding-bottom: 30px;
  }
.feature-display-none-chat-channel .onboarding_main_container {
  margin-bottom: 30px;
 }



  .container_trial.integrations_container
    .bg_feature_slider.bg_feature_slider_wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .container_trial.integrations_container
    .bg_feature_slider.bg_feature_slider_wrapper {
    margin-bottom: 0px;
  }

  .container_trial .landing_page.trial_page_img .feature_page_content_left h3 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
    line-height: 32px;
    margin-top: 0px;
  }

  .container_trial .feature_page_content_left p,
  .card_link_landing a {
    color: #193a5d;
    line-height: 24px;
  }

  .container_trial.integrations_container .pl-10-tage .feature_page_img_right {
    padding: 0px 20px;
  }

  .build_needs h2 {
    font-size: 26px;
    line-height: 32px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .build_needs .center_features_header {
    padding: 0px !important;
  }

  .container_trial .center_features_header p {
    color: #193a5d;
    line-height: 24px;
  }

  .cards_features .whitepaper_cards .card__image img {
    width: 64px;
    height: 64px;
  }

  .whitepaper_cards .card__title {
    font-size: 20px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .cards_features .whitepaper_cards .card__title {
    font-size: 20px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .cards_features .cards__item .card_link_landing a {
    font-size: 16px;
    font-family: "Gordita-Medium";
    color: #2468f6;
  }

  .cards_features .whitepaper_cards .card__text {
    font-size: 16px;
    color: #193a5d;
    line-height: 24px;
    margin-bottom: 0px;
  }

  .cards_features .cards__item .card_link_landing a {
    font-size: 16px;
    font-family: "Gordita-Medium";
    color: #2468f6;
    margin-top: 10px;
  }

  .arrow_svg_link svg {
    width: 16px;
    height: 18px;
    margin-left: 3px;
  }

  .center_feature_wrapper h2 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
    text-align: left;
  }

  .onprem_connect .automation_steps h2 {
    margin-bottom: 10px;
  }

  .onprem_connect .bg_header_trial.trial_mb_80 {
    padding-top: 20px;
  }

  .feature_pageinner_content_img.m_b_80 .feature_bg_img:before {
    display: none;
  }

  .center_image_home_cc h6 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
    line-height: 32px;
  }

  .center_image_home_cc .cc_card_1 h5 {
    font-family: "Roboto-Medium";
    font-size: 20px;
    color: #000;
  }

  .center_image_home_cc .cc_card_2 h5 {
    font-family: "Roboto-Medium";
    font-size: 20px;
    color: #000;
  }

  .container_trial .main-slider-left h1 {
    font-size: 26px;
    font-family: "Gordita-Medium";
    color: #000;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .beta-button button {
    font-size: 16px !important;
  }

  .form_below_content span {
    font-size: 14px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .container_trial .main-slider-left .basic_plans {
    font-size: 14px;
    font-family: "Gordita-Medium";
    color: #000;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .container_trial .landing_page.trial_page_img .feature_page_content_left h2 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
    line-height: 32px;
    margin-top: 0px;
  }

  .container_trial.integrations_container .pl-10-tage .feature_page_img_right {
    padding-top: 20px;
  }

  .trial_cards_page h4 {
    font-size: 26px;
    font-family: "Gordita-Medium";
    color: #000;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .it_support_chatbot .landing_bg.bg_header_trial.trial_mb_80 {
    padding-top: 20px;
    margin-bottom: 0px !important;
    padding-bottom: 40px;
  }

  .landing_bg .form_below_content span {
    font-size: 14px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .trial_mb_80.bg_header_trial .main-slider-left .basic_plans {
    margin: 5px 0px 10px;
  }

  #chatbot .second_p {
    color: #000 !important;
    font-family: "Roboto-Medium";
  }

  .container_trial .bg_landing_white .second_p {
    color: #000;
    font-family: "Roboto-Medium";
  }

  .container_trial .trial_page_img.bg_landing_yellow .second_p {
    color: #000000;
    font-family: "Roboto-Medium";
  }

  .chat_channels_trial_btn img {
    width: 40px;
    height: 40px;
  }

  .chat_channels_trial .chat_channels_trial_btn {
    color: #333;
    font-family: "Roboto-Medium";
    font-size: 13px;
  }

  .apps_landing_header_wrapper h3 {
    font-size: 24px;
    text-align: center;
    padding: 0px;
    font-family: "Roboto-Medium";
    color: #000000;
  }

  .apps_landing_header_wrapper h5 {
    font-size: 16px;
    height: 50px;
    background: #fff;
    color: #666666;
    margin: 0px;
    display: flex;
    align-items: center;
    border-radius: 6px 6px 0px 0px;
    font-size: 20px;
    font-family: "Roboto-Bold";
    text-align: left;
    box-shadow: none;
  }

  .apps_landing_cards_wrapper {
    background: #f8f8f8;
    padding: 20px 20px 4px;
    box-shadow: none;
  }

  .apps_landing_cards_wrapper ul li img {
    width: 40px;
    height: 40px;
  }

  .apps_landing_cards_wrapper ul li span {
    font-size: 16px;
    font-family: "Roboto-Medium";
    color: #000000;
  }

  .apps_landing_cards_wrapper ul li {
    list-style: none;
    margin: 10px 0px;
    margin-bottom: 13px;
    margin-top: 0px;
  }

  .container_trial p {
    color: #193a5d;
    line-height: 24px;
  }

  .trial_cards_page .card_landing_trial h4 {
    margin: 13px 0px 7px 0px;
  }

  .center_image_home.cards_landing_page .card_landing_section p {
    color: #416183;
    line-height: 24px;
  }

  .controls_wrapper {
    background: #adf0d5;
    color: #000;
    justify-content: space-between;
    padding: 0px 15px;
    height: 52px;
    display: flex;
    align-items: center;
    font-family: "Roboto-Medium";
    font-size: 18px;
    margin-top: 25px;
    width: 92%;
    float: left;
    margin-left: 4%;
    border-radius: 6px;
  }

  .container_trial.integrations_container
    .logs_wrapper
    .center_feature_wrapper.center_feature_wrapper_logs {
    margin-top: 40px;
  }

  .logs_wrapper .bg_conversation_ai .center_feature_wrapper {
    margin-bottom: 0px;
  }

  .onprem_connect .trial_mb_80.bg_header_trial .main-slider-left .basic_plans {
    margin: 10px 0px 20px;
  }

  .container_trial .onprem_connect .center_feature_wrapper p {
    margin-bottom: 0px;
  }

  .mob_res_slide .carousel-indicators {
    top: calc(100vh - 72.5vh);
  }

  .features_change_img_header.pl-0.mob_res_slide {
    display: block;
    position: relative;
  }

  .features_change_img_header.pl-0.mob_res_slide:before {
    content: "";
    height: 120px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }

  .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button
    .form_below_content {
    margin-bottom: 10px;
  }

  .main-slider .container {
    position: relative;
    padding: 0px 20px;
  }

  .cards_features {
    padding: 0px 20px;
    padding-top: 40px;
  }

  .features_change_img.mob_res_slider .carousel-indicators {
    display: none;
  }

  .features_change_img.mob_res_slider .bg_p_wihout_padding {
    margin-top: 0px;
  }

  .mob_image_scroll.m_b_40 {
    margin-bottom: 40px;
  }

  .container_trial .col-md-6.feature_page_content_left.align_top p {
    padding-bottom: 20px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .pl-10-tage
    .feature_page_content_left.align_top {
    padding-bottom: 30px;
    justify-content: flex-start;
    padding-top: 20px;
  }
  .new-app-integration-single-automate .footer_form {
    padding: 0px !important;
  }

  .footer_form {
    padding: 0px 20px;
  }

  .all_features_wrapper {
    width: 100%;
    float: left;
  }

  .all_features_wrapper .bg_slider_home.features_header_home.mobile_feat_video {
    background: inherit;
    padding-top: 0px;
    margin-bottom: 40px;
  }

  .all_features_wrapper .features_change_img-center video {
    margin-top: 20px;
  }

  .all_features_wrapper
    .features_header_home.mobile_feat_video
    .features_change_img_header.pl-0 {
    display: block;
  }

  .all_features_wrapper
    .bg_slider_home.features_header_home.mobile_feat_video:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 120px;
    background: #e7f2fd;
  }

  .all_features_wrapper .bg_slider_home.features_header_home {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .all_features_wrapper .icon-top-img span {
    font-size: 18px;
  }

  .container_trial.integrations_container .pl-10-tage .feature_page_img_right {
    padding-top: 10px;
  }

  .feature_page_content_left p {
    margin-bottom: 10px;
  }

  /* Feature page css End */
  /* Integrations */
  .inter_slider_assist .trial_cards_page {
    width: 100%;
    float: left;
    background: #e7f2fd;
    padding: 40px 0px;
    padding-bottom: 20px;
  }

  .inter_slider_assist .trial_cards_page .col-12.grid_card_trial {
    padding: 0px;
  }

  .inter_slider_assist .trial_cards_page .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 0px;
    margin: 0px;
  }

  .inter_slider_assist .card_landing_section.integrations_popular_bg {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 30px #0000001a;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(1) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(2) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .inter_slider_assist
    .grid_card_trial
    .row
    .card_landing_section:nth-child(3) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .inter_slider_assist .card_landing_section.integrations_popular_bg {
    max-width: 85%;
    flex: 0 0 85%;
  }

  .card_landing_section.integrations_popular_bg img {
    width: 64px;
    height: 64px;
    margin-bottom: 0px;
  }

  .card_landing_section.integrations_popular_bg h4 {
    font-size: 18px;
    font-family: "Roboto-Medium";
    color: #000000;
  }

  .card_landing_section.integrations_popular_bg p {
    color: #416183;
    font-size: 16px;
    line-height: 24px;
    min-height: 50px;
  }

  .card_landing_section.integrations_popular_bg h6 {
    font-size: 16px;
  }

  .card_link_landing a {
    font-size: 16px;
  }

  .card_link_landing a {
    color: #2468f6;
    font-size: 16px;
    font-family: "Roboto-Medium";
  }

  .inter_slider_assist
    .card_landing_section.integrations_popular_bg
    .card_landing_trial {
    padding: 30px 20px;
  }

  .inter_slider_assist .trial_cards_page.trial_cards_page_img {
    background: #fff;
    padding: 0px;
    margin-bottom: 30px;
  }

  .inter_slider_assist
    .trial_cards_page.trial_cards_page_img
    .card_landing_section.integrations_popular_bg {
    box-shadow: 0px 5px 10px #0000001f;
    border-radius: 10px;
  }

  .inter_slider_assist
    .trial_cards_page_img
    .card_landing_section.integrations_popular_bg
    img {
    border-radius: 10px 10px 0px 0px;
  }

  .inter_slider_assist
    .trial_cards_page.trial_cards_page_img
    .grid_card_trial
    .row
    .card_landing_section:nth-child(1) {
    margin-bottom: 20px;
    margin-left: 5px;
  }

  .inter_slider_assist
    .trial_cards_page.trial_cards_page_img
    .grid_card_trial
    .row
    .card_landing_section:nth-child(2) {
    margin-bottom: 20px;
  }

  .inter_slider_assist
    .trial_cards_page.trial_cards_page_img
    .grid_card_trial
    .row
    .card_landing_section:nth-child(3) {
    margin-bottom: 20px;
  }

  .trial_cards_page_img .card_landing_section.integrations_popular_bg h4 {
    color: #000;
    font-size: 24px;
    font-family: "Gordita-Medium";
    font-weight: bold;
  }

  .card_landing_section.integrations_popular_bg
    .trial_cards_page.trial_cards_page_img
    .card_landing_trial {
    padding: 20px;
  }

  .controls_left svg {
    transform: rotate(180deg);
  }

  .container_trial .feature_page_img_right.mob_slider_pad_0 img {
    height: 57%;
    width: 75% !important;
    display: flex;
    left: 0;
  }

  .container_trial .inter_slider_assist .basic_plans {
    font-size: 14px;
    font-family: "Roboto-Medium";
    color: #000;
    margin-top: 8px;
  }

  .inter_slider_assist .feature_page_content_left p.second_p {
    font-size: 16px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .inter_slider_assist .integration_header_top h3.header_integrations {
    color: #193a5d;
    font-size: 20px;
    font-family: "Roboto-Bold";
    margin-bottom: 0px;
    line-height: 36px;
  }

  .container_trial
    .inter_slider_assist
    .integration_header_top
    h3.header_integrations_name {
    font-family: "Gordita-Medium";
    color: #000;
    margin-top: 0px;
    margin-bottom: 10px;
    line-height: 36px;
  }

  .container_trial
    .inter_slider_assist
    .landing_page.trial_page_img
    .feature_page_content_left
    h1 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
    margin-top: 0px;
  }

  .inter_slider_assist .inegrations_header_right img {
    width: 84px;
    height: 84px;
    top: 0px;
  }

  .container_trial.integrations_container
    .pl-20-tage
    .feature_page_content_left {
    padding: 0px 20px;
  }

  .container_trial .pl-20-tage.trial_page_img .feature_page_img_right {
    padding: 0px 20px;
  }

  .container_trial.integrations_container
    .pl-10-tage
    .feature_page_content_left {
    padding: 0px 20px;
  }

  .inter_slider_assist .trial_cards_page .container {
    padding: 0px 20px;
    padding-right: 0px;
  }

  .inter_slider_assist .trial_cards_page .col-12.grid_card_trial {
    padding-right: 0px;
  }

  /* Ussecases */
  .mob_sticky_wrapper .boarding_wrapper_container img {
    width: 64px;
    height: 64px;
  }

  .boarding_wrapper_container .col-md-12.col-lg-3.col-12 {
    margin-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .boarding_wrapper_container h4 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .boarding_wrapper_container .pad_left_30 {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .boarding_wrapper_container .pad_right_30 {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .resources_card.knowledge_cards h2 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
    padding: 0px 20px;
    text-align: left;
  }

  .resources_card .whitepaper_cards .card__title {
    font-family: "Gordita-Medium";
    color: #000;
    font-size: 20px;
  }

  .knowledge_cards .whitepaper_cards .cards {
    padding: 0px 20px;
  }

  .resources_card.knowledge_cards .whitepaper_cards .card__text {
    color: #193a5d;
    margin-top: 0px;
  }

  .container_trial .bg_header_trial.trial_mb_80.bg_apps_usecaes {
    padding-bottom: 40px;
    padding-top: 20px;
  }

  .cogni_fav_apps_color .card_office {
    margin: 8px !important;
  }

  .center_image_home.cards_landing_page .card_landing_trial {
    padding: 30px 20px;
  }

  .ai-self-service-chatbot.it_support_chatbot .feature_page_img_right img {
    padding-top: 20px;
  }

  .header_content {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
    margin-bottom: 10px;
    line-height: 30px;
    padding: 0px 20px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .landing_page.trial_page_img
    .feature_page_content_left.align_top
    h3 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .container_trial.integrations_container
    .slider_container
    .pl-10-tage
    .feature_page_content_left {
    padding: 0px !important;
  }

  .ai-self-service-chatbot.it_support_chatbot #marketplace .container {
    padding: 0px 20px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .trial_page_img.bg_landing_white
    #section2
    .row {
    padding: 0px 16px;
  }

  .it_support_chatbot.ai-self-service-chatbot
    .trial_page_img.bg_landing_white
    #section2
    .controls_wrapper
    span {
    font-size: 20px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .ai-self-service-chatbot.it_support_chatbot .controls_wrapper {
    width: 98%;
    margin-left: 2%;
  }

  .container_trial .bg_landing_white .second_p {
    color: #000 !important;
  }

  .agent_assit_bot {
    width: 100%;
    float: left;
  }

  .agent_assit_bot .trial_page_img.bg_assist_white {
    background: #fff;
    padding-bottom: 0px;
    padding-top: 30px;
  }

  .agent_assit_bot.container_trial.integrations_container
    .pl-10-tage
    .feature_page_img_right {
    padding-top: 0px;
  }

  .agent_assit_bot .chat_channels_trial {
    margin-top: 20px;
  }

  .apps_landing_page.apps_self_page .container {
    padding: 0px 20px;
  }

  .apps_landing_page .container {
    padding: 0px 20px;
  }

  .apps_landing_page .apps_landing_cards_section .apps_landing_cards {
    padding: 0px;
    box-shadow: 0px 5px 10px #00000040;
  }

  /* how it works */
  .how_it_works_header {
    flex-basis: inherit;
    margin-right: 0px;
    background: #f8f8f8;
    padding: 30px 10px;
    border-radius: 6px;
    position: relative;
    border-bottom: 1px solid #193a5d;
  }

  .how_it_works_header:last-child {
    border-bottom: 1px solid #193a5d;
  }

  .how_it_works_header:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: url("../images/howitwork_arrow.svg") no-repeat;
    left: 0px;
    margin-top: 28px;
    margin-left: 50%;
  }

  .how_it_section_page .x_scroll_page {
    padding-top: 10px;
    padding-left: 20px;
    overflow: hidden;
    padding-bottom: 7px;
  }

  .how_it_content img {
    width: 70%;
    height: 100%;
    margin-bottom: 7px;
  }

  .how_it_content h3 {
    font-size: 20px;
    font-family: "Roboto-Medium";
    color: #000;
    margin-bottom: 7px;
  }

  .page_header_center h1 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .how_it_works_header .how_it_content p {
    color: #193a5d;
    line-height: 24px;
    margin-bottom: 0px;
  }

  .how_it_section.how_it_page_content {
    padding-bottom: 40px;
  }

  .how_it_section_page .how_3_steps {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .automate_new_single_app .footer_form_container {
    border-radius: 0px !important;
  }
  .automate_new_single_app .footer_form {
    padding: 0px !important;
  }
  .footer_form_container {
    border-radius: 20px;
    padding: 24px 15px !important;
    padding-bottom: 24px !important;
  }
  .footer_below_span_content_last{
    padding-bottom:0px !important;
  }

  .how_works_wrapper .icon-top-img {
    display: none;
  }

  .workflow_automation_wrapper .icon-top-img {
    margin-bottom: 10px;
  }

  .container_trial
    .how_works_wrapper
    .landing_page.trial_page_img
    .feature_page_content_left
    h2 {
    margin-top: 0px;
  }

  .card_link_landing.jira_landing_link.how_ts_link a {
    font-size: 16px;
    font-family: "Gordita-Medium";
    color: #2468f6;
    margin-bottom: 0px;
  }

  /* Marketpalce */
  .marketplace_assistant {
    width: 100%;
    float: left;
  }

  .marketplace_assistant .container_trial .main-slider-left h1 {
    text-align: center;
    font-size: 28px;
  }

  .marketplace_assistant .trial_mb_80.bg_header_trial .main-slider-left p {
    text-align: center;
  }

  .marketplace_assistant .bg_marketpalce .cc_feature_apps_header h3 {
    text-align: center;
    font-family: "Roboto-Medium";
    font-size: 24px;
    padding: 0px;
    color: #000000;
    line-height: 32px;
  }

  .marketplace_assistant .apps_integrations.bg_marketpalce {
    background: #f8f8f8;
    margin-top: 40px;
    padding-bottom: 40px;
  }

  .marketplace_assistant .bg_marketpalce .marketplace_integrations_icons {
    background: #f8f8f8;
  }

  .marketplace_assistant .bg_marketpalce .btn-primary {
    height: 52px;
    font-family: "Roboto-Medium";
    font-size: 16px;
    background: #2468f6;
    border: 1px solid #2468f6;
    width: 100%;
  }

  .trial_cards_page .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 0px;
    margin: 0px;
  }

  .card_landing_section.integrations_popular_bg {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 30px #0000001a;
  }

  .grid_card_trial .row .card_landing_section:nth-child(1) {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 3px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(2) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .grid_card_trial .row .card_landing_section:nth-child(3) {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .card_landing_section.integrations_popular_bg {
    max-width: 87%;
    flex: 0 0 87%;
  }

  .card_landing_section.integrations_popular_bg img {
    width: 64px;
    height: 64px;
    margin-bottom: 0px;
  }

  .card_landing_section.integrations_popular_bg h4 {
    font-size: 18px;
    font-family: "Roboto-Medium";
    color: #000000;
  }

  .card_landing_section.integrations_popular_bg p {
    color: #416183;
    font-size: 16px;
    line-height: 24px;
    min-height: 50px;
  }

  .card_landing_section.integrations_popular_bg h6 {
    font-size: 16px;
  }

  .card_link_landing a {
    font-size: 16px;
  }

  .card_link_landing a {
    color: #2468f6;
    font-size: 16px;
    font-family: "Gordita-Medium";
    margin-top: 10px;
  }

  .card_landing_section.integrations_popular_bg .card_landing_trial {
    padding: 30px 20px;
  }

  .trial_cards_page .col-12.grid_card_trial {
    padding: 0px;
  }

  .trial_cards_page_img .card_landing_section.integrations_popular_bg img {
    border-radius: 10px 10px 0 0;
  }

  .trial_cards_page.trial_cards_page_img
    .card_landing_section.integrations_popular_bg {
    box-shadow: 0 5px 10px rgb(0 0 0 / 12%);
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .trial_cards_page.trial_cards_page_img h4 {
    padding-right: 20px;
    /* padding-left: 5px; */
  }

  .trial_cards_page.trial_cards_page_img .container {
    padding-left: 15px;
    padding-right: 0px;
  }

  .videos_container .video_list_ul {
    grid-gap: 0px;
    grid-template-columns: 100%;
    border: 1px solid #e7f2fd;
    border-radius: 6px;
    background: #e7f2fd;
    border-radius: 6px !important;
  }

  .videos_wrapper .wrapper_left {
    border: 1px solid #e7f2fd;
    border-radius: 6px;
    background: #e7f2fd;
    height: 58px;
  }

  .videos_container .video_list_ul .wrapper_left.close h4 {
    font-size: 18px;
    color: #333;
  }

  .videos_container .video_list_ul .wrapper_left.open h4 {
    font-size: 18px;
    color: #333;
  }

  .videos_container .search_icon svg {
    width: 22px;
    height: 22px;
  }

  .videos_container .video_list_ul.video_list_ul_mobile {
    margin-bottom: 0px;
  }

  .videos_container .utube_rows > .utube_col_4 p {
    font-size: 18px;
    color: #000000;
  }

  .videos_container .utube_content {
    padding: 0px 20px;
  }

  .show_more_videos button {
    width: 100%;
    padding: 0px 20px;
    height: 52px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dad9d9;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    color: #000;
    font-size: 18px;
    font-family: "Roboto-Medium";
  }

  .utube_iframe span svg {
    fill: #fff;
    width: 48px;
    height: 48px;
    z-index: 1;
  }

  .videos_container .search_marketpalce .search_click {
    padding-left: 50px;
  }

  .videos_container .video_list_ul .wrapper_left.close {
    opacity: 1 !important;
  }

  .show_more_videos {
    margin-top: 30px;
  }

  .videos_container .utube_videos {
    margin-bottom: 40px;
  }

  .videos_container .container {
    padding: 0px 20px;
  }

  .videos_wrapper .features_change_img_header.pl-0 {
    display: block;
  }

  .videos_container .search_marketpalce .search_click {
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 18px;
    height: 58px;
  }

  .videos_container .wrapper_left_mobile {
    padding-right: 0px;
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 15px;
  }

  .videos_container .video_list_ul li {
    border-radius: 0px;
  }

  .videos_container .wrapper_left_mobile svg {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .videos_container .wrapper_left.open .wrapper_left_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    padding-right: 0px;
    padding-left: 0px;
  }

  .videos_wrapper .overview_wrapper h3 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .overview_wrapper .sign_up-form_us.demo_page h6 {
    line-height: 26px;
    margin-bottom: 20px;
  }

  .videos_wrapper .sign_up-form_us.demo_page p {
    margin-bottom: 20px;
  }

  .overview_wrapper .row.beta_change_form ul li {
    list-style: none;
    margin-bottom: 20px;
  }

  .overview_wrapper .row.beta_change_form ul li b {
    font-size: 18px;
  }

  .overview_wrapper .row.beta_change_form ul {
    padding: 0px;
  }

  .overview_wrapper .col-lg-8 {
    padding: 0px 20px;
  }

  .overview_wrapper .sign_up-form_us.demo_page .col-lg-4 h6 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .overview_wrapper .sign_up-form_us.demo_page h6 {
    font-size: 18px;
    font-family: "Roboto-Medium";
    color: #000;
    margin-bottom: 10px;
  }

  .overview_wrapper .row.beta_change_form .col-lg-8 ul li {
    font-size: 16px;
    color: #193a5d;
  }

  .overview_wrapper .row.beta_change_form ul li {
    font-size: 18px;
    color: #000000;
    margin-bottom: 10px;
  }

  .resource_wrapper {
    padding: 30px 20px;
  }

  .overview_wrapper .demo_page_right {
    padding: 0px 20px;
  }

  .overview_wrapper .sign_up-form_us.demo_page .get_started_wrapper h5 {
    font-size: 18px;
  }

  .get_started_wrapper button {
    height: 52px;
    font-size: 20px;
  }

  .get_started_wrapper {
    padding: 30px 20px;
  }

  .similar_videos_wrapper {
    width: 100%;
    float: left;
    background: #fff;
    padding-top: 20px;
    margin-bottom: 40px;
  }

  .videos_wrapper .similar_videos_wrapper h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .similar_videos_wrapper .utube_videos {
    margin-bottom: 0px;
    padding: 0px 20px;
  }

  .videos_wrapper .footer_form {
    margin-bottom: 40px;
  }

  .similar_videos_wrapper .utube_rows > .utube_col_4 p {
    padding: 20px;
  }

  .videos_wrapper
    .feature_inner_page.bg_feature_slider
    .features_change_img-center
    iframe {
    width: 100%;
    height: 220px;
    border-radius: 10px;
  }

  .center_image_home.cards_landing_page .trial_cards_page .row {
    flex-wrap: wrap;
    overflow: hidden;
  }

  .center_image_home.cards_landing_page
    .grid_card_trial
    .row
    .card_landing_section:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .center_image_home.cards_landing_page
    .grid_card_trial
    .row
    .card_landing_section:nth-child(2) {
    margin-right: 0px;
  }

  .center_image_home.cards_landing_page
    .grid_card_trial
    .row
    .card_landing_section:nth-child(3) {
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .trial_header_left h1 {
    font-size: 26px;
    font-family: "Gordita-Medium";
    color: #000;
    line-height: 32px;
  }
  .it_support_chatbot .landing_bg.bg_header_trial.trial_mb_80.it_support_new_page{
    padding-bottom: 0;
  }
}

/* Automate css */

@media only screen and (max-width: 520px) {
  .footer_form_right .form_below_content.signup_cta span:last-child {
    width: max-content;
  }
  .new-pricing-page-automate .pricing-columns.pricing-section {
    margin-top: 10px;
  }

  .footer_form_right .form_below_content.signup_cta span {
    justify-content: center;
  }

  .container_trial .automate_homepage .page_header_center h1 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
    line-height: 32px;
  }

  .workflow_automation_wrapper.trigger_page
    .trial_header_left
    .main-slider-left {
    padding-bottom: 0px;
  }

  .workflow_autoamte_wrapper .ahref-wrapper {
    color: #09a86c;
  }

  .workflow_autoamte_wrapper .ahref-wrapper a:hover {
    color: #09a86c;
  }

  .workflow_autoamte_wrapper .ahref-wrapper a {
    text-decoration: none;
  }

  .automate_homepage .page_header_center {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
    border: none;
  }

  .automate_homepage .tabgroup_bg {
    width: 100%;
    float: left;
    background: #f7f7f7;
    padding-top: 40px;
  }

  .automate_homepage .tabgroup_bg .accordian_landing {
    margin-bottom: 0px;
  }

  .workflow_automation_wrapper.workflow_autoamte_wrapper.trigger_page.automate_product
    .tabgroup_bg_white
    .accordian_landing {
    margin-bottom: 0px;
  }

  .automate_homepage .mobile_view_tab .mob_accordian .accordion__panel {
    background: #e3fff4 0% 0% no-repeat padding-box !important;
    box-shadow: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .automate_homepage
    .mobile_view_tab
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"] {
    background: #e3fff4 0% 0% no-repeat padding-box !important;
    box-shadow: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .workflow_automation_wrapper.workflow_autoamte_wrapper.trigger_page.automate_product
    .tabgroup_bg_white {
    width: 100%;
    float: left;
    background: #f7f7f7;
    padding-top: 40px;
    border: none;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .automate_homepage .mobile_view_tab .mob_accordian .accordion__button {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile_view_tab .mob_accordian.mob_accordian_faq .container h2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile_view_tab .mob_accordian.mob_accordian_faq .container h2 {
    font-size: 24px !important;
    font-family: "Roboto-Medium";
    color: #333333;
    line-height: 32px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .table_full_comapare {
    margin-bottom: 24px;
  }

  .mobile_view_tab .mob_accordian.mob_accordian_faq p {
    margin-bottom: 16px;
  }

  .container-fluid.autoamte_apps {
    margin-bottom: 0px;
    padding: 0px;
  }

  .autoamte_apps_header h1 {
    font-size: 26px;
    font-family: "Roboto-Medium";
    color: #000;
    line-height: 32px;
  }

  .marketplace_header_container p {
    padding: 0px;
  }

  .marketplace_autoamte_apps.automate_marketplace {
    padding-top: 30px;
  }

  .market_place .loader_demo {
    margin-top: 40px;
  }

  .automate_marketplace .bg_marketpalce .btn-primary {
    background: #b5ffe3;
    color: #222;
    border-radius: 0px;
    width: 100%;
    text-align: center;
    height: 48px;
    border: 1px solid #15c381;
    border-radius: 6px;
    font-size: 16px;
    font-family: "Roboto-Medium";
  }

  .automate_marketplace .bg_marketpalce .marketplace_integrations_icons {
    background: inherit;
    padding-top: 0px;
  }

  .automate_marketplace .apps_integrations.bg_marketpalce .cogni_fav_apps {
    margin-bottom: 0px;
  }

  .automate_homepage .how_it_works_header {
    border-bottom: 1px solid #193a5d;
  }

  .automate_homepage .how_it_works_header:last-child {
    border-bottom: 1px solid #193a5d;
  }

  .mob_search h2 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .mob_search h2:before {
    display: none;
  }

  .automate_marketplace .wrapper_left {
    background: #CBFFEC 0% 0% no-repeat padding-box;
    border: 1px solid #46B18A !important;
    height: 50px;
    border-radius: 6px;
  }
  .search_marketplace_integrations_automate{
    margin: 0px !important;
  }
 .search_marketplace_integrations_automate .search_icon{
    height: 30px !important;
    width: 30px !important;
    left: 6px !important;
    top: 7px !important;
  }
  .show_more_integrations_automate button{
    background: #CBFFEC 0% 0% no-repeat padding-box !important;
    border: 1px solid #46B18A !important;
    border-radius: 30px !important;
    color:#333333 !important;
  }

  .automate_marketplace .wrapper_left h4 {
    font-size: 18px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .mob_search .search_marketpalce {
    display: none !important;
  }

  .mob_automate_search .search_marketpalce {
    display: block !important;
    margin-bottom: 10px;
  }

  .automate_marketplace .wrapper_left {
    margin-top: 0px !important;
  }

  .mob_automate_search .search_marketpalce .search_click {
    padding: 16px 15px 16px 48px;
    color: #818181;
    font-size: 18px;
    border: 1px solid #c4c2c2;
  }

  .mob_automate_search {
    width: 100%;
    float: left;
    margin-top: 10px;
  }

  .mob_automate_search .search_marketpalce .search_icon {
    height: 55px;
    width: 45px;
  }

  .marketplace_wrapper .container {
    padding: 0px 20px;
  }

  .mob_automate_search .search_icon svg {
    width: 22px;
    height: 22px;
    color: #474747;
  }

  .automate_marketplace .marketplace_wrapper {
    margin-bottom: 40px;
  }

  .automate_marketplace .market_place .loader_demo {
    margin-bottom: 40px;
  }

  .automate_marketplace .dropdown_left_show .li_active {
    color: #2468F6;
    font-family: "Gordita-Medium";
    background: #F0F9FF 0 0 no-repeat padding-box;
    border-radius: 6px;
  }
 
  .automate_marketplace .dropdown_left_show ul li {
    font-size: 14px;
  }

  .automate_marketplace .dropdown_left {
    width: 100%;
    float: left;
    border:none !important;
    padding-top: 0px !important;
    margin-top: 10px;
    border-radius: 0;
  }

  .automate_marketplace .show_more_videos {
    display: flex;
  }

  .automate_marketplace .show_more_videos button:hover {
    background: #ceffeb;
    border: 1px solid #ceffeb;
  }

  .container_trial .autoamte_apps_header p {
    color: #193a5d;
  }

  .container_trial .automate_marketplace .apps_header_left_container p {
    line-height: 20px;
  }

  .center_connection {
    width: 42px;
    height: 40px;
    top: -2px;
  }

  .autoamte_apps_header_right {
    position: relative;
    top: -3px;
  }

  .center_connection svg {
    width: 15px;
    height: 15px;
    fill: #416183;
  }

  .autoamte_apps_header_right .search_marketpalce .search_click {
    height: 60px;
    font-size: 15px;
    margin: 0 !important;
    padding: 1px 0px 0px 48px !important;
    border: 1px solid #c4c2c2;
    color: #7a94af;
  }

  .autoamte_apps_header_right .search_icon svg {
    width: 22px;
    height: 22px;
  }

  .autoamte_apps_header_right .search_marketpalce .search_icon {
    top: 10px;
    left: 23px;
  }

  .search_marketpalce .search_click::placeholder {
    color: #7a94af;
  }

  .apps_list {
    min-height: 140px;
    margin-bottom: 0;
  }

  .apps_list img {
    width: 60px;
    height: 60px;
    margin-top: 25px;
  }

  .apps_list h6 {
    font-size: 14px;
    margin-top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Roboto-Medium";
  }

  .container_trial .content_center_automate p {
    font-size: 16px;
    margin-bottom: 24px;
  }

  .am_cards_wrapper {
    padding-top: 34px;
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
    margin-bottom: 0px;
  }

  .am_cards_wrapper .show_morw_option {
    margin-top: 0px;
  }

  .am_cards_img {
    height: 60px;
  }

  .am_cards_img span img {
    width: 42px;
    height: 42px;
  }

  .am_cards_img span svg {
    width: 10px;
    height: 12px;
  }

  .am_cards_img span.space_apps {
    margin: 0 4px;
  }

  .am_cards_wrapper .container {
    width: 100%;
    padding: 0px;
  }

  .am_cards_wrapper .autoamte_apps_header_right {
    padding: 0px;
  }

  .am_cards_wrapper .col-12 {
    padding: 0px 20px;
  }

  .am_cards_wrapper .row {
    margin: 0px;
  }

  .am_cards_wrapper h3 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
    margin-top: 20px;
  }

  .am_cards_wrapper .am_cards_column {
    width: 100%;
    float: left;
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
    margin-bottom: 0px;
  }

  .am_cards_column .am_cards_column_left {
    padding: 0px 20px;
  }

  .am_cards_container h5 {
    font-family: "Gordita-Medium";
    margin-top: 15px;
  }

  .mobile_cards_width .am_cards_container h5 {
    text-align: center;
    margin-bottom: 18px;
  }

  .am_cards_button button,
  .am_cards_button button.soon_btn_bg {
    height: 48px;
    min-width: max-content;
    font-size: 16px;
    font-family: "Gordita-Medium";
  }

  .search_apps_box {
    overflow-y: auto;
    min-height: 308px;
    max-height: 308px;
  }

  .am_cards_wrapper.actions_wrapper h2 {
    font-size: 24px;
    font-family: "Gordita-Medium";
    color: #000;
  }

  .am_cards_wrapper.actions_wrapper .container {
    padding: 0px;
  }

  .am_cards_wrapper.actions_wrapper .am_cards_column_left {
    padding: 0px;
  }

  .am_cards_wrapper.actions_wrapper .actions_wrapper_container {
    padding: 15px 20px;
    padding-bottom: 30px;
  }

  .am_cards_wrapper
    .autoamte_apps_header_right
    .search_marketpalce
    .search_icon {
    left: 10px;
    top: 10px;
  }

  .am_cards_wrapper.actions_wrapper .actions_wrapper_container h4 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000;
  }

  .am_cards_wrapper.actions_wrapper .am_cards_img span img {
    width: 36px;
    height: 36px;
  }

  .am_cards_wrapper.actions_wrapper .am_cards_img span img {
    width: 26px;
    height: 26px;
  }

  .am_cards_wrapper.actions_wrapper
    .actions_wrapper_container
    .am_cards_container
    .am_cards_button
    button.soon_btn_bg {
    height: 24px;
    min-width: max-content;
    font-size: 11px;
    font-family: "Roboto-Medium";
  }

  .am_cards_wrapper.actions_wrapper
    .actions_wrapper_container
    .am_cards_container._responsive_mode
    .am_cards_button {
    margin-top: 5px;
  }

  .why_autoamte_wrapper h3 {
    font-size: 24px;
  }
  .new-single-app-main-container {
    flex-direction: column;
  }
  .main-popular-integration h5 {
    text-align: center !important;
  }

  .three_steps_right h5 {
    font-size: 18px;
  }

  .three_steps_wrapper img {
    width: 58px;
    height: 58px;
  }
  .mobile-padding-demo {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .container_trial .three_steps_right p {
    margin-top: 8px;
  }

  .three_steps_wrapper {
    margin-top: 5px;
    margin-bottom: 12px;
  }

  .why_autoamte_wrapper .container {
    padding: 0px 20px;
  }

  .why_autoamte_wrapper .row {
    justify-content: space-between;
    margin: 0px;
  }

  .why_autoamte_wrapper .col-md-6 {
    justify-content: space-between;
    padding: 0px;
  }

  .marketplace_autoamte_apps.automate_marketplace .container {
    padding: 0px;
  }

  .marketplace_autoamte_apps.automate_marketplace .row {
    margin: 0px;
  }

  .marketplace_autoamte_apps.automate_marketplace
    .container
    .autoamte_apps_header {
    padding: 0px 20px;
  }

  .marketplace_autoamte_apps.automate_marketplace .container .col-md-6 {
    padding: 0px 20px;
  }

  .automate_marketplace.automate_product .wrapper_page {
    overflow: hidden;
  }

  .automate_homepage .footer_form .container {
    padding-right: 12px;
  }

  .selected_apps_left_border {
    width: 130px;
    min-height: 100px;
  }

  .selected_apps_left_border img {
    width: 40px;
    height: 40px;
  }

  .selected_apps_left_border h6 {
    font-size: 10px;
    margin-top: 7px;
    font-family: "Gordita-Medium";
  }

  .container_trial .content_center_automate_selected p {
    font-size: 16px;
    color: #000000;
  }

  .am_cards_wrapper h2 {
    font-size: 24px;
    margin-top: 20px;
    font-family: "Roboto-Medium";
    color: #000000;
  }

  .am_cards_wrapper .am_cards_column.d-flex h2 {
    padding: 0px 20px;
  }

  .explore_apps_container h3 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000000;
  }

  .explore_apps_box h4 img {
    width: 54px;
    height: 54px;
    margin-right: 10px;
  }

  .container_trial .explore_apps_box p {
    padding: 22px 22px 30px;
    line-height: 24px;
  }

  .explore_apps_box_img {
    padding: 33px 0;
  }

  .show_morw_option span {
    color: #09a86c;
    font-size: 18px;
  }

  .selected_center_connection {
    width: 42px;
    height: 40px;
  }

  .selected_center_connection span svg {
    width: 15px;
    height: 15px;
  }

  .marketplace_autoamte_apps.automate_marketplace .content_center_automate {
    margin-top: 20px;
  }

  /* Pricing page */
  .pricing-columns.pricing-section .plan {
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    position: relative;
  }

  .pricing-columns .pricing-chart .plan svg,
  .pricing-columns-both .pricing-chart .plan svg {
    width: 120px;
    margin-bottom: 15px;
    margin-top: 0px;
    height: 120px;
    fill: #2468f6;
  }

  .automate_pricing .pricing-columns .pricing-chart .plan svg,
  .automate_pricing .pricing-columns-both .pricing-chart .plan svg {
    width: 120px;
    margin-bottom: 10px;
    margin-top: 0px;
    height: 120px;
  }

  .automate_pricing .pricing-columns.pricing-section .plan .view_details {
    color: #33906b;
  }

  .pricing-columns .pricing-chart .plan a.sign-up,
  .pricing-columns-both .pricing-chart .plan a.sign-up {
    height: 48px;
    width: 100%;
    font-size: 16px;
    font-family: "Roboto-Medium";
    border-radius: 6px;
  }

  .pricing-columns .pricing-chart .plan ul,
  .pricing-columns-both .pricing-chart .plan ul {
    margin-bottom: 30px;
    padding-left: 1px !important;
  }

  .automate_pricing .mob_accordian.mob_accordian_faq .container h2 {
    font-family: "Roboto-Medium";
    text-align: left;
    font-size: 24px !important;
    color: #000;
  }

  .pricing-columns.pricing-section .plan .view_details {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 49px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    font-size: 12px;
    color: #2468f6;
    background: #fff;
    border-top: 1px solid #d5d5d5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
  }

  .pricing-columns .pricing-chart .plan:first-child,
  .pricing-columns-both .pricing-chart .plan:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .view_details._open:before {
    position: absolute;
    content: "";
    background: linear-gradient(180deg, #ffffff1a 0%, #fff 100%);
    width: 100%;
    height: 30px;
    top: -32px;
  }

  .pricing-columns .pricing-chart .plan svg,
  .pricing-columns-both .pricing-chart .plan svg {
    margin-bottom: 12px;
  }

  .pricing-columns .pricing-chart .plan h4,
  .pricing-columns-both .pricing-chart .plan h4 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000000;
    line-height: 32px;
    margin-bottom: 8px;
  }

  .pricing-chart .users_upto {
    font-size: 14px;
    font-family: "Roboto-Medium";
    color: #333;
    margin-bottom: 12px;
  }

  .pricing-columns .pricing-chart .plan ul li label,
  .pricing-columns-both .pricing-chart .plan ul li label {
    width: 12px;
    height: 12px;
    top: 1px;
    margin-right: 8px;
    border: 2px solid #2468f6;
  }

  .pricing-columns .pricing-chart .plan ul li,
  .pricing-columns-both .pricing-chart .plan ul li {
    font-size: 14px !important;
    color: #333;
  }

  .pricing-columns .pricing-chart .plan ul li span svg,
  .pricing-columns-both .pricing-chart .plan ul li span svg {
    margin-left: 5px;
  }

  .pricing-columns .pricing-chart .plan .view_details svg,
  .pricing-columns-both .pricing-chart .plan .view_details svg {
    fill: #000;
    width: 7px;
    height: 7px;
    margin-bottom: 0px;
  }

  .view_details div {
    width: 100%;
    float: left;
    line-height: 9px;
  }

  .show_button_full_comaparison.open ~ .table_mob_comapre {
    width: 100%;
    display: flex;
    overflow-x: scroll;
  }

  .pricing-columns .pricing-chart .plan,
  .pricing-columns-both .pricing-chart .plan {
    padding: 30px 20px 20px;
  }

  .top_section.pricing_page .container {
    padding: 0px 30px;
  }
  .top_section.pricing_page.assistant_pricing_page .container {
    padding: 0px 15px !important;
  }

  .top_section.pricing_page .container-fluid {
    margin: 0px;
  }

  .table_full_comapare .table-bordered {
    overflow: hidden;
  }

  .container_trial .top_section.pricing_page .main-slider-left p {
    color: #416183;
  }

  .top_section.pricing_page .form_below_content span {
    margin-right: 0px;
    color: #333333;
    font-size: 14px;
    font-family: "Gordita-Medium";
  }

  .top_section.pricing_page._assistant_pricing .form_below_content span {
    text-align: center;
  }

  .top_section.pricing_page .main-slider-left h1 {
    margin: 0px;
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 36px;
  }

  .top_section.pricing_page {
    padding: 20px 0px;
  }

  .pricing-columns.pricing-section .assistant_pricing:before {
    height: 130px;
  }

  .pricing-columns.pricing-section .container {
    padding: 0px 20px;
  }

  .container_trial .pricing-columns .enterprise_message {
    color: #333333;
    font-size: 14px;
    margin-top: 10px;
  }

  .plan.plan_nterprise .users_upto {
    visibility: hidden;
    margin-bottom: 0px;
    line-height: 12px;
  }

  .full_comaparision h1 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000000;
    line-height: 32px;
  }

  .container_trial .full_comaparision p {
    color: #193a5d;
    margin-bottom: 25px;
  }

  .show_button_full_comaparison button {
    font-family: "Roboto-Medium";
    height: 48px;
    border-radius: 6px;
  }

  .full_comaparision .container {
    padding: 0px 20px;
    padding-right: 0px;
    overflow: hidden;
  }

  .full_comaparision .container h1 {
    padding-right: 20px;
  }

  .full_comaparision .container p {
    padding-right: 20px;
  }

  .full_comaparision .container .show_button_full_comaparison {
    padding-right: 20px;
  }

  .bud_space {
    padding: 10px;
  }

  .plan.plan_nterprise ul {
    margin-bottom: 0px;
  }

  .top_section.top_section_bottom.pricing_page .main-slider-left h1 {
    font-size: 24px;
  }

  .top_section.top_section_bottom.pricing_page .main-slider-left h6 {
    font-size: 18px;
    color: #193a5d;
    line-height: 24px;
  }

  .container_trial
    .top_section.top_section_bottom.pricing_page
    .main-slider-left
    p {
    text-align: left;
    color: #193a5d;
    font-size: 16px;
    line-height: 24px;
  }

  .top_section.top_section_bottom.pricing_page .container {
    padding: 0px 5px;
  }

  .top_section.top_section_bottom.pricing_page {
    margin-bottom: 40px;
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .mob_accordian.mob_accordian_faq .container h2 {
    font-size: 24px;
    font-family: "Roboto-Medium";
    color: #000000;
    line-height: 32px;
    text-align: left;
  }

  .mob_accordian.mob_accordian_faq .accordion__button:before {
    width: 18px;
    height: 18px;
    top: 15px;
  }

  .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    width: 18px;
    height: 18px;
    top: 15px;
  }

  

   
  .mob_accordian.mob_accordian_faq .accordion__button {
    font-family: "Gordita-Medium";
  }

  .mob_accordian .accordion__button {
    width: 100%;
    padding: 12px 0px;
  }

  .mob_accordian.mob_accordian_faq .container {
    padding: 0px 20px;
  }

  .mob_accordian.mob_accordian_faq .accordion__button {
    color: #494949;
  }

  .pricing-columns .pricing-chart .plan:hover,
  .pricing-columns-both .pricing-chart .plan:hover {
    background: #fff;
    border: 1px solid #d5d5d5;
    cursor: pointer;
    box-shadow: none;
  }

  .pricing-columns .pricing-chart .plan:hover svg,
  .pricing-columns-both .pricing-chart .plan:hover svg {
    fill: #2e5ce6;
  }

  .pricing-columns .pricing-chart .plan:hover h4,
  .pricing-columns-both .pricing-chart .plan:hover h4 {
    color: #000;
  }

  .pricing-columns .pricing-chart .plan:hover .users_upto {
    color: #333;
  }

  .pricing-columns .pricing-chart .plan:hover a.sign-up,
  .pricing-columns-both .pricing-chart .plan:hover a.sign-up {
    background-color: #fff;
    border: 1px solid #2468f6;
    color: #2468f6;
    box-shadow: none;
  }

  .pricing-columns .pricing-chart .plan:hover ul li label,
  .pricing-columns-both .pricing-chart .plan:hover ul li label {
    border: 2px solid #2468f6;
  }

  .pricing-columns .pricing-chart .plan:hover ul li,
  .pricing-columns-both .pricing-chart .plan:hover ul li {
    color: #000;
  }

  .pricing-columns .pricing-chart .plan:hover ul li span svg,
  .pricing-columns-both .pricing-chart .plan:hover ul li span svg {
    color: #b7b7b7;
  }

  .pricing-columns .pricing-chart .plan:hover .view_details svg,
  .pricing-columns-both .pricing-chart .plan:hover .view_details svg {
    color: #000;
  }

  .automate_pricing .pricing-columns .pricing-chart .plan:hover,
  .automate_pricing .pricing-columns-both .pricing-chart .plan:hover {
    background-color: #fff;
    border: 1px solid #d5d5d5;
    box-shadow: none;
  }

  .automate_pricing .pricing-columns .pricing-chart .plan:hover svg,
  .automate_pricing .pricing-columns-both .pricing-chart .plan:hover svg {
    fill: #15c381;
  }

  .automate_pricing .pricing-columns-both .pricing-chart .plan:hover a.sign-up,
  .automate_pricing .pricing-columns .pricing-chart .plan:hover a.sign-up {
    border: 1px solid #15c381;
    border-radius: 6px;
    color: #33906b;
    box-shadow: none;
  }

  .automate_pricing .pricing-columns .pricing-chart .plan:hover ul li label,
  .automate_pricing
    .pricing-columns-both
    .pricing-chart
    .plan:hover
    ul
    li
    label {
    border: 2px solid #33906b;
  }

  .automate_pricing .pricing-columns .pricing-chart .plan ul li label,
  .automate_pricing .pricing-columns-both .pricing-chart .plan ul li label {
    border: 2px solid #33906b;
  }

  .table_full_comapare .table thead tr th {
    font-family: "Roboto-Bold";
    font-size: 18px;
    color: #000;
    height: 80px;
  }

  .table_full_comapare .table thead tr th {
    padding-left: 15px !important;
  }

  .table_full_comapare .table tbody tr th {
    padding-left: 15x !important;
    font-family: "Roboto-Medium";
    font-size: 16px;
  }

  .table.table-bordered.table-responsive-sm tbody tr {
    border-top: 1px solid #dee2e6 !important;
    height: 60px;
  }

  .table_full_comapare .table tbody tr td {
    color: #333;
  }

  .table_full_comapare .table tr td:nth-child(1) {
    text-align: left;
    border-left: 1px solid #fff !important;
    white-space: normal;
  }

  .table_full_comapare .table tr th:nth-child(1) {
    text-align: left;
    border-left: 1px solid #fff !important;
    white-space: pre;
  }

  .table_full_comapare .table tr td:nth-child(5) {
    text-align: center;
    border-right: 1px solid #d5d5d5 !important;
  }

  .table_full_comapare .table tr td:nth-child(6) {
    text-align: center;
    border-right: 1px solid #fff !important;
  }

  .table_full_comapare .table tbody tr:last-child td {
    border-bottom: 1px solid #fff !important;
  }

  .pricing-columns .pricing-chart .plan.plan_nterprise ul,
  .pricing-columns-both .pricing-chart .plan.plan_nterprise ul {
    margin-bottom: 0px;
  }

  .automate_pricing
    .pricing-columns
    .pricing-chart
    .plan:hover
    .view_details
    svg,
  .automate_pricing
    .pricing-columns-both
    .pricing-chart
    .plan:hover
    .view_details
    svg {
    fill: #000;
  }

  .automate_pricing .pricing-columns .pricing-chart .plan a.sign-up,
  .automate_pricing .pricing-columns-both .pricing-chart .plan a.sign-up {
    border-radius: 6px;
  }

  .pricing-columns .pricing-chart .plan:hover .enterprise_message,
  .pricing-columns-both .pricing-chart .plan:hover .enterprise_message {
    color: #000;
  }

  .pricing-columns .pricing-chart .plan:hover p a,
  .pricing-columns-both .pricing-chart .plan:hover p a {
    color: #3366ff;
  }

  .enterprise_wrapper_right button {
    width: 100%;
    border-radius: 6px;
  }

  .automate_pricing thead .tr_bg {
    border: none;
  }

  .automate_pricing .table_full_comapare .table tr td:nth-child(5) {
    text-align: left;
    border-right: 1px solid #d5d5d5 !important;
  }

  .automate_pricing .table_full_comapare .table tr td:nth-child(6) {
    text-align: left;
    border-right: 1px solid #fff !important;
  }

  .am_cards_wrapper .show_morw_option {
    margin-top: 0;
    background: #f4f4f436;
  }
  .double_app_automate_new .am_cards_wrapper.actions_wrapper
  .am_cards_column_left:first-child
  .show_morw_option {
  background: #f4f4f4 0 0 no-repeat padding-box;
  padding-bottom: 0px;
}
  .am_cards_wrapper.actions_wrapper
    .am_cards_column_left:first-child
    .show_morw_option {
    background: #f4f4f4 0 0 no-repeat padding-box;
    padding-bottom: 36px;
  }


  .am_cards_wrapper.actions_wrapper
    .am_cards_column_left:last-child
    .show_morw_option {
    background: #f4f4f4c4 0 0 no-repeat padding-box;
    padding-bottom: 0px;
  }

  .show_morw_option{
    padding-bottom: 24px !important;
  }
  .am_cards_wrapper .am_cards_column.d-flex.first_col {
    padding-bottom: 0px;
  }

  .am_cards_wrapper .am_cards_column.d-flex.explore_workflows {
    padding-bottom: 0px;
  }

  .apps_left_mob {
    width: 100%;
    float: left;
    display: flex;
    height: 300px !important;
  }

  .apps_left_mob .apps_header_left_container_mob {
    width: 100%;
    float: left;
    display: flex;
    border: 1px solid #c4c2c2;
    border-radius: 6px;
    padding: 20px;
    background: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  /* .apps_left_mob .apps_header_left_container_mob p b:first-child {
    color: #000;
  } */

  .apps_left_mob .apps_header_left_container_mob span {
    border: 1px solid #dad9d9;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    background: #fff;
    flex-direction: column;
    width: 53%;
    height: 53%;
    gap: 10px !important;
  }

  .apps_left_mob .apps_header_left_container_mob span img {
    width: 60px;
    height: 60px;
  }

  .apps_left_mob .apps_header_left_container_mob p {
    width: 100%;
    margin-bottom: 0px;
    text-align: center;
  }

  .boarding_wrapper_container .col-md-12.col-lg-9.col-12.pad_left_30 {
    padding-top: 10px;
  }

  .boarding_wrapper_container .col-md-12.col-lg-9.col-12.pad_right_30 {
    padding-top: 10px;
  }

  .ai-self-service-chatbot.it_support_chatbot
    #section2
    .slider_wrapper
    .controls_wrapper {
    display: none;
  }

  .ai-self-service-chatbot.it_support_chatbot #section1 {
    padding-bottom: 0px;
  }

  .ai-self-service-chatbot.it_support_chatbot #section3 {
    padding-bottom: 0px;
  }

  .it_support_chatbot.ai-self-service-chatbot .controls_wrapper {
    font-size: 13px;
  }

  .slack_teams_wrapper .feature_page_content_left .card_link_landing {
    margin-top: 10px;
  }

  .slack_teams_wrapper {
    padding: 0px 5px;
  }

  .integrations_container
    .top_menu_sticky
    .featured_content#section_5
    .slick-slide {
    padding-left: 32px;
  }

  .popular_workflows_ .trial_cards_page .col-12.grid_card_trial {
    padding-left: 3px;
  }

  .integrations_cards_wrapper h5 {
    font-size: 20px;
    color: #000;
    padding-bottom: 10px;
  }

  .integrations_cards_wrapper .card-text {
    font-size: 16px;
    color: #193a5d;
  }

  .integrations_cards_wrapper .card_link {
    font-size: 16px;
    font-family: "Roboto-Medium";
  }

  .Integrations_card .search_marketpalce .search_click {
    font-size: 18px;
    padding: 11px 15px 11px 48px !important;
    color: #818181;
  }

  .Integrations_card .search_marketpalce .search_icon {
    position: absolute;
    left: 6px;
    top: 11px;
    width: 40px;
    height: 46px;
  }

  .marketplace_assistant
    .trial_mb_80.bg_header_trial
    .main-slider-left
    p.basic_plans {
    text-align: left;
  }

  .mob_sticky_wrapper .dropdown.wv-dropdown {
    top: 60px;
  }

  .support_chatbot.autoamte_chatbot {
    margin-bottom: 40px;
  }

  .onprem_connect
    .onprem_cards
    .helpdesk_solutions
    .suprcharging_benefits
    .cogni_workspace_content {
    margin-top: 0px;
  }

  .onprem_connect .cards_wrapper_home .pad_0 {
    padding-left: 0px;
  }

  .container-fluid.p-0.c_a {
    padding: 0px 15px !important;
  }
  .customer-banner-auto-mobile-pricing {
    margin-top: 40px !important;
  }
  .automate_pricing .top_section.top_section_bottom.pricing_page {
    margin-bottom: 0px;
  }
  .new-faq-pricing {
    margin-top: 0px !important;
  }
  .it-help-title{
    margin-top: 20px;
  }
  .it_bot_main_container{
    padding-top: 30px;
  }
}

@media (max-width: 520px) {
  .chat_channels_trial .slack_img1 img {
    /* margin-left: -10px; */
  }

  .chat_channels_trial .teams_img2 img {
    /* margin-left: -25px; */
  }

  .chat_channels_trial .widget_img3 img {
    /* margin-left: -35px; */
  }

  .apps_landing_page .chatbot_align1 {
    justify-content: center;
    margin-top: 10px;
  }

  .apps_landing_page .chatbot_align2 {
    justify-content: center;
    margin-top: 5px;
  } 

  .form_below_content.signup_cta .span_marginleft3 {
     width: 100%;
  }

  .form_below_content.signup_cta .automate-signup-form-span3 {
    width: 100%;
  }

  .center {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .feature_inner_page.bg_feature_slider
    .main-slider-button.beta-button
    .form_below_content {
    justify-content: unset;
  }
}

@media only screen and (min-width: 1200px) {
  .apps_landing_page .chatbot_align1 {
    justify-content: flex-end;
  }

  .apps_landing_page .chatbot_align2 {
    justify-content: start;
  }
}

@media (max-width: 420px) {
  .chat_channels_trial_btn img {
    width: 25px;
    height: 25px;
  }
}

/*slider responsive changes */

@media only screen and (max-width: 520px) {
  .heading_feature {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .heading_feature {
    font-size: 22px;
  }
}

@media screen and (max-width: 1920px) {
  .heading_feature {
    font-size: 32px;
    padding-right: 70px;
  }

  .right_header_content > h5 {
    font-size: 32px;
  }

  .features_content_left .features_content_left_box {
    margin-left: 0%;
  }

  .slider-header-top p,
  .url_manipulation_features_link,
  .right_header_content > p {
    font-size: 20px;
  }
}

@media screen and (max-width: 1440px) {
  .heading_feature {
    font-size: 25px;
    padding-right: 34px;
    line-height: 1.5;
  }

  .onboarding_heading > h2 {
    font-size: 34px;
  }

  .onboarding_heading > h5 {
    font-size: 24px;
  }

  .onboarding_slider_content_header {
    font-size: 26px;
    line-height: 1.2;
  }

  .features_content_left .features_content_left_box {
    margin-left: 0%;
  }

  .slider-header-top p,
  .url_manipulation_features_link,
  .right_header_content > p {
    font-size: 17px;
  }

  .right_header_content > h5 {
    font-size: 27px;
  }

  #Progress_Status1 {
    width: 11.9%;
  }

  #Progress_Status2 {
    width: 18.4%;
  }

  #Progress_Status3 {
    width: 19.5%;
  }

  #Progress_Status4 {
    width: 14.9%;
  }
}

@media (max-width: 5000px) {
  .form_below_content.signup_cta {
    justify-content: flex-start;
  }

  .responsive-p-slider {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1440px) {
  .video_prompt .main-slider-left h3 {
    font-size: 30px;
  }

  .responsive-p-slider {
    font-size: 18px !important;
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 1280px) {
  .heading_feature {
    font-size: 22px;
  }

  .onboarding_heading > h2 {
    font-size: 34px;
  }

  .onboarding_heading > h5 {
    font-size: 26px;
  }

  .onboarding_slider_content_header {
    font-size: 22px;
    line-height: 1.5;
  }

  .right_header_content > h5 {
    font-size: 22px;
  }

  .features_content_left .features_content_left_box {
    margin-left: 0%;
  }

  .slider-header-top p,
  .url_manipulation_features_link,
  .right_header_content > p {
    font-size: 18px;
  }

  #Progress_Status1 {
    width: 11.9%;
  }

  #Progress_Status2 {
    width: 18.4%;
  }

  #Progress_Status3 {
    width: 19.4%;
  }

  #Progress_Status4 {
    width: 14.9%;
  }

  .responsive-p-slider {
    font-size: 18px !important;
    padding-bottom: 0px !important;
  }
}

@media screen and (max-width: 1024px) {
  .heading_feature {
    font-size: 22px;
  }

  .onboarding_heading > h2 {
    font-size: 32px;
  }

  .onboarding_heading > h5 {
    font-size: 24px;
  }

  .onboarding_slider_step_text {
    font-size: 17px;
  }

  .onboarding_slider_step_number {
    font-size: 16px;
  }

  .onboarding_slider_content_header {
    font-size: 20px;
  }

  .right_header_content > h5 {
    font-size: 22px;
  }

  .features_content_left .features_content_left_box {
    margin-left: 0%;
  }

  .slider-header-top p,
  .url_manipulation_features_link,
  .right_header_content > p {
    font-size: 16px;
  }

  #Progress_Status1 {
    width: 11.6%;
  }

  #Progress_Status2 {
    width: 17.3%;
  }

  #Progress_Status3 {
    width: 18.3%;
  }

  #Progress_Status4 {
    width: 13.4%;
  }

  .responsive-p-slider {
    font-size: 14px !important;
  }
}

@media (max-width: 520px) {
  .icons_container > img {
    width: 24px;
    height: 24px;
  }

  .icons_container > h6 {
    font-size: 14px;
    margin-left: 5px;
  }

  .teams_feature_icon {
    margin-left: 20px;
  }

  .chat_feature_icon {
    margin-left: 20px;
  }
}

.font-size-resposive {
  font-size: 24px !important;
}

/* Page Header */

/* 1920  is fine (42px)
1680 is (36px)
1440 is (32px)
1280 is (28px) */


.font-page-header {
  font-size: 30px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media (max-width: 5000px) {
  .font-page-header {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 3440px) {
  .font-page-header {
    font-size: 62px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 3024px) {
  .font-page-header {
    font-size: 57px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 2560px) {
  .font-page-header {
    font-size: 52px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header {
    font-size: 38px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1680px) {
  .font-page-header {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}@media (max-width: 1519px) {
  .font-page-header {
    font-size: 33px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1512px) {
  .font-page-header {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header {
    font-size: 28px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 820px) {
  .font-page-header {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
  .font-page-header-mobile-blog{
    font-family: "Gordita-Bold" !important;
    line-height: 1.5 !important;
    font-size: 32px !important;
  }
}

.font-page-header-cog {
  font-size: 44px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.7 !important;
}

@media (max-width: 5000px) {
  .font-page-header-cog {
    font-size: 42px !important;
    line-height: 1.7 !important;
  }
}
@media (max-width: 3440px) {
  .font-page-header-cog {
    font-size: 62px !important;
    line-height: 1.7 !important;
  }
}
@media (max-width: 3024px) {
  .font-page-header-cog {
    font-size: 57px !important;
    line-height: 1.7 !important;
  }
}
@media (max-width: 2560px) {
  .font-page-header-cog {
    font-size: 52px !important;
    line-height: 1.7 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-cog {
    font-size: 50px !important;
    line-height: 1.7 !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-cog {
    font-size: 45px !important;
    line-height: 1.7 !important;
  }
}
@media (max-width: 1680px) {
  .font-page-header-cog {
    font-size: 44px !important;
    line-height: 1.7 !important;
  }
}
@media (max-width: 1512px) {
  .font-page-header-cog {
    font-size: 38px !important;
    line-height: 1.7 !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-cog {
    font-size: 38px !important;
    line-height: 1.7 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-cog {
    font-size: 38px !important;
    line-height: 1.7 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-cog {
    font-size: 31px !important;
    line-height: 1.7 !important;
  }
}
@media screen and (max-width: 820px) {
  .font-page-header-cog {
    font-size: 24px !important;
    line-height: 1.7 !important;
  }
}

@media screen and (max-width: 520px) {
  .font-page-header-cog {
    font-size: 24px !important;
    line-height: 1.7 !important;
  }
}
/*Home Page Header */

.font-page-header-home {
  font-size: 46px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media (max-width: 5000px) {
  .font-page-header-home {
    font-size: 46px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 3024px) {
  .font-page-header-home {
    font-size: 44px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 2560px) {
  .font-page-header-home {
    font-size: 54px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home {
    font-size: 41px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1680px) {
  .font-page-header-home {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1512px) {
  .font-page-header-home {
    font-size: 38px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-page-header-home {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home {
    font-size: 27px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home {
    font-size: 26px !important;
    line-height: 1.5 !important;
  }
}

.font-page-header-home-normal {
  font-size: 46px !important;
  font-family: "Gordita-Normal" !important;
  line-height: 1.5 !important;
}

@media (max-width: 5000px) {
  .font-page-header-home-normal {
    font-size: 46px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 3024px) {
  .font-page-header-home-normal {
    font-size: 44px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 2560px) {
  .font-page-header-home-normal {
    font-size: 54px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-normal {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home-normal {
    font-size: 41px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1680px) {
  .font-page-header-home-normal {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1512px) {
  .font-page-header-home-normal {
    font-size: 38px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-page-header-home-normal {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-normal {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-normal {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-normal {
    font-size: 27px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-normal {
    font-size: 26px !important;
    line-height: 1.5 !important;
  }
}
.font-page-header-home-blog-employee {
  font-size: 94px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.6 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog-employee {
    font-size: 90px !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog-employee {
    font-size: 78px !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home-blog-employee {
    font-size: 76px !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-home-blog-employee {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 1536px) {
  .font-page-header-home-blog-employee {
    font-size: 66px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-home-blog-employee {
    font-size: 64px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-home-blog-employee {
    font-size: 60px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog-employee {
    font-size: 54px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog-employee {
    font-size: 46px !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog-employee {
    font-size: 40px !important;
  }
}

.font-page-header-home-blog-smb {
  font-size: 94px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.6 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog-smb {
    font-size: 90px !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog-smb {
    font-size: 68px !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home-blog-smb {
    font-size: 66px !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-home-blog-smb {
    font-size: 60px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-home-blog-smb {
    font-size: 54px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-home-blog-smb {
    font-size: 50px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog-smb {
    font-size: 46px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog-smb {
    font-size: 40px !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog-smb {
    font-size: 32px !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-blog-smb {
    font-size: 34px !important;
    line-height: 1.6 !important;
  }
}

.font-page-header-blog-gen-ai {
  font-size: 56px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 2 !important;
}
@media (max-width: 3024px) {
  .font-page-header-blog-gen-ai {
    font-size: 60px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-blog-gen-ai {
    font-size: 58px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1720px) {
  .font-page-header-blog-gen-ai {
    font-size: 56px !important;
    line-height: 1.5 !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-blog-gen-ai {
    font-size: 54px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-blog-gen-ai {
    font-size: 52px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-blog-gen-ai{
    font-size: 50px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-blog-gen-ai {
    font-size: 46px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-blog-gen-ai {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-blog-gen-ai {
    font-size: 43px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-blog-gen-ai {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}
.font-page-header-blog-gen-ai-normal {
  font-size: 56px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 2 !important;
}
@media (max-width: 3024px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 60px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 58px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1720px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 56px !important;
    line-height: 1.5 !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 54px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 52px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-blog-gen-ai-normal{
    font-size: 50px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 46px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 43px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-blog-gen-ai-normal {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}
.font-page-header-home-blog {
  font-size: 46px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 2 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog {
    font-size: 48px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog {
    font-size: 46px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1720px) {
  .font-page-header-home-blog {
    font-size: 44px !important;
    line-height: 1.5 !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-home-blog {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-home-blog {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-home-blog {
    font-size: 38px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog {
    font-size: 34px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog {
    font-size: 34px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog {
    font-size: 33px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-blog {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}

.font-page-header-home-blog-normal {
  font-size: 46px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 2 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog-normal {
    font-size: 48px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog-normal {
    font-size: 46px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1720px) {
  .font-page-header-home-blog-normal {
    font-size: 44px !important;
    line-height: 1.5 !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-home-blog-normal {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-home-blog-normal {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-home-blog-normal {
    font-size: 38px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog-normal {
    font-size: 34px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog-normal {
    font-size: 34px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog-normal {
    font-size: 33px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-blog-normal {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}

.font-page-header-home-blog-bigger {
  font-size: 94px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog-bigger {
    font-size: 94px !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog-bigger {
    font-size: 84px !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home-blog-bigger {
    font-size: 80px !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-home-blog-bigger {
    font-size: 76px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-home-blog-bigger {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-home-blog-bigger {
    font-size: 66px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog-bigger {
    font-size: 60px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog-bigger {
    font-size: 54px !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog-bigger {
    font-size: 50px !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-blog-bigger {
    font-size: 34px !important;
    line-height: 1.5 !important;
  }
}

.font-page-header-home-blog-new {
  font-size: 94px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.6 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog-new {
    font-size: 84px !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog-new {
    font-size: 70.45px !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home-blog-new {
    font-size: 66.45px !important;
  }
}
@media (max-width: 1680px) {
  .font-page-header-home-blog-new {
    font-size: 64.45px !important;
  }
}
@media (max-width: 1536px) {
  .font-page-header-home-blog-new {
    font-size: 63.45px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-home-blog-new {
    font-size: 60.45px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-home-blog-new {
    font-size: 56.45px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog-new {
    font-size: 56.45px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog-new  {
    font-size: 50.45px !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog-new  {
    font-size: 46.45px !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-blog-new  {
    font-size: 34px !important;
  }
}

.font-page-header-home-blog-bigger-normal {
  font-size: 94px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 94px !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 84px !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 80px !important;
  }
}

@media (max-width: 1680px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 76px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 70px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 66px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 60px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 54px !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 50px !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-blog-bigger-normal {
    font-size: 34px !important;
  }
}
.font-page-header-home-blog-gpt  {
  font-size: 46px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 2 !important;
}
@media (max-width: 3024px) {
  .font-page-header-home-blog-gpt {
    font-size: 48px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-home-blog-gpt {
    font-size: 46px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-home-blog-gpt  {
    font-size: 44px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1680px) {
  .font-page-header-home-blog-gpt  {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1512px) {
  .font-page-header-home-blog-gpt  {
    font-size: 38px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-page-header-home-blog-gpt  {
    font-size: 34px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-home-blog-gpt  {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-home-blog-gpt  {
    font-size: 28px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-home-blog-gpt {
    font-size: 25px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-home-blog-gpt {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}

/* Page Header Automate */

.font-page-header-automate {
  font-size: 30px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media (max-width: 5000px) {
  .font-page-header-automate {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 3024px) {
  .font-page-header-automate {
    font-size: 40px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-page-header-automate {
    font-size: 38px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1728px) {
  .font-page-header-automate {
    font-size: 37px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1680px) {
  .font-page-header-automate {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1512px) {
  .font-page-header-automate {
    font-size: 33px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-page-header-automate {
    font-size: 30px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-page-header-automate {
    font-size: 28px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-page-header-automate {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-page-header-automate {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-page-header-automate {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}

/* Section Header */
/* 1920  is fine (42px)
1680 is (36px)
1440 is (32px)
1280 is (28px) */

.font-section-header {
  font-size: 34px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media (max-width: 5000px) {
  .font-section-header {
    font-size: 42px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 3440px) {
  .font-section-header {
    font-size: 58px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 3024px) {
  .font-section-header {
    font-size: 56px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 2560px) {
  .font-section-header {
    font-size: 44px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1920px) {
  .font-section-header {
    font-size: 34px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1728px) {
  .font-section-header {
    font-size: 33px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 1680px) {
  .font-section-header {
    font-size: 32px !important;
    line-height: 1.5 !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-header {
    font-size: 31px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-section-header {
    font-size: 30px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-header {
    font-size: 28px !important;
    line-height: 1.5 !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-header {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 820px) {
  .font-section-header {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}
@media (max-width: 520px) {
  .font-section-header {
    font-size: 24px !important;
    line-height: 1.5 !important;
  }
}

/*  Sub header - layer::2 */

/* Sub header ;
1920  is fine (36px)
1680 is (32px)
1440 is (30px)
1280 is (28px)
*/

.font-section-sub-header {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header {
    font-size: 54px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header {
    font-size: 51px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header {
    font-size: 48px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-section-sub-header {
    font-size: 24px !important;
  }
}

.font-section-sub-header-normal {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-normal {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-normal {
    font-size: 54px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-normal {
    font-size: 51px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-normal {
    font-size: 48px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header-normal {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-normal {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-normal {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-normal {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-normal {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-normal {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-normal {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header-normal {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-sub-header-normal {
    font-size: 24px !important;
  }
}
.font-section-sub-header-blog-title {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 2 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-blog-title {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-blog-title {
    font-size: 34px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-blog-title {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-blog-title {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-blog-title {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-blog-title {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-blog-title {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-blog-title {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-blog-title {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header-blog-title {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-sub-header-blog-title {
    font-size: 22px !important;
  }
}

.font-section-sub-header-big-landing {
  font-size: 40px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5010px) {
  .font-section-sub-header-big-landing {
    font-size: 46px !important;
  }
}
@media screen and (max-width: 3034px) {
  .font-section-sub-header-big-landing {
    font-size: 44px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-big-landing {
    font-size: 56px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-big-landing {
    font-size: 52px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-big-landing {
    font-size: 51px !important;
  }
}
@media screen and (max-width: 1519px) {
  .font-section-sub-header-big-landing {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-big-landing {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-big-landing {
    font-size: 42px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-big-landing {
    font-size: 42px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-big-landing {
    font-size: 39px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header-big-landing {
    font-size: 33px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-sub-header-big-landing {
    font-size: 34px !important;
  }
}

.font-section-sub-header-big-landing-bold {
  font-size: 40px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5010px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 46px !important;
  }
}
@media screen and (max-width: 3034px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 44px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 56px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 52px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 51px !important;
  }
}
@media screen and (max-width: 1519px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 42px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 42px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 39px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 33px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-sub-header-big-landing-bold {
    font-size: 34px !important;
  }
}
.font-section-medium-bigger{
  font-size: 56px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5010px) {
  .font-section-medium-bigger{
    font-size: 118px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-medium-bigger {
    font-size: 140px !important;
 }
}
@media screen and (max-width: 3034px) {
  .font-section-medium-bigger {
    font-size: 116px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-medium-bigger {
    font-size: 106px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-medium-bigger {
    font-size: 78px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-medium-bigger {
    font-size: 69px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-medium-bigger {
    font-size: 67px !important;
  }
}
@media screen and (max-width: 1519px) {
  .font-section-medium-bigger {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-medium-bigger {
    font-size: 55px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-medium-bigger {
    font-size: 56px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-medium-bigger {
    font-size: 55px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-medium-bigger {
    font-size: 52px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-medium-bigger {
    font-size: 44px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-medium-bigger{
    font-size: 34px !important;
  }
}
@media (max-width: 520px) {
  .card-box-section-container .font-section-sub-header {
    font-size: 23px !important;
  }
  .font-section-sub-header {
    font-size: 20px !important;
  }
  .font-sub-header-mobile-blog{
    font-family: "Gordita-Medium" !important;
    line-height: 1.5 !important;
    font-size: 22px !important;
  }
}
.font-section-sub-header-blog {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 2 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-blog {
    font-size: 33px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-blog {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header-blog {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-blog {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-blog {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-blog {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-blog {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-blog {
    font-size: 23px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-blog {
    font-size: 23px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-blog {
     font-size: 23px !important;
     line-height: 1.7 !important;
   }
 }
@media (max-width: 520px) {
 .font-section-sub-header-blog {
    font-size: 23px !important;
    line-height: 1.7 !important;
  }
}
.font-section-sub-header-slider {
  font-size: 28px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-slider {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-slider {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header-slider {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-slider {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-slider {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-slider {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-slider {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-slider {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-slider {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header-slider {
    font-size: 18px !important;
  }
}
  @media screen and (max-width: 520px) {
    .font-section-sub-header-slider {
      font-size: 17px !important;
    }
}

.font-section-sub-header-slider-cta {
  font-size: 28px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-slider-cta {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-slider-cta {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header-slider-cta {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-slider-cta {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-slider-cta {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-slider-cta {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-slider-cta {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-slider-cta {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-slider-cta {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header-slider-cta {
    font-size: 15px !important;
  }
}
  @media screen and (max-width: 520px) {
    .font-section-sub-header-slider-cta {
      font-size: 18px !important;
    }
}

/*font sub header automate */

.font-section-sub-header-automate {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-automate {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-automate {
    font-size: 32px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-automate {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-automate {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-automate {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-automate {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-automate {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-automate {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-automate {
    font-size: 24px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-automate {
    font-size: 23px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-automate {
    font-size: 22px !important;
  }
}

/* Sub header ; - normal - bold
1920  is fine (36px)
1680 is (32px)
1440 is (30px)
1280 is (28px)
*/

.font-section-sub-header-bold {
  font-size: 36px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-bold {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-bold {
    font-size: 56px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-bold {
    font-size: 52px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-bold {
    font-size: 46px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-bold {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-bold {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-bold {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-bold {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-bold {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-bold {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-bold {
    font-size: 28px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-bold {
    font-size: 25px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-bold {
    font-size: 24px !important;
  }
}


.font-section-sub-header-bold-demo {
  font-size: 36px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-bold-demo {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-bold-demo {
    font-size: 48px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-bold-demo {
    font-size: 46px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-bold-demo {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-bold-demo {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-bold-demo {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-bold-demo {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-bold-demo {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-bold-demo {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-bold-demo {
    font-size: 28px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-bold {
    font-size: 25px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-bold {
    font-size: 24px !important;
  }
}


@media (max-width: 520px) {
  .font-section-cta-mobile-sub-header{
    font-size: 26px !important;
  }
}

.font-section-sub-header-bold-goto-case {
  font-size: 31px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;

}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 3440px){
  .font-section-sub-header-bold-goto-case {
      font-size: 50px !important;
   }
  }
@media screen and (max-width: 3024px){
    .font-section-sub-header-bold-goto-case {
        font-size: 45px !important;
     }
}
@media screen and (max-width: 2560px){
.font-section-sub-header-bold-goto-case {
    font-size: 40px !important;
 }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 21px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 25px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 24px !important;
  }
}

@media (max-width: 375px) {
  .font-section-sub-header-bold-goto-case {
    font-size: 22px !important;
  }
}


/* Sub header ; - normal - bold
1920  is fine (36px)
1680 is (30px)
1440 is (24px)
1280 is (20px)
*/

.font-section-sub-header-bold-v2 {
  font-size: 36px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-bold-v2 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-bold-v2 {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-bold-v2 {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-bold-v2 {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-bold-v2 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-bold-v2 {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-bold-v2 {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-bold-v2 {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-bold-v2 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-bold-v2 {
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-bold-v2 {
    font-size: 14px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-bold-v2 {
    font-size: 15px !important;
  }
}
@media (max-width: 375px) {
  .font-section-sub-header-bold-v2 {
    font-size: 14px !important;
  }
}
.font-section-bold-cta-blog{
  font-size: 36px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
}

@media screen and (max-width: 5000px) {
  .font-section-bold-cta-blog {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-bold-cta-blog{
    font-size: 42px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-bold-cta-blog {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-bold-cta-blog{
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-bold-cta-blog{
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-bold-cta-blog{
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-bold-cta-blog{
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-bold-cta-blog {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-bold-cta-blog {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-bold-cta-blog{
    font-size: 20px !important;
  }
}
@media (max-width: 820px) {
  .font-section-bold-cta-blog {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-bold-cta-blog {
    font-size: 24px !important;
  }
}
.font-section-medium-cta-blog {
  font-size: 36px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media screen and (max-width: 5000px) {
  .font-section-medium-cta-blog{
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-medium-cta-blog{
    font-size: 42px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-medium-cta-blog {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-medium-cta-blog{
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-medium-cta-blog{
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-medium-cta-blog{
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-medium-cta-blog{
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-medium-cta-blog{
    font-size: 17px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-medium-cta-blog{
    font-size: 17px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-medium-cta-blog{
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-medium-cta-blog {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-medium-cta-blog{
    font-size: 17px !important;
  }
}
.font-text-medium-goto-solution {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}


@media screen and (max-width: 5000px) {
  .font-text-medium-goto-solution{
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-text-medium-goto-solution{
    font-size: 32px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-text-medium-goto-solution {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-text-medium-goto-solution {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-text-medium-goto-solution {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-text-medium-goto-solution{
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-text-medium-goto-solution{
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-text-medium-goto-solution {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-text-medium-goto-solution {
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-text-medium-goto-solution {
    font-size: 18px !important;
  }
}
@media (max-width: 520px) {
  .font-text-medium-goto-solution {
    font-size: 18px !important;
  }
}

.font-text-medium-apps-ci {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-text-medium-apps-ci {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-text-medium-apps-ci {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-text-medium-apps-ci {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-text-medium-apps-ci {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-text-medium-apps-ci {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-text-medium-apps-ci {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-text-medium-apps-ci {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-text-medium-apps-ci  {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-text-medium-apps-ci {
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-text-medium-apps-ci {
    font-size: 21px !important;
  }
}
@media (max-width: 520px) {
  .font-text-medium-apps-ci {
    font-size: 20px !important;
  }
}

/* Sub header - small
1920  is (30px)
1680 is (26px)
1440 is (22px)
1280 is (20px)
*/

.font-section-sub-header-smaller-bold-testimonials {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 44px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 42px !important;
  }
}

@media screen and (max-width: 2560px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1519px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 20px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-smaller-bold-testimonials  {
    font-size: 18px !important;
  }
}



.font-section-sub-header-smaller-blog{
  font-size: 20px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-smaller-blog  {
    font-size: 44px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-smaller-blog {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 2560px) {
  .font-section-sub-header-smaller-blog {
    font-size: 36px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-smaller-blog {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-smaller-blog  {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-smaller-blog {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-smaller-blog {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-smaller-blog  {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-smaller-blog {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-smaller-blog {
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-smaller-blog  {
    font-size: 14px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-smaller-blog  {
    font-size: 13px !important;
  }
}
.font-section-sub-header-small {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-small {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-small {
    font-size: 46px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-small {
    font-size: 43px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-small {
    font-size: 40px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header-small {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-small {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1680px) {
  .font-section-sub-header-small {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-small {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-small {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-small {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-small {
    font-size: 20px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-small {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-small {
    font-size: 18px !important;
  }
}

@media (max-width: 520px) {
  .font-section-cta-mobile-sub-header-small{
    font-size: 20px !important;
  }
}

.font-section-sub-header-small-home {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-small-home {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-small-home {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-small-home {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 2560px) {
  .font-section-sub-header-small-home {
    font-size: 36px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-small-home {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-small-home {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-small-home {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-small-home {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-small-home {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-small-home {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-small-home {
    font-size: 20px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-small-home {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-small-home {
    font-size: 18px !important;
  }
}

.font-section-sub-header-small-home-cta{
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.8 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-small-home-cta {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-small-home-cta {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-small-home-cta {
    font-size: 38px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-small-home-cta {
    font-size: 36px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-small-home-cta {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-small-home-cta {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-small-home-cta {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-small-home-cta {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-small-home-cta {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-small-home-cta {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-small-home-cta {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-sub-header-small-home-cta {
    font-size: 21px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-small-home-cta {
    font-size: 20px !important;
  }
}

/* Bold sub header - layer::3 */
/* H4
Gordita Bold
1920: 30px
1680: 28px 
1440: 26px 
1280: 24px */

.font-section-sub-header-small-bold {
  font-size: 30px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-small-bold {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-small-bold {
    font-size: 45px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-small-bold {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-small-bold {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-small-bold {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-small-bold {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-small-bold {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-small-bold {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-small-bold {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-small-bold {
    font-size: 20px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-small-bold {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-small-bold {
    font-size: 18px !important;
  }
}

/* Bold sub header - layer::3 */
/* H4
Gordita Bold
1920: 30px
1680: 28px 
1440: 26px 
1280: 24px */

.font-section-sub-header-small-bold-v2 {
  font-size: 30px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3440px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 48px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 35px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 20px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-small-bold-v2 {
    font-size: 18px !important;
  }
}

/*  Normal text as header - layer::4 */

.font-section-normal-text-medium {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-section-normal-text-medium {
    font-size: 40px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-normal-text-medium {
    font-size: 36px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-medium {
    font-size: 32px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-normal-text-medium {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-medium {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-medium {
    font-size: 18px !important;
  }
}
@media (max-width: 1519px) {
  .font-section-normal-text-medium {
    font-size: 16px !important;
  }
}
@media (max-width: 1512px) {
  .font-section-normal-text-medium {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-medium {
    font-size: 16px !important;
  }
  .it-help-page .support_switch_heading_click, .it-help-page .support_switch_heading{
    padding: 7px 20px 5px;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-medium {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-medium {
    font-size: 16px !important;
  }
  .font-section-app-workflow.font-section-normal-text-medium{
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-medium {
    font-size: 16px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-medium {
    font-size: 16px !important;
  }
}
.font-section-text-link{
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-section-text-link {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-text-link {
    font-size: 33px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-text-link {
    font-size: 32px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-text-link {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-text-link {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-text-link {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-text-link {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-text-link {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-text-link {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-text-link {
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-text-link {
    font-size: 14px !important;
  }
}
@media (max-width: 520px) {
  .font-section-text-link {
    font-size: 14px !important;
  }
}
/*Normal Text Medium Link */

.font-section-normal-text-medium-link {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-section-normal-text-medium-link {
    font-size:44px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-normal-text-medium-link {
    font-size: 40px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-medium-link {
    font-size:36px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-normal-text-medium-link {
    font-size: 22px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-medium-link {
    font-size: 21px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-medium-link {
    font-size: 20px !important;
  }
}
@media (max-width: 1519px) {
  .font-section-normal-text-medium-link {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-medium-link {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-section-normal-text-medium-link {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-medium-link {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-medium-link {
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-medium-link {
    font-size: 17px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-medium-link {
    font-size: 16px !important;
  }
}

.font-section-normal-text-medium-small {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-section-normal-text-medium-small {
    font-size:36px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-normal-text-medium-small {
    font-size:32px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-medium-small {
    font-size:28px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-normal-text-medium-small {
    font-size: 18px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-medium-small {
    font-size: 17px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-medium-small {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-medium-small {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-medium-small {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-medium-small {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-medium-small {
    font-size: 12px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-medium-small {
    font-size: 11px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-medium-small {
    font-size: 10px !important;
  }
}
.font-section-normal-text-medium-customer-banner {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 44px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 40px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 38px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 22px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 21px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 18px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-medium-customer-banner {
    font-size: 18px !important;
  }
}

.font-section-normal-text-medium-pricing {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3024px) {
  .font-section-normal-text-medium-pricing {
    font-size: 32px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-medium-pricing {
    font-size: 30px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-normal-text-medium-pricing {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-medium-pricing {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-medium-pricing {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-medium-pricing {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-medium-pricing {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-medium-pricing {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-medium-pricing {
    font-size: 16px !important;
  }
}

@media (max-width: 820px) {
  .font-section-normal-text-medium-pricing {
    font-size: 13px !important;
  }
}

@media (max-width: 520px) {
  .font-section-normal-text-medium-pricing {
    font-size: 12px !important;
  }
}

.font-section-normal-text {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-section-normal-text {
    font-size: 42px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-normal-text {
    font-size: 36px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text {
    font-size: 32px !important;
  }
}
@media (max-width: 2220px) {
  .font-section-normal-text {
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-section-normal-text {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text {
    font-size: 16px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text {
    font-size: 16px !important;
  }
}

@media (max-width: 520px) {
  .font-section-normal-text {
    font-size: 16px !important;
  }
}
.font-easy-normal-text {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-easy-normal-text {
    font-size: 42px !important;
  }
}
@media (max-width: 3024px) {
  .font-easy-normal-text {
    font-size: 36px !important;
  }
}
@media (max-width: 2560px) {
  .font-easy-normal-text {
    font-size: 32px !important;
  }
}
@media (max-width: 2220px) {
  .font-easy-normal-text {
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-easy-normal-text {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-easy-normal-text {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-easy-normal-text{
    font-size: 18px !important;
  }
}
@media (max-width: 1512px) {
  .font-easy-normal-text {
    font-size: 16px !important;
  }
}

@media (max-width: 1440px) {
  .font-easy-normal-text{
    font-size: 16px !important;
  }
}

@media (max-width: 1280px) {
  .font-easy-normal-text {
    font-size: 16px !important;
  }
}

@media (max-width: 1024px) {
  .font-easy-normal-text{
    font-size: 13px !important;
  }
}
@media (max-width: 820px) {
  .font-easy-normal-text {
    font-size: 11px !important;
  }
}
@media (max-width: 520px) {
  .font-easy-normal-text{
    font-size: 14px !important;
  }
}
@media (max-width: 400px) {
  .font-easy-normal-text{
    font-size: 13px !important;
  }
}
.font-section-bold-small-text {
  font-size: 34px !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3440px) {
  .font-section-bold-small-text{
    font-size: 42px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-bold-small-text {
    font-size: 38px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-bold-small-text {
    font-size: 34px !important;
  }
}
@media (max-width: 2220px) {
  .font-section-bold-small-text{
    font-size: 30px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-bold-small-text {
    font-size: 30px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-bold-small-text {
    font-size: 27px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-bold-small-text {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-bold-small-text{
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-section-bold-small-text{
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-bold-small-text{
    font-size: 22px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-bold-small-text {
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-section-bold-small-text{
    font-size: 17px !important;
  }
}

@media (max-width: 520px) {
  .font-section-bold-small-text {
    font-size: 16px !important;
  }
}
.font-section-normal-text-case-study {
  font-size: 23px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3024px) {
  .font-section-normal-text-case-study {
    font-size: 26px !important;
  }
}

@media (max-width: 2220px) {
  .font-section-normal-text-case-study {
    font-size: 23px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-case-study {
    font-size: 23px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-case-study {
    font-size: 22px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-case-study {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-case-study {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-section-normal-text-case-study {
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-case-study {
    font-size: 19px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-case-study {
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-case-study {
    font-size: 19px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-case-study {
    font-size: 19px !important;
  }
}



.font-goto-testi-normal {
  font-size: 34px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.7 !important;
}
.font-goto-testi-normal-medium{
  font-size: 34px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3024px) {
  .font-goto-testi-normal {
    font-size: 34px !important;
  }
  .font-goto-testi-normal-medium {
    font-size: 34px !important;
  }
}
@media (max-width: 2220px) {
  .font-goto-testi-normal{
    font-size: 32px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 32px !important;
  }
}

@media (max-width: 1920px) {
  .font-goto-testi-normal{
    font-size: 29px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 29px !important;
  }
}
@media (max-width: 1728px) {
  .font-goto-testi-normal{
    font-size: 26px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 26px !important;
  }
}
@media (max-width: 1680px) {
  .font-goto-testi-normal{
    font-size: 25px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-goto-testi-normal{
    font-size: 21px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-goto-testi-normal{
    font-size: 21px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-goto-testi-normal{
    font-size: 21px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 21px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-goto-testi-normal{
    font-size: 18px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 18px !important;
  }
}
@media (max-width: 820px) {
  .font-goto-testi-normal{
    font-size: 15px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 15px !important;
  }
}
@media (max-width: 520px) {
  .font-goto-testi-normal{
    font-size: 16px !important;
  }
  .font-goto-testi-normal-medium{
    font-size: 16px !important;
  }
}
.font-section-normal-text-goto-popup {
  font-size: 23px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.7 !important;
}
@media (max-width: 3024px) {
  .font-section-normal-text-goto-popup {
    font-size: 25px !important;
  }
}
@media (max-width: 2220px) {
  .font-section-normal-text-goto-popup {
    font-size: 23px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-goto-popup  {
    font-size: 23px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-goto-popup  {
    font-size: 23px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-goto-popup  {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-goto-popup  {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-goto-popup  {
    font-size: 23px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-goto-popup  {
    font-size: 23px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-goto-popup  {
    font-size: 23px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-goto-popup  {
    font-size: 21px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-goto-popup  {
    font-size: 20px !important;
  }
}

.font-section-normal-text-testimonials {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
}
@media (max-width: 3440px) {
  .font-section-normal-text-testimonials {
    font-size: 38px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-normal-text-testimonials {
    font-size: 35px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-testimonials {
    font-size: 32px !important;
  }
}

@media (max-width: 2220px) {
  .font-section-normal-text-testimonials {
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-testimonials {
    font-size: 21px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-testimonials {
    font-size: 20px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-testimonials {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1519px) {
  .font-section-normal-text-testimonials {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-testimonials {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-testimonials {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-testimonials {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-testimonials {
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-testimonials {
    font-size: 15px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-testimonials {
    font-size: 15px !important;
  }
}
.font-section-normal-text-single-app {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
}
@media (max-width: 3024px) {
  .font-section-normal-text-single-app {
    font-size: 24px !important;
  }
}
@media (max-width: 2220px) {
  .font-section-normal-text-single-app {
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-single-app {
    font-size: 21px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-single-app {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-single-app {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-single-app {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-single-app {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-single-app {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-single-app {
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-single-app {
    font-size: 15px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-single-app {
    font-size: 15px !important;
  }
}
.font-section-normal-text-testimonials-medium {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
}
@media (max-width: 3440px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 38px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 35px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 32px !important;
  }
}

@media (max-width: 2220px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 21px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 20px !important;
  }
}

@media (max-width: 1680px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 20px !important;
  }
}
@media (max-width: 1519px) {
.font-section-normal-text-testimonials-medium {
  font-size: 18px !important;
}
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 15px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-testimonials-medium  {
    font-size: 15px !important;
  }
}

.font-section-normal-text-resources {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
}
@media (max-width: 3024px) {
  .font-section-normal-text-resources {
    font-size: 28px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-resources {
    font-size: 26px !important;
  }
}

@media (max-width: 2220px) {
  .font-section-normal-text-resources {
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-resources {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-resources  {
    font-size: 18px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-normal-text-resources  {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-resources  {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-section-normal-text-resources  {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-resources  {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-resources  {
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-resources  {
    font-size: 15px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-resources  {
    font-size: 15px !important;
  }
}

.font-section-normal-text-resources-medium {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
}
@media (max-width: 3024px) {
  .font-section-normal-text-resources-medium {
    font-size: 30px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-normal-text-resources-medium {
    font-size: 30px !important;
  }
}

@media (max-width: 2220px) {
  .font-section-normal-text-resources-medium {
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-normal-text-resources-medium {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-normal-text-resources-medium  {
    font-size: 18px !important;
  }
}

@media (max-width: 1680px) {
  .font-section-normal-text-resources-medium  {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-resources-medium  {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-resources-medium  {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-resources-medium  {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-normal-text-resources-medium  {
    font-size: 14px !important;
  }
}
@media (max-width: 820px) {
  .font-section-normal-text-resources-medium  {
    font-size: 15px !important;
  }
}
@media (max-width: 520px) {
  .font-section-normal-text-resources-medium  {
    font-size: 15px !important;
  }
}

.font-section-small-text {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3440px) {
  .font-section-small-text {
    font-size: 36px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-small-text {
    font-size: 32px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-small-text {
    font-size: 28px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-small-text {
    font-size: 18px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-text {
    font-size: 17px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1519px) {
  .font-section-small-text {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-text {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-text {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-text {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-text {
    font-size: 12px !important;
  }
}

@media (max-width: 820px) {
  .font-section-small-text {
    font-size: 14px !important;
  }
}

@media (max-width: 520px) {
  .font-section-small-text {
    font-size: 14px !important;
  }
}


.font-section-small-text-ai-self {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3440px) {
  .font-section-small-text-ai-self {
    font-size: 28px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-small-text-ai-self {
    font-size: 24px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-small-text-ai-self {
    font-size: 19px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-text-ai-self {
    font-size: 18px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-text-ai-self {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-text-ai-self {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-section-small-text-ai-self {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-text-ai-self {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-text-ai-self {
    font-size: 12px !important;
  }
}
@media (max-width: 820px) {
  .font-section-small-text-ai-self {
    font-size: 14px !important;
  }
}

@media (max-width: 520px) {
  .font-section-small-text-ai-self {
    font-size: 14px !important;
  }
}

.font-section-small-signup-form{
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-section-small-signup-form {
    font-size: 16px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-small-signup-form {
    font-size: 14px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-signup-form {
    font-size: 13px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-signup-form {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-signup-form {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-signup-form {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-signup-form {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-signup-form {
    font-size: 9px !important;
  }
}
@media (max-width: 820px) {
  .font-section-small-signup-form {
    font-size: 11px !important;
  }
}
@media (max-width: 520px) {
  .font-section-small-signup-form {
    font-size: 11px !important;
  }
}


.font-section-small-signup-form-medium{
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-section-small-signup-form-medium {
    font-size: 16px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-small-signup-form-medium {
    font-size: 14px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-signup-form-medium {
    font-size: 13px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-signup-form-medium {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-signup-form-medium {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-signup-form-medium {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-signup-form-medium {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-signup-form-medium {
    font-size: 9px !important;
  }
}
@media (max-width: 820px) {
  .font-section-small-signup-form-medium {
    font-size: 11px !important;
  }
}
@media (max-width: 520px) {
  .font-section-small-signup-form-medium {
    font-size: 11px !important;
  }
}
/*  small text as bold*/

.font-section-small-text-medium {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media (max-width: 3440px) {
  .font-section-small-text-medium {
    font-size: 36px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-small-text-medium {
    font-size: 30px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-small-text-medium {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-text-medium {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-text-medium {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-text-medium {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-text-medium {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-text-medium {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-text-medium {
    font-size: 16px !important;
  }
}
@media (max-width: 820px) {
  .font-section-small-text-medium {
    font-size: 15px !important;
  }
}
@media (max-width: 520px) {
  .font-section-small-text-medium {
    font-size: 14px !important;
  }
}
.font-section-small-text-medium-apps-heading {
  font-size: 19px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3440px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 38px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 34px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 30px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 17px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 16px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 13px !important;
  }
}
@media (max-width: 820px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 13px !important;
  }
}
@media (max-width: 520px) {
  .font-section-small-text-medium-apps-heading {
    font-size: 13px !important;
  }
}

.font-section-small-text-medium-apps {
  font-size: 19px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3440px) {
  .font-section-small-text-medium-apps {
    font-size: 37px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-small-text-medium-apps {
    font-size: 32px !important;
  }
}
@media (max-width: 2560px) {
  .font-section-small-text-medium-apps {
    font-size: 28px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-small-text-medium-apps {
    font-size: 19px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-text-medium-apps {
    font-size: 18px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-text-medium-apps {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1519px) {
  .font-section-small-text-medium-apps {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-text-medium-apps {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-text-medium-apps {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-text-medium-apps {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-text-medium-apps {
    font-size: 15px !important;
  }
  .all-feature-font-size.font-section-small-text-medium-apps{
    font-size: 13px !important;
  } 
}
@media (max-width: 820px) {
  .font-section-small-text-medium-apps {
    font-size: 14px !important;
  }
}
@media (max-width: 520px) {
  .font-section-small-text-medium-apps {
    font-size: 13px !important;
  }
}
/*case study goto */
.font-section-small-text-medium-case {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-section-small-text-medium-case {
    font-size: 24px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-small-text-medium-case {
    font-size: 20px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-text-medium-case {
    font-size: 19px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-text-medium-case {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-text-medium-case{
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-text-medium-case{
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-text-medium-case {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-text-medium-case {
    font-size: 16px !important;
  }
}
@media (max-width: 820px) {
  .font-section-small-text-medium-case {
    font-size: 13px !important;
  }
}

@media (max-width: 520px) {
  .font-section-small-text-medium-case {
    font-size: 12px !important;
  }
}

/*MarketPlace Small Text */
.font-name-smaller-text {
  font-family: "Gordita-Regular" !important;
  line-height: 1.5;
}
@media screen and (max-width: 3024px) {
  .font-name-smaller-text {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-name-smaller-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-name-smaller-text {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-name-smaller-text {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-name-smaller-text {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-name-smaller-text {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-name-smaller-text {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-name-smaller-text {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-name-smaller-text {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-name-smaller-text {
    font-size: 12px !important;
  }
}

/*dialog box marketplace font */
.font-name-small-dialog-marketplace {
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-name-small-dialog-marketplace {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-name-small-dialog-marketplace {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-name-small-dialog-marketplace {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-name-small-dialog-marketplace {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-name-small-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-name-small-dialog-marketplace {
    font-size: 9px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-name-small-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-name-small-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-name-small-dialog-marketplace {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-name-small-dialog-marketplace {
    font-size: 8px !important;
  }
}

.font-name-normal-dialog-marketplace {
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-name-normal-dialog-marketplace {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-name-normal-dialog-marketplace {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-name-normal-dialog-marketplace {
    font-size: 9px !important;
  }
}

.font-name-medium-dialog-marketplace {
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-name-medium-dialog-marketplace {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-name-medium-dialog-marketplace {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-name-medium-dialog-marketplace {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-name-medium-dialog-marketplace {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-name-medium-dialog-marketplace {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-name-medium-dialog-marketplace {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-name-medium-dialog-marketplace {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-name-medium-dialog-marketplace {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-name-medium-dialog-marketplace {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-name-medium-dialog-marketplace {
    font-size: 14px !important;
  }
}

/*Marketplace Text Small Meidum*/
.font-name-smaller-text-medium {
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-name-smaller-text-medium {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-name-smaller-text-medium {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-name-smaller-text-medium {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1680px) {
  .font-name-smaller-text-medium {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1512px) {
  .font-name-smaller-text-medium {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1440px) {
  .font-name-smaller-text-medium {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-name-smaller-text-medium {
    font-size: 12px !important;
  }
}
@media (max-width: 3024px) {
  .font-section-download-chatbot-small {
    font-size: 18px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-download-chatbot-small {
    font-size: 13px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-download-chatbot-small {
    font-size: 13px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-download-chatbot-small {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-download-chatbot-small {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-download-chatbot-small {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1280px) {
  .font-section-download-chatbot-small {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-download-chatbot-small {
    font-size: 9px !important;
  }
}
@media (max-width: 820px) {
  .font-section-download-chatbot-small {
    font-size: 11px !important;
  }
}
@media (max-width: 520px) {
  .font-section-download-chatbot-small {
    font-size: 11px !important;
  }
}

/* small text medium case study */

.font-section-small-text-case-medium {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-section-small-text-case-medium  {
    font-size: 22px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-small-text-case-medium  {
    font-size: 18px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-small-text-case-medium  {
    font-size: 17px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-small-text-case-medium  {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-small-text-case-medium  {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-small-text-case-medium  {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-small-text-case-medium  {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-small-text-case-medium  {
    font-size: 12px !important;
  }
}
@media (max-width: 820px) {
  .font-section-small-text-case-medium  {
    font-size: 14px !important;
  }
}
@media (max-width: 520px) {
  .font-section-small-text-case-medium  {
    font-size: 14px !important;
  }
}

.font-section-webinar-date{
  font-size: 14px !important;
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-section-webinar-date{
    font-size: 18px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-webinar-date{
    font-size: 14px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-webinar-date{
    font-size: 13px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-webinar-date{
    font-size: 12px !important;
  }
}
@media screen and (max-width:1512px) {
  .font-section-webinar-date{
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-webinar-date{
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-webinar-date{
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-webinar-date{
    font-size: 8px !important;
  }
}
@media (max-width: 820px) {
  .font-section-webinar-date{
    font-size: 14px !important;
  }
}
@media (max-width: 520px) {
  .font-section-webinar-date{
    font-size: 14px !important;
  }
}

.font-section-webinar-register{
  font-size: 14px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-section-webinar-register{
    font-size: 18px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-webinar-register{
    font-size: 14px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-webinar-register{
    font-size: 13px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-webinar-register{
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-webinar-register{
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-webinar-register{
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-webinar-register{
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-webinar-regsiter{
    font-size: 8px !important;
  }
}
@media (max-width: 820px) {
  .font-section-webinar-register{
    font-size: 14px !important;
  }
}
@media (max-width: 520px) {
  .font-section-webinar-register{
    font-size: 14px !important;
  }
}

.font-section-webinar-upcoming{
  font-size: 10px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media (max-width: 3024px) {
  .font-section-webinar-upcoming{
    font-size: 20px !important;
  }
}

@media (max-width: 1920px) {
  .font-section-webinar-upcoming{
    font-size: 10px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-webinar-upcoming{
    font-size: 10px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-webinar-upcoming{
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-webinar-upcoming{
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-webinar-upcoming{
    font-size: 8px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-webinar-upcoming{
    font-size: 8px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-webinar-upcoming{
    font-size: 10px !important;
  }
}
@media (max-width: 820px) {
  .font-section-webinar-upcoming{
    font-size: 12px !important;
  }
}
@media (max-width: 520px) {
  .font-section-webinar-upcoming{
    font-size: 12px !important;
  }
}

.font-name-big-normal-text {
  font-family: "Gordita-Regular" !important;
}
@media screen and (max-width: 3024px) {
  .font-name-big-normal-text {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-name-big-normal-text {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-name-big-normal-text {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-name-big-normal-text {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-name-big-normal-text {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-name-big-normal-text {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-name-big-normal-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-name-big-normal-text {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-name-big-normal-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-name-big-normal-text {
    font-size: 18px !important;
  }
}

.font-name-big-normal-text-medium {
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-name-big-normal-text-medium {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-name-big-normal-text-medium {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-name-big-normal-text-medium {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-name-big-normal-text-medium {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-name-big-normal-text-medium {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-name-big-normal-text-medium {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-name-big-normal-text-medium {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-name-big-normal-text-medium {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-name-big-normal-text-medium {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-name-big-normal-text-medium {
    font-size: 18px !important;
  }
}

.font-section-normal-text-webinar-ul {
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-section-normal-text-webinar-ul {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-normal-text-webinar-ul {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-normal-text-webinar-ul {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-normal-text-webinar-ul {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-webinar-ul {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-webinar-ul {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-webinar-ul {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-section-normal-text-webinar-ul {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-normal-text-webinar-ul {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-normal-text-webinar-ul {
    font-size: 14px !important;
  }
}

.font-section-normal-small-text-pricing-plan-description {
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 9px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-normal-small-text-pricing-plan-description {
    font-size: 11px !important;
  }
}

.font-section-normal-small-text-pricing-discount {
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-section-normal-small-text-pricing-discount {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-normal-small-text-pricing-discount {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-normal-small-text-pricing-discount {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-normal-small-text-pricing-discount {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-small-text-pricing-discount  {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-small-text-pricing-discount  {
    font-size: 8px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-small-text-pricing-discount  {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-section-normal-small-text-pricing-discount {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-normal-small-text-pricing-discount  {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-normal-small-text-pricing-discount  {
    font-size: 9px !important;
  }
}
.font-section-normal-text-pricing-plan-details {
  font-family: "Gordita-Regular" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-normal-text-pricing-plan-details {
    font-size: 10px !important;
  }
}

.font-section-medium-text-pricing-plan-heading {
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-medium-text-pricing-plan-heading {
    font-size: 12px !important;
  }
}

.font-section-medium-text-small-pricing-right {
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}
@media screen and (max-width: 3024px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-medium-text-small-pricing-right {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .font-section-medium-text-small-pricing-right {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 820px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 520px) {
  .font-section-medium-text-small-pricing-right  {
    font-size: 10px !important;
  }
}


.font-section-automate-normal-text-testimonials {
  font-size: 20px !important;
  font-family: "Gordita-Regular" !important;
}

@media (max-width: 3024px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 24px !important;
  }
}
@media (max-width: 1920px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 19px !important;
  }
}
@media (max-width: 1728px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 18px !important;
  }
}
@media (max-width: 1680px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 12px !important;
  }
}
@media (max-width: 820px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 13px !important;
  }
}
@media (max-width: 520px) {
  .font-section-automate-normal-text-testimonials {
    font-size: 13px !important;
  }
}
.font-section-sub-header-automate {
  font-size: 30px !important;
  font-weight: normal !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-automate {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-automate {
    font-size: 38px !important;
  }
}
@media screen and (max-width: 1920px) {
  .font-section-sub-header-automate {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-automate {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-automate {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-automate {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-automate {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-automate {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-automate {
    font-size: 24px !important;
  }
}
@media (max-width: 80px) {
  .font-section-sub-header-automate {
    font-size: 23px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-automate {
    font-size: 22px !important;
  }
}

/* Sub header ; - normal - bold
1920  is fine (36px)
1680 is (32px)
1440 is (30px)
1280 is (28px)
*/

.font-section-sub-header-bold-testimonials-automate {
  font-size: 36px !important;
  font-weight: normal !important;
  font-family: "Gordita-Bold" !important;
  line-height: 1.5 !important;
}

@media screen and (max-width: 5000px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 3024px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 48px !important;
  }
}
@media screen and (max-width: 2560px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 46px !important;
  }
}

@media screen and (max-width: 1920px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 36px !important;
  }
}
@media screen and (max-width: 1728px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 34px !important;
  }
}
@media screen and (max-width: 1680px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 32px !important;
  }
}
@media screen and (max-width: 1512px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 31px !important;
  }
}
@media screen and (max-width: 1440px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 1280px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 28px !important;
  }
}

@media screen and (max-width: 1024px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 24px !important;
  }
}
@media (max-width: 820px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 24px !important;
  }
}
@media (max-width: 520px) {
  .font-section-sub-header-bold-testimonials-automate {
    font-size: 24px !important;
  }
}
/* linearGradient background */

.last-section-linear-background {
  background: transparent linear-gradient(180deg, #f8f8f8 0%, #ffffff 100%) 0%
    0% no-repeat padding-box !important;
}

/* Onmnichannel */

.omni-min-text-height {
  min-height: 150px;
}

@media (max-width: 1920px) {
  .omni-min-text-height {
    min-height: 200px;
  }
  .workflow_automate_feature_paragraph {
    padding-right: 31% !important;
  }
  .header_automate_feature_new {
    padding-right: 8%;
  }
}

@media (max-width: 1680px) {
  .omni-min-text-height {
    min-height: 150px;
  }
  .workflow_automate_feature_paragraph {
    padding-right: 29% !important;
  }
}

@media screen and (max-width: 1440px) {
  .omni-min-text-height {
    min-height: 150px;
  }
  .workflow_automate_feature_paragraph {
    padding-right: 20% !important;
  }
  .header_automate_feature_new {
    padding-right: 4%;
  }
}

@media screen and (max-width: 1280px) {
  .omni-min-text-height {
    min-height: 150px;
  }
  .workflow_automate_feature_paragraph {
    padding-right: 25% !important;
  }
  .header_automate_feature_new {
    padding-right: 15%;
  }
}

@media screen and (max-width: 1199px) {
  .omni-min-text-height {
    min-height: 154px;
  }
}

@media screen and (max-width: 992px) {
  .omni-min-text-height {
    min-height: 0px;
  }
}

@media (max-width: 520px) {
  .omni-min-text-height {
    min-height: 100px;
  }
  .workflow_automate_feature_paragraph {
  }
  /* .app-market-left-panel-app-market-first{
    display:none;
  } */
}

.image_slider_li {
  list-style: none;
  display: inline;
  color: blue;
}

.image_slider_li .rwt__tab {
  border-radius: 0;
  min-width: auto;
  max-width: auto;
  width: auto !important;
  background: inherit !important;
  padding: 0 !important;
  height: auto;
  text-align: left;
  z-index: 1;
  color: #2e6dfa !important;
  cursor: pointer;
  display: contents;
}

/* .right_header_content .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    height: 48px !important;
} */

.digital-assistant
  .rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  height: 45px !important;
}

/* 
@media (max-width: 1920px) {
    .virtual-top-features {
        padding-top: 20px;
    }
}

@media (max-width: 1680px) {
    .virtual-top-features {
        padding-top: 20px;
    }
} */

.desktop_tab_list.font-section-normal-text .rwt__tab {
  font-size: 20px !important;
  font-family: "Gordita-Medium" !important;
  line-height: 1.7 !important;
}

@media (max-width: 1920px) {
  .desktop_tab_list.font-section-normal-text .rwt__tab {
    font-size: 20px !important;
  }
}

@media (max-width: 1680px) {
  .desktop_tab_list.font-section-normal-text .rwt__tab {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1440px) {
  .desktop_tab_list.font-section-normal-text .rwt__tab {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1280px) {
  .desktop_tab_list.font-section-normal-text .rwt__tab {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .desktop_tab_list.font-section-normal-text .rwt__tab {
    font-size: 16px !important;
  }
}

@media (max-width: 520px) {
  .desktop_tab_list.font-section-normal-text .rwt__tab {
    font-size: 16px !important;
  }
}

@media (max-width: 520px) {
  .margin-bottom-p10 {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 768px) {
  .video_prompt2 {
    width: 100%;
    float: left;
    position: relative;
    background-size: contain;
    height: 400px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .video_prompt_with_background .container {
    padding: 0 10px;
  }
}
@media (min-width: 521px) and (max-width: 5000px) {
  .demo_footer_hide_desktop {
    display: none !important;
  }
}
@media (max-width: 520px) {
  .support_chatbot_it_help .video_prompt2 { 
    height: 440px;
  }
  .video_prompt2 {
    width: 100%;
    float: left;
    position: relative;
    background-size: contain;
    height: 200px;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin: 0px 0px;
    border-radius: 12px;
  }

  .video_prompt_with_background .container {
    padding: 0 10px;
  }
}

.margin-top-bottom-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.min-height-150 {
  min-height: 150px;
}

.chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
  margin-bottom: 20px !important;
}

.chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
  margin-bottom: 20px !important;
}

@media (max-width: 1920px) {
  .chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
    margin-bottom: 20px !important;
  }
  .ibm-watson-top-second-section
    .chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 1680px) {
  .chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
    margin-bottom: 20px !important;
  }

  .popup-demo {
    width: 30%;
    font-size: 13px !important;
  }
  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: 11.7%;
  }
}

@media screen and (max-width: 1440px) {
  .chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
    margin-bottom: 20px !important;
  }

  .popup-demo {
    width: 30%;
    font-size: 13px !important;
  }
  .new-pricing-page-automate .pricing-columns.pricing-section {
    margin-top: 44px;
  }
  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    top: 21px !important;
  }

  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    top: 21px !important;
  }
}
@media screen and (max-width: 1280px) {
  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button:before {
    top: 21px !important;
  }

  .automate_homepage
    .mob_accordian.mob_accordian_faq
    .accordion__button[aria-expanded="true"]:before {
    top: 21px !important;
  }
}

@media screen and (max-width: 1024px) {
  .chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
    margin-bottom: 20px !important;
  }

  .popup-demo {
    width: 38%;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 992px) {
  .chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
    margin-bottom: 0px !important;
  }

  .popup-demo {
    width: 40%;
    font-size: 13px !important;
  }
}

@media (max-width: 767px) {
  .popup-demo {
    width: 50%;
    font-size: 13px !important;
  }
}

@media (max-width: 520px) {
  .chat-channel.assistant_change_img.feature_cc_slider.mb-0 {
    margin-bottom: -15px !important;
  }

  .popup-demo {
    width: 50%;
    font-size: 13px !important;
  }
  .chatbot-guide-blog-main-container .video_prompt2 {
    height: 200px;
  }
  .chatbot-guide-blog-main-freshservice .video_prompt2 {
    height: 200px;
  }
}

.margin-top-minus-10 {
  margin-top: -10px !important;
}

@media (max-width: 1000px) {
  .new_padding {
    padding: 0px 0px 10px 0px !important;
  }
}

@media (max-width: 520px) {
  .new_padding {
    padding: 0px 0px 0px 0px !important;
  }
}

@media (max-width: 520px) {
  .usecase_bottom_blog ul {
    /* line-height: 2.3rem; */
  }

  .mobile-padding-40px {
    padding-top: 40px;
  }
  .card-assistant-box{
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%) 0% 0% no-repeat padding-box;
    padding: 0px 20px 40px !important;
  }
  .card-automate-box{
    padding: 0px 20px !important;
  }
  .button_navbar_automate{
    margin: 20px !important;
  }
  .automate_homepage
  .mob_accordian.mob_accordian_faq
  .accordion__button:before {
  top: 15px !important;
 }
 .automate_homepage
 .mob_accordian.mob_accordian_faq
 .accordion__button[aria-expanded="true"]:before {
 top: 15px !important;
 }


  .mobile-section-with-tabs h5 {
    margin-top: -15px !important;
  }

  .key-resources-card-text-wrap {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    min-height: 120px !important;
  }

  .mobile-min-height-80 {
    min-height: 80px !important;
  }
  .ibm-watson-top-second-section {
    margin-bottom: 36px;
  }
  .ibm-watson-assistants-main-container .trial_header_left {
    margin-top: 0;
  }
  .ibm-watson-assistants-main-container
    .landing_bg.bg_header_trial.trial_mb_80 {
    padding-bottom: 30px;
  }
  .flex_no_wrap_ibm_tab {
    flex-wrap: nowrap;
  }
  .flex_no_wrap_ibm_tab .mobile_step_header {
    text-align: center;
  }
  .features_usecases_ibm_sub_container {
    flex-direction: column;
    gap: 20px;
  }
  .padding-bottom-mobile-features-ibm {
    padding-bottom: 10px !important;
  }
  .text-align-ibm-mobile {
    text-align: center;
  }
  .ibm_watson_faq_container.mob_accordian.mob_accordian_faq
    .accordion__button:before {
    top: 16px;
  }
  .features_usecases_ibm_container li {
    align-items: normal;
  }
  .features_usecases_ibm_container img {
    position: relative;
    top: 3px;
  }
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.container_trial .bg_header_trial.trial_mb_80.mb-0 {
  margin-bottom: 0 !important;
  padding-bottom: 30px;
}

.right_header_content .feature_page_img_right img {
  width: 100% !important;
}

@media (max-width: 520px) {
  .margin-top-0 {
    margin-top: 0px !important;
  }
}

@media (max-width: 520px) {
  .margin-bottom-mob-0 {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 520px) {
  .padding-bottom-mob-25 {
    padding-bottom: 25px !important;
  }
}

@media (max-width: 358px) {
  .card_landing_section.integrations_popular_bg .card_landing_trial {
    padding: 30px 15px;
  }
}

.line-height-2 {
  line-height: 2.1 !important;
}

@media (min-width: 520px) and (max-width: 767px) {
  .margin-top-usecase-vid {
    margin-top: -55px !important;
  }
}

@media (max-width: 520px) {
  .margin-usecase-vid-bottom-0 {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 520px) {
  .usecase_bottom_blog > li {
    line-height: 2.1 !important;
  }
}

@media (max-width: 575px) {
  .paid-campaign-right {
    padding: 0px 30px 0px 30px !important;
  }

  .paid-campaign-left {
    padding: 40px 0px 40px 0px !important;
  }

  .paid-campaign-left .beta-button .sign_up_form button {
    width: 100% !important;
  }

  .paid-campaign-left .beta-button .sign_up_form input {
    width: 100% !important;
  }

  .landing-page-vi-agent .paid-campaign-right {
    padding: 0px 30px 0px 30px !important;
  }
}

@media (max-width: 1920px) {
  .swipe-one {
    width: 32px;
    height: 32px;
    margin: 0px 5px;
  }
  .testimonial_new_slider .swipe-one {
    margin: 0px 8px;
  }

  .swipe-one.active span {
    width: 18px;
    height: 18px;
  }

  .seperator-pop-demo {
    width: 45%;
  }

  .sign_up_font_size_reduce {
    font-size: 18px !important;
  }
  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: 10.4%;
  }
  .automate-slider-bottom-left-div p {
    padding-right: 19%;
  }
  .automate-slider-bottom-right-div p {
    padding-right: 12%;
  }
  .automate-center-image-content-first .automate-center-content {
    padding-left: 100px;
  }
  .automate-center-image-content-first .automate-center-image {
    padding-right: 12%;
  }
  .automate-center-image-content-second .automate-center-content {
    padding-left: 100px;
  }
  .automate-center-image-content-first .automate-center-content p {
    padding-right: 32%;
  }
  .automate-center-image-content-second .automate-center-content p {
    padding-right: 15%;
  }
  .margin-automate-header-logo {
    margin-top: 40px;
  }
  .new_automate_background .ibm-watson-top-second-section .swipe-chat-channel {
    margin-top: 20px;
  }
  .automate-swipe-image-home {
    width: 90% !important;
  }
  .chat-channel-ind-feature-image{
    width: 85% !important;
  }
  .automate-header-banner-image {
    width: 100%;
  }
  .goto_testimonials_line_height_first{
    line-height: 2.1 !important;
  }
  .goto_testimonials_line_height_two{
    line-height: 2.1 !important;
  }
  .goto_testimonials_line_height_three{
    line-height: 2.1 !important;
  }
}

@media (max-width: 1680px) {
  .swipe-one {
    width: 32px;
    height: 32px;
    margin: 0px 4px;
  }
  .testimonial_new_slider .swipe-one {
    margin: 0px 7px;
  }

  .swipe-one.active span {
    width: 18px;
    height: 18px;
  }

  .seperator-pop-demo {
    width: 45%;
  }

  .content-left-popup .beta-button .sign_up_form button {
    font-size: 14px !important;
  }

  .sign_up_font_size_reduce {
    font-size: 18px !important;
  }
  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: 11.3%;
  }
  .automate-center-image-content-first .automate-center-content {
    padding-left: 100px;
  }
  .automate-center-image-content-first .automate-center-image {
    padding-right: 100px;
  }
  .automate-center-image-content-second .automate-center-content {
    padding-left: 100px;
  }
  .automate-swipe-image-home {
    width: 84% !important;
  }
  .chat-channel-ind-feature-image{
    width: 80% !important;
  }
  .automate-header-banner-image {
    width: 100%;
  }
  
  
}

@media screen and (max-width: 1440px) {
  .swipe-one {
    width: 26px;
    height: 26px;
    margin: 0px 3px;
  }
  .testimonial_new_slider .swipe-one {
    margin: 0px 6px;
  }

  .swipe-one.active span {
    width: 16px;
    height: 16px;
  }

  .seperator-pop-demo {
    width: 45%;
  }

  .content-left-popup .beta-button .sign_up_form button {
    font-size: 14px !important;
  }

  .sign_up_font_size_reduce {
    font-size: 18px !important;
  }

  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: 13.7%;
  }
  .automate-slider-bottom-left-div p {
    padding-right: 8%;
  }
  .automate-slider-bottom-right-div p {
    padding-right: 4%;
  }
  .automate-center-image-content-first .automate-center-content {
    padding-left: 74px;
  }
  .automate-center-image-content-first .automate-center-image {
    padding-right: 54px;
  }
  .automate-center-image-content-second .automate-center-content {
    padding-left: 64px;
  }
  .automate-center-image-content-first .automate-center-content p {
    padding-right: 24%;
  }
  .automate-center-image-content-second .automate-center-content p {
    padding-right: 2%;
  }
  .margin-automate-header-logo {
    margin-top: 24px;
  }
  .automate-swipe-image-home {
    width: 80% !important;
  }
  .chat-channel-ind-feature-image{
    width: 75% !important;
  }
  .automate-header-banner-image {
    width: 90% !important;
  }
  
}

@media screen and (max-width: 1280px) {
  .swipe-one {
    width: 22px;
    height: 22px;
    margin: 0px 2px;
  }
  .testimonial_new_slider .swipe-one {
    margin: 0px 5px;
  }
  .swipe-one.active span {
    width: 14px;
    height: 14px;
  }

  .seperator-pop-demo {
    width: 44%;
  }

  .content-left-popup .beta-button .sign_up_form button {
    font-size: 14px !important;
  }
  
}

@media screen and (max-width: 1024px) {
  .swipe-one {
    width: 18px;
    height: 18px;
    margin: 0px 2px;
  }
  .testimonial_new_slider .swipe-one {
    margin: 0px 5px;
  }

  .swipe-one.active span {
    width: 12px;
    height: 12px;
  }

  .seperator-pop-demo {
    width: 42%;
  }
  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: 16.1%;
  }
  .automate-center-image-content-first .automate-center-content p {
    padding-right: 11%;
  }
  .automate-center-image-content-first .automate-center-image {
    padding-right: 26px;
  }
}
.padding-0-imp {
  padding: 0px !important;
}

.margin-top-5-negative {
  margin-top: -5px !important;
}

.background-color-slider {
  background-color: #edf1ff;
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .mobile-flex-flow {
    flex-flow: column-reverse;
  }

  .feature_page_content_left_landing {
    padding: 0px 15px !important;
  }

  .padding_chat_channel_mob_paid_ad {
    padding-bottom: 20px;
  }

  .icon_container_chatbot_integration .chat_channels_trial_btn:nth-child(1),
  .icon_container_chatbot_integration .chat_channels_trial_btn:nth-child(2) {
    margin-right: 10px;
  }

  .icon_container_chatbot_integration {
    margin-top: 10px;
  }

  .seperator-pop-demo {
    width: 40%;
  }

  .content-left-popup .beta-button .sign_up_form button {
    font-size: 14px !important;
  }

  .marketplace_assist_reach_button button {
    padding: 12px 7px;
  }
}

@media only screen and (max-width: 500px) {
  .icon_container_chatbot_integration .chat_channels_trial_btn {
    font-size: 14px !important;
  }
  .automate-slider-bottom-left-div
    .automate-slider-left-scnd-div
    .automate-slider-bottom-image {
    width: auto;
  }
}

@media only screen and (max-width: 320px) {
  .mobile-flex-flow {
    flex-flow: column-reverse;
  }

  .feature_page_content_left_landing {
    padding: 0px 15px !important;
  }

  .padding_chat_channel_mob_paid_ad {
    padding-bottom: 20px;
  }

  .icon_container_chatbot_integration .chat_channels_trial_btn:nth-child(1),
  .icon_container_chatbot_integration .chat_channels_trial_btn:nth-child(2) {
    margin-right: 10px;
  }

  .icon_container_chatbot_integration {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .paid_main_con #Progress_Status1 {
    width: 12.4%;
  }

  .paid_main_con #Progress_Status2 {
    width: 16.9%;
  }

  .paid_main_con #Progress_Status3 {
    width: 17.9%;
  }

  .paid_main_con #Progress_Status4 {
    width: 14.4%;
  }

  .landing-page-vi-agent #Progress_Status4 {
    width: 17.7%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .landing-page-vi-agent #Progress_Status4 {
    width: 17.7%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .landing-page-vi-agent #Progress_Status4 {
    width: 17.3%;
  }
}

@media screen and (max-width: 520px) {
  .trial_page_img.bg_trial_page {
    padding-top: 0px;
  }
  .sign_up_font_size_reduce {
    font-size: 16px !important;
  }
  .mobile-padding-top-20 {
    padding-top: 20px;
  }
  .automate-center-image-content-second .automate-center-content {
    margin-bottom: 0;
  }
  .new_automate_background .beta-button .sign_up_form input{
    font-size: 17px !important;
    color: #555555 !important;
  }
  .new_automate_background .beta-button .sign_up_form .color-red-req{
    color: #FC2A2A !important; 
  }
  .new_automate_background .main-slider-button.beta-button input[type="text"],
  select{
    height: 52px !important;
  }
  .new_automate_background .main-slider-button.beta-button input::placeholder{
    font-size: 14px !important;
  }
  .demo_automate_new .beta-button .sign_up_form input::placeholder{
    font-size: 14px !important;
  }
  .new_automate_background .beta-button .sign_up_form button {
    height: 48px !important;
  }
  .image-mobile-automate-home-23{
    position: relative;
    right: 20px;
  }
  .new-app-integration-single-automate
    .main-slider-button.beta-button
    input[type="text"],
  select{
    height: 52px !important;
  }
  .new-app-integration-single-automate .beta-button .sign_up_form button{
    height: 48px !important;
  }
  .demo_automate_new .main-slider-button.beta-button input[type="text"],
  select{
    height: 52px !important;
  }
  .demo_automate_new .beta-button .sign_up_form button{
    height: 48px !important;
  }
  .home_page_new_workativ .main-slider-button.beta-button input[type="text"],
  select,
  .home_page_new_workativ .beta-button .sign_up_form button {
    height: 62px !important;
  }
  .new_automate_background .beta-button .sign_up_form button {
    font-size: 15px !important;
  }
  .home_page_new_workativ .beta-button .sign_up_form button {
    font-size: 20px !important;
    padding-top: 12px;
  }
  .home_page_new_workativ .main-slider-button.beta-button input::placeholder {
    color: #555555 !important;
    font-size: 16px !important;
  }

  .home_page_new_workativ .form_below_content.signup_cta span {
    font-size: 14px !important;
  }
  .home_page_new_workativ .form_below_content.signup_cta .span_marginleft3, .home_page_new_workativ .form_below_content.signup_cta .span_marginleft2, .home_page_new_workativ .form_below_content.signup_cta .span_marginleft1 {
    display: flex;
  }
  .home_page_new_workativ .customer-home-container-new{
    margin-top: 10px;
  }
  .home_page_new_workativ .form_below_content.signup_cta {
    justify-content: center;
    width: 90%;
  }
  .new-app-integration-single-automate .beta-button .sign_up_form button {
    font-size: 20px !important;
  }
  .double_app_automate_new .beta-button .sign_up_form button {
    font-size: 15px !important;
  }
  .demo_automate_new .beta-button .sign_up_form button {
    font-size: 15px !important;
  }
  .demo_automate_new .beta-button .sign_up_form input {
    font-size: 17px !important;
    color: #555555 !important;
  }
  .home_page_new_workativ .main-slider-button.beta-button form.sign_up_form {
    width: 100%;
  }
  .new_mobile_home_design .trial_mb_80.bg_header_trial {
    padding-top: 0px !important;
  }
  .double_app_automate_new .footer_form {
    padding: 0px !important;
  }
  .double_app_automate_new .footer_form_container{
    border-radius: 0px !important;
  }
  .marketplace_assist_cards .cards__item {
    width: 100%;
    flex-direction: column;
  }
  .automate-home-header-main-section {
    background: #f2fffa;
    padding: 40px 0px 30px;
    margin-bottom: 40px;
  }
  .automate-home-header-main-section-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .automate-home-header-main-section-flex p {
    padding-bottom: 0px !important;
  }
  .features-top-left-section img {
    width: 74px;
  }
  .feature-page-section-second-automate {
    padding-bottom: 20px;
    margin: 16px 0px 46px;
  }
  .feature-page-section-third-automate {
    width: 100%;
    margin-bottom: 16px;
  }
  .feature-page-section-fourth-automate {
    padding-bottom: 20px;
  }
  .feature-page-section-five-automate {
    width: 100%;
    margin-bottom: 16px;
  }
  .features-top-left-section,
  .features-top-right-section,
  .features-bottom-flex-section div {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .automate-home-header-main-section .sign_up_form {
    margin-top: 20px;
  }
  .new_automate_background
    .automate-home-header-main-section
    .form_below_content {
    margin-top: 20px;
  }
 
  .automate-home-slider-section {
    background: #f5f5f5;
  }
  .automate-home-header-main-section .display-none-all-features {
    display: none;
  }
  .margin-automate-header-logo {
    margin-top: 0;
    padding-top: 10px !important;
  }
  .new_mobile_home_design .margin-automate-header-logo {
    display: none;
  }
  .feature-automate-new-workflow .main-slider-right {
    margin-top: 10px;
  }
  .new_automate_background .main-slider-right {
    margin-top: 24px;
  }
  .why-automate-customer-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .why-automate-customer-banner img {
    width: 100%;
  }
  .new_mobile_home_design .why-customer-banner-mobile-section-automate {
    margin-bottom: 0px !important;
    margin-top: 24px !important;
    background: #f2fffa;
    padding: 40px 0px;
  }
  .new-mobile-feature-design .why-customer-banner-mobile-section-automate {
    margin-bottom: 0px !important;
    background: #f2fffa;
    padding: 40px 0px;
  }
  .new-mobile-feature-design .footer_form {
    padding: 0px;
  }
  .new-mobile-feature-design .footer_form_container {
    border-radius: 0px;
  }

  .why-customer-banner-mobile-section {
    margin-bottom: 40px !important;
  }

  .feature-automate-new-workflow .onboarding_mobile_background {
    padding-top: 0px;
  }
  .automate-center-image-content-first .automate-center-content p {
    padding-right: 20px;
    margin-bottom: 0;
  }
  .automate-center-image-content-second .automate-center-content p {
    margin-bottom: 0;
  }
  .automate-center-image-content-first .automate-center-content {
    padding-left: 4px;
  }
  .automate-center-image-content-second .automate-center-content {
    padding-left: 4px;
  }
  .width-90 {
    width: 100% !important;
  }
  .automate-slider-bottom-left-div p {
    padding-right: 0;
  }
  .automate-slider-bottom-right-div p {
    padding-right: 0;
  }
  .new_automate_background .ibm-watson-top-second-section {
    margin-bottom: 16px;
  }
  .integrations-apps-paragaraph-automate {
    padding: 0px;
    margin-bottom: 0px;
  }
  .new_automate_background .cc_feature_apps .cogni_fav_apps_color {
    align-items: flex-start;
  }
  .new_automate_background .container-fluid.autoamte_apps {
    margin-bottom: 10px;
  }
  .new_automate_background .ibm-watson-top-second-section .swipe-chat-channel {
    margin-top: 20px;
  }
  .new_automate_background .features_change_img_header {
    padding: 0px;
  }
  .new_automate_background .mobile-display-hide {
    display: none;
  }
  .automate-slider-bottom-main-container {
    margin-top: 0px;
  }
  .features-top-flex-section {
    flex-direction: column;
  }
  .features-bottom-flex-section {
    flex-direction: column;
  }
  .features-top-flex-section button {
    width: 50%;
  }
  .features-top-right-section {
    margin-top: 20px;
  }
  .automate-swipe-image-home {
    width: 100% !important;
  }
  .chat-channel-ind-feature-image{
    width: 100% !important;
  }
  .automate-header-banner-image {
    width: 100%;
  }
  .why-automate-right-main-flex {
    margin-bottom: 0px;
  }
  .our-live-demo-margin-mobile {
    margin-top: 16px;
    margin-bottom: 16px !important;
  }
  .demo_automate_new .three_steps_wrapper {
    margin-bottom: 10px;
  }
  .form-automate-signup-new {
    justify-items: center !important;
  }
  .form-automate-signup-new span {
    font-size: 14px !important;
  }
  .basic-plan-center-automate {
    text-align: center !important;
  }
  .feature-automate-new-workflow .main-slider-right img {
    width: 100% !important;
  }
  .feature-automate-new-workflow .main-slider-right {
    margin-top: 10px !important;
  }
  .automate-home-slider-section .automate-swipe-image-home {
    width: 100% !important;
  }

  .app-list-automate-home h6 {
    font-size: 12px !important;
  }
  .app-list-automate-home {
    padding: 0 10px !important;
  }
  .app-list-automate-home .card_office {
    width: 60px !important;
    height: 60px !important;
  }
  .card-box-home-main-container {
    flex-direction: column;
  }
  
  .card_automate_box_paragraph {
    padding-right: 0px !important;
  }
.demo-testimonials-parent {
  margin-top: 40px;
}

.plan-new-pricing-assistants h6 {
  padding: 14px 10px;
  display: flex;
  flex-direction: column;
 }
 .plan-new-pricing-automate h6{
  padding: 14px 10px;
  display: flex;
  flex-direction: column;
 }
 .new_goto_mobile_logo{
  width: 50%;
 }
 .pricing_discounts_assitants{
  padding-top: 10px;
 }
 .pricing_discounts_automate{
   padding-top: 10px;
 }
 .footer_form_right .main-slider-button.beta-button input.sign_up_form_input {
  width: 100%;
  margin: 0px 0px 14px 0px !important;
  height: 42px;
}
.footer_form_container .footer_form_right .main-slider-button .sign_up_form button {
  height: 42px;
  padding: 7px 0px 0px;
}
}

@media only screen and (max-width: 767px) {
  .content-right-popup {
    display: none;
  }
}
@media only screen and (min-width: 521px) and (max-width: 1024px) {
  .ibm-watson-tab-section .int_landing_page .rwt__tablist.horizontal_slider {
    justify-content: center;
  }
}

@media only screen and (min-width: 520px) and (max-width: 767px) {
  .popup-assistants .sign_up_form input {
    width: 100%;
  }

  .popup-assistants .beta-button .sign_up_form button {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 320px) {
  .popup-assistants {
    padding: 50px 0px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .popup-assistants {
    padding: 50px 0px;
  }
  .new_automate_popup .popup-assistants .beta-button .sign_up_form button {
    font-size: 18px !important;
  }
  .new_automate_popup .popup-assistants .beta-button .sign_up_form_input::placeholder {
    font-size: 14px !important;
  }

  .popup-assistants .beta-button .sign_up_form button {
    font-size: 12px !important;
    text-align: center;
    position: relative;
    width: 100%;
  }
  

  .automate_popup p {
    padding-right: 0px;
    text-align: center;
  }

  .automate_popup .content-left-popup {
    padding: 15px !important;
    text-align: center;
  }

  .automate_popup .content-left-popup p {
    padding-top: 15px;
  }

  .content-left-popup {
    text-align: center;
    padding: 0 30px !important;
  }

  .popup-background .main-slider-button.beta-button form.sign_up_form {
    width: 100%;
  }

  .automate_popup .content-left-popup .font-section-sub-header-bold {
    font-size: 24px !important;
  }

  .automate_popup
    .content-left-popup
    .main-slider-button.beta-button
    input[type="text"],
  .automate_popup .content-left-popup select {
    margin-bottom: 10px !important;
  }

  .content-left-popup .popup-logo {
    position: relative;
    width: 100%;
    padding: 0 50px;
  }

  .automate_popup .content-left-popup p.line-height-2 {
    padding: 15px 0;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1100px) {
  .popup-assistants {
    padding: 50px 0px;
  }

  .popup-assistants .beta-button .sign_up_form button {
    font-size: 12px !important;
  }

  .automate_popup .content-left-popup p {
    padding-top: 15px;
  }

  .automate_popup .content-left-popup p.line-height-2 {
    line-height: 1.5 !important;
  }

  .automate_popup .content-left-popup {
    padding-left: 30px !important;
  }

  .automate_popup p {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .automate_popup .content-left-popup p.line-height-2 {
    line-height: 1.5 !important;
    padding: 15px 10px 10px 0;
  }

  .popup-background .main-slider-button.beta-button form.sign_up_form {
    width: 100%;
  }

  .popup-assistants {
    padding: 50px 0px;
  }
  .ml-40{
    margin-left: 0px !important;
  }
  .ml-30{
    margin-left: 0px !important;
  }
  .nocode_wrapper_upd button{
    width: 54%;
  }
}

@media only screen and (max-width: 520px) {
  .feature-display-none .features_content_left {
    padding-top: 40px;
  }

  .feature-display-none .feature-padding-change,
  .chatbot_integration_left_container .hr-itsm-padding-mobile {
    padding-top: 40px;
  }
  .chatbot_integration_left_container .slack-teams-sec-top {
    margin-top: 40px !important;
  }

  .padding-bottom-mobile-features {
    padding-bottom: 30px;
  }
  .padding-bottom-mobile-features-assist {
    padding-bottom: 40px !important;
  }

  .margin-top-mobile-features {
    margin-top: 30px;
  }
  .join-banner-cutomer-home {
    margin-bottom: 10px;
  }

  .feature-display-none
    .cards_features
    .whitepaper_cards
    .cards__item:nth-child(7) {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1680px) and (max-width: 5000px){
  .line-height-self-service{
    line-height: 1.8 !important;
  }
}

@media only screen and (min-width: 1501px) {
  .cards_features .whitepaper_cards .cards__item:nth-child(7) {
    padding-left: 0px !important;
  }
}

@media (max-width: 1920px) {
  .automate_popup .sign_up_form.button_only_form button {
    width: 70%;
  }
  .automate_form_p {
    padding-right: 0px !important;
  }

  .chatbot-top-div-marketplace {
    padding: 0px 20px 50px 20px;
  }

  .app-chatbot-icon-marketplace {
    margin: 10px 10px 10px 0px;
    width: 36px;
    height: 36px;
  }

  .chatbot-bottom-div-marketplace {
    padding: 20px 24px 10px 24px;
  }

  .paid_main_con .testimonial_feedback_right_content_box {
    padding: 20px 0px 0px 0px;
  }

  .paid_main_con .testimonial_feedback_right_content_box p {
    font-size: 18px !important;
  }

  .rating_top_box_landing {
    padding: 44px 20px;
  }

  .rating_bottom_box_landing {
    padding: 44px 20px;
  }

  .paid-campaign-left .form_below_content span {
    font-size: 17px;
  }

  .paid-campaign-left .beta-button .sign_up_form button {
    font-size: 16px !important;
  }

  .paid_main_con .form_below_content.signup_cta span {
    font-size: 16px;
  }

  .paid_main_con .beta-button .sign_up_form button {
    font-size: 18px;
  }
  .padding-bottom-box-chatbot-all-screen {
    padding-bottom: 73px;
  }
  .manage-engine-all-screen-padding {
    padding-bottom: 73px;
  }
  .dialog-box-chat-bot-marketplace-img {
    margin: 10px 10px 10px 14px;
  }
  .dialog-box-main-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .downshift-ul {
    width: 89.8%;
  }
  .new-pricing-page-automate:before {
    height: calc(87% - 148px);
  }
  .card_assistants_box_paragraph {
    padding-right: 23% !important;
  }
  .customer-home-container-new img {
    width: 90%;
  }
  .card_automate_box_paragraph {
    padding-right: 23% !important;
  }
  .min-height-250 {
    min-height: 214px;
  }
  .width-280 {
    width: 374px !important;
  }
}

@media (max-width: 1680px) {
  .automate_popup .sign_up_form.button_only_form button {
    width: 70%;
  }
  .customer-home-container-new img {
    width: 96%;
  }

  .chatbot-top-div-marketplace {
    padding: 0px 20px 40px 20px;
  }

  .card_assistants_box_paragraph {
    padding-right: 19% !important;
  }
  .card_automate_box_paragraph {
    padding-right: 21% !important;
  }
  .app-chatbot-icon-marketplace {
    margin: 10px 10px 10px 0px;
    width: 32px;
    height: 32px;
  }

  .paid_main_con .testimonial_feedback_right_content_box {
    padding: 10px 0px 0px 0px;
  }

  .rating_top_box_landing {
    padding: 44px 20px;
  }

  .rating_bottom_box_landing {
    padding: 44px 20px;
  }

  .paid_main_con .beta-button .sign_up_form button {
    font-size: 14px !important;
  }

  .rating_top_box_landing {
    padding: 44px 20px;
  }
  .rating_bottom_box_landing {
    padding: 44px 20px;
  }
  .paid_main_con .beta-button .sign_up_form button {
    font-size: 14px !important;
  }
  .padding-bottom-box-chatbot-all-screen {
    padding-bottom: 70px;
  }
  .mdm-bottom-box-chatbot-all-screen {
    padding-bottom: 70px;
  }
  .manage-engine-all-screen-padding {
    padding-bottom: 70px;
  }
  .dialog-box-chat-bot-marketplace-img {
    margin: 10px 10px 10px 14px;
  }
  .dialog-box-main-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .downshift-ul {
    width: 88.4%;
  }
  .new-pricing-page-automate:before {
    height: calc(89% - 148px);
  }
  .swipe-button-home {
    bottom: 26%;
  }
  .plans-home-page-new {
    width: 66%;
  }
  .min-height-250 {
    min-height: 210px;
  }
  .width-280 {
    width: 327px !important;
  }
}
@media screen and (max-width: 1512px) {
.card_assistants_box_paragraph {
  padding-right: 15% !important;
}
.card_automate_box_paragraph {
  padding-right: 21% !important;
}
}
@media screen and (max-width: 1440px) {
  .automate_popup .sign_up_form.button_only_form button {
    width: 50%;
  }
  .new-pricing-page-automate:before {
    height: calc(91% - 148px);
  }
  .customer-home-container-new img {
    width: 87%;
  }
  .card_assistants_box_paragraph {
    padding-right: 9% !important;
  }
  .card_automate_box_paragraph {
    padding-right: 11% !important;
  }
  .swipe-button-home {
    bottom: 22%;
  }
  .plans-home-page-new {
    width: 68%;
  }
  .new-single-app-container {
    padding: 20px 10px 40px 30px;
  }
  .popular-learn-more-link-two {
    position: relative;
    top: 29px;
  }
  .popular-learn-more-link-three {
    position: relative;
    top: 27px;
  }
  .testimonial_feedback_right_content_box {
    padding: 0px 20px;
  }
  .rating_top_box_landing {
    padding: 44px 20px;
  }
  .rating_bottom_box_landing {
    padding: 44px 20px;
  }
  .paid-campaign-right {
    padding: 40px !important;
  }
  .paid-campaign-left {
    padding: 40px !important;
  }
  .padding-bottom-box-chatbot-all-screen {
    padding-bottom: 68px;
  }
  .mdm-bottom-box-chatbot-all-screen {
    padding-bottom: 68px;
  }
  .manage-engine-all-screen-padding {
    padding-bottom: 68px;
  }

  .sap-factor-box-padding {
    padding-bottom: 68px;
  }

  /* App Marketplace */
  .chatbot-card-div-marketplace {
    padding: 20px 0px;
  }
  .chatbot-card-div-marketplace {
    padding: 20px 0px;
  }

  .chatbot-top-div-marketplace {
    padding: 0px 20px 30px 20px;
  }
  .dialog-top-div-marketplace-chatbot {
    padding: 10px 10px 10px 0px;
  }
  .app-chatbot-icon-marketplace {
    margin: 10px 10px 10px 0px;
    width: 30px;
    height: 30px;
  }
  .dialog-box-chat-bot-marketplace-img {
    margin: 10px 10px 10px 14px;
  }
  .paid-campaign-right {
    padding: 30px !important;
  }
  .paid-campaign-left {
    padding: 30px !important;
  }
  .paid_main_con .form_below_content.signup_cta span {
    font-size: 13px;
  }

  .chatbot-bottom-div-marketplace {
    padding: 20px 20px 0px 20px;
  }
  .downshift-ul {
    width: 86.2%;
  }
  .min-height-250 {
    min-height: 241px;
  }
  .width-280 {
    width: 282px !important;
  }
}

/*Chatbot MarketPlace */
.bot-div-chatbot-market {
  padding: 0px 40px 50px 30px;
}

.bottom-div-for-marketplace {
  padding-bottom: 0px;
}

.app-icon-marketplace-first {
  margin-left: 0px;
}

.testimonial_feedback_right_content_box {
  padding: 0px 20px;
}

.rating_top_box_landing {
  padding: 44px 20px;
}

.rating_bottom_box_landing {
  padding: 44px 20px;
}

.paid-campaign-right {
  padding: 40px !important;
}

.paid-campaign-left {
  padding: 40px !important;
}

@media screen and (max-width: 1280px) {
  .automate_popup .sign_up_form.button_only_form button {
    width: 45%;
  }
  .new-pricing-page-automate:before {
    height: calc(93% - 148px);
  }
  .new_automate_popup .automate_popup .beta-button .sign_up_form button {
   width: 100%;
  }
  .plan-new-pricing-assistants {
    margin-bottom: 14px;
  }
  .swipe-button-home {
    bottom: 35%;
  }
  .customer-home-container-new img {
    width: 82%;
  }
  .card_assistants_box_paragraph {
    padding-right: 14% !important;
  }
  .card_automate_box_paragraph {
    padding-right: 16% !important;
  }

 

  /* App Marketplace */
  .chatbot-card-div-marketplace {
    padding: 20px 0px;
  }

  .popular-learn-more-link-three {
    position: relative;
    top: 0px;
  }
  .popular-learn-more-link-two {
    position: relative;
    top: 26px;
  }

  .dialog-box-chat-bot-marketplace-img {
    margin: 10px 10px 10px 14px !important;
  }
  .chatbot-top-div-marketplace {
    padding: 0px 20px 30px 20px;
  }

  .app-chatbot-icon-marketplace {
    margin: 10px 10px 10px 0px;
    width: 24px;
    height: 24px;
  }

  .chatbot-bottom-div-marketplace {
    padding: 20px 20px 0px 20px;
  }

  /*Chatbot MarketPlace */
  .bot-div-chatbot-market {
    padding: 0px 30px 40px 30px;
  }

  .bottom-div-for-marketplace {
    padding-bottom: 0px;
  }

  .app-icon-marketplace-first {
    margin-left: 0px;
  }
  .downshift-ul {
    width: 86.2%;
  }
  .sap-factor-box-padding {
    padding-bottom: 68px;
  }
  .min-height-250 {
    min-height: 201px;
  }
}

@media screen and (max-width: 1024px) {
  .automate_popup .sign_up_form.button_only_form button {
    width: 55%;
  }
  .swipe-button-home {
    bottom: 44%;
  }
  .card_automate_box_paragraph, .card_assistants_box_paragraph {
    padding-right: 0% !important;
    height: 33%;
  }
  .popular-learn-more-link-three {
    position: relative;
    top: 23px;
  }

  .paid-campaign-left .beta-button .sign_up_form button {
    font-size: 14px !important;
  }

  .paid-campaign-right {
    padding: 30px !important;
  }

  .paid-campaign-left {
    padding: 30px !important;
  }

  .paid_main_con .form_below_content.signup_cta span {
    font-size: 13px;
  }

  .paid_main_con .form_below_content.signup_cta span {
    font-size: 13px;
  }
  .center-left-img-card-top-div {
    padding: 0px 14px 40px 14px;
  }
  .sap-factor-box-padding {
    padding-bottom: 68px;
  }
  .padding-bottom-box-chatbot-all-screen {
    padding-bottom: 68px;
  }
  .mdm-bottom-box-chatbot-all-screen {
    padding-bottom: 68px;
  }
  .manage-engine-all-screen-padding {
    padding-bottom: 93px;
  }

  .chatbot-small-screen-padding-bottom {
    padding-bottom: 68px;
  }

  .dialog-box-chat-bot-marketplace-img {
    margin: 10px 10px 10px 14px;
  }
  .dialog-box-main-container {
    grid-template-columns: 1fr 1fr;
  }
  .downshift-ul {
    width: 83.6%;
  }
  .cards_single_main_container_app_workflow{
    padding: 11px 18px 9px;
  }
  .support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container .right_container_tab_slider_it_suuport .cards_all_main_container_app_workflow .cards_single_main_container_app_workflow {
    padding: 8px 18px 6px;
  } 
  .width-280 {
    width: 310px !important;
  }
  .request_form_top_slider .alert_signup_mail{
    margin-top: 0 !important;
    padding-top: 10px !important;
  }
  .request_form_assist_left .alert_signup_mail {
    top: -17px;
  }
 
}

@media screen and (max-width: 767px) {
  .automate_popup.sign_up_form.button_only_form button {
    width: 70%;
  }

  .automate_popup form.button_only_form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 520px) {
  .automate_popup .sign_up_form.button_only_form button {
    width: 70%;
  }
  .new-single-app-container {
  padding: 20px 10px 20px 30px;
  }
  .new-single-app-container a {
    color: #2468f6 !important;
    position: relative !important;
    bottom: 0px !important;
  }

  .app-market-main-container {
    margin-top: 20px;
  }
  .customer-banner-home-main-container {
    margin-top: 1rem;
  }
  .assistant-main-home-page-container {
    padding-top: 40px;
  }
  .testimonial_background_main_container-home-page {
    margin-bottom: 46px;
  }
  .card-box-section-container {
    margin-bottom: 46px;
  }
  .assistant-main-home-page-container {
    margin-bottom: 36px;
  }
  .feature-page-section-second-automate {
    background: #f5f5f5 0% 0% no-repeat padding-box;
  }

  .home-page-slider-left-container {
    padding: 0 0 10px !important;
  }
  .popular-learn-more-link-three {
    position: relative;
    top: 0px;
  }
  .customer-home-container img {
    width: 100%;
    align-self: center;
  }
  .customer-home-container-new img {
    width: 100%;
    align-self: center;
  }

  .third-span-plans {
    margin-top: 10px;
  }
  .swipe-button-home {
    top: 112%;
  }
  .app_workflow_section_marketplace {
    padding-bottom: 20px;
  }
  .text-overflow-ellipsis-bot {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical;
  }

  .sap-factor-box-padding {
    padding: 0px 14px 40px 14px;
  }
  .feature-display-none .container-width-changes-features {
    padding: 0px 0px !important;
  }

  .feature-display-none-chat-channel .container-width-changes-features {
    padding: 0px 0px !important;
  }

  .feature-display-none-chat-channel-slack .container-width-changes-features {
    padding: 0px 0px !important;
  }

  .feature-display-none .center_feature_wrapper {
    padding: 0px 15px;
  }

  .feature-display-none .container-width-padding {
    padding: 0px 0% 15px 0% !important;
  }

  .padding-center-new {
    padding: 0px !important;
  }

  .display-none-desktop-feature {
    display: none;
  }

  .display-none-mob-feature {
    display: block;
  }

  .rating_box_main_container {
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: row;
    justify-content: center;
    text-align: center;
  }

  .paid_main_con .features_change_img-center {
    padding: 0px 0px 20px;
  }

  .paid-campaign-right {
    padding: 0px 30px 0px !important;
  }
  .feature-display-none-chat-channel .container-width-changes-features {
    padding: 0px 0px !important;
  }
  .feature-display-none-chat-channel-slack .container-width-changes-features {
    padding: 0px 0px !important;
  }
  .feature-display-none .center_feature_wrapper {
    padding: 0px 15px;
  }
  .feature-display-none .container-width-padding {
    padding: 0px 0% 15px 0% !important;
  }
  .padding-center-new {
    padding: 0px !important;
  }
  .display-none-desktop-feature {
    display: none;
  }
  .display-none-mob-feature {
    display: block;
  }
  .rating_box_main_container {
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: row;
    justify-content: center;
    text-align: center;
  }
  .onboarding_main_container .rating_left_box, .onboarding_main_container .rating_right_box {
      padding: 30px 20px;
  }
  .onboarding_main_container .rating_box_main_container {
    margin-bottom: 40px;
  }
  .paid_main_con .features_change_img-center {
    padding: 0px 0px 20px;
  }
  .paid-campaign-right {
    padding: 0px 30px 0px !important;
  }
  .padding-bottom-box-chatbot-all-screen {
    padding: 0px 14px 40px 14px;
  }
  .mdm-bottom-box-chatbot-all-screen {
    padding: 0px 14px 40px 14px;
  }
  .manage-engine-all-screen-padding {
    padding: 0px 14px 40px 14px;
  }

  .chatbot-small-screen-padding-bottom {
    padding: 0px 14px 40px 14px;
  }
  .dialog-box-main-container {
    grid-template-columns: 1fr;
  }
  .downshift-ul {
    width: 90.7%;
  }
  .page-not-found-image {
    width: 100%;
  }
  .center-left-img-card-main-div {
    flex-direction: column;
  }
  .flex-direction-mobile {
    flex-direction: column;
  }
  .homepage-free-bot-button,
  .homepage-book-demo-button {
    width: 90%;
  }
  .plans-home-page-new {
    flex-wrap: wrap;
  }
  .plans-home-page-new:last-child {
    margin-top: 14px;
  }
  .card-box-home-main-container button {
    width: 50%;
  }
  .home-page-automate-slider-left-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .new_pricing_coming_soon{
    align-self: flex-end;
    position:absolute;
  }
  .new_button_pricing_show{
    margin-top: 20px;
  }
  .ci_bottom_blogs{
    padding-left: 25px !important;
  }
 
}

@media (max-width: 375px) {
  .chat_channels_trial .chat_channels_trial_btn:nth-child(2) {
    margin-right: 10px;
  }
  .chat_channels_trial_btn:first-child {
    margin-right: 10px;
  }
}

@media (max-width: 520px) {
  .chatbot_integration_left_container .trial_header_left {
    margin-top: 0px;
  }
  .dialog-box-description-div {
    margin-top: 14px;
  }
  .demo-left-automate-content {
    margin-top: 0;
  }
  .demo-left-automate-content h1 {
    margin-bottom: 14px;
  }
  .roi-form-text-last {
    margin-top: 16px;
    margin-bottom: 0px !important;
    padding: 0px !important;
  }
  .roi-top-header {
    margin-top: 20px;
  }
  .demo_automate_new .footer_form_container {
    border-radius: 0px !important;
  }
  .demo_automate_new .footer_form {
    padding: 0px 0px !important;
  }
  .new_automate_background .iso-section-automate {
    margin-bottom: 40px;
  }
  .new_mobile_home_design .footer_form_container {
    border-radius: 0px !important;
  }
  .new_mobile_home_design .footer_form {
    padding: 0px 0px !important;
  }
  .three_steps_wrapper {
    flex-direction: column;
    gap: 20px;
  }
  .why-automate-customer-banner {
    margin-top: 0px;
  }
 
  .row.beta_change_form ul li {
    margin-bottom: 16px;
  }
  .demo-page-before-tick::before {
    width: 18px;
  }
  .demo-page-before-tick:nth-child(1)::before {
    width: 20px;
  }
  .new-single-app-container {
    width: auto;
  }
  .popular-learn-more-link-two {
    top: 0;
  }
  .iso-image-section-home {
    margin-bottom: 46px;
  }

  .line-height-chatbot {
    line-height: 36px !important;
    font-size: 24px !important;
  }

  .header-both-right-left-marketplace {
    display: none;
  }

  .app-market-main-container-mobile-chatbot {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .chatbot_main_marketplace {
    grid-template-columns: auto;
  }

  .app-market-main-container-mobile-chatbot .search_marketpalce {
    display: none !important;
  }

  .marketplace-mobile-center-text p,
  .marketplace-mobile-center-text span,
  .marketplace-mobile-center-text h1 {
    text-align: center;
  }

  .last_demo_footer {
    border-radius: 0px !important;
    margin-top: 0px !important;
    background: #fff;
    color: #2468f6 !important;
    text-transform: uppercase;
    text-decoration: none;
    border: 0px !important;
  }
  .paid-campaign-left {
    padding: 0px 0px 40px 0px !important;
  }
  .logo_container_integration {
    width: 14%;
  }
  .coming_soon_integrations {
    padding: 5px 10px;
  }
  .coming_soon_wrapper_integrations {
    width: 50% !important;
  }
  .testimonial_background_rating_padding_mobile {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
  }
  .paid_main_con .icon_container_chatbot_integration .chat_channels_trial_btn {
    font-size: 11px !important;
  }
  .center-head-left-img-container .feature_page_content_left {
    margin-top: 24px;
  }
  .header-center-chat-bot-channels {
    text-align: center;
    margin-bottom: 20px;
  }

  .marketplace_assist_reach_button_mobile button {
    width: 60% !important;
  }

  .download_section_marketplace_mobile {
    padding: 20px 10px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .second_section_marketplace_appworkflow {
    padding: 0px 20px;
  }

  .button_for_download_bots {
    float: none;
    display: block;
    width: 80%;
    text-align: center;
  }

  .header_container_back_chatbot_mobile {
    display: none;
  }

  .show_more_marketplace_assist_mobile button {
    width: 50%;
    border: 1px solid #2468f6;
    color: #2468f6;
  }

  .header_container_back_mobile,
  .search_marketplace_mobile,
  .chatbot-section-marketplace_heading_mobile {
    display: none;
  }

  .download_button_marketplace_mobile {
    display: block;
    float: none;
    text-align: center;
  }

  .show_more_marketplace_assist_mobile_display_none {
    display: none;
  }

  .background_marketplace_assist {
    padding-top: 0;
  }

  .app-market-main-container-left-side {
    margin-top: 0;
    border: none;
    border-radius: 0;
  }

  .app-market-left-panel-new {
    border: none;
    border-radius: 0;
  }

  .mobile-search-click-marketplace {
    border: none;
  }

  .last_demo_footer {
    border-radius: 0px !important;
    margin-top: 0px !important;
    background: #fff;
    color: #2468f6 !important;
    text-transform: uppercase;
    text-decoration: none;
    border: 0px !important;
  }

  .paid-campaign-left {
    padding: 0px 0px 40px 0px !important;
  }

  .logo_container_integration {
    width: 14%;
  }

  .coming_soon_integrations {
    padding: 5px 10px;
  }

  .coming_soon_wrapper_integrations {
    width: 50% !important;
  }

  .testimonial_background_rating_padding_mobile {
    padding-right: 20px;
    padding-left: 20px;
  }

  .paid_main_con .icon_container_chatbot_integration .chat_channels_trial_btn {
    font-size: 11px !important;
  }
  .card-ibm-watson-assistant {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
  .new_home_page_main_container .main-slider-right img {
    width: 100% !important;
  }
  .new_home_page_main_container .ibm-left-section-bottom {
    padding-bottom: 0px;
  }
  .new_home_page_main_container .trial_mb_80.bg_header_trial .main-slider-left .basic_plans {
    text-align: center !important;
  }
  .support_it_hr_main_container h4 {
    padding: 0 !important; 
  }
  .support_heading_container_clicked{
    background: #CBFFEC 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -2px 0px #46B18A;
    border: 1px solid #46B18A;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    padding: 6px 20px 7px;
    align-items: center;
  }
  .support_heading_container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -2px 0px #46B18A;
    border: 1px solid #46B18A;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    padding: 6px 20px 7px;
    align-items: center;
    flex-wrap: wrap;
  }
  .feature_heading_container_clicked{
    background: #CBFFEC 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -2px 0px #46B18A;
    border: 1px solid #46B18A;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    padding: 6px 20px 7px;
    align-items: center;
  }
  .feature_heading_container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -2px 0px #46B18A;
    border: 1px solid #46B18A;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    padding: 6px 20px 7px;
    align-items: center;
  }
  .onboarding_heading_container_clicked{
    background: #CBFFEC 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -2px 0px #46B18A;
    border: 1px solid #46B18A;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    padding: 6px 20px 7px;
    align-items: center;
  }
  .onboarding_heading_container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -2px 0px #46B18A;
    border: 1px solid #46B18A;
    border-radius: 28px;
    display: flex;
    justify-content: space-between;
    padding: 6px 20px 7px;
    align-items: center;
  }
  .feature_heading_container h5, .feature_heading_container_clicked h5, .support_heading_container h5, .support_heading_container_clicked h5, .onboarding_heading_container h5, .onboarding_heading_container_clicked h5{
    padding-top: 3px;
   }
  .support_it_hr_main_container{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    padding: 30px 0 20px;
    margin-bottom: 30px;
  }
  .feature_main_container {
    padding: 30px 0 20px;
    margin-bottom: 30px;
  }
  .onboarding_main_container {
    padding: 30px 0 20px;
    margin-bottom: 30px ;
  }
  .ad_home_page_main_container{
    padding: 30px 0px 0px;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .pps_mobile_integration_home{
    padding: 30px 0px;
    margin-bottom: 30px;
  }
  .feature_content_container .icons_container_onboarding {
    column-gap: 10px;
  }
  .support_content_container {
    display: grid;
    grid-auto-flow: row;
    padding: 0 !important;
    grid-template-columns: 1fr;
    background: none;
    box-shadow: none;
    border-radius: 0 !important;
  }
  .underline_hr_it_support{
    border-top: 1px solid #BBBBBA;
    width: 42%;
    float: left;
    margin: 0;
    position: relative;
    top: -23px;
    left: 18px;
  }
  .feature_content_container {
    display: grid;
    grid-auto-flow: row;
    padding: 0 !important;
    grid-template-columns: 1fr;
    background: none;
    box-shadow: none;
    border-radius: 0 !important;
  }
  .onboard_content_container{
    display: grid;
    grid-auto-flow: row;
    padding: 0 !important;
    grid-template-columns: 1fr;
    background: none;
    box-shadow: none;
    border-radius: 0 !important;
    gap:0;
  }
  
  .home_assistant_tesimonials_mobile  {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    padding: 0px 18px !important;
  }
  .home_assistant_tesimonials_content_mobile{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #00000040;
    border-radius: 20px;
  }
  .arrow_opened_up{
    width:25px;
    transform: rotate(90deg);
  }
  .arrow_closed_down{
    width:25px;
    transform: rotate(270deg);
  }
  .goto_case_study_button_redirection {
    padding: 8px 20px 6px;
  }
  .home_assistant_tesimonials_content_mobile .testimonial_logo_mobile_goto{
    width: 30% !important;
  }
  .ad_home_page_main_container h4 {
    padding: 0 8%;
  }
  .ad_flex_container_assist {
    overflow-x: scroll;
    justify-content: flex-start;
  }
  .ad_container_one_assist, .ad_container_two_assist, .ad_container_three_assist {
    flex-shrink: 0;
    width: 85%;
    max-width: none;
    background: #F4FAFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #0000001a;
    border-radius: 20px;
    padding-top: 30px !important;
    /* border-bottom: none; */
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .it-help-page .ad_container_one_assist, 
  .it-help-page .ad_container_two_assist, 
  .it-help-page .ad_container_three_assist{
    width: 85%;
    max-width: none;
  }
  .it-help-page .ad_flex_container_assist{
    flex-wrap: wrap;
  }
  .border_ad_home_assist{
    height: 21px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 20px 20px;
  }
  .ad_flex_container_assist {
    gap: 10px;
  }

  .iso_new_main_container{
    flex-direction: column;
  }
  .new_home_page_main_container .center_image_home.cards_landing_page {
    margin-bottom: 40px;
  }
  .new_home_page_main_container .form_below_content {
    right:6px;
    color:#666666 !important;
  }
  .form_assitants_signup_new{
    right: 6px !important;
  }
  .new_home_page_main_container .form_below_content span, .form_assitants_signup_new span {
    color:#666666 !important;
  }
  .ad_mobile_right_arrow svg{
    fill: #F07F31 !important;
    width: 25px;
    position: relative;
    top: -10px;
    left: 300px;
  }
  .ad_flex_container_assist img {
    padding-left: 15px !important;
  }
  .video_section_home_new {
    margin-bottom: 30px !important;
    margin-top: 0px !important;
  }
  .iso_new_main_container, .iso_new_main_container div{
    padding: 0 !important; 
  }
  .iso_new_main_container img, .iso_new_main_container p {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
  }
  .iso_mobile_sizing {
    margin-bottom: 30px !important;
  }
  .new_home_page_main_container .container_trial .bg_header_trial .trial_mb_80 {
    margin-bottom: 30px !important;
  }
  .heading_services_support_home{
    margin-bottom: 20px;
  }
  .teams_slack_widget_icons_home .icon_text{
    align-items: center;
    column-gap: 7px;
  }
  .teams_slack_widget_icons_home {
    align-items: center;
  }
  .right_container_tab_slider_it_suuport {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    align-items:center;
  }
  .width-280 {
    width: 280px !important;
  }
  .request_form_assist_left  .form_sign_update{
    margin-top: 10px;
  }
  .request_form_top_slider .form_sign_update {
    margin-top: 20px;
  }
  .request_form_assist_left .alert_signup_mail{
    top: 0px;
  }
} 


@media (max-width: 767px) {
  .mobile_image_enable .assistant_right_img.beta-slider img {
    display: block !important;
    margin-top: 20px !important;
  }

  .mobile_image_enable .assistant_right_img {
    display: block !important;
  }

  .display-none-gif {
    display: none;
  }

  .svg_roate_icon.svg_roate_header,
  .svg_roate_icon_inside.svg_roate_header {
    color: rgb(36, 104, 246);
    fill: rgb(36, 104, 246);
  }
  .home_page_new_workativ .alert_signup_mail{
    align-self: center;
    position: absolute;
  }
}

@media (max-width: 5000px) and (min-width: 1921px) {
  .landing_page_chatbot .desktop_tabs {
    width: 80%;
  }
}

@media (max-width: 5000px) and (min-width: 3440px) {
  .cont_right {
    display: block !important;
  }

  .featured_content .cogni_workspace_content.home_slider img {
    height: auto;
  }
}

@media (max-width: 992px) {
  .app_header_market_assist {
    display: none;
  }
}
@media only screen and (min-width: 521px) and (max-width: 5000px) {
  .why-customer-banner-mobile-section {
    display: none;
  }
}

@media only screen and (min-width: 521px) and (max-width: 820px) {
  .paid-campaign-left .beta-button .sign_up_form button {
    font-size: 12px !important;
  }

  .paid_main_con .form_below_content.signup_cta span {
    font-size: 10px !important;
  }
  .card-box-home-main-container {
    gap: 0px;
  }

  .paid_main_con .int_landing_page .horizontal_slider .rwt__tab {
    font-size: 14px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .paid_main_con .int_landing_page .rwt__tablist.horizontal_slider {
    overflow-x: hidden;
  }

  .paid_main_con .int_landing_page .rwt__tablist.horizontal_slider {
    justify-content: center;
  }

  .paid_main_con .onboarding_slider_step_text {
    font-size: 14px;
  }

  .landing-page-vi-agent .onboarding_slider_step_text {
    font-size: 11px;
  }

  .font-size-tablet-view {
    font-size: 12px !important;
  }

  .paid_main_con .video_prompt2 {
    height: 400px;
  }

  .paid_main_con .bottom_designation_box_right_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .paid_main_con .testimonial_reviewer_image {
    height: 200px;
  }

  .paid_main_con .iocn_virtual img {
    display: none;
  }

  .paid_main_con .tablet-image-size-landing {
    width: 100%;
    height: auto;
  }

  .padding-top-40-landing {
    padding-top: 40px !important;
  }

  .padding-top-20-landing {
    padding-top: 20px !important;
  }

  .paid_main_con .feature_page_content_left,
  .paid_main_con .feature_page_content_left_landing {
    justify-content: flex-start;
  }

  .paid_main_con .feature_page_img_right {
    margin-top: 10px;
  }

  .paid_main_con .testimonial_hammer_logo_mobile {
    width: 200px;
  }
  
}

.line-3-ellipsis {
  height: 100px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 1440px) and  (max-width: 1920px) {
  .video_prompt{
    width: 85%;
  }
 }
 @media screen and (min-width: 1441px) and  (max-width: 1512px) {
 .feature_content_container.content-box-assitant-home-features{
  grid-template-columns: 1fr 1.7fr;
 }
}
@media screen and (min-width: 3440px){
  .paid__ad__tbody__tr:nth-child(1)::after{
    width: 167rem !important;
    height: 92px !important;
  }
  .paid__ad__tbody__tr:nth-child(3)::after{
    width: 167rem !important;
    height: 155px !important;
  }
  .paid__ad__tbody__tr:nth-child(5)::after{
    width: 167rem !important;
    height: 96px !important;
  }
  .table__description__paid__ad{
    width: 48rem;
  }
  .quotes__testi__lan {
    top: -80px !important;
  }
 .testi-box-lan{
   padding: 70px 20px 24px !important;
  }
  .testi-box-top {
    height: 430px !important;
 }
 .tab__icon__landing {
  width: 94px !important;
  height: 94px !important;
 }
 .header__landing {
  height: 110px !important;
}
.limited__spot__text {
  left: 28rem !important;
}
.form__paid__ad input{
  border-radius: 45px !important;
}
.form__paid__ad button {
  border-radius: 45px !important;
}
.right-item-demo-signup-lan-form {
  width: 700px !important;
}
.list__items__paid__tick {
  top: 7px !important;
  width: 30px !important;
}
.paid__ad__book__demo__integrate__icons {
  margin-top: 5px !important;
}
.paid__ad__book__demo__integrate__icons img {
  width: 21% !important;
  height: 21% !important;
}
}
@media screen and (min-width: 2560px){
  .paid__ad__tbody__tr:nth-child(1)::after{
    width: 122rem;
    height: 80px;
  }
  .paid__ad__tbody__tr:nth-child(3)::after, .paid__ad__tbody__tr:nth-child(5)::after {
    width: 122rem;
    height: 132px;
  }
  .four-in-one {
    right: 120px;
  }
  .tab__icon__landing {
    width: 74px;
    height: 74px;
  }
  .button-landing{
    border-radius: 40px;
  }
  .quotes__testi__lan{
    top: -58px;
  }
  .client__image__testi__lan {
    width: 180px;
    height: 180px;
  }
  .testi-box-top{
    height: 340px;
  }
  .header__landing{
    height: 80px;
  }
  .limited__spot__text{
    left: 21rem;
  }
  .form__paid__ad input{
    border-radius: 40px !important;
  }
  .form__paid__ad button {
    border-radius: 40px !important;
  }
  .paid__ad__book__demo__integrate__icons img {
    width: 19%;
    height: 19%;
  }
  .list__items__paid__tick{
    top: 4px;
    width: 26px;
  }
  .paid__ad__book__demo__integrate__icons{
    margin-top: 1px;
  }
  .hero__wider__image__popup{
    width:77% !important;
  }
}

@media screen and (max-width: 1920px) {
  .automate_home_page_onboarding .bar_container {
    width: 84%;
  }
  .new_automate_background .coming_soon_automate_new{
    width: 23%;
    text-align: center;
  }
  .feature-automate-new-workflow .coming_soon_automate_new{
    width: 26%;
    text-align: center;
  }
  .designation_and_hammer_logo {
    top: 30px;
  }
  .rating_goto_new .testimonial_feedback_box {
    grid-template-columns: 1fr 3fr;
  }
  .solution_list_flex_container {
     padding: 0px 70px;
  }
  .feature_content_container, .onboard_content_container, .support_chatbot_it_help .support_it_hr_main_container .support_content_container {
    height: 655px;
  }
  .testimonials_container_support{
    width: 89%;
  }
  .support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
    top: 76px;
  }
  .c_integration_slider_top {
    min-height: 120px;
  }
  .blog_top_header_upt {
    height: 780px;
  }
  .play_webinar_div {
  top: 12rem;
  }
  .top-message-wrapper-pricing{
   margin-top: 16px !important;
  }
.custom-margin-pricing-assistants {
  margin-bottom: 11px !important;
  margin-top: 2px;
}
.custom-margin-pricing-assistants-last-before {
  margin-bottom: 9px !important;
}
.pricing-plan-last-discount{
  margin-top: 13px;
}
.last-message-container-assist{
  margin-bottom: 12px !important;
}
 .assit-pricing-content-right {
  gap: 15.2px !important;
 }
 .cta-blog-container{
  height: 230px;
 }
 .header-new-launch {
  right: 4.5rem;
  bottom: 1.2rem;
  }
  .header-new-launch-soon {
    right: 1rem;
    bottom: 1.2rem;
  }
  .case-study-customer-box{
    padding: 30px 60px;
  }
  .designation_cognosante{
    position: relative;
    top: 4rem;
  }
  .designation_goto_case_study{
    position: relative;
    top: 2.4rem;
  }
  .cognosante_right_content_testi{
    padding-right: 6rem !important;
  }
  .new-release-features{
    left: 14rem;
  }
  .new-release-features-beta{
    left: 11.4rem;
  }
  .content-item-easy {
    left: 4.7rem;
    padding-right: 7rem;
 }
 .springed-arrow-assitant{
  right: 40px;
 }
 .four-in-one {
  right: 73px;
 }
.support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
  height: 700px;
}
.header-menu-flex-container {
  width: 1648px;
  justify-content: space-between;
}
.sub-menu-header-description, .sub-menu-header-description-last-before  {
  width: 280px;
}
.integration-menu-white-box{
  width: 1590px;
}
.drop-down-box-mega-menu {
  top: 91% !important;
}
.support_content_container.integrations-grid-layout-height{
  height:700px !important;
 }
 .onboard_content_container.onboarding-grid-layout-height{
  height: 687px !important;
 }
 .right_cards_tab_appworkflow {
  right: 3rem;
  min-width: 105%;
}
.case-study-cta-flex-box{
  width: 90%;
 }
 .image-bottom-zero{
  position: relative;
  top: 25px;
 }
 .first-category-two-line{
  margin-bottom: 21px;
}
.assit-plan-desc{
  padding-right: 50px !important;
}
.paid__ad__tbody__tr:nth-child(3)::after{
  width: 89.5rem;
  height: 104px;
}
.paid__ad__tbody__tr:nth-child(1)::after,
.paid__ad__tbody__tr:nth-child(5)::after {
  width: 89.5rem;
  height: 70px;
}
.limited__spot__text{
  left: 15rem;
}
.demo__testimonial__left{
  flex: 30%;
}
.demo__testimonial__right{
  flex: 60%;
}
}
@media screen and (max-width: 1728px) {
.feature_content_container, .onboard_content_container, .support_chatbot_it_help .support_it_hr_main_container .support_content_container{
  height: 575px;
}
.beta-button .sign_up_form button {
  font-size: 18px;
}
.support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
  top: 50px;
}
.custom-margin-pricing-assistants {
  margin-bottom: 13px !important;
  margin-top: 3px;
}
.custom-margin-pricing-assistants-last-before {
  margin-bottom: 10px !important;
}
.pricing-plan-last-discount{
  margin-top: 17px;
}
.last-message-container-assist{
  margin-bottom: 13.6px !important;
}
.assit-pricing-content-right {
  gap: 14px !important;
}
.ai_self_service_features_container.support_chatbot_it_help .support_it_hr_main_container .support_content_container{
  height: 630px;
}
.downshift-ul {
  width: 88.8%;
}
.support_chatbot_it_help.ntn_parent_con_ci .support_it_hr_main_container .support_content_container .right_container_tab_slider_it_suuport .left_cards_tab_appworkflow .cards_all_main_container_app_workflow .cards_single_main_container_app_workflow   {
  padding: 14px 10px 12px;
}
.header-new-launch {
  right: 5.5rem;
  bottom: 1rem;
}
.header-new-launch-soon {
  right: 2.3rem;
  bottom: 1rem;
}
.case-study-customer-box {
  padding: 30px 40px;
}
.designation_cognosante{
  top: 0rem;
}
.designation_goto_case_study {
  top: 0rem;
}
.new-release-features{
  left: 13.4rem;
}
.new-release-features-beta{
  left: 11rem;
}
.content-item-easy {
  left: 4rem;
  padding-right: 6rem;
}
.springed-arrow-assitant {
  right: 20px;
}
.support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
  height: 640px;
}
.header-menu-flex-container {
  width: 1508px;
  justify-content: space-between;
}
.sub-menu-header-description,.sub-menu-header-description-last-before  {
  width: 270px;
}
.integration-menu-white-box{
  width: 1448px;
}
.drop-down-box-mega-menu {
  top: 89% !important;
}
.support_content_container.integrations-grid-layout-height{
  height:630px !important;
 }
 .onboard_content_container.onboarding-grid-layout-height{
  height: 607px !important;
 }
 .right_cards_tab_appworkflow {
  right: 3rem;
  min-width: 106%;
 }
 .case-study-cta-flex-box{
  width: 89%;
 }
 .image-bottom-zero{
  position: relative;
  top: 26px;
 }
 .col-lg-6.image-box-home{
  max-width: 55%;
  flex: 0 0 55%;
}
.col-lg-6.content-box-home{
  max-width: 45%;
  flex: 0 0 45%;
}
.assit-plan-desc{
  padding-right: 30px !important;
}
.first-category-two-line{
  margin-bottom: 15px ;
}
.left-flex-item-landing {
  flex: 0 0 36%;
}
.testi-box-lan {
  padding: 40px 16px 24px;
  width: 46%;
}
.paid__ad__tbody__tr:nth-child(3)::after{
  width: 80.8rem;
  height: 104px;
}
.paid__ad__tbody__tr:nth-child(1)::after,
.paid__ad__tbody__tr:nth-child(5)::after {
  width: 80.8rem;
  height: 70px;
}
.limited__spot__text{
  left: 13.5rem;
}
.demo__testimonial__left{
  flex: 28%;
}
.demo__testimonial__right{
  flex: 70%;
}
}
@media screen and (max-width: 1680px) {
  .new_automate_background .coming_soon_automate_new{
    width: 26%;
    text-align: center;
  }
  .feature-automate-new-workflow .coming_soon_automate_new{
    width: 29%;
    text-align: center;
  }
  .designation_and_hammer_logo {
    top: 30px;
  }
  .rating_goto_new .designation_and_hammer_logo {
    top: 20px;
  }
  .rating_goto_new .testimonial_feedback_box {
    grid-template-columns: 1.4fr 3fr;
  }
  .solution_list_flex_container {
    padding: 0 33px;
  }
  .goto_popu_button {
    width: 54%
  }
  .feature_content_container, .onboard_content_container, .support_chatbot_it_help .support_it_hr_main_container .support_content_container  {
    height: 575px;
  }
  .support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container  {
    height: 580px;
  } 
  .testimonials_container_support{
    width: 88%;
  }
  .support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
    top: 60px;
  }
  .c_integration_slider_top {
    min-height: 92px;
  }
  .footer_last_column_padding{
    padding-left: 30px;
  }
  .blog_top_header_upt {
    height: 680px;
  }
  .play_webinar_div {
    top: 10rem;
  }
  .top-message-wrapper-pricing{
    margin-top: 16px !important;
   }
  .assit-plan-desc{
    padding-right: 16px !important;
  }
  .assit-pricing-content-right {
    gap: 13.8px !important;
  }
  .cta-blog-container{
    height: 200px;
  }
  .header-new-launch {
    right: 5.5rem;
    bottom: 1rem;
  }
  .header-new-launch-soon {
    right: 2.3rem;
    bottom: 1rem;
  }
  .designation_cognosante{
    top: 1rem;
  }
  .new-release-features{
    left: 13rem;
  }
  .new-release-features-beta{
    left: 10.5rem;
  }
  .content-item-easy {
    left: 4rem;
    padding-right: 6rem;
  }
  .four-in-one {
   right: 70px;
  }
  .springed-arrow-assitant{
   right: 10px;
  }
  .support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
    height: 610px;
 }
 .header-menu-flex-container {
  width: 1470px;
  justify-content: space-between;
}
.sub-menu-header-description, .sub-menu-header-description-last-before  {
  width: 260px;
}
.integration-menu-white-box{
  width: 1414px;
}
.support_content_container.integrations-grid-layout-height{
  height:600px !important;
}
.onboard_content_container.onboarding-grid-layout-height{
  height: 590px !important;
 }
 .right_cards_tab_appworkflow {
  right: 3rem;
  min-width: 106%;
 }
 .case-study-cta-flex-box{
  width: 89%;
 }
 .image-bottom-zero{
  position: relative;
  top: 26px;
 }
 .col-lg-6.image-box-home{
  max-width: 55%;
  flex: 0 0 55%;
}
.col-lg-6.content-box-home{
  max-width: 45%;
  flex: 0 0 45%;
}
.first-category-two-line{
  margin-bottom: 17px ;
}
.custom-margin-pricing-assistants {
  margin-bottom: 13px !important;
  margin-top: 3px;
}
.custom-margin-pricing-assistants-last-before {
  margin-bottom: 10px !important;
}
.pricing-plan-last-discount{
  margin-top: 5px;
}
.last-message-container-assist{
  margin-bottom: 13.2px !important;
}
.left-flex-item-landing {
  flex: 0 0 34%;
}
.testi-box-lan {
  padding: 40px 20px 24px;
  width: 46%;
}
.testi-box-top {
  height: 220px;
}
.quotes__testi__lan {
  top: -36px;
}
.paid__ad__tbody__tr:nth-child(3)::after{
  width: 78.5rem;
  height: 102px;
}
.paid__ad__tbody__tr:nth-child(1)::after,
.paid__ad__tbody__tr:nth-child(5)::after {
  width: 78.5rem;
  height: 68px;
}
.limited__spot__text{
  left: 14rem;
}
.demo__testimonial__left{
  flex: 30%;
}
.demo__testimonial__right{
  flex: 70%;
}
}
@media screen and (max-width: 1519px){
.beta-button .sign_up_form button {
    font-size: 16px;
}
.paid__ad__tbody__tr:nth-child(1)::after, .paid__ad__tbody__tr:nth-child(5)::after {
  width: 65.5rem;
  height: 68px;
}
.paid__ad__tbody__tr:nth-child(3)::after {
  width: 65.5rem;
  height: 102px;
}
.testi-box-lan {
  padding: 40px 10px 24px;
  width: 50%;
}
.limited__spot__text{
  left: 11rem;
}
}
@media screen and (max-width: 1512px) {
.new_home_page_main_container .ibm-left-section-bottom {
  padding-bottom: 10px;
}
.support_it_hr_main_container h4 {
padding: 0px 17%;
}
.features_all_case_parent_div .support_it_hr_main_container h4 {
  line-height: 1.8 !important;
}
.heading_services_support_home {
  margin-bottom: 20px;
}
.support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
  top: 40px;
}
.support_chatbot_it_help.ntn_parent_con_ci .support_it_hr_main_container .support_content_container .right_container_tab_slider_it_suuport .left_cards_tab_appworkflow .cards_all_main_container_app_workflow .cards_single_main_container_app_workflow   {
  padding: 14px 4px 12px;
}
.support_chatbot_it_help.xero_chatbot_integration_upt .support_it_hr_main_container .support_content_container .right_container_tab_slider_it_suuport .left_cards_tab_appworkflow .cards_all_main_container_app_workflow .cards_single_main_container_app_workflow   {
  padding: 14px 4px 12px;
}
.header-new-launch {
  right: 5.8rem;
  bottom: 1rem;
}
.header-new-launch-soon {
  right: 2.8rem;
  bottom: 1rem;
}
.case-study-customer-box {
  padding: 30px 30px;
}
.new-release-features{
  left: 12rem;
}
.new-release-features-beta{
  left: 9.5rem;
}
.content-item-easy {
  left: 3.6rem;
  padding-right: 3rem;
}
.four-in-one{
  right: 68px;
}
.springed-arrow-assitant{
  right: 26px;
}
.support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
  height: 510px;
}
.header-menu-flex-container {
  width: 1270px;
  justify-content: space-between;
}
.sub-menu-header-description, .sub-menu-header-description-last-before {
  width: 210px;
}
.integration-menu-white-box{
  width: 1204px;
}
.support_content_container.integrations-grid-layout-height{
  height:495px !important;
}
.onboard_content_container.onboarding-grid-layout-height {
  height: 480px !important;
}
.right_cards_tab_appworkflow {
  right: 2.3rem;
  min-width: 107%;
 }
 .case-study-cta-flex-box{
  width: 87%;
 }
 .image-bottom-zero{
  position: relative;
  top: 21px;
 }
 .col-lg-6.image-box-home{
  max-width: 55%;
  flex: 0 0 55%;
}
.col-lg-6.content-box-home{
  max-width: 45%;
  flex: 0 0 45%;
}
.bottom-60px{
  bottom:30px;
}
.assit-plan-desc{
  padding-right:10px !important;
}
.first-category-two-line {
  margin-bottom: 17.2px;
}
.assit-pricing-content-right {
  gap: 12px !important;
}
.custom-margin-pricing-assistants {
  margin-bottom: 12px !important;
  margin-top: 2px;
}
.custom-margin-pricing-assistants-last-before {
  margin-bottom: 9px !important;
}
.pricing-plan-last-discount{
  margin-top: 6px;
}
.last-message-container-assist{
  margin-bottom: 11.8px !important;
}
.left-flex-item-landing {
  flex: 0 0 36%;
}
.testi-box-lan {
  padding: 40px 10px 24px;
  width: 50%;
}
.testi-box-top {
  height: 190px;
}
.quotes__testi__lan {
  top: -33px;
}
.paid__ad__tbody__tr:nth-child(3)::after{
  width: 65.5rem;
  height: 94px;
}
.paid__ad__tbody__tr:nth-child(1)::after,
.paid__ad__tbody__tr:nth-child(5)::after {
  width: 65.5rem;
  height: 66px;
}
.limited__spot__text{
  left: 10.5rem;
}
.demo__testimonial__left{
  flex: 24%;
}
.demo__testimonial__right{
  flex: 70%;
}
}

@media screen and (max-width: 1440px) {
  .new_automate_background .coming_soon_automate_new{
    width: 29%;
    text-align: center;
  }
  .feature-automate-new-workflow .coming_soon_automate_new{
    width: 31%;
    text-align: center;
  }
  .designation_and_hammer_logo {
    top: 10px;
  }
  .rating_goto_new .designation_and_hammer_logo {
    top: 10px;
  }
  .rating_goto_new .testimonial_feedback_box {
    grid-template-columns: 1.4fr 3fr;
  }
  .solution_list_flex_container {
    padding: 0 27px;
  }
  .feature_content_container, .onboard_content_container, .support_chatbot_it_help .support_it_hr_main_container .support_content_container  {
    height: 480px;
  }
 
  .support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container  {
    height: 540px;
  } 
  .support_chatbot_it_help.ntn_parent_con_ci .support_it_hr_main_container .support_content_container  {
    height: 510px;
  } 
  .testimonials_container_support{
    width: 86%;
  }
  .support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
    top: 40px;
  }
  .c_integration_slider_top {
    min-height: 80px;
  }
  .blog_top_header_upt {
    height: 579px;
  }
  .play_webinar_div {
    top: 8rem;
  }
  .top-message-wrapper-pricing{
    margin-top: 16px !important;
  }
  .assit-plan-desc{
     padding-right: 8px !important;
  }
  .assit-pricing-content-right {
   gap: 12.5px !important;
  }
  .grouping-header-right-pricing svg{
    width: 14px !important;
    height: 14px !important;
  }
  .message-right-last-child svg{
    width: 14px !important;
    height: 14px !important;
  }
  .assit-pricing-content-right svg{
    width: 14px !important;
    height: 14px !important;
  }
  .cta-blog-container{
    height: 170px;
  }
  .ai_self_service_features_container.support_chatbot_it_help .support_it_hr_main_container .support_content_container{
  height: 500px;
  }
  .header-new-launch {
    right: 5.8rem;
    bottom: 1rem;
  }
  .header-new-launch-soon {
    right: 3.2rem;
    bottom: 1rem;
  }
  .designation_cognosante{
    top: 0rem;
  }
  .new-release-features{
    left: 12rem;
  }
  .new-release-features-beta{
    left: 9.5rem;
  }
  .content-item-easy {
    left: 3.6rem;
    padding-right: 3rem;
  }
  .springed-arrow-assitant {
    right: 30px;
  }
  .four-in-one {
    right: 68px;
  }
  .support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
    height: 510px;
  }
  .header-menu-flex-container {
    width: 1268px;
    justify-content: space-between;
  }
  .sub-menu-header-description, .sub-menu-header-description-last-before {
    width: 210px;
  }
  .integration-menu-white-box{
    width: 1208px;
  }
  .support_content_container.integrations-grid-layout-height{
    height:500px !important;
  }
  .onboard_content_container.onboarding-grid-layout-height {
    height: 480px !important;
  }
  .right_cards_tab_appworkflow {
    right: 2.4rem;
    min-width: 108%;
   }
   .case-study-cta-flex-box{
    width: 87%;
   }
   .image-bottom-zero{
    position: relative;
    top: 22px;
   }
   .col-lg-6.image-box-home{
    max-width: 55%;
    flex: 0 0 55%;
  }
  .col-lg-6.content-box-home{
    max-width: 45%;
    flex: 0 0 45%;
  }
  .bottom-60px{
    bottom:35px;
  }
  .first-category-two-line{
    margin-bottom: 16px ;
  }
  .custom-margin-pricing-assistants {
    margin-bottom: 7px !important;
    margin-top: 1px;
  }
  .custom-margin-pricing-assistants-last-before {
    margin-bottom: 7px !important;
  }
  .pricing-plan-last-discount{
    margin-top: 0px;
  }
  .last-message-container-assist{
    margin-bottom: 7px !important;
  }
  .left-flex-item-landing {
    flex: 0 0 36%;
  }
  .testi-box-lan {
    padding: 40px 10px 24px;
    width: 50%;
  }
  .testi-box-top {
    height: 190px;
  }
  .quotes__testi__lan {
    top: -32px;
  }
  .paid__ad__tbody__tr:nth-child(3)::after{
    width: 65.5rem;
    height: 92px;
  }
  .paid__ad__tbody__tr:nth-child(1)::after,
  .paid__ad__tbody__tr:nth-child(5)::after {
    width: 65.5rem;
    height: 64px;
  }
  .limited__spot__text{
    left: 12.5rem;
  }
  .demo__testimonial__left{
    flex: 20%;
  }
  .demo__testimonial__right{
    flex: 70%;
  }
}

@media screen and (max-width: 1280px) {
  .new_automate_background .coming_soon_automate_new{
    width: 29%;
    text-align: center;
  }
  .feature-automate-new-workflow .coming_soon_automate_new{
    width: 32%;
    text-align: center;
  }
  .designation_and_hammer_logo {
    top: 30px;
  }
  .rating_goto_new .designation_and_hammer_logo {
    top: 30px;
  }
  .rating_goto_new .testimonial_feedback_box {
    grid-template-columns: 1.2fr 3fr;
  }
  .solution_list_flex_container {
    padding: 0 35px;
  }
  .cookies_popup_container {
    width: 18%;
  }
  .feature_content_container, .onboard_content_container, .support_chatbot_it_help .support_it_hr_main_container .support_content_container  {
    height: 485px;
  }
  .support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container  {
    height: 540px;
  } 
  .support_chatbot_it_help.ntn_parent_con_ci .support_it_hr_main_container .support_content_container  {
    height: 510px;
  } 
  .support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
    top: 38px;
  }
  .blog_top_header_upt {
    height: 510px;
  }
  .play_webinar_div {
    top: 8rem;
  }
  .top-message-wrapper-pricing{
    margin-top: 16px !important;
   }
  .assit-plan-desc{
      padding-right: 8px !important;
   }
  .assit-pricing-content-right {
    gap: 10.7px !important;
  }
  .grouping-header-right-pricing svg{
    width: 14px !important;
    height: 14px !important;
  }
  .message-right-last-child svg{
    width: 14px !important;
    height: 14px !important;
  }
  .assit-pricing-content-right svg{
    width: 14px !important;
    height: 14px !important;
  }
  .ai_self_service_features_container.support_chatbot_it_help .support_it_hr_main_container .support_content_container{
  height: 540px;
  }
  .header-new-launch {
    right: 5.8rem;
    bottom: 1rem;
  }
  .header-new-launch-soon {
    right: 3.4rem;
    bottom: 1rem;
  }
  .designation_cognosante {
    top: 2rem;
  }
  .case-study-customer-box {
  padding: 30px 46px;
  }
  .designation_cognosante {
    top: 2rem;
  }
  .new-release-features{
    left: 11rem;
  }
  .new-release-features-beta{
    left: 8.8rem;
  }
  .content-item-easy {
    left: 3.6rem;
    padding-right: 3rem;
  }
 .springed-arrow-assitant {
    right: 46px;
  }
 .four-in-one {
    right: 60px;
  }
  .support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
    height: 500px;
  }
  .header-menu-flex-container {
    width: 1200px;
    justify-content: space-between;
    padding: 30px 50px;
  }
  .sub-menu-header-descriptio{
    width: 207px;
  }
  .sub-menu-header-description-last-before {
   width: 185px;
  }
  .integration-menu-white-box{
    width: 1208px;
  }
  .support_content_container.integrations-grid-layout-height {
    height: 495px !important;
  }
  .onboard_content_container.onboarding-grid-layout-height {
    height: 483px !important;
  }
  .right_cards_tab_appworkflow {
    right: 2.3rem;
    min-width: 107%;
   }
   .case-study-cta-flex-box{
    width: 87%;
   }
   .image-bottom-zero{
    position: relative;
    top: 21px;
   }
   .col-lg-6.image-box-home{
    max-width: 55%;
    flex: 0 0 55%;
  }
  .col-lg-6.content-box-home{
    max-width: 45%;
    flex: 0 0 45%;
  }
  .bottom-60px{
    bottom:35px;
  }
  .first-category-two-line {
    margin-bottom: 17px;
  }
  .custom-margin-pricing-assistants {
    margin-bottom: 8px !important;
    margin-top: 1px;
  }
  .custom-margin-pricing-assistants-last-before {
    margin-bottom: 7px !important;
  }
  .pricing-plan-last-discount{
    margin-top: 0px;
  }
  .last-message-container-assist{
    margin-bottom: 8.8px !important;
  }
  .left-flex-item-landing {
    flex: 0 0 32%;
  }
  .testi-box-lan {
    padding: 40px 10px 24px;
    width: 50%;
  }
  .testi-box-top {
    height: 190px;
  }
  .quotes__testi__lan {
    top: -32px;
  }
  .paid__ad__tbody__tr:nth-child(3)::after{
    width: 65.5rem;
    height: 86px;
  }
  .paid__ad__tbody__tr:nth-child(1)::after,
  .paid__ad__tbody__tr:nth-child(5)::after {
    width: 65.5rem;
    height: 62px;
  }
  .limited__spot__text{
    left: 12rem;
  }
  .demo__testimonial__left{
    flex: 25%;
  }
  .demo__testimonial__right{
    flex: 70%;
  }
}

@media screen and (max-width: 1024px) {
  .new_automate_background .coming_soon_automate_new{
    width: 33%;
    text-align: center;
  }
  .feature-automate-new-workflow .coming_soon_automate_new{
    width: 38%;
    text-align: center;
  }
  .designation_and_hammer_logo {
    top: 10px;
  }
  .rating_goto_new .designation_and_hammer_logo {
    top: 10px;
  }
  .solution_case_list_container {
    width: 88%;
  }
  .grid-left-container-goto {
    width: 95%;
  }
  .solution_case_list_container{
    padding: 30px 50px;
  }
  .solution_list_flex_container {
    padding: 0;
  }
  .cookies_popup_container {
    width: 19%;
  }
  .goto_popup_case{
    animation-delay:9s;
  }
  .support_content_container{
    padding: 30px 40px 0px 34px;
  }
  .support_content_container{
    padding: 30px 40px 0px 34px;
  }
  .feature_content_container .icons_container_onboarding {
    column-gap: 3px;
  }
  .support_content_container .icons_container_onboarding {
    column-gap: 10px;
  }
  .home_page_testimonials_assistant .testimonial_new_goto_image_box{
    align-self: center;
  }
  .feature_content_container{
    height: 444px;
  }
  .feature_content_left_container .icons_container_onboarding {
    top: 44px;
  }
  .support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
    top: 20px;
  }
  .support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding .icon_text{
    column-gap: 0;
  }
  .support_chatbot_it_help .support_it_hr_main_container .support_content_container .underline_hr_it_support {
    border-top: 1px solid #BBBBBA;
    width: 45%;
    float: left;
    margin: 0;
    position: relative;
    top: -1px;
    left: 21px;
  }
  .onboard_content_container, .support_chatbot_it_help .support_it_hr_main_container .support_content_container  {
    height: 430px;
    grid-template-columns: 1fr 1fr;
  }
  .support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container  {
    height: 464px;
  } 
  .support_chatbot_it_help.onboarding_itsm_height_card_otrs  .support_it_hr_main_container .support_content_container  {
    height: 500px;
  }
  .support_chatbot_it_help.ntn_parent_con_ci .support_it_hr_main_container .support_content_container  {
    height: 440px;
  } 
  .ai_self_service_features_container.support_chatbot_it_help .support_it_hr_main_container .support_content_container{
    height: 410px;
  }
  .feature_heading_container {
    gap: 7px;
  }
  .support_switch_heading, .support_switch_heading_click {
    padding: 7px 18px 5px;
  }
  .testimonials_container_support{
    width: 84%;
   }
   .button_usecase_it_support{
    width: 32% !important;
  }
  .left_cards_tab_appworkflow {
   width:100%;
  }
  .features_all_case_parent_div .support_it_hr_main_container .support_heading_container .support_switch_heading,
  .features_all_case_parent_div .support_it_hr_main_container .support_heading_container .support_switch_heading_click {
    padding: 7px 13px 5px;
  }
  .ci_single_card_slider_main {
    width: 31.2%;
  }
  .c_integration_slider_top {
    min-height: 90px;
  }
  .onboarding_itsm_height_card .onboarding_main_container .onboard_heading_container .support_switch_heading, .onboarding_itsm_height_card .onboarding_main_container .onboard_heading_container .support_switch_heading_click { 
    padding: 7px 14px 5px;
  }
  .onboarding_itsm_height_card_ssc .onboarding_main_container .onboard_heading_container .support_switch_heading, .onboarding_itsm_height_card_ssc .onboarding_main_container .onboard_heading_container .support_switch_heading_click { 
    padding: 7px 8px 5px;
  }
  .onboarding_itsm_height_card_ssc .support_it_hr_main_container .support_heading_container .support_switch_heading, .onboarding_itsm_height_card_ssc .support_it_hr_main_container .support_heading_container .support_switch_heading_click{
    padding: 7px 15px 5px;
  }
  .onboarding_itsm_height_card_ssc.onboarding_itsm_height_card_otrs  .onboarding_main_container .onboard_heading_container .support_switch_heading, .onboarding_itsm_height_card_ssc.onboarding_itsm_height_card_otrs .onboarding_main_container .onboard_heading_container .support_switch_heading_click { 
    padding: 7px 6px 5px;
  }
  .onboarding_itsm_height_card_ssc.onboarding_itsm_height_card_otrs  .support_it_hr_main_container .support_heading_container .support_switch_heading, .onboarding_itsm_height_card_ssc.onboarding_itsm_height_card_otrs .support_it_hr_main_container .support_heading_container .support_switch_heading_click{
    padding: 7px 13px 5px;
  }
  .onboarding_itsm_height_card_mamp .support_it_hr_main_container .support_heading_container{
    gap: 10px;
  }
  .onboarding_itsm_height_card_mamp .onboarding_main_container .onboard_heading_container {
    gap: 10px;
  }
  .onboarding_itsm_height_card_mamp .onboarding_main_container .onboard_heading_container .support_switch_heading, .onboarding_itsm_height_card_mamp .onboarding_main_container .onboard_heading_container .support_switch_heading_click { 
    padding: 7px 6px 5px;
  }
  .onboarding_itsm_height_card_mamp .support_it_hr_main_container .support_heading_container .support_switch_heading_click, .onboarding_itsm_height_card_mamp .support_it_hr_main_container .support_heading_container .support_switch_heading_click{
    padding: 7px 4px 5px;
  }
  .footer_last_column_padding{
    padding-left: 0px;
  }
  .nocode_wrapper_upd {
    padding: 20px 0px;
    padding-left: 16px;
  }
  .blog_top_header_upt {
    height: 400px;
  }
  .nocode_wrapper_first_hr{
    gap: 2rem;
  }
  .play_webinar_div {
    top: 6rem;
  }
  .play_webinar_div img {
    width: 100px;
  }
  .resource-content-webinar {
    height: 4rem;
  }

   .last-message-container-assist{
    margin-bottom: 12px !important;
   }
   .assit-pricing-content-right {
     gap: 9px !important;
   }
   .pricing-assist-main-container-upt{
    grid-row-gap: 50px !important;
   }
   .cta-blog-container{
    height: 140px;
   }
   .bg-blue-cta-btn, .bg-blue-cta-btn{
    width: 26%;
   }
   .font-section-normal-text-medium.support_switch_heading_click, .font-section-normal-text-medium.support_switch_heading {
    font-size: 13px !important;
   }
   .demo-grid-box {
    grid-template-columns: 50% auto;
   }
   .header-new-launch {
    right: 44.5rem;
    bottom: 1rem;
    }
    .header-new-launch-soon {
      right: 42.2rem;
      bottom: 1rem;
    }
    .footer-new-launch {
    right: -0.3rem;
    bottom: -1rem;
    }
    .footer-new-launch-coming-soon {
      right: -0.3rem;
      bottom: -1rem;
    }
    .case-study-home-heart{
      width: 30px !important;
    }
    .case-study-customer-box {
      padding: 30px 20px;
   }
   .designation_cognosante {
    top: 0rem;
  }
  .new-release-features{
    left: 10.2rem;
  }
  .new-release-features-beta{
    left: 8rem;
  }
  .content-item-easy {
    top:2rem;
    left: 2.9rem;
    padding-right: 4rem;
  }
  .feature_content_container.content-box-assitant-home-features{
    grid-template-columns: 1fr 1.7fr;
  }
  .springed-arrow-assitant {
    right: 30px;
  }
  .four-in-one {
    right: 60px;
  }
  .slack_feature_icon_onboarding, .teams_feature_icon_onboarding, .chat_feature_icon_onboarding {
    width: 18px !important;
    height: 18px !important;
  }
 .support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
    height: 420px;
  }
  .omini-channel-content-start{
    justify-content: center !important;
  }
  .support_content_container.integrations-grid-layout-height {
    height: 400px !important;
  }
  .grid-layout-little-large-support {
    grid-template-columns: 1fr 1.2fr !important;
  }
  .grid-layout-onboarding-large {
    grid-template-columns: 1fr 1.33fr !important;
  }
  .onboard_content_container.onboarding-grid-layout-height {
    height: 384px !important;
  }
  .right_cards_tab_appworkflow img {
    max-width: 68%;
    position: relative;
    left: 8rem;
  }
  .right_cards_tab_appworkflow {
    right: 10rem;
    min-width: 113%;
   }
  .case-study-cta-flex-box{
    width: 84%;
  }
  .image-bottom-zero{
    position: relative;
    top: 45px;
   }
   .col-lg-6.image-box-home{
    max-width: 52%;
    flex: 0 0 52%;
  }
  .col-lg-6.content-box-home{
    max-width: 48%;
    flex: 0 0 48%;
  }
  .header-count-image{
    left: 0rem;
  }
  .bottom-60px{
    bottom:20px;
  }
  .assit-plan-desc{
    padding-right: 55px !important;
  }
  .assit-pricing-content-left{
    gap: 9.5px !important;
  }
  .first-category-two-line {
    margin-bottom: 0px;
 }
 .custom-margin-pricing-assistants {
  margin-bottom: 12px !important;
  margin-top: 2px;
}
.custom-margin-pricing-assistants-last-before {
  margin-bottom: 7px !important;
}
.pricing-plan-last-discount{
  margin-top: 6px;
}
.last-message-container-assist{
  margin-bottom: 12.5px !important;
}
.flex-box-testi-lan {
  gap: 2rem;
}
.tab__icon__landing {
  width: 30px;
  height: 30px;
}
.testi-box-lan {
  padding: 40px 10px 24px;
  width: 50%;
}
.testi-box-top {
  height: 190px;
}
.quotes__testi__lan {
  top: -32px;
}
.tab__button__landing {
  padding: 0px;
}
.left-flex-item-landing {
  flex: 0 0 32%;
}
.paid__ad__tbody__tr:nth-child(3)::after{
  width: 54.3rem;
  height: 86px;
}
.paid__ad__tbody__tr:nth-child(1)::after,
.paid__ad__tbody__tr:nth-child(5)::after {
  width: 54.3rem;
  height: 62px;
}
.limited__spot__text{
  left: 10rem;
}
.demo__testimonial__left {
  flex: 10%;
}
} 
@media screen and (max-width: 820px) {
.play_webinar_div {
    top: 5rem;
}
.play_webinar_div img {
  width: 80px;
}
.bg-green-cta-btn, .bg-blue-cta-btn{
  width:35%
}
.beta-button .sign_up_form button {
  font-size: 13px !important;
}
.card-box-home-main-container button {
  width: 50%;
}
.card_automate_box_paragraph, .card_assistants_box_paragraph {
  height: 38%;
}
.new-release-features{
  left: 10rem;
}
.new-release-features-beta{
  left: 7.5rem;
}
.content-item-easy {
  top: 1.5rem;
  left: 2.3rem;
  padding-right: 3rem;
}
.four-in-one {
  right: 60px;
  width: 10%;
}
.feature_content_container.content-box-assitant-home-features {
  grid-template-columns: 1fr 1fr;
}
}
@media screen and (max-width: 800px) {
.beta-button .sign_up_form button {
  font-size: 13px !important;
}
.card_automate_box_paragraph, .card_assistants_box_paragraph {
  padding-right: 0% !important;
  height: 40%;
}
.card-box-home-main-container button {
  width: 50%;
}
}
@media screen and (max-width: 770px) {
  .play_webinar_div {
    top: 4.3rem;
}
.play_webinar_div img {
  width: 70px;
}
}
@media screen and (max-width: 520px) {
  .new_automate_background .coming_soon_automate_new{
    width: 44%;
    text-align: center;
  }
  .testimonial_logo_mobile_goto, .new_goto_mobile_logo{
    width: 40% !important;
  }
  .testimonial_new_slider .testimonial_background_main_container_mobile {
   padding: 40px 10px;
   margin-bottom:0px;
  }
  .testimonial_new_slider .col-lg-12, .testimonial_new_slider .col-md-12, .testimonial_new_slider .col-sm-12{
    padding:0px !important;
  }
  .testimonial_hammer_logo_mobile_new{
    width: 80% !important;
  }
  .line-height-199{
    line-height: 1.83 !important;
  }
 .bottom_container_testimonial_mobile{
    grid-gap: 20px;
  }
  .goto_case_grid_container{
   grid-auto-flow: row;
   grid-gap: 20px
  }
  .grid_container_goto_second{
    flex-direction: column;
    gap: 34px;
  }
  .cards_goto_case {
    gap: 10px;
  }
  .cards_goto_case div{
    padding: 12px 6px;
  }
  .goto_case_right_top{
    margin-top: 0;
  }
  .content_goto_logo {
    width: 15%;
  }
  .solution_img_case_goto {
    width: 80%;
  }
  .solution_case_list_container {
    width: 100%;
    padding: 30px 20px;
  }
  .solution_list_flex_container{
    flex-direction: column;
  }
  .solution_case_list_container h3 {
    border-bottom: 2px solid #A4A0E6;
    padding-bottom: 10px;
    text-decoration: none;
  }
  .why_goto_case_right {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 2px solid #000000;
    border-bottom-right-radius: 0px;
    padding: 30px 20px 0px;
    border-left: none;
 }
 .why_goto_case_section {
   flex-direction: column-reverse;
 }
 .goto_quote_icon_case {
    width: 16%;
    position: static;
 }
 .goto_manager_case_mobile{
    width: 30%;
 }
 .why_goto_case_left {
    align-items: flex-start;
    padding-left: 20px;
    width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
 }
 .mobile_why_goto_manager{
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
 }
 .why_goto_logo_mobile{
   width: 20%;
 }
 .why_goto_order_list {
  line-height: 1.8;
  margin-bottom: 20px;
}
.last_text_case_goto {
  width: 100%;
  color: #193A5D;
  margin-top: 20px;
}
.goto_case_right_card{
  padding: 24px;
}
.right_card_case_logo_mobile{
  width: 30%;
}
.right_card_case_logo_mobile{
  margin-top: 10px;
  gap: 10px;
}
.last_line_goto_case{
  border: 1px solid #BCBCBC;
  width: 40%;
}
.why_goto_order_list li {
  display: inline-flex;
}
.solution_list_left_flex{
  margin-top: 14px;
  gap:10px;
}
.grid-left-container-goto h2 {
  text-decoration: none;
}
.case_study_second_card {
  align-items: center;
}
.goto_case_book_demo_button {
  width: 60%;
}
.case_study_second_card {
  margin: auto;
  width: 98%;
}
.grid-left-container-goto{
  width:100%;
}
.goto_popup_case {
  width: 294px;
  padding: 10px 10px 14px;
}
.close_popup_goto {
  width: 22px;
}
.cookies_popup_container {
  width: 60%;
}
.feature_content_container, .onboard_content_container  {
  height: auto;
  margin-bottom: 20px;
}
.onboard_content_container  {
 grid-auto-flow: row;
 grid-template-columns: 1fr;
}
.support_chatbot_it_help .support_it_hr_main_container .support_content_container  {
  height: auto;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
}
.support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container {
  height: auto;
}
.support_chatbot_it_help.ntn_parent_con_ci .support_it_hr_main_container .support_content_container  {
  height: auto;
} 
.no_code_button_teams_slack{
  width: 100% !important;
}
.support_chatbot_it_help .support_it_hr_main_container{
  padding-bottom: 10px;
}
.onboarding_testimoanials_main_container .onboarding_main_container {
  margin-bottom: 0px;
  padding: 30px 0 0px;
}
.support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding {
  top: 10px;
}
.support_chatbot_it_help .support_it_hr_main_container .support_content_container .icons_container_onboarding .icon_text{
  column-gap: 7px;
}
.features_updated_nov .features_header_home {
  padding:30px 0 20px !important;
}
.features_all_case_parent_div{
  padding: 40px 0 10px;
  gap: 0px;
}
.bamboo_logo_image{
  width: 18% !important;
  height: 18% !important;
}
.ci_slider_container{
  padding: 34px 0 40px;
}
.ci_single_card_slider_container{
  flex-direction: row;
}
.ci_single_card_slider_main {
  width: 100%;
}
.c_integration_slider_box {
  margin: 2px 0 0 !important;
}
.c_integration_slider_top {
  min-height: 84px;
}
.ci_slider_workflow_navigation {
  margin-top: 16px !important;
}
.onboarding_itsm_height_card_mamp .support_it_hr_main_container .support_heading_container, .onboarding_itsm_height_card_mamp .support_it_hr_main_container .support_heading_container_clicked {
  padding: 6px 12px 7px;
}
._step_onboarding_mobile{
  position: relative;
  bottom: 1px;
}
.radio_btn_contact_prdt{
  position: relative;
  top: 2px;
}
.faq_btn_feature_parent {
  gap: 10px;
}
.faq_btn_feature {
  gap: 3px;
}
.feature_ind_chnl_con{
  flex-direction: column;
  width: 100%;
  padding: 30px 20px 20px;
}
.all_apps_automate_marketplace_integration {
  padding: 30px 0 0px !important;
}
.footer_last_column_padding{
  padding-left: 0px;
}
.new-pricing-page-automate:before {
  height: calc(43% - 148px);
}
.nocode_wrapper_upd{
 flex-direction: column;
 gap: 10px;
}
.nocode_wrapper_upd button{
  width: 54%;
}
.grid_container_goto_second.grid_blogs_container_mobile{
  gap: 24px;
}
.divs_blogs_upt {
  margin-bottom: 1.5rem;
}
.margin-ul-btm {
  margin-bottom: 1rem !important;
}
.author_bio_flex_container{
  flex-direction: column-reverse;
}
.author_bio_parent_cont {
  border-radius: 0%;
}
.author_bio_flex_left{
  display: flex;
  gap: 20px;
}
.blog_top_header_upt{
  background-image: url("../../assets/img/blogs/mobile_help_header.png");
}
.blog_top_header_upt.hr_blog_header_upt{
  background-image: url("../../assets/img/blogs/hr_blog_header_mob.png");
}
.blog_top_header_upt.ai_blog_header_upt{
  background-image: url("../../assets/img/blogs/ai_blog_header_mob.png");
}
.blog_top_header_upt.sda_blog_header_upt{
  background-image: url("../../assets/img/blogs/sda_header_mob.png");
}
.blog_top_header_upt.it_support_blog_header_upt{
  background-image: url("../../assets/img/blogs/it_support_blog_header_mob.png");
}
.blog_top_header_upt.hr_help_desk_blog_header_upt{
  background-image: url("../../assets/img/blogs/hr_help_desk_blog_header_mob.png");
}
.blog_top_header_upt.itsm_blog_header_upt{
  background-image: url("../../assets/img/blogs/itsm_blog_header_mob.png");
}
.blog_top_header_upt.hr_va_guide_blog_header_upt{
  background-image: url("../../assets/img/blogs/hr_va_guide_header_mob.png");
}
.blog_top_header_upt.roi_ch_blog_header_upt{
  background-image: url("../../assets/img/blogs/roi_blog_header_mob.png");
}
.blog_top_header_upt.sdc_blog_header_upt{
  background-image: url("../../assets/img/blogs/sdc_blog_header_mob.png");
}
.blog_top_header_upt.esd_blog_header_upt{
  background-image: url("../../assets/img/blogs/esd_blog_header_mob.png");
}
.blog_top_header_upt.hdsd_blog_header_upt{
  background-image: url("../../assets/img/blogs/hdsd_blog_header_mob.png");
}
.blog_top_header_upt.hsmg_blog_header_upt{
  background-image: url("../../assets/img/blogs/hsmg_blog_header_mob.png");
}
.blog_top_header_upt.tit_blog_header_upt{
  background-image: url("../../assets/img/blogs/tit_blog_header_mob.png");
}
.blog_top_header_upt.gae_blog_header_upt{
  background-image: url("../../assets/img/blogs/gae_blog_header_mob.png");
}
.blog_top_header_upt.aiitsm_blog_header_upt{
  background-image: url("../../assets/img/blogs/aiitsm_blog_header_mob.png");
}
.blog_top_header_upt.why_gpt_blog_header_upt{
  background-image: url("../../assets/img/blogs/why_gpt_blog_header_mob.png");
}
.blog_top_header_upt.itsmdt_blog_header_upt{
  background-image: url("../../assets/img/blogs/itsmdt_blog_header_mob.png");
}
.blog_top_header_upt.product_blog_header_upt{
  background-image: url("../../assets/img/blogs/product_blog_header_mob.png");
}
.blog_top_header_upt.wmt_blog_header_upt{
  background-image: url("../../assets/img/blogs/wmt_blog_header_mob.png");
}
.blog_top_header_upt.tnfie_blog_header_upt{
  background-image: url("../../assets/img/blogs/tnfie_blog_header_mob.png");
}
.blog_top_header_upt.mtbsd_blog_header_upt{
  background-image: url("../../assets/img/blogs/mtbsd_blog_header_mob.png");
}
.blog_top_header_upt.hps_blog_header_upt{
  background-image: url("../../assets/img/blogs/hsp_blog_header_mob.png");
}
.blog_top_header_upt.llm_blog_header_upt{
  background-image: url("../../assets/img/blogs/llm_blog_header_mob.png");
}
.blog_top_header_upt.gai_blog_header_upt{
  background-image: url("../../assets/img/blogs/gai_blog_header_mob.png");
}
.blog_top_header_upt.gaitsm_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaitsm_blog_header_mob.png");
}
.blog_top_header_upt.hris_blog_header_upt{
  background-image: url("../../assets/img/blogs/hris_blog_header_mob.png");
}
.blog_top_header_upt.gaesd_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaesd_blog_header_mob.png");
}
.blog_top_header_upt.gasic_blog_header_upt{
  background-image: url("../../assets/img/blogs/gasic_blog_header_mob.png");
}
.blog_top_header_upt.tvsg_blog_header_upt{
  background-image: url("../../assets/img/blogs/tsvg_blog_header_mob.png");
}
.blog_top_header_upt.lssd_blog_header_upt{
  background-image: url("../../assets/img/blogs/lssd_blog_header_mob.png");
}
.blog_top_header_upt.gaepc_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaepc_blog_header_mob.png");
}
.blog_top_header_upt.gakma_blog_header_upt{
  background-image: url("../../assets/img/blogs/gakma_blog_header_mob.png");
}
.blog_top_header_upt.gaisd_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaisd_blog_header_mob.png");
}
.blog_top_header_upt.igikb_blog_header_upt{
  background-image: url("../../assets/img/blogs/igikb_blog_header_mob.png");
}
.blog_top_header_upt.gad_blog_header_upt{
  background-image: url("../../assets/img/blogs/gad_blog_header_mob.png");
}
.blog_top_header_upt.badoo_blog_header_upt{
  background-image: url("../../assets/img/blogs/badoo_blog_header_mob.png");
}
.blog_top_header_upt.gaso_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaso_blog_header_mob.png");
}
.blog_top_header_upt.istaga_blog_header_upt{
  background-image: url("../../assets/img/blogs/istaga_blog_header_mob.png");
}
.blog_top_header_upt.garrt_blog_header_upt{
  background-image: url("../../assets/img/blogs/garrt_blog_header_mob.png");
}
.blog_top_header_upt.dtga_blog_header_upt{
  background-image: url("../../assets/img/blogs/dtga_blog_header_mob.png");
}
.blog_top_header_upt.lgasdc_blog_header_upt{
  background-image: url("../../assets/img/blogs/lgasdc_blog_header_mob.png");
}
.blog_top_header_upt.cefc_blog_header_upt{
  background-image: url("../../assets/img/blogs/cefc_blog_header_mob.png");
}
.blog_top_header_upt.ces_blog_header_upt{
  background-image: url("../../assets/img/blogs/ces_blog_header_mob.png");
}
.blog_top_header_upt.kaes_blog_header_upt{
  background-image: url("../../assets/img/blogs/kaes_blog_header_mob.png");
}
.blog_top_header_upt.weio_blog_header_upt{
  background-image: url("../../assets/img/blogs/ebook_blog_header_mob.png");
}
.blog_top_header_upt.kaeq_blog_header_upt{
  background-image: url("../../assets/img/blogs/kaeq_blog_header_mob.png");
}
.blog_top_header_upt.gaavesd_blog_header_upt{
  background-image: url("../../assets/img/blogs/gaavesd_blog_header_mob.png");
}
.blog_top_header_upt.gahs_blog_header_upt{
  background-image: url("../../assets/img/blogs/gahs_blog_header_mob.png");
}
.blog_top_header_upt.kaess_blog_header_upt{
  background-image: url("../../assets/img/blogs/kaess_blog_header_mob.png");
}
.blog_top_header_upt.ceo_blog_header_upt{
  background-image: url("../../assets/img/blogs/ceo_blog_header_mob.png");
}
.blog_top_header_upt.kabp_blog_header_upt{
  background-image: url("../../assets/img/blogs/kabp_blog_header_mob.png");
}
.blog_top_header_upt.gash_blog_header_upt{
  background-image: url("../../assets/img/blogs/gash_blog_header_mob.png");
}
.blog_top_header_upt.itsm_2024_blog_header_upt{
  background-image: url("../../assets/img/blogs/itsm_2024_blog_header_mob.png");
}
.blog_top_header_upt.top_itsm_2024_blog_header_upt{
  background-image: url("../../assets/img/blogs/top_itsm_2024_blog_header_mob.png");
}
.blog_top_header_upt.eoka_blog_header_upt{
  background-image: url("../../assets/img/blogs/eoka_blog_header_mob.png");
}
.blog_top_header_upt.eisdm_blog_header_upt{
  background-image: url("../../assets/img/blogs/eisdm_blog_header_mob.png");
}
.blog_top_header_upt {
  height: 700px;
}
.llm_blog_header_upt .flex_cont_top_blog_img {
  margin-top: 5rem;
}
.lgasdc_blog_header_upt .flex_cont_top_blog_img {
  margin-top: 5rem;
}
.burn_blog_header .flex_cont_top_blog_img{
  margin-top: 5rem;
}
.blog_top_header_upt{
  align-items: flex-start;
}
.flex_cont_top_blog_img{
  margin-top: 7.5rem;
}
.faq_blog_upd {
  margin-top: 30px;
}
.nocode_wrapper_upd {
  padding: 20px 0px;
}
.blogs_ol_link_upd li {
  margin-bottom: 16px;
}
.ul_related_blogs_upd {
  padding-top: 20px !important;
}
.support_chatbot_it_help.onboarding_itsm_height_card_otrs  .support_it_hr_main_container .support_content_container  {
  height: auto;
}
.support_chatbot_it_help.onboarding_itsm_height_card_otrs  .support_it_hr_main_container .support_heading_container  {
  padding: 6px 15px 7px;
 }
 .ai_self_service_features_container.support_chatbot_it_help .support_it_hr_main_container .support_content_container {
  height: auto;
}
 .video_page_upt{
   flex-direction: column;
 }
 .resource_wrapper, .get_started_wrapper{
   width:100% !important;
 }
 .pl-5-7{
  padding-left: 4.7rem !important;
 }
 .pl-8-5{
  padding-left: 7rem !important;
 }
 .webinar-vd-flex{
  flex-direction: column;
 }
 .webinar-video-box{
  width: 100% !important;
 }
 .date-register-webinar {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
 }
 .webinar-top-bottom-box {
  padding: 16px 20px 34px;
 }
 .webinar-upcoming {
  top:-30px;
 }
 .webinar-utube-container .container_trial .landing_bg.bg_header_trial.trial_mb_80 .main-slider-left .basic_plans.signup_cta_p {
  margin-top: 0.25rem !important;
 }
 .webinar-utube-container .container_trial .landing_bg .assistant_right_img.beta-slider img {
  padding-bottom: 0px !important;
 }
 .webinar-videos-main-container {
  padding-bottom: 60px;
 }
 .web-single-flex-container{
  flex-direction: column;
  gap:20px;
 }
 .webinar-resources-flex{
  flex-direction: column;
  gap:30px;
 }
 .webinar-resource-box{
  border-radius: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #0000001F;
 }
 .resource-box-up img{
  border-radius: 10px 10px 0px 0px;
 }
 .webinar-single-container {
 margin-bottom: 40px;
 }
 .play_webinar_div {
  top:6rem;
 }
 .play_webinar_div img {
  width: 70px;
 }
.resource-content-webinar {
  height: auto;
}
.resource-box-down {
  padding: 20px 30px;
}
.goto_manager_image_mobile {
  width: 30% !important;
}
.assit-pricing-content-right {
  gap: 10.8px !important;
}
.cmng-soon-wrapper-pricing-assist{
  margin-bottom: -10px !important;
}
.grouping-header-pricing{
  margin-top: 8px !important;
}
.last-before-element-grouping {
  margin-top: 0px !important;
  padding-top: 12px !important;
}
.plan-wrap-assit {
  padding: 10px 13px 6px !important;
}
.mob_view_pricing_upt{
  height: 55px !important;
}
.mobile-view-last-before-mt{
  margin-top: 5px !important;
}
.pricing-card-box-assist:nth-child(2){
  margin-top:20px !important;
}
.pricing-assist-main-container-upt{
  grid-row-gap: 50px !important;
}
.mtp-1 {
  margin-top: 0px !important;
}
.webinar-single-container {
padding-bottom: 40px !important;
}
.webinar-speaker-container {
  gap:30px !important;
}
.automate_testimonial_director{
  width:50%;
}
.am_cards_wrapper.actions_wrapper .actions_wrapper_container{
  border-bottom: 0px !important;
}
.bg-green-cta-btn, .bg-blue-cta-btn{
  width: 60%;
}
.cta-blog-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0;
  height: 21rem;
  background-size: cover !important;
  background-position: center !important;
  justify-content: center;
  border-radius:none;
}
.demo-grid-box{
  grid-auto-flow:row;
  grid-template-columns: 100%;
}
.demo-grid-box-left{
  grid-column: 0 !important;
}
.demo-grid-box-right{
  grid-column: 0 !important;
  align-self: center;
}
.goto_case_grid_container.cognosante-grid-container {
  grid-template-columns: 1fr;
  gap: 40px;
}
.cognosante_case_left_top{
  gap: 30px;
  margin-top:50px;
}
.goto_case_section_desk.cognosante_case_section_desk {
  background-size: cover;
  background-position: bottom;
}
.related-articles-flex-wrapper{
  flex-direction: column;
}
.height-5-rem-mob{
 height: 5rem;
}
.header-new-launch {
  right: 11rem;
  bottom: 1rem;
}
.header-new-launch-soon {
  right: 13.3rem;
  bottom: 1rem;
}
.footer-new-launch {
  right: 14rem;
  bottom: 0.8rem;
}
.footer-new-launch-coming-soon {
  right: 14rem;
  bottom: 0.8rem;
}
.case-study-flex-box{
  flex-direction: column;
}
.case-study-section-container {
  padding-top: 10px;
}
.case-study-home-heart{
  width: 27px !important;
}
.case-study-book-demo{
  width: 35%;
}
.ad-case-study-bottom{
margin-top: 20px;
}
.bottom-box-customer-case{
  padding: 20px 20px;
}
.cefc_blog_header_upt .flex_cont_top_blog_img, .ces_blog_header_upt .flex_cont_top_blog_img, .kaes_blog_header_upt .flex_cont_top_blog_img, .gahs_blog_header_upt .flex_cont_top_blog_img, .kaess_blog_header_upt .flex_cont_top_blog_img, .ceo_blog_header_upt .flex_cont_top_blog_img {
 margin-top: 0 !important;
}
.blog_top_header_upt.cefc_blog_header_upt, .blog_top_header_upt.ces_blog_header_upt, .blog_top_header_upt.kaes_blog_header_upt, .blog_top_header_upt.gahs_blog_header_upt, .blog_top_header_upt.kaess_blog_header_upt, .blog_top_header_upt.ceo_blog_header_upt{
  align-items: center !important;
}
.blog-text-color-padding{
 padding: 5px;
}
.mobile-case-study-testi{
 width: 18%;
 position: relative;
 top: 28px;
}
.case-study-cta-flex-box-mobile{
  padding: 58px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 10px 20px #0000001a;
}
.case-study-cta-img-box {
  width: 45%;
}
.case-study-mobile-button{
  background: #2468F6 0% 0% no-repeat padding-box;
  border-radius: 32px;
  width: 52%;
  padding: 4px 0;
  border: none;
  outline: none;
  color:#fff;
  margin-top: 13px;
  padding: 10px 0 6px;
}
.bottom-20{
  bottom: 20px !important;
}
.case-study-cta-img-blog{
  width: 40%;
  height: 40%;
}
.new-release-features{
  left: 0rem;
  top: -1.4rem;
}
.new-release-features-beta{
  left: 0rem;
  top: -1.4rem;
}
.shared-inbox-content-box, .shared-inbox-image-box{
  width: 100%;
}
.grid-container-easy-platform {
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: 1fr;
  gap: 0px;
}
.flex-container-goto-knowledge-ai{
  flex-direction: column;
}
.flex-item-goto-knowledge-ai.flex-2 p{
  padding-right: 0;
}
.grid-container-grade-security {
  grid-template-columns: 1fr;
  row-gap: 10px;
  width: 90%;
}
.grade-security-section-container {
  margin-bottom: 20px;
}
.content-item-easy {
  top: 1.9rem;
  left: 2.3rem;
  padding-right: 3rem;
}
.goto-knowledge-ai-section{
  padding: 50px 0;
}
.flex-container-enterprise-success {
  flex-direction: column;
}
.four-in-one {
  left: 54px;
  width: 18%;
}
.support_chatbot_it_help .features_all_case_parent_div .support_it_hr_main_container .support_content_container {
  height: auto;
  grid-template-columns: 1fr;
}
.font-header-menu-bold-small{
  font-family: "Gordita-Bold" !important;
  font-size: 13px !important;
}
.font-header-menu-medium-small{
  font-family: "Gordita-Medium" !important;
  font-size: 12px !important;
}
.blog-home-header-flex-container{
  flex-direction: column;
}
.flex-container-blog-home{
  flex-direction: column;
}
.flex-wrapper-blog-home {
  width: 100%;
}
.col-lg-6.image-box-home{
  max-width: 100%;
  flex: 0 0 100%;
}
.col-lg-6.content-box-home{
  max-width: 100%;
  flex: 0 0 100%;
}
.image-bottom-zero {
  position: relative;
  top: 14px;
}
.header-count-image {
  left: 3rem;
  top: -3px;
  width: 2.25rem;
}
.header-count-wih-image-container{
  align-items: flex-start;
}
.assit-pricing-content-left {
  gap: 10px !important;
}
.first-category-two-line {
  margin-bottom: 16px;
}
.custom-margin-pricing-assistants {
  margin-bottom: 12px !important;
  margin-top: 2px;
}
.custom-margin-pricing-assistants-last-before {
  margin-bottom: 9px !important;
}
.pricing-plan-last-discount{
  margin-top: 12px;
}
.last-message-container-assist{
  margin-bottom: 12.5px !important;
}
.flex-container-landing, .tabs__wrapper__landing, .right-box-testi-lan, .flex-container-demo-signup-landing{
  flex-direction: column;
}
.left-flex-item-landing, .right-flex-item-landing{
  flex: 0 0 100%;
}
.tabs__wrapper__landing{
  border-bottom: none;
}
.tab__items__button__icon{
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
  padding: 0 0px 7px;
}
.tab__icon__landing {
  width: 30px;
  height: 30px;
}
.tab__button__landing {
  text-align: left;
}
.tabs__content__container__landing{
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.flex-box-testi-lan{
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  gap: 3rem;
}
.testi-box-lan{
  width: 100%;
  padding: 40px 30px 24px;
}
.custom__lineheight.font-text-medium-goto-solution{
  line-height: 1.8 !important;
}
.testi-box-top {
  height: 220px;
}
.flex-container-demo-signup-landing, .flex-container-demo-signup-landing-with-form{
  gap: 2rem !important;
}
.form__paid__ad{
  gap: 0;
}
.right-item-demo-signup-lan-form{
  width: 100%;
}
.flex-container-demo-signup-landing-with-form{
 justify-content: center !important;
}
.left-item-demo-signup-lan{
  align-self: start;
}
.right-item-demo-signup-lan{
  width: 100%
}
.header__cointainer__form__paid__ad{
  width: 100%;
}
.right-box-testi-lan{
  gap: 5rem;
}
.apps-section-landing {
  margin: 40px 0 40px;
}
.header__logo__landing {
  width: 30%;
}
.book-demo-sign-up-section-landing {
  padding: 40px 0;
  margin-bottom: 40px;
}
.box__paid__ad__table{
  display: flex;
  overflow-x: scroll;
  min-width: 100%;
  scroll-behavior: smooth;
}
.table__description__paid__ad{
  min-width: 20rem;
}
.paid__ad__tbody__tr:nth-child(3)::after{
  width: 63.2rem;
  height: 86px;
}
.paid__ad__tbody__tr:nth-child(1)::after,
.paid__ad__tbody__tr:nth-child(5)::after {
  width: 63.2rem;
  height: 62px;
}
.table__section__paid__ad{
  margin-bottom: 50px;
}
.paid__ad__book__demo__integrate__icons {
  gap: 5px;
}
.limited__spot__text{
  left: 0;
}
.demo-wide-landing-number-box {
  gap: 5px;
  flex-direction: column;
  align-self: flex-start;
}
.demo-content-box-wide-landing{
  margin-top: 20px;
  margin-bottom: 30px;
}
.book-demo-box-calendly{
  margin-bottom: 20px;
}
.book-demo-calendly-paid-lan{
  padding: 50px 0px;
}
.wide-head-section-landing-mobile{
  background: linear-gradient(#CCFFEC, #CCFFEC) 0 0 / 100% 68% no-repeat;
}
.list__items__paid__tick{
  position: relative;
  top: 2px;
  width: 14px;
}
}
@media screen and (max-width: 486px){
.content-item-easy {
  top: 3.5rem;
  left: 3rem;
  padding-right: 4rem;
}
}

@media screen and (max-width: 445px){
  .goto_manager_image_mobile{
    width: 30% !important;
  }
  .line-height-199 {
    line-height: 1.96 !important;
  }
  .testimonial_new_slider_mobile .testimonial_reviewer_image {
    width: 30%;
  }
  .testimonial_new_slider_mobile .testimonial_review_bottom_image_box_mobile {
    gap:20px;
  }
  .play_webinar_div {
    top: 5rem;
  }
  .header-new-launch {
    right: 12.6rem;
    bottom: 1rem;
 }
.header-new-launch-soon {
  right: 9.6rem;
  bottom: 1rem;
}
.footer-new-launch {
  right: 15.8rem;
  top: -0.8rem;
}
.footer-new-launch-coming-soon {
  right: 13rem;
  top: -0.8rem;
}
.content-item-easy {
  top: 2.5rem;
  left: 3rem;
}
.features_all_case_parent_div.features-all-shared-inbox {
  padding-bottom: 0px !important;
}
.wide-head-section-landing-mobile{
  background: linear-gradient(#CCFFEC, #CCFFEC) 0 0 / 100% 70% no-repeat;
}
}
@media screen and (max-width: 425px){
.footer-new-launch {
    right: 14.8rem;
  }
.footer-new-launch-coming-soon {
    right: 12.1rem;
  }
.header-new-launch {
    right: 11.6rem;
 }
.header-new-launch-soon {
  right: 8.8rem;
}
}

@media screen and (max-width: 414px){
    .goto_manager_image_mobile {
        width: 32% !important;
    }
    .testimonial_new_slider_mobile .testimonial_reviewer_image {
      width: 32%;
    }
    .line-height-199 {
      line-height: 1.82 !important;
    }
    .play_webinar_div {
      top: 5rem;
  }
    .footer-new-launch {
      right: 14.1rem;
      top: -0.8rem;
  }
   .footer-new-launch-coming-soon {
    right: 11.5rem;
    top: -0.8rem;
  }
   .header-new-launch {
    right: 11rem;
    bottom: 1rem;
  }
   .header-new-launch-soon {
    right: 8.2rem;
    bottom: 1rem;
  }
  .content-item-easy {
    top: 1.9rem;
    left: 2.5rem;
    padding-right: 3rem;
  }
  .wide-head-section-landing-mobile{
    background: linear-gradient(#CCFFEC, #CCFFEC) 0 0 / 100% 68% no-repeat;
  }
  }
  @media screen and (min-width: 376px) and (max-width: 390px){
    .play_webinar_div {
      top: 4.5rem;
    }
    .header-new-launch {
      right: 9.4rem;
      bottom: 1rem;
    }
    .header-new-launch-soon {
      right: 6.6rem;
      bottom: 1rem;
    }
    .footer-new-launch {
      right: 12.5rem;
      top: -0.8rem;
   }
   .footer-new-launch-coming-soon {
    right: 9.5rem;
    top: -0.8rem;
  }
   .content-item-easy {
    top: 1.5rem;
    left: 2.3rem;
    padding-right: 3rem;
   }
   .wide-head-section-landing-mobile{
    background: linear-gradient(#CCFFEC, #CCFFEC) 0 0 / 100% 71% no-repeat;
  }
  }

@media screen and (max-width: 375px){
  .line-height-199 {
    line-height: 1.99 !important;
  }
  .goto_manager_image_mobile {
    width: 35% !important;
  }
  .testimonial_new_slider_mobile .testimonial_reviewer_image {
    width: 32%;
  }
  .play_webinar_div {
    top: 4rem;
  }
  .footer-new-launch {
    right: 11.7rem;
    top: -0.8rem;
 }
 .footer-new-launch-coming-soon {
  right: 9rem;
  top: -0.8rem;
}
 .header-new-launch {
  right: 9rem;
  bottom: 1rem;
}
.header-new-launch-soon {
  right: 6.3rem;
  bottom: 1rem;
}
.content-item-easy {
  top: 1.5rem;
  left: 2.3rem;
  padding-right: 3rem;
}
.four-in-one {
  left: 95px;
}
.image-bottom-zero {
  position: relative;
  top: 12px;
}
.wide-head-section-landing-mobile{
  background: linear-gradient(#CCFFEC, #CCFFEC) 0 0 / 100% 72% no-repeat;
}
}

@media screen and (min-width: 520px) and  (max-width: 992px)  {
  .automate_marketplace .dropdown_left {
    width: 100%;
    float: left;
    border:none !important;
    padding-top: 0px !important;
    border-radius: 0;
  }
}
@media screen and (max-width: 1440px) {
.slack_feature_icon_onboarding, .teams_feature_icon_onboarding, .chat_feature_icon_onboarding{
  width: 24px;
  height: 24px;
 }
}
@media screen and (min-width: 1441px) and  (max-width: 1512px) {
  .new_home_page_main_container .ibm-left-section-bottom {
    padding-bottom: 10px;
  }
  .support_it_hr_main_container h4 {
   padding: 0px 17%;
  }
  .heading_services_support_home {
   margin-bottom: 20px;
  }
  .heading_goto_testimonials_new {
    padding: 0 8%;
  }
  .line-height-18 {
   line-height: 1.6 !important;
 }
 .goto_case_study_button_redirection {
  margin-top: 10px!important;
 }
 .feature_content_container, .onboard_content_container, .support_chatbot_it_help .support_it_hr_main_container .support_content_container  {
  height: 480px;
}
.ai_self_service_features_container.support_chatbot_it_help .support_it_hr_main_container .support_content_container{
  height: 500px;
}
.support_chatbot_it_help.mdm_parent_con_ci .support_it_hr_main_container .support_content_container  {
  height: 500px;
} 
.support_chatbot_it_help.ntn_parent_con_ci .support_it_hr_main_container .support_content_container .right_container_tab_slider_it_suuport .left_cards_tab_appworkflow .cards_all_main_container_app_workflow .cards_single_main_container_app_workflow   {
  padding: 14px 6px 12px;
}
.support_chatbot_it_help.xero_chatbot_integration_upt .support_it_hr_main_container .support_content_container .right_container_tab_slider_it_suuport .left_cards_tab_appworkflow .cards_all_main_container_app_workflow .cards_single_main_container_app_workflow   {
  padding: 14px 6px 12px; 
}

.ai_self_service_features_container.support_chatbot_it_help .support_it_hr_main_container .support_content_container .support_content_left_container {
  padding-bottom: 28px;
}
.slack_feature_icon_onboarding, .teams_feature_icon_onboarding, .chat_feature_icon_onboarding{
  width: 24px;
  height: 24px;
}
.testimonial_hammer_logo {
  width: 230px !important;
}
.main-slider-button.beta-button form.sign_up_form {
  grid-template-columns: 1fr 40%;
}
.ad_home_page_main_container h4 {
  padding: 0 16%;
}
.left-whitespace {
  left: -17% !important;
}
.home_link {
  padding: 0px 20px 30px 20px;
}
.workflow_arrow_svg {
  width: 56%;
}
.last-message-container-assist{
  margin-bottom: 11.8px !important;
}
 .downshift-ul {
  width: 86.8%;
}
}
@media screen and (min-width: 1366px) and (max-width: 1439px) {
  .pricing-plan-last-discount {
    margin-top: 0px;
  }
  .assit-plan-desc.description-plan-last{
    margin-bottom: 8px !important;
  }
}


@media screen and (min-width: 1440px) and  (max-width: 1680px)  {
.autoamte_apps_header_left {
  padding: 7.5% 0% !important;
 }
}
@media screen and (min-width: 2058px) and  (max-width: 2560px)  {
  .form_below_content.signup_cta span {
    font-size: 28px;
  }
  .goto_case_study_button_redirection, .support_switch_heading_click, .support_switch_heading  {
    border-radius: 50px;
  }
  .header_product_name{
    font-size:26px !important;
  }
  .container_trial .header_menu p {
    font-size: 22px;
    max-width: 340px;
  }
  .header_menu span svg {
    width: 46px !important;
    height: 46px !important;
  }
  .header_button_style {
    width: 370px;
    height: 54px;
    border-radius: 50px;
  }
  .paid-landing-page-icon-list{
    display: flex;
    align-items: center;
    height: 100px;
  }
  .all_apps_sec_header_assist{
    min-height: 100px !important;
  }
  .apps_landing_cards_wrapper ul li img{
    width: 65px;
    height: 65px;
  }
  .right_arrow_round_home img, .left_arrow_round_home img  {
    width: 30px;
    height: 30px;
  }
  .right_arrow_round_home, .left_arrow_round_home {
    padding: 18px;
  }
  .onboard_here_arrow_automate {
    width: 80px;
    top:0;
  }
  .video_prompt .comapny_name{
    font-size: 32px;
  }
  .slack_feature_icon_onboarding, .teams_feature_icon_onboarding, .chat_feature_icon_onboarding{
    width: 38px;
    height: 38px;
  }
  .g2_logo_right {
    width: 100px;
  }
  .capterra_logo_left {
    width: 250px;
  }
  .card-box-home-main-container button {
    border-radius: 50px;
  }
  .resolution_automate_first.home_page_new_workativ .container{
    max-width: 80% !important;
  }
  .card-automate-box-top-header img, .card-assistant-box-top-header img {
    width: 104px;
    height: 104px;
  }
  .card_automate_box_paragraph_two{
    padding-bottom: 64px !important;
  }
  .beta-button .sign_up_form button, .beta-button .sign_up_form input {
    height: 84px;
  }
  .beta-button .sign_up_form button{
    font-size: 28px;
    border-radius: 0px 50px 50px 0px;
  }
  .beta-button .sign_up_form input {
    font-size: 28px;
    border-radius: 50px 0px 0px 50px;
  }
  .res_header.sticky{
    padding: 50px 0;
  }
  .testimonial_reviewer_image_landing_page {
    width: 200px;
  }
  .it_support_hammer_logo {
    width: 173px;
  }
  .rating_star_image {
    width: 30px;
    height: 30px;
  }
  .market_main_wrapper .nocode_wrapper h4 {
    font-size: 40px;
  }
  .market_main_wrapper .nocode_wrapper button { 
    font-size: 30px;
  }
  .min-width-400{
    min-width: 400px;
  }
}

@media screen and (min-width: 2561px) and  (max-width: 3440px)  {
 .resolution_automate_first.home_page_new_workativ .container{
    max-width: 80% !important;
  }
  .beta-button .sign_up_form button, .beta-button .sign_up_form input {
    height: 94px;
  }
  .beta-button .sign_up_form button{
    font-size: 34px;
    border-radius: 0px 50px 50px 0px;
  }
  .beta-button .sign_up_form input {
    font-size: 34px;
    border-radius: 50px 0px 0px 50px;
  }
  .form_below_content.signup_cta span {
    font-size: 34px;
  }
  .card-box-home-main-container button {
    border-radius: 50px;
  }
  .card-automate-box-top-header img, .card-assistant-box-top-header img {
    width: 124px;
    height: 124px;
  }
  .card_automate_box_paragraph_two{
    padding-bottom: 80px !important;
  }
  .g2_logo_right {
    width: 130px;
  }
  .capterra_logo_left {
    width: 300px;
  }
  .rating_star_image {
    width: 34px;
    height: 34px;
  }
  .cards_features .whitepaper_cards .card__image img {
    width: 124px;
    height: 124px;
  }
  .col-md-6.feature_page_content_left .coming_soon{
    font-size: 36px;
  }
  .chat_omnichannel_icon {
    width: 70px !important;
    height: 70px !important;
  }
  .feature_ind_chnl_con_right {
    width: 100% !important
  }

  .helpdesk_solutions .suprcharging_benefits .cogni_workspace_content img {
    width: 114px;
    height: 114px;
  }
  .right_arrow_round_home img, .left_arrow_round_home img  {
    width: 40px;
    height: 40px;
  }
  .right_arrow_round_home, .left_arrow_round_home {
    padding: 30px;
  }
  .support_switch_heading,.support_switch_heading_click  {
    border-radius: 40px;
    padding: 7px 30px 7px;
  }
  .testimonial_reviewer_image_landing_page {
    width: 300px;
  }
  .it_support_hammer_logo {
    width: 313px;
  }
  .goto_case_study_button_redirection{
    border-radius: 50px;
  }
  .all_apps_sec_header_assist{
    min-height: 100px !important;
  }
  .apps_landing_cards_wrapper ul li img{
    width: 65px;
    height: 65px;
  }
  .paid-landing-page-icon-list{
    display: flex;
    align-items: center;
    height: 100px;
  }
  .video_sub_cont_upt{
   height: 950px;
  }
  .slack_feature_icon_onboarding, .teams_feature_icon_onboarding, .chat_feature_icon_onboarding{
    width: 50px;
    height: 50px;
  }
  .button_last button {
    font-size: 30px;
    width: 370px;
    height: 68px;
  }
  .market_main_wrapper .nocode_wrapper h4 {
    font-size: 46px;
  }
  .market_main_wrapper .nocode_wrapper button { 
    font-size: 36px;
  }
}
@media screen and (min-width: 1681px) and  (max-width: 1920px)  {
  .webinar-upcoming {
    top: -27px;
  }
}
@media screen and (min-width: 1441px) and  (max-width: 1680px)  {
  .webinar-upcoming {
    top: -27px;
  }
}
@media screen and (min-width: 761px) and  (max-width: 1024px)  {
  .webinar-upcoming {
    top: -27px;
  }
}
@media (max-width: 1750px) {
 .pricing-assist-main-container-upt{
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)) !important;
 }
}
@media (max-width: 1250px) {
  .pricing-assist-main-container-upt {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px; /* Gap between items */
  }

  .pricing-card-box-assist {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(45% - 2.5px);
    max-width: calc(45% - 2.5px);
  }
}
@media (max-width: 520px) {
.pricing-assist-main-container-upt{
  display:grid !important;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
}

.pricing-card-box-assist {
  max-width: 100%;
}
.cookie-container{
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.cookie-box-left-button{
  flex-direction: column;
  width: 100%;
}
.cookie-button-preference-accept{
  width: 100%;
}
.preference-popup{
  width: 100%;
  height: 80%;
  border: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: none;
}
.list-box-preference{
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.confirm-my-choice{
  width: -webkit-fill-available;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
}
.active-button-preference, .button-preference{
  width: 100%;
}
.content-box-preference, .button-box-preference {
  flex: 0; 
}

}